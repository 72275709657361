import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import "./index.scss";
import ViewRequest from "../ViewRequest";
import EditRequest from "../EditRequest";
import MyRequestList from "../../components/MyRequestList";
import SubNavBar from "../../components/SubNavBar";
import RequestApprove from "../../components/RequestApprove";
import ForbiddenPage from "../../components/ForbiddenPage";

function MyRequests() {
  const { user } = useSelector((state) => state.auth);
  return (
    <div>
      <SubNavBar />
      <Switch>
        <RedirectRoute exact path="/app/client/myrequests" />
        <Route path="/app/client/myrequests/all-requests">
          <MyRequestList requestStatusSection="all-requests" />
        </Route>
        <Route path="/app/client/myrequests/open-requests">
          <MyRequestList requestStatusSection="open-requests" />
        </Route>
        <Route path="/app/client/myrequests/wip">
          <MyRequestList requestStatusSection="wip" />
        </Route>
        <Route path="/app/client/myrequests/needs-information">
          <MyRequestList requestStatusSection="needs-information" />
        </Route>
        <Route path="/app/client/myrequests/prioritized">
          <MyRequestList requestStatusSection="prioritized" />
        </Route>
        <Route path="/app/client/myrequests/needs-approval">
          <MyRequestList requestStatusSection="needs-approval" />
        </Route>
        <Route path="/app/client/myrequests/closed">
          <MyRequestList requestStatusSection="closed" />
        </Route>
        <Route path="/app/client/myrequests/yourdrafts">
          {user?.group_id === 4
            ? <ForbiddenPage />
            : <MyRequestList requestStatusSection="yourdrafts" />}
        </Route>
        <Route
          path="/app/client/myrequests/viewrequest/:id"
          component={ViewRequest}
        />
        <Route
          path="/app/client/myrequests/editrequest/:id"
          component={EditRequest}
        />
        <Route
          path="/app/client/myrequests/review-submit/:reviewFor"
          component={RequestApprove}
        />
      </Switch>
    </div>
  );
}

function RedirectRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: "/app/client/myrequests/all-requests",
            state: { from: location },
          }}
        />
      )}
    />
  );
}

export default MyRequests;
