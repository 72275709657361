import React from "react";
import {  
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./index.scss";
import DesignerSubNavBar from "../../components/DesignerSubNavBar";
import ClientsRequestList from "../../components/ClientsRequestList";
import ClientRequestNeedsApprovalList from "../../components/ClientRequestNeedsApprovalList";
import Reports from "../Reports";
import ViewRequestDesigner from "../ViewRequestDesigner";
import PriortizeRequestList from "../../components/PriortizeRequestList";
function ClientRequests() {
  return (
    <div>
      <DesignerSubNavBar />
      <Switch>
        <RedirectRoute exact path="/app/designer/allclients/clientrequests" />
        <Route path="/app/designer/allclients/clientrequests/all-requests">
          <ClientsRequestList requestStatusSection="all-requests" />
        </Route>
        <Route path="/app/designer/allclients/clientrequests/open-requests">
          <ClientsRequestList requestStatusSection="open-requests" />
        </Route>
        <Route path="/app/designer/allclients/clientrequests/wip">
          <ClientsRequestList requestStatusSection="wip" />
        </Route>
        <Route path="/app/designer/allclients/clientrequests/needs-information">
          <ClientsRequestList requestStatusSection="needs-information" />
        </Route>
        <Route path="/app/designer/allclients/clientrequests/closed">
          <ClientsRequestList requestStatusSection="closed" />
        </Route>
        <Route path="/app/designer/allclients/clientrequests/priortize">
          <PriortizeRequestList />
        </Route>
        <Route path="/app/designer/allclients/clientrequests/needs-approval">
          <ClientRequestNeedsApprovalList />
        </Route>
        <Route path="/app/designer/allclients/clientrequests/reports">
          <Reports />
        </Route>
        <Route
          path="/app/designer/allclients/clientrequests/viewrequest/:id"
          component={ViewRequestDesigner}
        />
      </Switch>
    </div>
  );
}

function RedirectRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: "/app/designer/allclients/clientrequests/all-requests",
            state: { from: location },
          }}
        />
      )}
    />
  );
}

export default ClientRequests;
