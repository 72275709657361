import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import moment from 'moment'
import { actions as AuthActions } from "../../../redux/reducers/MyAccountReducer";
import LoaderComponent from "../../../components/Loader";
import { ReactBootstrapTable } from '../../ReusableComponents';
import { capitalizeFirstLetter } from '../../../Utils/utils';

const currentSeletedRowRow = (key) => key.hasOwnProperty("order");

function headerFormatter(column, colIndex, p) {
    const { sortElement, filterElement } = p;
    return (
        <div className={`${currentSeletedRowRow(sortElement.props) ? "sort-active" : ""}`}>
            {filterElement}
            <span >{column.text}</span>
            {sortElement}
        </div>
    );
}

function MySubscription() {
    const dispatch = useDispatch();
    const [cookies] = useCookies(["clientAccessToken"]);
    const {
        getSubscriptionLoading,
        getSubscriptionResponse,

    } = (useSelector(state => state?.myAccountReducer) || {});
    const [show, setShow] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [sortState, setSortState] = useState({ dataField: "plan_start", order: "desc" })
    const [subscriptionId, setSubscriptionId] = useState("");

    const tableColumns = [
        {
            dataField: "name",
            text: "Subscription",
            sort: true,
            headerFormatter,
            formatter: (cellContent, row) => {
                return <div>{row.plan_name}</div>
            }
        },
        {
            dataField: "status",
            text: "Status",
            sort: true,
            headerFormatter,
            formatter: (cellContent, row) => {
                return <div>{capitalizeFirstLetter(row.status)}</div>
            }
        },
        {
            dataField: "price",
            text: "Inital Amount",
            sort: true,
            headerFormatter,
            formatter: (cellContent, row) => {
                return row.price ? <div>${row.price}</div> : ""
            }
        },
        {
            dataField: "plan_start",
            text: "Start Date",
            sort: true,
            headerFormatter,
            formatter: (cellContent, row) => {
                return row.plan_start ? <div>{moment(row.plan_start).format('MMM DD, YYYY')}</div> : ""
            }
        },
        {
            dataField: "plan_end",
            text: "End Date",
            sort: true,
            headerFormatter,
            formatter: (cellContent, row) => {
                return row.plan_end ? <div>{moment(row.plan_end).format('MMM DD, YYYY')}</div> : ""
            }
        },
        {
            dataField: "action",
            text: "Action",
            formatter: (cellContent, row) => {

                return (
                    <div className='subaction-buttons'>
                        {<button
                            className={row.status == "active" && row.is_cancelable == 0 ? "btn btn-danger btn-xs cancel-tooltip-btn":"btn btn-danger btn-xs"}
                            onClick={() => {
                                setSubscriptionId(row.id);
                                handleToggleModel();
                            }}
                            disabled={row.status === "active" && row.is_cancelable == 1 ? false : true}>
                            Cancel
                            <span>You can not cancel the subscription now as the renewal is in progress</span>
                        </button>}

                    </div>

                );
            },
        },
    ];

    const handleToggleModel = () => {
        setShow(!show);
    }

    return (
        <div>
            <LoaderComponent showhide={getSubscriptionLoading} />
            <div className={`image-popup-outer ${show ? "show is-open-popup" : ""}`}>
                <div className="image-popup-inner">
                    <span
                        className="close-btn"
                        onClick={(e) => {
                            handleToggleModel();
                        }}
                    ></span>
                    <div className="image-box">
                        <p>Are you sure you want to cancel</p>
                        <div className="buttons">
                            <button className="common-button orange-btn" onClick={() => {
                                handleToggleModel();
                            }}>NO</button>
                            <button className="common-button border-btn" onClick={() => {
                                handleToggleModel();
                                dispatch(AuthActions.cancelSubscription({ id: subscriptionId, clientAccessToken: cookies.clientAccessToken }));
                            }} >YES</button>
                        </div>
                    </div>
                </div>
            </div>
            <ReactBootstrapTable
                listLoading={false}
                entities={(getSubscriptionResponse?.data?.dataTable?.data || [])}
                columns={tableColumns}
                paginationOptions={{
                    custom: true,
                    page: currentPage,
                    paginationStartIndex: 1,
                    sizePerPage: 10,
                    totalSize: getSubscriptionResponse?.data?.dataTable?.recordsTotal
                }}
                handleTableChange={(a, b) => {
                    setSortState({ dataField: b.sortField, order: b.sortOrder })
                    setCurrentPage(b.page)
                    dispatch(AuthActions.getSubscription({ tableObj: { ...b, length: b.sizePerPage }, clientAccessToken: cookies.clientAccessToken }));
                }}
                sortState={sortState}
            />
        </div>
    )
}
export default MySubscription



{/* <div className="select-plan">
            <h3>Select Plan</h3>
             <ul className="listing-block">
               <li>
                 <div className="inner-plan-block">
                 <div className="radiobutton">
                 <input type="radio" id="test1" name="radio-group" checked></input>
                  <label for="test1"></label>
                 </div>
                 <h5>Plan Number One</h5>
                 <strong><sup>$</sup>25</strong>
                 <span>One Time</span>
                 <p>Lorem Ipsum is simply dummy text of the printing and other typesetting industry.</p>
                 <button type="button" className="btn btn-b-blue">Select Plan</button>
                 </div>
               </li>

               <li>
               <div className="inner-plan-block">
                 <div className="radiobutton">
                 <input type="radio" id="test2" name="radio-group" checked></input>
                  <label for="test2"></label>
                 </div>
                 <h5>Plan Number Two</h5>
                 <strong><sup>$</sup>995</strong>
                 <span>Monthly</span>
                 <p>Lorem Ipsum is simply dummy text of the printing and other typesetting industry.</p>
                 <button type="button" className="btn btn-b-blue">Select Plan</button>
                 </div>
               </li>

               <li>
               <div className="inner-plan-block">
                 <div className="radiobutton">
                 <input type="radio" id="test3" name="radio-group" checked></input>
                  <label for="test3"></label>
                 </div>
                 <h5>Plan Number Three</h5>
                 <strong><sup>$</sup>999</strong>
                 <span>Monthly</span>
                 <p>Lorem Ipsum is simply dummy text of the printing and other typesetting industry.</p>
                 <button type="button" className="btn btn-b-blue">Select Plan</button>
                 </div>
               </li>
             </ul>
           </div> */}