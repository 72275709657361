/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

import { actions as AuthActions } from "../../redux/reducers/AuthReducer";
import "./index.scss";
import LoginForm from "../../components/LoginForm";
import LoaderComponent from "../../components/Loader";

function Login() {
  const history = useHistory();
  const location  = useLocation();
  const dispatch = useDispatch();
  const {
    loginLoading,
    loginSuccess,
    loginFailed,
    clientAccessToken,
    expire_in,
    user,
    redirect,
    logoutSuccess,
  } = useSelector((state) => state.auth);
  const [cookies, setCookie,removeCookie] = useCookies(["clientAccessToken"]);

  //when navigating from admin for the first time
  //1st step 
useEffect(() => {
  let clientAccessTokenFromURL = new URLSearchParams(location.search).get("clientAccessToken");
  let redirectUrl = new URLSearchParams(location.search).get("redirect");
  if (clientAccessTokenFromURL) {
    dispatch(AuthActions.getUserDetails(clientAccessTokenFromURL,redirectUrl));
    removeCookie("clientAccessToken", { path: "/" });
    setCookie("clientAccessToken", clientAccessTokenFromURL, {path: "/"});
  } else {
    history.push("/login");
  }
}, [location.search, dispatch, history, setCookie, removeCookie]);
//2ndstep
useEffect(()=>{
  if(loginSuccess && redirect){
    history.push(redirect);
  }else if(loginSuccess){
    if (user.group_id == 3) {
      history.push("/app/designer/myrequest");
    }else{
      history.push("/app/client/myrequests/all-requests");
    }
  }
  dispatch(AuthActions.clearLoginFlags());
},[loginSuccess,redirect])

//(when navigating from admin for the first time )done

//when navigationg from main.js when cookies.clientAccessToken
useEffect(()=>{
if(clientAccessToken){
  dispatch(AuthActions.getUserDetails(clientAccessToken));
  removeCookie('clientAccessToken', { path : '/' });
  setCookie("clientAccessToken", clientAccessToken, {
    path: "/",
  });
  if (user.group_id == 3) {
    history.push("/app/designer/myrequest");
  }else{
    if(redirect){
      history.push(redirect);
    }else{
      history.push("/app/client/myrequests/all-requests");
    }
  }
  
  dispatch(AuthActions.clearAccessToken());
}
},[clientAccessToken,history,dispatch, setCookie,removeCookie,redirect])

  const handleLoginSuccess = useCallback(
    (clientAccessToken, expire_in, group_id) => {
      let seconds = Math.round(new Date() / 1000);
      seconds = seconds + expire_in;
      let tokenExpiryDate = new Date(seconds * 1000);
      setCookie("clientAccessToken", clientAccessToken, {
        path: "/",
        expires: tokenExpiryDate,
      });
      dispatch(AuthActions.clearLoginFlags());
      // eslint-disable-next-line eqeqeq
      if (user.group_id == 3) {
        history.push("/app/designer/myrequest");
      } else {
        history.push("/app/client/myrequests/all-requests");
      }
    },
    [dispatch, history, setCookie, user]
  );

  useEffect(() => {
    if (
      !loginLoading &&
      loginSuccess &&
      !loginFailed &&
      clientAccessToken !== "" &&
      expire_in &&
      user.group_id
    ) {
      handleLoginSuccess(clientAccessToken, expire_in, user.group_id);
    }
  }, [
    loginLoading,
    loginSuccess,
    loginFailed,
    clientAccessToken,
    expire_in,
    handleLoginSuccess,
  ]);

  const signInClicked = (email, password) => {
    dispatch(AuthActions.login(email, password));
  };
  return (
    <div>
      <LoaderComponent showhide={loginLoading} />
      <div className="login-wrapper">
        <div className="login-left">
          <LoginForm
            signInClicked={(email, password) => signInClicked(email, password)}
          />
        </div>
        <div className="login-right">
          <div className="login-title">
            <h2>
              Creating Beautiful<span>Kitchen Designs</span>
            </h2>
            <img
              src={require("../../assets/images/cabinet-img02.svg")}
              alt="Kitchen Cabinet Design"
            />
          </div>
          <div className="kitchen-cabinet">
            <img
              src={require("../../assets/images/kitchen-cabinet.png")}
              alt="Kitchen Cabinet Design"
            />
          </div>
        </div>
      </div>

      <section className="footer-container">
	<div className="footer-bottom">
		<div className="container">
			<div className="footer-bottom-outer">
				<div className="footer-column footer-col1">
					<p className="footer-site-info">COPYRIGHT © 2017-2023, KITCHEN365 INC. ALL RIGHTS RESERVED.</p>
				</div>
				<div className="footer-column footer-col2">
					<div className="footer-nav">
						<ul>
            <li><a href="https://www.kitchen365.com/digital-marketing/">Digital Marketing</a></li>
            <li><a href="https://www.kitchen365.com/kitchen-design/">Design Services</a></li>
							<li><a href="https://www.kitchen365.com/faqs/">FAQs</a></li>
							<li><a href="https://www.kitchen365.com/blog/">Blog</a></li>
							{/* <li><a href="https://www.kitchen365.com/resources/">Resources</a></li> */}
							<li><a href="https://www.kitchen365.com/careers/">Careers</a></li>
							<li><a href="https://www.kitchen365.com/platform-sign-up/">Marketing Platform Sign Up</a></li>
							<li><a href="https://www.kitchen365.com/ecommerce-platform-sign-up/">Ecommerce Platform Sign Up</a></li>
							{/* <li><a href="https://www.kitchen365.com/kbis/">KBIS 2022</a></li> */}
						</ul>
					</div>
				</div>
				<div className="footer-column footer-col3">
					<ul className="social-icons">
						<li>
							<a href="https://www.facebook.com/kitchen365com"><img src={require("../../assets/images/facebook-img.svg")} alt="Facebook" /><img src={require("../../assets/images/facebook-img-h.svg")} alt="Facebook" className="hover-img" /></a>
						</li>
						<li>
							<a href="https://www.linkedin.com/company/kitchen365com/"><img src={require("../../assets/images/linkedin-img.svg")} alt="Linkedin" /><img src={require("../../assets/images/linkedin-img-h.svg")} alt="Linkedin" className="hover-img" /></a>
						</li>
						<li className="twitter-img">
							<a href="https://twitter.com/kitchen365com"><img src={require("../../assets/images/twitter-img.svg")} alt="Twitter" /><img src={require("../../assets/images/twitter-img-h.svg")} alt="Twitter" className="hover-img" /></a>
						</li>
						<li className="instagram-img">
							<a href="https://www.instagram.com/kitchen.365/"><img src={require("../../assets/images/instagram-img.svg")} alt="Instagram" /><img src={require("../../assets/images/instagram-img-h.svg")} alt="Instagram" className="hover-img" /></a>
						</li>
						<li>
							<a href="https://www.pinterest.com/kitchen365/_created/"><img src={require("../../assets/images/pinterest-img.svg")} alt="Pitenrest" /><img src={require("../../assets/images/pinterest-img-h.svg")} alt="Pitenrest" className="hover-img" /></a>
						</li>
						<li>
							<a href="https://www.youtube.com/channel/UCalurDhpLOdbj-PlTpadcuQ"><img src={require("../../assets/images/youtube-img.svg")} alt="Youtube" /><img src={require("../../assets/images/youtube-img-h.svg")} alt="Youtube" className="hover-img" /></a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>
    </div>
  );
}

export default Login;
