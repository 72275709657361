import { put, takeLatest, call, all } from "redux-saga/effects";
import { getApiToken } from "../apis";
import apiUrl from "../../config/urls";

export const actionTypes = {
  GET_CLIENT_LIST: "GET_CLIENT_LIST",
  SET_CLIENT_DATA: "SET_CLIENT_DATA",
  GET_CLIENT_LOADING: "GET_CLIENT_LOADING",
  GET_CLIENT_SUCCESS: "GET_CLIENT_SUCCESS",
  GET_CLIENT_FAILED: "GET_CLIENT_FAILED",
};
const initialState = {
  clientsList: undefined,
  clientLoading: false,
  clientSuccess: false,
  clientFailed: false,
  clientListErrorMessage: "Something went wrong",
};
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CLIENT_LIST: {
      return {
        ...state,
      };
    }
    case actionTypes.SET_CLIENT_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        clientsList: data,
      };
    }
    case actionTypes.GET_CLIENT_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        clientSuccess: flag,
      };
    }

    case actionTypes.GET_CLIENT_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        clientLoading: flag,
      };
    }

    case actionTypes.GET_CLIENT_FAILED: {
      const { flag, errorMessage } = action.payload;
      return {
        ...state,
        clientFailed: flag,
        clientListErrorMessage: errorMessage,
      };
    }
    default: {
      return state;
    }
  }
};
export const actions = {
  getClientList: (clientAccessToken) => ({
    type: actionTypes.GET_CLIENT_LIST,
    payload: { clientAccessToken },
  }),
  setClientData: (data) => ({
    type: actionTypes.SET_CLIENT_DATA,
    payload: { data },
  }),
  setClientLoading: (flag) => ({
    type: actionTypes.GET_CLIENT_LOADING,
    payload: { flag },
  }),
  setClientSuccess: (flag) => ({
    type: actionTypes.GET_CLIENT_SUCCESS,
    payload: { flag },
  }),
  setClientFailed: (flag, errorMessage = "Something went wrong") => ({
    type: actionTypes.GET_CLIENT_FAILED,
    payload: { flag, errorMessage },
  }),
};
export function* saga() {
  yield takeLatest(actionTypes.GET_CLIENT_LIST, clientListSaga);
}

function* clientListSaga(action) {
  const { clientAccessToken } = action.payload;
  yield all([
    put(actions.setClientLoading(true)),
    put(actions.setClientSuccess(false)),
    put(actions.setClientFailed(false)),
  ]);
  try {
    const response = yield call(
      getApiToken,
      apiUrl("GET_ALLCLIENTS_URL"),
      null,
      clientAccessToken
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.setClientData(responseData.data)),
          put(actions.setClientLoading(false)),
          put(actions.setClientSuccess(true)),
          put(actions.setClientFailed(false)),
        ]);
      } else {
        yield all([
          put(actions.setClientLoading(false)),
          put(actions.setClientSuccess(false)),
          put(actions.setClientFailed(true)),
        ]);
      }
    } else if (response && response.status === 422) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.message) {
        yield all([
          put(actions.setClientLoading(false)),
          put(actions.setClientFailed(true, responseData.message)),
          put(actions.setClientSuccess(false)),
        ]);
      } else {
        yield all([
          put(actions.setClientLoading(false)),
          put(actions.setClientFailed(true)),
          put(actions.setClientSuccess(false)),
        ]);
      }
    }
  } catch (error) {
    yield all([
      put(actions.setClientLoading(false)),
      put(actions.setClientSuccess(false)),
      put(actions.setClientFailed(true)),
    ]);
  }
}
