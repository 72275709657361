import { put, takeLatest, call, all, select } from "redux-saga/effects";
import queryString from "query-string";
import ShortUniqueId from "short-unique-id";
import { postApiToken, putApiToken, getApiTokenSlash, getApiToken, deleteApiTokenSlash } from "../apis";
import apiUrl from "../../config/urls";
import { actions as generalActions } from "./GeneralReducer";
// import FileSaver from 'file-saver';

const uid = new ShortUniqueId();

export const actionTypes = {
    // account information start
    CHANGE_PASSWORD: "CHANGE_PASSWORD", // seaction change password start
    CHANGE_PASSWORD_LOADIING: "CHANGE_PASSWORD_LOADIING",
    CHANGE_PASSWORD_SUCESSS: "CHANGE_PASSWORD_SUCESSS",
    CHANGE_PASSWORD_FAILED: "CHANGE_PASSWORD_FAILED",
    CLEAR_CHANGE_PASSWORD_FLAGS: "CLEAR_CHANGE_PASSWORD_FLAGS",// seaction change password end
    ACCOUNT_INFO_UPDATE: "ACCOUNT_INFO_UPDATE",
    ACCOUNT_INFO_UPDATE_LOADIING: "ACCOUNT_INFO_UPDATE_LOADIING",
    ACCOUNT_INFO_UPDATE_SUCESSS: "ACCOUNT_INFO_UPDATE_SUCESSS",
    ACCOUNT_INFO_UPDATE_FAILED: "ACCOUNT_INFO_UPDATE_FAILED",
    CLEAR_ACCOUNT_INFO_UPDATE_FLAGS: "CLEAR_ACCOUNT_INFO_UPDATE_FLAGS",
    GET_ACCOUNT_INFO: "GET_ACCOUNT_INFO",
    GET_ACCOUNT_INFO_LOADIING: "GET_ACCOUNT_INFO_LOADIING",
    GET_ACCOUNT_INFO_SUCESSS: "GET_ACCOUNT_INFO_SUCESSS",
    GET_ACCOUNT_INFO_FAILED: "GET_ACCOUNT_INFO_FAILED",
    CLEAR_GET_ACCOUNT_INFO_FLAGS: "CLEAR_GET_ACCOUNT_INFO_FLAGS", // account information end
    ADD_CLIENT_ADDRESS: "ADD_CLIENT_ADDRESS",// address book start
    ADD_CLIENT_ADDRESS_LOADIING: "ADD_CLIENT_ADDRESS_LOADIING",
    ADD_CLIENT_ADDRESS_SUCESSS: "ADD_CLIENT_ADDRESS_SUCESSS",
    ADD_CLIENT_ADDRESS_FAILED: "ADD_CLIENT_ADDRESS_FAILED",
    CLEAR_ADD_CLIENT_ADDRESS_FLAGS: "CLEAR_ADD_CLIENT_ADDRESS_FLAGS",
    GET_CLIENT_ADDRESS: "GET_CLIENT_ADDRESS",
    GET_CLIENT_ADDRESS_LOADIING: "GET_CLIENT_ADDRESS_LOADIING",
    GET_CLIENT_ADDRESS_SUCESSS: "GET_CLIENT_ADDRESS_SUCESSS",
    GET_CLIENT_ADDRESS_FAILED: "GET_CLIENT_ADDRESS_FAILED",
    CLEAR_GET_CLIENT_ADDRESS_FLAGS: "CLEAR_GET_CLIENT_ADDRESS_FLAGS",
    DELETE_CLIENT_ADDRESS: "DELETE_CLIENT_ADDRESS",
    DELETE_CLIENT_ADDRESS_LOADIING: "DELETE_CLIENT_ADDRESS_LOADIING",
    DELETE_CLIENT_ADDRESS_SUCESSS: "DELETE_CLIENT_ADDRESS_SUCESSS",
    DELETE_CLIENT_ADDRESS_FAILED: "DELETE_CLIENT_ADDRESS_FAILED",
    CLEAR_DELETE_CLIENT_ADDRESS_FLAGS: "CLEAR_DELETE_CLIENT_ADDRESS_FLAGS",
    GET_COUNTRY_LIST: "GET_COUNTRY_LIST",
    GET_COUNTRY_LIST_LOADIING: "GET_COUNTRY_LIST_LOADIING",
    GET_COUNTRY_LIST_SUCESSS: "GET_COUNTRY_LIST_SUCESSS",
    GET_COUNTRY_LIST_FAILED: "GET_COUNTRY_LIST_FAILED",
    CLEAR_GET_COUNTRY_LIST_FLAGS: "CLEAR_GET_COUNTRY_LIST_FLAGS",
    EDIT_CLIENT_ADDRESS: "EDIT_CLIENT_ADDRESS",
    EDIT_CLIENT_ADDRESS_LOADIING: "EDIT_CLIENT_ADDRESS_LOADIING",
    EDIT_CLIENT_ADDRESS_SUCESSS: "EDIT_CLIENT_ADDRESS_SUCESSS",
    EDIT_CLIENT_ADDRESS_FAILED: "EDIT_CLIENT_ADDRESS_FAILED",
    CLEAR_EDIT_CLIENT_ADDRESS_FLAGS: "CLEAR_EDIT_CLIENT_ADDRESS_FLAGS",
    UPDATE_CLIENT_ADDRESS: "UPDATE_CLIENT_ADDRESS",
    UPDATE_CLIENT_ADDRESS_LOADIING: "UPDATE_CLIENT_ADDRESS_LOADIING",
    UPDATE_CLIENT_ADDRESS_SUCESSS: "UPDATE_CLIENT_ADDRESS_SUCESSS",
    UPDATE_CLIENT_ADDRESS_FAILED: "UPDATE_CLIENT_ADDRESS_FAILED",
    CLEAR_UPDATE_CLIENT_ADDRESS_FLAGS: "CLEAR_UPDATE_CLIENT_ADDRESS_FLAGS",
    SET_DEFAULT_ADDRESS: "SET_DEFAULT_ADDRESS",
    SET_DEFAULT_ADDRESS_LOADIING: "SET_DEFAULT_ADDRESS_LOADIING",
    SET_DEFAULT_ADDRESS_SUCESSS: "SET_DEFAULT_ADDRESS_SUCESSS",
    SET_DEFAULT_ADDRESS_FAILED: "SET_DEFAULT_ADDRESS_FAILED",
    CLEAR_SET_DEFAULT_ADDRESS_FLAGS: "CLEAR_SET_DEFAULT_ADDRESS_FLAGS", // address book end

    GET_SUBSCRIPTION: "GET_SUBSCRIPTION",
    GET_SUBSCRIPTION_LOADIING: "GET_SUBSCRIPTION_LOADIING",
    GET_SUBSCRIPTION_SUCESSS: "GET_SUBSCRIPTION_SUCESSS",
    GET_SUBSCRIPTION_FAILED: "GET_SUBSCRIPTION_FAILED",
    CLEAR_GET_SUBSCRIPTION_FLAGS: "CLEAR_GET_SUBSCRIPTION_FLAGS",

    CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
    CANCEL_SUBSCRIPTION_LOADIING: "CANCEL_SUBSCRIPTION_LOADIING",
    CANCEL_SUBSCRIPTION_SUCESSS: "CANCEL_SUBSCRIPTION_SUCESSS",
    CANCEL_SUBSCRIPTION_FAILED: "CANCEL_SUBSCRIPTION_FAILED",
    CLEAR_CANCEL_SUBSCRIPTION_FLAGS: "CLEAR_CANCEL_SUBSCRIPTION_FLAGS",


    VIEW_INVOICE: "VIEW_INVOICE",
    VIEW_INVOICE_LOADIING: "VIEW_INVOICE_LOADIING",
    VIEW_INVOICE_SUCESSS: "VIEW_INVOICE_SUCESSS",
    VIEW_INVOICE_FAILED: "VIEW_INVOICE_FAILED",
    CLEAR_VIEW_INVOICE_FLAGS: "CLEAR_VIEW_INVOICE_FLAGS",

    GET_INVOICES: "GET_INVOICES",
    GET_INVOICES_LOADIING: "GET_INVOICES_LOADIING",
    GET_INVOICES_SUCESSS: "GET_INVOICES_SUCESSS",
    GET_INVOICES_FAILED: "GET_INVOICES_FAILED",
    CLEAR_GET_INVOICES_FLAGS: "CLEAR_GET_INVOICES_FLAGS",

    GENERATE_INVOICE: "GENERATE_INVOICE",
    GENERATE_INVOICE_LOADIING: "GENERATE_INVOICE_LOADIING",
    GENERATE_INVOICE_SUCESSS: "GENERATE_INVOICE_SUCESSS",
    GENERATE_INVOICE_FAILED: "GENERATE_INVOICE_FAILED",
    CLEAR_GENERATE_INVOICE_FLAGS: "CLEAR_GENERATE_INVOICE_FLAGS",


    SAVED_CARDS: "SAVED_CARDS",
    SAVED_CARDS_LOADIING: "SAVED_CARDS_LOADIING",
    SAVED_CARDS_SUCESSS: "SAVED_CARDS_SUCESSS",
    SAVED_CARDS_FAILED: "SAVED_CARDS_FAILED",
    CLEAR_SAVED_CARDS_FLAGS: "CLEAR_SAVED_CARDS_FLAGS",
    DELETE_CARDS: "DELETE_CARDS",
    DELETE_CARDS_LOADIING: "DELETE_CARDS_LOADIING",
    DELETE_CARDS_SUCESSS: "DELETE_CARDS_SUCESSS",
    DELETE_CARDS_FAILED: "DELETE_CARDS_FAILED",
    CLEAR_DELETE_CARDS_FLAGS: "CLEAR_DELETE_CARDS_FLAGS",
    GET_ACCOUNT_SPOC_INFO: "GET_ACCOUNT_SPOC_INFO",
    SET_ACCOUNT_SPOC_INFO: "SET_ACCOUNT_SPOC_INFO",
    API_LOADING: "API_LOADING",
};

const initialState = {
    // account information start
    setErrorMsgForChangePassword: "",// seaction change password start
    changePasswordResponse: '',
    changePasswordLoading: false,
    setSuccessForChangePassword: false,
    changePasswordFailed: false,// seaction change password end
    setErrorMsgForAccountInfoUpdate: "",
    accountInfoUpdateResponse: '',
    accountInfoUpdateLoading: false,
    setSuccessForAccountInfoUpdate: false,
    accountInfoUpdateFailed: false,
    setErrorMsgForGetAccountInfo: "",
    getAccountInfoResponse: '',
    getAccountInfoLoading: false,
    setSuccessForGetAccountInfo: false,
    getAccountInfoFailed: false, // account information end
    setErrorMsgForAddClientAddress: "", // address book start
    addClientAddressResponse: '',
    addClientAddressLoading: false,
    setSuccessForAddClientAddress: false,
    addClientAddressFailed: false,
    setErrorMsgForGetClientAddress: "",
    getClientAddressResponse: '',
    getClientAddressLoading: false,
    setSuccessForGetClientAddress: false,
    getClientAddressFailed: false,
    setErrorMsgForDeleteClientAddress: "",
    deleteClientAddressResponse: '',
    deleteClientAddressLoading: false,
    setSuccessForDeleteClientAddress: false,
    deleteClientAddressFailed: false,
    setErrorMsgForGetCountryList: "",
    getCountryListResponse: '',
    getCountryListLoading: false,
    setSuccessForGetCountryList: false,
    getCountryListFailed: false,
    setErrorMsgForEditClientAddress: "",
    editClientAddressResponse: '',
    editClientAddressLoading: false,
    setSuccessForEditClientAddress: false,
    editClientAddressFailed: false,
    setErrorMsgForUpdateClientAddress: "",
    updateClientAddressResponse: '',
    updateClientAddressLoading: false,
    setSuccessForUpdateClientAddress: false,
    updateClientAddressFailed: false,
    setErrorMsgForSetDefaultAddress: "",
    setDefaultAddressResponse: '',
    setDefaultAddressLoading: false,
    setSuccessForSetDefaultAddress: false,
    setDefaultAddressFailed: false,// address book end


    setErrorMsgForGetSubscription: "",
    getSubscriptionResponse: '',
    getSubscriptionLoading: false,
    setSuccessForGetSubscription: false,
    getSubscriptionFailed: false,

    setErrorMsgForCancelSubscription: "",
    cancelSubscriptionResponse: '',
    cancelSubscriptionLoading: false,
    setSuccessForCancelSubscription: false,
    cancelSubscriptionFailed: false,


    setErrorMsgForViewInvoice: "",
    viewInvoiceResponse: '',
    viewInvoiceLoading: false,
    setSuccessForViewInvoice: false,
    viewInvoiceFailed: false,

    setErrorMsgForGetInvoices: "",
    getInvoicesResponse: '',
    getInvoicesLoading: false,
    setSuccessForGetInvoices: false,
    getInvoicesFailed: false,


    setErrorMsgForGenerateInvoice: "",
    generateInvoiceResponse: '',
    generateInvoiceLoading: false,
    setSuccessForGenerateInvoice: false,
    generateInvoiceFailed: false,


    setErrorMsgForSavedCards: "",
    savedCardsResponse: '',
    savedCardsLoading: false,
    setSuccessForSavedCards: false,
    savedCardsFailed: false,
    setErrorMsgForDeleteCard: "",
    deleteCardResponse: '',
    deleteCardLoading: false,
    setSuccessForDeleteCard: false,
    deleteCardFailed: false,
    spocInfo: [],
    apiLoading: false,
};
export const reducer = (state = initialState, action) => {
    switch (action.type) { // account information start
        case actionTypes.CHANGE_PASSWORD_SUCESSS: {// seaction change password start
            const { flag, changePasswordResponse } = action.payload;
            return {
                ...state,
                setSuccessForChangePassword: flag,
                changePasswordResponse: changePasswordResponse,
            };
        }
        case actionTypes.CHANGE_PASSWORD_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                changePasswordLoading: flag,
            };
        }
        case actionTypes.CHANGE_PASSWORD_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                changePasswordFailed: flag,
                setErrorMsgForChangePassword: errorMessage,
            };
        }
        case actionTypes.CLEAR_CHANGE_PASSWORD_FLAGS: {// seaction change password end
            return {
                ...state,
                changePasswordLoading: false,
                setSuccessForChangePassword: false,
                changePasswordFailed: false,
                setErrorMsgForChangePassword: "",
                changePasswordResponse: '',
            };
        }
        case actionTypes.ACCOUNT_INFO_UPDATE_SUCESSS: {
            const { flag, accountInfoUpdateResponse } = action.payload;
            return {
                ...state,
                setSuccessForAccountInfoUpdate: flag,
                accountInfoUpdateResponse: accountInfoUpdateResponse,
            };
        }
        case actionTypes.ACCOUNT_INFO_UPDATE_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                accountInfoUpdateLoading: flag,
            };
        }
        case actionTypes.ACCOUNT_INFO_UPDATE_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                accountInfoUpdateFailed: flag,
                setErrorMsgForAccountInfoUpdate: errorMessage,
            };
        }
        case actionTypes.CLEAR_ACCOUNT_INFO_UPDATE_FLAGS: {
            return {
                ...state,
                accountInfoUpdateLoading: false,
                setSuccessForAccountInfoUpdate: false,
                accountInfoUpdateFailed: false,
                setErrorMsgForAccountInfoUpdate: "",
                accountInfoUpdateResponse: '',
            };
        }
        case actionTypes.GET_ACCOUNT_INFO_SUCESSS: {
            const { flag, getAccountInfoResponse } = action.payload;
            return {
                ...state,
                setSuccessForGetAccountInfo: flag,
                getAccountInfoResponse: getAccountInfoResponse,
            };
        }
        case actionTypes.GET_ACCOUNT_INFO_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                getAccountInfoLoading: flag,
            };
        }
        case actionTypes.GET_ACCOUNT_INFO_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                getAccountInfoFailed: flag,
                setErrorMsgForGetAccountInfo: errorMessage,
            };
        }
        case actionTypes.CLEAR_GET_ACCOUNT_INFO_FLAGS: {
            return {
                ...state,
                getAccountInfoLoading: false,
                setSuccessForGetAccountInfo: false,
                getAccountInfoFailed: false,
                setErrorMsgForGetAccountInfo: "",
                getAccountInfoResponse: '',
            };
        } // account information end
        // address book start
        case actionTypes.ADD_CLIENT_ADDRESS_SUCESSS: {
            const { flag, addClientAddressResponse } = action.payload;
            return {
                ...state,
                setSuccessForAddClientAddress: flag,
                addClientAddressResponse: addClientAddressResponse,
            };
        }
        case actionTypes.ADD_CLIENT_ADDRESS_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                addClientAddressLoading: flag,
            };
        }
        case actionTypes.ADD_CLIENT_ADDRESS_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                addClientAddressFailed: flag,
                setErrorMsgForAddClientAddress: errorMessage,
            };
        }
        case actionTypes.CLEAR_ADD_CLIENT_ADDRESS_FLAGS: {
            return {
                ...state,
                addClientAddressLoading: false,
                setSuccessForAddClientAddress: false,
                addClientAddressFailed: false,
                setErrorMsgForAddClientAddress: "",
                addClientAddressResponse: '',
            };
        }
        case actionTypes.GET_CLIENT_ADDRESS_SUCESSS: {
            const { flag, getClientAddressResponse } = action.payload;
            return {
                ...state,
                setSuccessForGetClientAddress: flag,
                getClientAddressResponse: getClientAddressResponse,
            };
        }
        case actionTypes.GET_CLIENT_ADDRESS_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                getClientAddressLoading: flag,
            };
        }
        case actionTypes.GET_CLIENT_ADDRESS_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                getClientAddressFailed: flag,
                setErrorMsgForGetClientAddress: errorMessage,
            };
        }
        case actionTypes.CLEAR_GET_CLIENT_ADDRESS_FLAGS: {
            return {
                ...state,
                getClientAddressLoading: false,
                setSuccessForGetClientAddress: false,
                getClientAddressFailed: false,
                setErrorMsgForGetClientAddress: "",
                getClientAddressResponse: '',
            };
        }
        case actionTypes.DELETE_CLIENT_ADDRESS_SUCESSS: {
            const { flag, deleteClientAddressResponse } = action.payload;
            return {
                ...state,
                setSuccessForDeleteClientAddress: flag,
                deleteClientAddressResponse: deleteClientAddressResponse,
            };
        }
        case actionTypes.DELETE_CLIENT_ADDRESS_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                deleteClientAddressLoading: flag,
            };
        }
        case actionTypes.DELETE_CLIENT_ADDRESS_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                deleteClientAddressFailed: flag,
                setErrorMsgForDeleteClientAddress: errorMessage,
            };
        }
        case actionTypes.CLEAR_DELETE_CLIENT_ADDRESS_FLAGS: {
            return {
                ...state,
                deleteClientAddressLoading: false,
                setSuccessForDeleteClientAddress: false,
                deleteClientAddressFailed: false,
                setErrorMsgForDeleteClientAddress: "",
                deleteClientAddressResponse: '',
            };
        }
        case actionTypes.GET_COUNTRY_LIST_SUCESSS: {
            const { flag, getCountryListResponse } = action.payload;
            return {
                ...state,
                setSuccessForGetCountryList: flag,
                getCountryListResponse: getCountryListResponse,
            };
        }
        case actionTypes.GET_COUNTRY_LIST_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                getCountryListLoading: flag,
            };
        }
        case actionTypes.GET_COUNTRY_LIST_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                getCountryListFailed: flag,
                setErrorMsgForGetCountryList: errorMessage,
            };
        }
        case actionTypes.CLEAR_GET_COUNTRY_LIST_FLAGS: {
            return {
                ...state,
                getCountryListLoading: false,
                setSuccessForGetCountryList: false,
                getCountryListFailed: false,
                setErrorMsgForGetCountryList: "",
                getCountryListResponse: '',
            };
        }
        case actionTypes.EDIT_CLIENT_ADDRESS_SUCESSS: {
            const { flag, editClientAddressResponse } = action.payload;
            return {
                ...state,
                setSuccessForEditClientAddress: flag,
                editClientAddressResponse: editClientAddressResponse,
            };
        }
        case actionTypes.EDIT_CLIENT_ADDRESS_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                editClientAddressLoading: flag,
            };
        }
        case actionTypes.EDIT_CLIENT_ADDRESS_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                editClientAddressFailed: flag,
                setErrorMsgForEditClientAddress: errorMessage,
            };
        }
        case actionTypes.CLEAR_EDIT_CLIENT_ADDRESS_FLAGS: {
            return {
                ...state,
                editClientAddressLoading: false,
                setSuccessForEditClientAddress: false,
                editClientAddressFailed: false,
                setErrorMsgForEditClientAddress: "",
                editClientAddressResponse: '',
            };
        }
        case actionTypes.UPDATE_CLIENT_ADDRESS_SUCESSS: {
            const { flag, updateClientAddressResponse } = action.payload;
            return {
                ...state,
                setSuccessForUpdateClientAddress: flag,
                updateClientAddressResponse: updateClientAddressResponse,
            };
        }
        case actionTypes.UPDATE_CLIENT_ADDRESS_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                updateClientAddressLoading: flag,
            };
        }
        case actionTypes.UPDATE_CLIENT_ADDRESS_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                updateClientAddressFailed: flag,
                setErrorMsgForUpdateClientAddress: errorMessage,
            };
        }
        case actionTypes.CLEAR_UPDATE_CLIENT_ADDRESS_FLAGS: {
            return {
                ...state,
                updateClientAddressLoading: false,
                setSuccessForUpdateClientAddress: false,
                updateClientAddressFailed: false,
                setErrorMsgForUpdateClientAddress: "",
                updateClientAddressResponse: '',
            };
        }
        case actionTypes.SET_DEFAULT_ADDRESS_SUCESSS: {
            const { flag, setDefaultAddressResponse } = action.payload;
            return {
                ...state,
                setSuccessForSetDefaultAddress: flag,
                setDefaultAddressResponse: setDefaultAddressResponse,
            };
        }
        case actionTypes.SET_DEFAULT_ADDRESS_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                setDefaultAddressLoading: flag,
            };
        }
        case actionTypes.SET_DEFAULT_ADDRESS_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                setDefaultAddressFailed: flag,
                setErrorMsgForSetDefaultAddress: errorMessage,
            };
        }
        case actionTypes.CLEAR_SET_DEFAULT_ADDRESS_FLAGS: {
            return {
                ...state,
                setDefaultAddressLoading: false,
                setSuccessForSetDefaultAddress: false,
                setDefaultAddressFailed: false,
                setErrorMsgForSetDefaultAddress: "",
                setDefaultAddressResponse: '',
            };
        }// address book end

        case actionTypes.GET_SUBSCRIPTION_SUCESSS: {
            const { flag, getSubscriptionResponse } = action.payload;
            return {
                ...state,
                setSuccessForGetSubscription: flag,
                getSubscriptionResponse: getSubscriptionResponse,
            };
        }
        case actionTypes.GET_SUBSCRIPTION_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                getSubscriptionLoading: flag,
            };
        }
        case actionTypes.GET_SUBSCRIPTION_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                getSubscriptionFailed: flag,
                setErrorMsgForGetSubscription: errorMessage,
            };
        }
        case actionTypes.CLEAR_GET_SUBSCRIPTION_FLAGS: {
            return {
                ...state,
                getSubscriptionLoading: false,
                setSuccessForGetSubscription: false,
                getSubscriptionFailed: false,
                setErrorMsgForGetSubscription: "",
                getSubscriptionResponse: '',
            };
        }

        case actionTypes.CANCEL_SUBSCRIPTION_SUCESSS: {
            const { flag, cancelSubscriptionResponse } = action.payload;
            return {
                ...state,
                setSuccessForCancelSubscription: flag,
                cancelSubscriptionResponse: cancelSubscriptionResponse,
            };
        }
        case actionTypes.CANCEL_SUBSCRIPTION_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                cancelSubscriptionLoading: flag,
            };
        }
        case actionTypes.CANCEL_SUBSCRIPTION_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                cancelSubscriptionFailed: flag,
                setErrorMsgForCancelSubscription: errorMessage,
            };
        }
        case actionTypes.CLEAR_CANCEL_SUBSCRIPTION_FLAGS: {
            return {
                ...state,
                cancelSubscriptionLoading: false,
                setSuccessForCancelSubscription: false,
                cancelSubscriptionFailed: false,
                setErrorMsgForCancelSubscription: "",
                cancelSubscriptionResponse: '',
            };
        }



        case actionTypes.VIEW_INVOICE_SUCESSS: {
            const { flag, viewInvoiceResponse } = action.payload;
            return {
                ...state,
                setSuccessForViewInvoice: flag,
                viewInvoiceResponse: viewInvoiceResponse,
            };
        }
        case actionTypes.VIEW_INVOICE_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                viewInvoiceLoading: flag,
            };
        }
        case actionTypes.VIEW_INVOICE_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                viewInvoiceFailed: flag,
                setErrorMsgForViewInvoice: errorMessage,
            };
        }
        case actionTypes.CLEAR_VIEW_INVOICE_FLAGS: {
            return {
                ...state,
                viewInvoiceLoading: false,
                setSuccessForViewInvoice: false,
                viewInvoiceFailed: false,
                setErrorMsgForViewInvoice: "",
                viewInvoiceResponse: '',
            };
        }


        case actionTypes.GET_INVOICES_SUCESSS: {
            const { flag, getInvoicesResponse } = action.payload;
            return {
                ...state,
                setSuccessForGetInvoices: flag,
                getInvoicesResponse: getInvoicesResponse,
            };
        }
        case actionTypes.GET_INVOICES_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                getInvoicesLoading: flag,
            };
        }
        case actionTypes.GET_INVOICES_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                getInvoicesFailed: flag,
                setErrorMsgForGetInvoices: errorMessage,
            };
        }
        case actionTypes.CLEAR_GET_INVOICES_FLAGS: {
            return {
                ...state,
                getInvoicesLoading: false,
                setSuccessForGetInvoices: false,
                getInvoicesFailed: false,
                setErrorMsgForGetInvoices: "",
                getInvoicesResponse: '',
            };
        }

        case actionTypes.GENERATE_INVOICE_SUCESSS: {
            const { flag, generateInvoiceResponse } = action.payload;
            return {
                ...state,
                setSuccessForGenerateInvoice: flag,
                generateInvoiceResponse: generateInvoiceResponse,
            };
        }
        case actionTypes.GENERATE_INVOICE_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                generateInvoiceLoading: flag,
            };
        }
        case actionTypes.GENERATE_INVOICE_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                generateInvoiceFailed: flag,
                setErrorMsgForGenerateInvoice: errorMessage,
            };
        }
        case actionTypes.CLEAR_GENERATE_INVOICE_FLAGS: {
            return {
                ...state,
                generateInvoiceLoading: false,
                setSuccessForGenerateInvoice: false,
                generateInvoiceFailed: false,
                setErrorMsgForGenerateInvoice: "",
                generateInvoiceResponse: '',
            };
        }


        case actionTypes.SAVED_CARDS_SUCESSS: {
            const { flag, savedCardsResponse } = action.payload;
            return {
                ...state,
                setSuccessForSavedCards: flag,
                savedCardsResponse: savedCardsResponse,
            };
        }
        case actionTypes.SAVED_CARDS_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                savedCardsLoading: flag,
            };
        }
        case actionTypes.SAVED_CARDS_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                savedCardsFailed: flag,
                setErrorMsgForSavedCards: errorMessage,
            };
        }
        case actionTypes.CLEAR_SAVED_CARDS_FLAGS: {
            return {
                ...state,
                savedCardsLoading: false,
                setSuccessForSavedCards: false,
                savedCardsFailed: false,
                setErrorMsgForSavedCards: "",
                savedCardsResponse: '',
            };
        }
        case actionTypes.DELETE_CARDS_SUCESSS: {
            const { flag, deleteCardResponse } = action.payload;
            return {
                ...state,
                setSuccessForDeleteCard: flag,
                deleteCardResponse: deleteCardResponse,
            };
        }
        case actionTypes.DELETE_CARDS_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                deleteCardLoading: flag,
            };
        }
        case actionTypes.DELETE_CARDS_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                deleteCardFailed: flag,
                setErrorMsgForDeleteCard: errorMessage,
            };
        }
        case actionTypes.CLEAR_DELETE_CARDS_FLAGS: {
            return {
                ...state,
                deleteCardLoading: false,
                setSuccessForDeleteCard: false,
                deleteCardFailed: false,
                setErrorMsgForDeleteCard: "",
                deleteCardResponse: '',
            };
        }

        case actionTypes.SET_ACCOUNT_SPOC_INFO: {
            const { data } = action.payload;
            return {
                ...state,
                spocInfo: data,
            };
        }

        case actionTypes.API_LOADING: {
            const { flag } = action.payload;
            return {
                ...state,
                apiLoading: flag,
            };
        }

        default: {
            return state;
        }
    }
};

export const actions = { // account information start
    changePassword: (payload) => ({// seaction change password start
        type: actionTypes.CHANGE_PASSWORD,
        payload,
    }),
    setChangePasswordLoading: (flag) => ({
        type: actionTypes.CHANGE_PASSWORD_LOADIING,
        payload: { flag },
    }),
    setChangePasswordSuccess: (flag, changePasswordResponse = "") => ({
        type: actionTypes.CHANGE_PASSWORD_SUCESSS,
        payload: { flag, changePasswordResponse },
    }),
    setChangePasswordFailed: (flag, errorMessage = "") => ({
        type: actionTypes.CHANGE_PASSWORD_FAILED,
        payload: { flag, errorMessage },
    }),
    clearChangePasswordFlags: () => ({
        type: actionTypes.CLEAR_CHANGE_PASSWORD_FLAGS,
    }),// seaction change password end
    accountInfoUpdate: (payload) => ({
        type: actionTypes.ACCOUNT_INFO_UPDATE,
        payload,
    }),
    setAccountInfoUpdateLoading: (flag) => ({
        type: actionTypes.ACCOUNT_INFO_UPDATE_LOADIING,
        payload: { flag },
    }),
    setAccountInfoUpdateSuccess: (flag, accountInfoUpdateResponse = "") => ({
        type: actionTypes.ACCOUNT_INFO_UPDATE_SUCESSS,
        payload: { flag, accountInfoUpdateResponse },
    }),
    setAccountInfoUpdateFailed: (flag, errorMessage = "") => ({
        type: actionTypes.ACCOUNT_INFO_UPDATE_FAILED,
        payload: { flag, errorMessage },
    }),
    clearAccountInfoUpdateFlags: () => ({
        type: actionTypes.CLEAR_ACCOUNT_INFO_UPDATE_FLAGS,
    }),
    getAccountInfo: (payload) => ({
        type: actionTypes.GET_ACCOUNT_INFO,
        payload,
    }),
    setGetAccountInfoLoading: (flag) => ({
        type: actionTypes.GET_ACCOUNT_INFO_LOADIING,
        payload: { flag },
    }),
    setGetAccountInfoSuccess: (flag, getAccountInfoResponse = "") => ({
        type: actionTypes.GET_ACCOUNT_INFO_SUCESSS,
        payload: { flag, getAccountInfoResponse },
    }),
    setGetAccountInfoFailed: (flag, errorMessage = "") => ({
        type: actionTypes.GET_ACCOUNT_INFO_FAILED,
        payload: { flag, errorMessage },
    }),
    clearGetAccountInfoFlags: () => ({
        type: actionTypes.CLEAR_GET_ACCOUNT_INFO_FLAGS,
    }), // account information end
    // address book start
    addClientAddress: (payload) => ({
        type: actionTypes.ADD_CLIENT_ADDRESS,
        payload,
    }),
    setAddClientAddressLoading: (flag) => ({
        type: actionTypes.ADD_CLIENT_ADDRESS_LOADIING,
        payload: { flag },
    }),
    setAddClientAddressSuccess: (flag, addClientAddressResponse = "") => ({
        type: actionTypes.ADD_CLIENT_ADDRESS_SUCESSS,
        payload: { flag, addClientAddressResponse },
    }),
    setAddClientAddressFailed: (flag, errorMessage = "") => ({
        type: actionTypes.ADD_CLIENT_ADDRESS_FAILED,
        payload: { flag, errorMessage },
    }),
    clearAddClientAddressFlags: () => ({
        type: actionTypes.CLEAR_ADD_CLIENT_ADDRESS_FLAGS,
    }),
    getClientAddress: (payload) => ({
        type: actionTypes.GET_CLIENT_ADDRESS,
        payload,
    }),
    setGetClientAddressLoading: (flag) => ({
        type: actionTypes.GET_CLIENT_ADDRESS_LOADIING,
        payload: { flag },
    }),
    setGetClientAddressSuccess: (flag, getClientAddressResponse = "") => ({
        type: actionTypes.GET_CLIENT_ADDRESS_SUCESSS,
        payload: { flag, getClientAddressResponse },
    }),
    setGetClientAddressFailed: (flag, errorMessage = "") => ({
        type: actionTypes.GET_CLIENT_ADDRESS_FAILED,
        payload: { flag, errorMessage },
    }),
    clearGetClientAddressFlags: () => ({
        type: actionTypes.CLEAR_GET_CLIENT_ADDRESS_FLAGS,
    }),
    deleteClientAddress: (payload) => ({
        type: actionTypes.DELETE_CLIENT_ADDRESS,
        payload,
    }),
    setDeleteClientAddressLoading: (flag) => ({
        type: actionTypes.DELETE_CLIENT_ADDRESS_LOADIING,
        payload: { flag },
    }),
    setDeleteClientAddressSuccess: (flag, deleteClientAddressResponse = "") => ({
        type: actionTypes.DELETE_CLIENT_ADDRESS_SUCESSS,
        payload: { flag, deleteClientAddressResponse },
    }),
    setDeleteClientAddressFailed: (flag, errorMessage = "") => ({
        type: actionTypes.DELETE_CLIENT_ADDRESS_FAILED,
        payload: { flag, errorMessage },
    }),
    clearDeleteClientAddressFlags: () => ({
        type: actionTypes.CLEAR_DELETE_CLIENT_ADDRESS_FLAGS,
    }),
    getCountryList: (payload) => ({
        type: actionTypes.GET_COUNTRY_LIST,
        payload,
    }),
    setGetCountryListLoading: (flag) => ({
        type: actionTypes.GET_COUNTRY_LIST_LOADIING,
        payload: { flag },
    }),
    setGetCountryListSuccess: (flag, getCountryListResponse = "") => ({
        type: actionTypes.GET_COUNTRY_LIST_SUCESSS,
        payload: { flag, getCountryListResponse },
    }),
    setGetCountryListFailed: (flag, errorMessage = "") => ({
        type: actionTypes.GET_COUNTRY_LIST_FAILED,
        payload: { flag, errorMessage },
    }),
    clearGetCountryListFlags: () => ({
        type: actionTypes.CLEAR_GET_COUNTRY_LIST_FLAGS,
    }),
    editClientAddress: (payload) => ({
        type: actionTypes.EDIT_CLIENT_ADDRESS,
        payload,
    }),
    setEditClientAddressLoading: (flag) => ({
        type: actionTypes.EDIT_CLIENT_ADDRESS_LOADIING,
        payload: { flag },
    }),
    setEditClientAddressSuccess: (flag, editClientAddressResponse = "") => ({
        type: actionTypes.EDIT_CLIENT_ADDRESS_SUCESSS,
        payload: { flag, editClientAddressResponse },
    }),
    setEditClientAddressFailed: (flag, errorMessage = "") => ({
        type: actionTypes.EDIT_CLIENT_ADDRESS_FAILED,
        payload: { flag, errorMessage },
    }),
    clearEditClientAddressFlags: () => ({
        type: actionTypes.CLEAR_EDIT_CLIENT_ADDRESS_FLAGS,
    }),
    updateClientAddress: (payload) => ({
        type: actionTypes.UPDATE_CLIENT_ADDRESS,
        payload,
    }),
    setUpdateClientAddressLoading: (flag) => ({
        type: actionTypes.UPDATE_CLIENT_ADDRESS_LOADIING,
        payload: { flag },
    }),
    setUpdateClientAddressSuccess: (flag, updateClientAddressResponse = "") => ({
        type: actionTypes.UPDATE_CLIENT_ADDRESS_SUCESSS,
        payload: { flag, updateClientAddressResponse },
    }),
    setUpdateClientAddressFailed: (flag, errorMessage = "") => ({
        type: actionTypes.UPDATE_CLIENT_ADDRESS_FAILED,
        payload: { flag, errorMessage },
    }),
    clearUpdateClientAddressFlags: () => ({
        type: actionTypes.CLEAR_UPDATE_CLIENT_ADDRESS_FLAGS,
    }),
    setDefaultAddress: (payload) => ({
        type: actionTypes.SET_DEFAULT_ADDRESS,
        payload,
    }),
    setSetDefaultAddressLoading: (flag) => ({
        type: actionTypes.SET_DEFAULT_ADDRESS_LOADIING,
        payload: { flag },
    }),
    setSetDefaultAddressSuccess: (flag, setDefaultAddressResponse = "") => ({
        type: actionTypes.SET_DEFAULT_ADDRESS_SUCESSS,
        payload: { flag, setDefaultAddressResponse },
    }),
    setSetDefaultAddressFailed: (flag, errorMessage = "") => ({
        type: actionTypes.SET_DEFAULT_ADDRESS_FAILED,
        payload: { flag, errorMessage },
    }),
    clearSetDefaultAddressFlags: () => ({
        type: actionTypes.CLEAR_SET_DEFAULT_ADDRESS_FLAGS,
    }),// address book end

    getSubscription: (payload) => ({
        type: actionTypes.GET_SUBSCRIPTION,
        payload,
    }),
    setGetSubscriptionLoading: (flag) => ({
        type: actionTypes.GET_SUBSCRIPTION_LOADIING,
        payload: { flag },
    }),
    setGetSubscriptionSuccess: (flag, getSubscriptionResponse = "") => ({
        type: actionTypes.GET_SUBSCRIPTION_SUCESSS,
        payload: { flag, getSubscriptionResponse },
    }),
    setGetSubscriptionFailed: (flag, errorMessage = "") => ({
        type: actionTypes.GET_SUBSCRIPTION_FAILED,
        payload: { flag, errorMessage },
    }),
    clearGetSubscriptionFlags: () => ({
        type: actionTypes.CLEAR_GET_SUBSCRIPTION_FLAGS,
    }),

    cancelSubscription: (payload) => ({
        type: actionTypes.CANCEL_SUBSCRIPTION,
        payload,
    }),
    setCancelSubscriptionLoading: (flag) => ({
        type: actionTypes.CANCEL_SUBSCRIPTION_LOADIING,
        payload: { flag },
    }),
    setCancelSubscriptionSuccess: (flag, cancelSubscriptionResponse = "") => ({
        type: actionTypes.CANCEL_SUBSCRIPTION_SUCESSS,
        payload: { flag, cancelSubscriptionResponse },
    }),
    setCancelSubscriptionFailed: (flag, errorMessage = "") => ({
        type: actionTypes.CANCEL_SUBSCRIPTION_FAILED,
        payload: { flag, errorMessage },
    }),
    clearCancelSubscriptionFlags: () => ({
        type: actionTypes.CLEAR_CANCEL_SUBSCRIPTION_FLAGS,
    }),

    viewInvoice: (payload) => ({
        type: actionTypes.VIEW_INVOICE,
        payload,
    }),
    setViewInvoiceLoading: (flag) => ({
        type: actionTypes.VIEW_INVOICE_LOADIING,
        payload: { flag },
    }),
    setViewInvoiceSuccess: (flag, viewInvoiceResponse = "") => ({
        type: actionTypes.VIEW_INVOICE_SUCESSS,
        payload: { flag, viewInvoiceResponse },
    }),
    setViewInvoiceFailed: (flag, errorMessage = "") => ({
        type: actionTypes.VIEW_INVOICE_FAILED,
        payload: { flag, errorMessage },
    }),
    clearViewInvoiceFlags: () => ({
        type: actionTypes.CLEAR_VIEW_INVOICE_FLAGS,
    }),

    getInvoices: (payload) => ({
        type: actionTypes.GET_INVOICES,
        payload,
    }),
    setGetInvoicesLoading: (flag) => ({
        type: actionTypes.GET_INVOICES_LOADIING,
        payload: { flag },
    }),
    setGetInvoicesSuccess: (flag, getInvoicesResponse = "") => ({
        type: actionTypes.GET_INVOICES_SUCESSS,
        payload: { flag, getInvoicesResponse },
    }),
    setGetInvoicesFailed: (flag, errorMessage = "") => ({
        type: actionTypes.GET_INVOICES_FAILED,
        payload: { flag, errorMessage },
    }),
    clearGetInvoicesFlags: () => ({
        type: actionTypes.CLEAR_GET_INVOICES_FLAGS,
    }),


    generateInvoice: (payload) => ({
        type: actionTypes.GENERATE_INVOICE,
        payload,
    }),
    setGenerateInvoiceLoading: (flag) => ({
        type: actionTypes.GENERATE_INVOICE_LOADIING,
        payload: { flag },
    }),
    setGenerateInvoiceSuccess: (flag, generateInvoiceResponse = "") => ({
        type: actionTypes.GENERATE_INVOICE_SUCESSS,
        payload: { flag, generateInvoiceResponse },
    }),
    setGenerateInvoiceFailed: (flag, errorMessage = "") => ({
        type: actionTypes.GENERATE_INVOICE_FAILED,
        payload: { flag, errorMessage },
    }),
    clearGenerateInvoiceFlags: () => ({
        type: actionTypes.CLEAR_GENERATE_INVOICE_FLAGS,
    }),

    savedCards: (payload) => ({
        type: actionTypes.SAVED_CARDS,
        payload,
    }),
    setSavedCardsLoading: (flag) => ({
        type: actionTypes.SAVED_CARDS_LOADIING,
        payload: { flag },
    }),
    setSavedCardsSuccess: (flag, savedCardsResponse = "") => ({
        type: actionTypes.SAVED_CARDS_SUCESSS,
        payload: { flag, savedCardsResponse },
    }),
    setSavedCardsFailed: (flag, errorMessage = "") => ({
        type: actionTypes.SAVED_CARDS_FAILED,
        payload: { flag, errorMessage },
    }),
    clearSavedCardsFlags: () => ({
        type: actionTypes.CLEAR_SAVED_CARDS_FLAGS,
    }),
    deleteCard: (payload) => ({
        type: actionTypes.DELETE_CARDS,
        payload,
    }),
    setDeleteCardLoading: (flag) => ({
        type: actionTypes.DELETE_CARDS_LOADIING,
        payload: { flag },
    }),
    setDeleteCardSuccess: (flag, deleteCardResponse = "") => ({
        type: actionTypes.DELETE_CARDS_SUCESSS,
        payload: { flag, deleteCardResponse },
    }),
    setDeleteCardFailed: (flag, errorMessage = "") => ({
        type: actionTypes.DELETE_CARDS_FAILED,
        payload: { flag, errorMessage },
    }),
    clearDeleteCardFlags: () => ({
        type: actionTypes.CLEAR_DELETE_CARDS_FLAGS,
    }),
    setSpocInfo: (data) => ({
        type: actionTypes.SET_ACCOUNT_SPOC_INFO,
        payload: { data },
    }),
    getAccountSpocInfo: (payload) => ({
        type: actionTypes.GET_ACCOUNT_SPOC_INFO,
        payload,
    }),
    setApiLoading: (flag) => ({
        type: actionTypes.API_LOADING,
        payload: { flag },
    }),
};

export function* saga() { // account information start
    yield takeLatest(actionTypes.CHANGE_PASSWORD, changePasswordSaga);
    yield takeLatest(actionTypes.ACCOUNT_INFO_UPDATE, accountInfoUpdateSaga);
    yield takeLatest(actionTypes.GET_ACCOUNT_INFO, getAccountInfoSaga); // account information end
    yield takeLatest(actionTypes.ADD_CLIENT_ADDRESS, addClientAddressSaga);// address book start
    yield takeLatest(actionTypes.GET_CLIENT_ADDRESS, getClientAddressSaga);
    yield takeLatest(actionTypes.DELETE_CLIENT_ADDRESS, deleteClientAddressSaga);
    yield takeLatest(actionTypes.GET_COUNTRY_LIST, getCountryListSaga);
    yield takeLatest(actionTypes.EDIT_CLIENT_ADDRESS, editClientAddressSaga);
    yield takeLatest(actionTypes.UPDATE_CLIENT_ADDRESS, updateClientAddressSaga);
    yield takeLatest(actionTypes.SET_DEFAULT_ADDRESS, setDefaultAddressSaga);// address book end

    yield takeLatest(actionTypes.GET_SUBSCRIPTION, getSubscriptionSaga);
    yield takeLatest(actionTypes.CANCEL_SUBSCRIPTION, cancelSubscriptionSaga);
    yield takeLatest(actionTypes.VIEW_INVOICE, viewInvoiceSaga);
    yield takeLatest(actionTypes.GET_INVOICES, getInvoicesSaga);
    yield takeLatest(actionTypes.GENERATE_INVOICE, generateInvoiceSaga);

    yield takeLatest(actionTypes.SAVED_CARDS, savedCardsSaga);
    yield takeLatest(actionTypes.DELETE_CARDS, deleteCardSaga);
    yield takeLatest(actionTypes.GET_ACCOUNT_SPOC_INFO, getAccountSpocInfoSaga);
}
// account information start
function* changePasswordSaga(action) {
    yield all([
        put(actions.setChangePasswordLoading(true)),
        put(actions.setChangePasswordSuccess(false)),
        put(actions.setChangePasswordFailed(false)),
    ]);
    try {
        const { old_password, new_password, confirm_password, clientAccessToken } = action.payload
        const payload = { old_password, new_password, confirm_password };
        const response = yield call(
            postApiToken,
            apiUrl("CHANGE_PASSWORD"),
            clientAccessToken,
            queryString.stringify(payload)
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield put(
                    generalActions.addToast(
                        "Account Information",
                        responseData.message,
                        "success",
                        uid()
                    )
                );
                yield all([
                    put(actions.setChangePasswordLoading(false)),
                    put(actions.setChangePasswordSuccess(true, responseData)),
                    put(actions.setChangePasswordFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setChangePasswordLoading(false)),
                    put(actions.setChangePasswordSuccess(false)),
                    put(actions.setChangePasswordFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setChangePasswordLoading(false)),
                    put(actions.setChangePasswordFailed(true, responseData.message)),
                    put(actions.setChangePasswordSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Account Information",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setChangePasswordLoading(false)),
                    put(actions.setChangePasswordFailed(true)),
                    put(actions.setChangePasswordSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Account Information",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setChangePasswordLoading(false)),
            put(actions.setChangePasswordSuccess(false)),
            put(actions.setChangePasswordFailed(true)),
        ]);
    }
}

function* accountInfoUpdateSaga(action) {
    yield all([
        put(actions.setAccountInfoUpdateLoading(true)),
        put(actions.setAccountInfoUpdateSuccess(false)),
        put(actions.setAccountInfoUpdateFailed(false)),
    ]);
    try {
        const { user } = yield select((state) => state.auth);
        const { name, email, phone, company_name, phone_code, clientAccessToken } = action.payload
        const payload = { name, email, phone, company_name, phone_code };
        const response = yield call(
            postApiToken,
            apiUrl("ACCOUNT_INFO_UPDATE"),
            clientAccessToken,
            queryString.stringify(payload)
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield put(
                    generalActions.addToast(
                        "Account Information",
                        responseData.message,
                        "success",
                        uid()
                    )
                );
                yield all([
                    put(actions.setAccountInfoUpdateLoading(false)),
                    put(actions.setAccountInfoUpdateSuccess(true, responseData)),
                    put(actions.setAccountInfoUpdateFailed(false)),
                ]);
                yield put(actions.getAccountInfo({ client_id: user?.id, clientAccessToken }));
            } else {
                yield all([
                    put(actions.setAccountInfoUpdateLoading(false)),
                    put(actions.setAccountInfoUpdateSuccess(false)),
                    put(actions.setAccountInfoUpdateFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setAccountInfoUpdateLoading(false)),
                    put(actions.setAccountInfoUpdateFailed(true, responseData.message)),
                    put(actions.setAccountInfoUpdateSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Account Information",
                        responseData.message,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setAccountInfoUpdateLoading(false)),
                    put(actions.setAccountInfoUpdateFailed(true)),
                    put(actions.setAccountInfoUpdateSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Account Information",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setAccountInfoUpdateLoading(false)),
            put(actions.setAccountInfoUpdateSuccess(false)),
            put(actions.setAccountInfoUpdateFailed(true)),
        ]);
    }
}

function* getAccountInfoSaga({ payload: { client_id, clientAccessToken } }) {
    yield all([
        put(actions.setGetAccountInfoLoading(true)),
        put(actions.setGetAccountInfoSuccess(false)),
        put(actions.setGetAccountInfoFailed(false)),
    ]);
    try {
        const response = yield call(
            getApiToken,
            apiUrl("GET_ACCOUNT_INFO"),
            queryString.stringify({ client_id: client_id }),
            clientAccessToken
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield all([
                    put(actions.setGetAccountInfoLoading(false)),
                    put(actions.setGetAccountInfoSuccess(true, responseData)),
                    put(actions.setGetAccountInfoFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setGetAccountInfoLoading(false)),
                    put(actions.setGetAccountInfoSuccess(false)),
                    put(actions.setGetAccountInfoFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setGetAccountInfoLoading(false)),
                    put(actions.setGetAccountInfoFailed(true, responseData.message)),
                    put(actions.setGetAccountInfoSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Account Information",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setGetAccountInfoLoading(false)),
                    put(actions.setGetAccountInfoFailed(true)),
                    put(actions.setGetAccountInfoSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Account Information",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setGetAccountInfoLoading(false)),
            put(actions.setGetAccountInfoSuccess(false)),
            put(actions.setGetAccountInfoFailed(true)),
        ]);
    }
}
// account information end
// address book start
function* addClientAddressSaga({ payload: { payloadObj, clientAccessToken } }) {
    yield all([
        put(actions.setAddClientAddressLoading(true)),
        put(actions.setAddClientAddressSuccess(false)),
        put(actions.setAddClientAddressFailed(false)),
    ]);
    try {
        const response = yield call(
            postApiToken,
            apiUrl("CLIENT_ADDRESS"),
            clientAccessToken,
            queryString.stringify(payloadObj)

        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.message,
                        "success",
                        uid()
                    )
                );
                yield all([
                    put(actions.setAddClientAddressLoading(false)),
                    put(actions.setAddClientAddressSuccess(true, responseData)),
                    put(actions.setAddClientAddressFailed(false)),
                ]);
                yield put(actions.getClientAddress({ clientAccessToken }))
            } else {
                yield all([
                    put(actions.setAddClientAddressLoading(false)),
                    put(actions.setAddClientAddressSuccess(false)),
                    put(actions.setAddClientAddressFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setAddClientAddressLoading(false)),
                    put(actions.setAddClientAddressFailed(true, responseData.message)),
                    put(actions.setAddClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setAddClientAddressLoading(false)),
                    put(actions.setAddClientAddressFailed(true)),
                    put(actions.setAddClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setAddClientAddressLoading(false)),
            put(actions.setAddClientAddressSuccess(false)),
            put(actions.setAddClientAddressFailed(true)),
        ]);
    }
}

function* getClientAddressSaga({ payload: { clientAccessToken } }) {
    yield all([
        put(actions.setGetClientAddressLoading(true)),
        put(actions.setGetClientAddressSuccess(false)),
        put(actions.setGetClientAddressFailed(false)),
    ]);
    try {
        const response = yield call(
            getApiToken,
            apiUrl("CLIENT_ADDRESS"),
            null,
            clientAccessToken
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield all([
                    put(actions.setGetClientAddressLoading(false)),
                    put(actions.setGetClientAddressSuccess(true, responseData)),
                    put(actions.setGetClientAddressFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setGetClientAddressLoading(false)),
                    put(actions.setGetClientAddressSuccess(false)),
                    put(actions.setGetClientAddressFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setGetClientAddressLoading(false)),
                    put(actions.setGetClientAddressFailed(true, responseData.message)),
                    put(actions.setGetClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setGetClientAddressLoading(false)),
                    put(actions.setGetClientAddressFailed(true)),
                    put(actions.setGetClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setGetClientAddressLoading(false)),
            put(actions.setGetClientAddressSuccess(false)),
            put(actions.setGetClientAddressFailed(true)),
        ]);
    }
}

function* deleteClientAddressSaga({ payload: { deleteId, clientAccessToken } }) {
    yield all([
        put(actions.setDeleteClientAddressLoading(true)),
        put(actions.setDeleteClientAddressSuccess(false)),
        put(actions.setDeleteClientAddressFailed(false)),
    ]);
    try {
        const response = yield call(
            deleteApiTokenSlash,
            apiUrl("CLIENT_ADDRESS"),
            clientAccessToken,
            deleteId,
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.message,
                        "success",
                        uid()
                    )
                );
                yield all([
                    put(actions.setDeleteClientAddressLoading(false)),
                    put(actions.setDeleteClientAddressSuccess(true, responseData)),
                    put(actions.setDeleteClientAddressFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setDeleteClientAddressLoading(false)),
                    put(actions.setDeleteClientAddressSuccess(false)),
                    put(actions.setDeleteClientAddressFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setDeleteClientAddressLoading(false)),
                    put(actions.setDeleteClientAddressFailed(true, responseData.message)),
                    put(actions.setDeleteClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setDeleteClientAddressLoading(false)),
                    put(actions.setDeleteClientAddressFailed(true)),
                    put(actions.setDeleteClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setDeleteClientAddressLoading(false)),
            put(actions.setDeleteClientAddressSuccess(false)),
            put(actions.setDeleteClientAddressFailed(true)),
        ]);
    }
}

function* getCountryListSaga({ payload: { clientAccessToken } }) {
    yield all([
        put(actions.setGetCountryListLoading(true)),
        put(actions.setGetCountryListSuccess(false)),
        put(actions.setGetCountryListFailed(false)),
    ]);
    try {
        const response = yield call(
            getApiToken,
            apiUrl("GET_COUNTRY_LIST"),
            null,
            clientAccessToken
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield all([
                    put(actions.setGetCountryListLoading(false)),
                    put(actions.setGetCountryListSuccess(true, responseData)),
                    put(actions.setGetCountryListFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setGetCountryListLoading(false)),
                    put(actions.setGetCountryListSuccess(false)),
                    put(actions.setGetCountryListFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setGetCountryListLoading(false)),
                    put(actions.setGetCountryListFailed(true, responseData.message)),
                    put(actions.setGetCountryListSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setGetCountryListLoading(false)),
                    put(actions.setGetCountryListFailed(true)),
                    put(actions.setGetCountryListSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setGetCountryListLoading(false)),
            put(actions.setGetCountryListSuccess(false)),
            put(actions.setGetCountryListFailed(true)),
        ]);
    }
}

function* editClientAddressSaga({ payload: { editClientId, clientAccessToken } }) {
    yield all([
        put(actions.setEditClientAddressLoading(true)),
        put(actions.setEditClientAddressSuccess(false)),
        put(actions.setEditClientAddressFailed(false)),
    ]);
    try {
        const response = yield call(
            getApiTokenSlash,
            apiUrl("CLIENT_ADDRESS"),
            editClientId,
            clientAccessToken
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield all([
                    put(actions.setEditClientAddressLoading(false)),
                    put(actions.setEditClientAddressSuccess(true, responseData)),
                    put(actions.setEditClientAddressFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setEditClientAddressLoading(false)),
                    put(actions.setEditClientAddressSuccess(false)),
                    put(actions.setEditClientAddressFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setEditClientAddressLoading(false)),
                    put(actions.setEditClientAddressFailed(true, responseData.message)),
                    put(actions.setEditClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setEditClientAddressLoading(false)),
                    put(actions.setEditClientAddressFailed(true)),
                    put(actions.setEditClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setEditClientAddressLoading(false)),
            put(actions.setEditClientAddressSuccess(false)),
            put(actions.setEditClientAddressFailed(true)),
        ]);
    }
}

function* updateClientAddressSaga({ payload: { payloadObj, id, clientAccessToken } }) {
    yield all([
        put(actions.setUpdateClientAddressLoading(true)),
        put(actions.setUpdateClientAddressSuccess(false)),
        put(actions.setUpdateClientAddressFailed(false)),
    ]);
    try {
        const response = yield call(
            putApiToken,
            apiUrl("CLIENT_ADDRESS") + "/" + id,
            queryString.stringify(payloadObj),
            clientAccessToken,
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.message,
                        "success",
                        uid()
                    )
                );
                yield all([
                    put(actions.setUpdateClientAddressLoading(false)),
                    put(actions.setUpdateClientAddressSuccess(true, responseData)),
                    put(actions.setUpdateClientAddressFailed(false)),
                ]);

            } else {
                yield all([
                    put(actions.setUpdateClientAddressLoading(false)),
                    put(actions.setUpdateClientAddressSuccess(false)),
                    put(actions.setUpdateClientAddressFailed(true)),
                ]);
            }
            yield put(actions.getClientAddress({ clientAccessToken }))
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setUpdateClientAddressLoading(false)),
                    put(actions.setUpdateClientAddressFailed(true, responseData.message)),
                    put(actions.setUpdateClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setUpdateClientAddressLoading(false)),
                    put(actions.setUpdateClientAddressFailed(true)),
                    put(actions.setUpdateClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setUpdateClientAddressLoading(false)),
            put(actions.setUpdateClientAddressSuccess(false)),
            put(actions.setUpdateClientAddressFailed(true)),
        ]);
    }
}

function* setDefaultAddressSaga({ payload: { id, clientAccessToken } }) {
    yield all([
        put(actions.setSetDefaultAddressLoading(true)),
        put(actions.setSetDefaultAddressSuccess(false)),
        put(actions.setSetDefaultAddressFailed(false)),
    ]);
    try {
        const response = yield call(
            postApiToken,
            apiUrl("SET_DEFAULT_ADDRESS"),
            clientAccessToken,
            queryString.stringify({ id }),

        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.message,
                        "success",
                        uid()
                    )
                );
                yield all([
                    put(actions.setSetDefaultAddressLoading(false)),
                    put(actions.setSetDefaultAddressSuccess(true, responseData)),
                    put(actions.setSetDefaultAddressFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setSetDefaultAddressLoading(false)),
                    put(actions.setSetDefaultAddressSuccess(false)),
                    put(actions.setSetDefaultAddressFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setSetDefaultAddressLoading(false)),
                    put(actions.setSetDefaultAddressFailed(true, responseData.message)),
                    put(actions.setSetDefaultAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setSetDefaultAddressLoading(false)),
                    put(actions.setSetDefaultAddressFailed(true)),
                    put(actions.setSetDefaultAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        } else{
            yield all([
                put(actions.setSetDefaultAddressLoading(false)),
                put(actions.setSetDefaultAddressSuccess(false)),
                put(actions.setSetDefaultAddressFailed(false)),
            ]);
        }
    } catch (error) {
        yield all([
            put(actions.setSetDefaultAddressLoading(false)),
            put(actions.setSetDefaultAddressSuccess(false)),
            put(actions.setSetDefaultAddressFailed(true)),
        ]);
    }
}// address book end

function* getSubscriptionSaga({ payload: { tableObj: { page, length, sortField, sortOrder }, clientAccessToken } }) {
    yield all([
        put(actions.setGetSubscriptionLoading(true)),
        put(actions.setGetSubscriptionSuccess(false)),
        put(actions.setGetSubscriptionFailed(false)),
    ]);
    try {
        const filterParams = {
            start: (page - 1) * length,
            length: length,
        };
        const sortParams = `&columns[0][data]= &columns[0][name]= &columns[0][searchable]= false&columns[0][orderable]= false&columns[0][search][value]= &columns[0][search][regex]= false&columns[1][data]= ${sortField}&columns[1][name]= &columns[1][searchable]= false&columns[1][orderable]= true&columns[1][search][value]= &columns[1][search][regex]= false&order[0][column]= 1&order[0][dir]= ${sortOrder}`;
        const response = yield call(
            getApiToken,
            apiUrl("GET_SUBSCRIPTION"),
            `${queryString.stringify(filterParams)}${sortParams}`,
            clientAccessToken,
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield all([
                    put(actions.setGetSubscriptionLoading(false)),
                    put(actions.setGetSubscriptionSuccess(true, responseData)),
                    put(actions.setGetSubscriptionFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setGetSubscriptionLoading(false)),
                    put(actions.setGetSubscriptionSuccess(false)),
                    put(actions.setGetSubscriptionFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setGetSubscriptionLoading(false)),
                    put(actions.setGetSubscriptionFailed(true, responseData.message)),
                    put(actions.setGetSubscriptionSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "My subscription",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setGetSubscriptionLoading(false)),
                    put(actions.setGetSubscriptionFailed(true)),
                    put(actions.setGetSubscriptionSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "My subscription",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setGetSubscriptionLoading(false)),
            put(actions.setGetSubscriptionSuccess(false)),
            put(actions.setGetSubscriptionFailed(true)),
        ]);
    }
}

function* cancelSubscriptionSaga({ payload: { id, clientAccessToken } }) {
    yield all([
        put(actions.setCancelSubscriptionLoading(true)),
        put(actions.setCancelSubscriptionSuccess(false)),
        put(actions.setCancelSubscriptionFailed(false)),
    ]);
    try {
        const response = yield call(
            postApiToken,
            apiUrl("CANCEL_SUBSCRIPTION"),
            clientAccessToken,
            queryString.stringify({ id }),

        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield put(
                    generalActions.addToast(
                        "My subscription",
                        responseData.message,
                        "success",
                        uid()
                    )
                );
                yield all([
                    put(actions.setCancelSubscriptionLoading(false)),
                    put(actions.setCancelSubscriptionSuccess(true, responseData)),
                    put(actions.setCancelSubscriptionFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setCancelSubscriptionLoading(false)),
                    put(actions.setCancelSubscriptionSuccess(false)),
                    put(actions.setCancelSubscriptionFailed(true)),
                ]);
            }
            yield put(actions.getSubscription({ tableObj: { length: 10, page: 1, searchText: '', sizePerPage: 10, sortField: "name", sortOrder: "asc" }, clientAccessToken }));
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setCancelSubscriptionLoading(false)),
                    put(actions.setCancelSubscriptionFailed(true, responseData.message)),
                    put(actions.setCancelSubscriptionSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "My subscription",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setCancelSubscriptionLoading(false)),
                    put(actions.setCancelSubscriptionFailed(true)),
                    put(actions.setCancelSubscriptionSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "My subscription",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setCancelSubscriptionLoading(false)),
            put(actions.setCancelSubscriptionSuccess(false)),
            put(actions.setCancelSubscriptionFailed(true)),
        ]);
    }
}

function* viewInvoiceSaga({ payload: { id, clientAccessToken } }) {
    yield all([
        put(actions.setViewInvoiceLoading(true)),
        put(actions.setViewInvoiceSuccess(false)),
        put(actions.setViewInvoiceFailed(false)),
    ]);
    try {
        const response = yield call(
            getApiTokenSlash,
            apiUrl("VIEW_INVOICE"),
            id,
            clientAccessToken
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield all([
                    put(actions.setViewInvoiceLoading(false)),
                    put(actions.setViewInvoiceSuccess(true, responseData)),
                    put(actions.setViewInvoiceFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setViewInvoiceLoading(false)),
                    put(actions.setViewInvoiceSuccess(false)),
                    put(actions.setViewInvoiceFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setViewInvoiceLoading(false)),
                    put(actions.setViewInvoiceFailed(true, responseData.message)),
                    put(actions.setViewInvoiceSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "My Invoices",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setViewInvoiceLoading(false)),
                    put(actions.setViewInvoiceFailed(true)),
                    put(actions.setViewInvoiceSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "My Invoices",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setViewInvoiceLoading(false)),
            put(actions.setViewInvoiceSuccess(false)),
            put(actions.setViewInvoiceFailed(true)),
        ]);
    }
}

function* getInvoicesSaga({ payload: { tableObj: { page, length, sortField, sortOrder, searchText = "" }, clientAccessToken } }) {
    yield all([
        put(actions.setGetInvoicesLoading(true)),
        put(actions.setGetInvoicesSuccess(false)),
        put(actions.setGetInvoicesFailed(false)),
    ]);
    try {
        const filterParams = {
            start: (page - 1) * length,
            length: length,
            filter_data: searchText
        };
        const sortParams = `&columns[0][data]=&columns[0][name]=&columns[0][searchable]=false&columns[0][orderable]=false&columns[0][search][value]=&columns[0][search][regex]=false&columns[1][data]=${sortField}&columns[1][name]=&columns[1][searchable]=true&columns[1][orderable]=true&columns[1][search][value]=&columns[1][search][regex]=false&order[0][column]=1&order[0][dir]=${sortOrder}`

        const response = yield call(
            getApiToken,
            apiUrl("GET_INVOICES"),
            `${queryString.stringify(filterParams)}${sortParams}`,
            clientAccessToken,
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield all([
                    put(actions.setGetInvoicesLoading(false)),
                    put(actions.setGetInvoicesSuccess(true, responseData)),
                    put(actions.setGetInvoicesFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setGetInvoicesLoading(false)),
                    put(actions.setGetInvoicesSuccess(false)),
                    put(actions.setGetInvoicesFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setGetInvoicesLoading(false)),
                    put(actions.setGetInvoicesFailed(true, responseData.message)),
                    put(actions.setGetInvoicesSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "My Invoices",
                        responseData.message,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setGetInvoicesLoading(false)),
                    put(actions.setGetInvoicesFailed(true)),
                    put(actions.setGetInvoicesSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "My Invoices",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setGetInvoicesLoading(false)),
            put(actions.setGetInvoicesSuccess(false)),
            put(actions.setGetInvoicesFailed(true)),
        ]);
    }
}

function downloadBlob(blob, name = 'file.pdf') {
    // Create blob link to download
    const url = window.URL.createObjectURL(
        new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
        'download',
        name,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
}

function generateInvoiceSaga({ payload: { id, clientAccessToken } }) {
    postApiToken(apiUrl("GENERATE_INVOICE"), clientAccessToken, queryString.stringify({ id }))
        .then(async (response) => response.blob())
        .then((blob) => {
            downloadBlob(blob, `Invoice-${id}.pdf`);
        });
}

function* savedCardsSaga({ payload: { client_id, clientAccessToken } }) {
    yield all([
        put(actions.setSavedCardsLoading(true)),
        put(actions.setSavedCardsSuccess(false)),
        put(actions.setSavedCardsFailed(false)),
    ]);
    try {
        const response = yield call(
            postApiToken,
            apiUrl("SAVED_CARDS"),
            clientAccessToken,
            queryString.stringify({ client_id: client_id }),

        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield all([
                    put(actions.setSavedCardsLoading(false)),
                    put(actions.setSavedCardsSuccess(true, responseData)),
                    put(actions.setSavedCardsFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setSavedCardsLoading(false)),
                    put(actions.setSavedCardsSuccess(false)),
                    put(actions.setSavedCardsFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setSavedCardsLoading(false)),
                    put(actions.setSavedCardsFailed(true, responseData.message)),
                    put(actions.setSavedCardsSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Payment Method",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setSavedCardsLoading(false)),
                    put(actions.setSavedCardsFailed(true)),
                    put(actions.setSavedCardsSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Payment Method",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setSavedCardsLoading(false)),
            put(actions.setSavedCardsSuccess(false)),
            put(actions.setSavedCardsFailed(true)),
        ]);
    }
}

function* deleteCardSaga({ payload: { id, client_id, clientAccessToken } }) {
    yield all([
        put(actions.setDeleteCardLoading(true)),
        put(actions.setDeleteCardSuccess(false)),
        put(actions.setDeleteCardFailed(false)),
    ]);
    try {
        const response = yield call(
            postApiToken,
            apiUrl("DELETE_CARDS"),
            clientAccessToken,
            queryString.stringify({ payment_method_id: id }),

        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield put(
                    generalActions.addToast(
                        "Payment Method",
                        responseData.message,
                        "success",
                        uid()
                    )
                );
                yield all([
                    put(actions.setDeleteCardLoading(false)),
                    put(actions.setDeleteCardSuccess(true, responseData)),
                    put(actions.setDeleteCardFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setDeleteCardLoading(false)),
                    put(actions.setDeleteCardSuccess(false)),
                    put(actions.setDeleteCardFailed(true)),
                ]);
            }
            yield put(actions.savedCards({ client_id, clientAccessToken }));
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setDeleteCardLoading(false)),
                    put(actions.setDeleteCardFailed(true, responseData.message)),
                    put(actions.setDeleteCardSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Payment Method",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setDeleteCardLoading(false)),
                    put(actions.setDeleteCardFailed(true)),
                    put(actions.setDeleteCardSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Payment Method",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setDeleteCardLoading(false)),
            put(actions.setDeleteCardSuccess(false)),
            put(actions.setDeleteCardFailed(true)),
        ]);
    }
}

function* getAccountSpocInfoSaga({ payload: { client_id, clientAccessToken } }) {
    yield all([
        put(actions.setApiLoading(true)),
    ]);
    try {
        const response = yield call(
            getApiToken,
            apiUrl("GET_ACCOUNT_SPOC_INFO"),
            queryString.stringify({ client_id: client_id }),
            clientAccessToken
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield all([
                    put(actions.setApiLoading(false)),
                    put(actions.setSpocInfo(responseData.data)),
                ]);
            } else {
                yield all([
                    put(actions.setApiLoading(false)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setApiLoading(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Account SPOC Information",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setApiLoading(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Account SPOC Information",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setApiLoading(false)),
        ]);
    }
}