/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as myRequestActions } from "../../redux/reducers/MyRequestReducer";
import { useCookies } from "react-cookie";
import LoaderComponent from "../../components/Loader";
import EditRequestHeader from "../../components/EditRequestHeader";
import EditRequestContent from "../../components/EditRequestContent";
import EditRequestKitchenPhotos from "../../components/EditRequestKitchenPhotos";
import EditRequestAdditionalNotes from "../../components/EditRequestAdditionalNotes";
import { useHistory } from "react-router-dom";
import "./index.scss";

function EditRequest(props) {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const history = useHistory();
  const { requestDetailData, myRequestListingLoading } = useSelector(
    (state) => state.myRequest
  );
  
  useEffect(() => {
    dispatch(myRequestActions.hideSubNavbar(true));
    dispatch(
      myRequestActions.getRequestDetail(
        props.match.params.id,
        cookies.clientAccessToken
      )
    );
    
  }, []);
  useEffect(() => {
    if(requestDetailData && requestDetailData['request_status'] && requestDetailData['request_status']['status']){
      if(requestDetailData['request_status']['status'][0]['slug'] !== 'queued'){
        history.push("/app/client/myrequests/viewrequest/" + props.match.params.id);
      }
    }
  }, [requestDetailData]);
  return (
    <div className="container without-middle-row">
      <LoaderComponent showhide={myRequestListingLoading} />
      <div className="shadow-white-bg">
        <EditRequestHeader
          data={requestDetailData}
          requestId={props.match.params.id}
        />
        <EditRequestContent data={requestDetailData} />
        <EditRequestKitchenPhotos
          data={requestDetailData}
          requestId={props.match.params.id}
        />
        <EditRequestAdditionalNotes
          data={requestDetailData}
          requestId={props.match.params.id}
        />
      </div>
    </div>
  );
}

export default EditRequest;
