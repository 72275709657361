/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as designerRequestActions } from "../../redux/reducers/DesignerRequestReducer";
import { useCookies } from "react-cookie";
import LoaderComponent from "../../components/Loader";

import ViewRequestHeader from "../../components/ViewRequestHeader";
import ViewRequestStatus from "../../components/ViewRequestStatus";
import ViewRequestContent from "../../components/ViewRequestContent";
import ViewRequestForum from "../../components/ViewRequestForum";
import ViewRequestSubscribers from "../../components/ViewRequestSubscribers";

function ViewRequestDesigner(props) {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const { requestDetailData, myOpenRequestDataLoading } = useSelector(
    (state) => state.designerRequest
  );
  const { myRequestListingLoading } = useSelector((state) => state.myRequest);
  useEffect(() => {
    // dispatch(myRequestActions.hideSubNavbar(true));
    dispatch(
      designerRequestActions.getRequestDetail(
        props.match.params.id,
        cookies.clientAccessToken
      )
    );
    dispatch(designerRequestActions.changeRequestId(props.match.params.id));
  }, []);
  const customHtmlForLoader = {
    __html:`<div id="loader-for-comment"><div id="overlay"><div class="loader"><span id="fileUploadPercentage" style="display: block;"> %</span><div aria-busy="true" class=""><svg width="100" height="100" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" aria-label="audio-loading"><defs><linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a"><stop stop-color="#00BFFF" stop-opacity="0" offset="0%"></stop><stop stop-color="#00BFFF" stop-opacity=".631" offset="63.146%"></stop><stop stop-color="#00BFFF" offset="100%"></stop></linearGradient></defs><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)"><path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="#00BFFF" stroke-width="2"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite"></animateTransform></path><circle fill="#fff" cx="36" cy="18" r="1"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite"></animateTransform></circle></g></g></svg></div></div></div></div>`
  }
  return (
    <div className="container without-middle-row">
      <div dangerouslySetInnerHTML={customHtmlForLoader}>
      </div>
      <LoaderComponent
        showhide={myOpenRequestDataLoading || myRequestListingLoading}
      />
      <div className="shadow-white-bg">
        <ViewRequestHeader
          data={requestDetailData}
          id={props.match.params.id}
        />
        <ViewRequestContent data={requestDetailData} />
        <ViewRequestStatus data={requestDetailData} />
        <ViewRequestForum
          data={requestDetailData}
          requestId={props.match.params.id}
        />
        <ViewRequestSubscribers data={requestDetailData["subscribers"]} />
      </div>
    </div>
  );
}

export default ViewRequestDesigner;
