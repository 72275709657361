/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { actions as AuthActions } from "../../../../redux/reducers/MyAccountReducer";
import LoaderComponent from "../../../../components/Loader";
import { ModelConfirmation } from '../../../ReusableComponents/CustomComponents';

export default function PreviouslySavedAddresses({ handleToggleBillingAddressBook }) {
    const [isOpen, setIsOpen] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const dispatch = useDispatch();
    const [cookies] = useCookies(["clientAccessToken"]);
    const { setDefaultAddressLoading,
        editClientAddressLoading,
        deleteClientAddressLoading,
        getClientAddressLoading,
        getClientAddressResponse,
        setSuccessForDeleteClientAddress,
        setSuccessForEditClientAddress,
        setSuccessForSetDefaultAddress } = (useSelector(state => state?.myAccountReducer) || {});

    useEffect(() => {
        if (setSuccessForDeleteClientAddress) {
            getClientData()
            dispatch(AuthActions.clearDeleteClientAddressFlags());
        }
        if (setSuccessForSetDefaultAddress) {
            getClientData();
        }
    }, [setSuccessForDeleteClientAddress, setSuccessForSetDefaultAddress])

    const getClientData = () => {
        dispatch(AuthActions.getClientAddress({ clientAccessToken: cookies.clientAccessToken }));
    }

    useEffect(() => {
        if (setSuccessForEditClientAddress) {
            handleToggleBillingAddressBook();
        }
    }, [setSuccessForEditClientAddress])


    const handleDelete = (deleteId) => {
        dispatch(AuthActions.deleteClientAddress({ deleteId, clientAccessToken: cookies.clientAccessToken }));
    }

    const handleSetDefault = (id) => {
        dispatch(AuthActions.setDefaultAddress({ id, clientAccessToken: cookies.clientAccessToken }));
    }

    const handleEdit = (editClientId) => {
        dispatch(AuthActions.editClientAddress({ editClientId, clientAccessToken: cookies.clientAccessToken }));
    }

    const handleToggleDeleteModel = () => {
        setIsOpen(!isOpen)
    }

    const match = getClientAddressResponse.data?.dataTable?.data.find((a) => a.is_default === "1");

    const deleteConfirmationMessage = <div className="image-box">
        <p>Are you sure you want to delete this item?</p>
        <div className="buttons">
            <button className="common-button orange-btn" onClick={() => {
                handleDelete(deleteId)
                handleToggleDeleteModel();
            }}>Confirm</button>
            <button className="common-button border-btn" onClick={handleToggleDeleteModel}>Cancel</button>
        </div>
    </div>


return (
        <>
            <ModelConfirmation
                handleToggleModel={handleToggleDeleteModel}
                isOpen={isOpen}
                htmlElement={deleteConfirmationMessage}
            />
            <LoaderComponent showhide={(
                getClientAddressLoading||
                setDefaultAddressLoading || editClientAddressLoading || deleteClientAddressLoading)} />
            {
                match && <div className='inner-info-block'>
                    <div className='info-title'>
                        <h4>Default Billing Address</h4>
                        <i><img src={require("../../../../assets/images/edit-icon.png")} alt="Edit" onClick={() => { handleEdit(match.id) }} /></i>
                    </div>
                    <div className="edit-address-block full">
                        <ul>
                            <li>
                                <div className="account-address-list">
                                    <div className="radiobutton">
                                        <label htmlFor="test4">{match.street}<br></br> {match.street_2}<br></br> {match.city}  {match.region_name} {match.postcode}<br/>{match.country_name} </label>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>}
            <div className='save-address-block'>
                <div className='info-title account-title'>
                  {getClientAddressResponse.data?.dataTable?.data[0] && <h3>Other Addresses</h3>  }  
                </div>
                <div className="edit-address-block">
                    <ul>
                        {(getClientAddressResponse.data?.dataTable?.data || []).map((item) => {
                            return <li key={item.id}>
                                <div className="address-list">
                                    <span className="editable-link">
                                        <i>
                                            <img src={require("../../../../assets/images/edit-icon.png")} alt="Edit" onClick={() => { handleEdit(item.id) }} />
                                            <img src={require("../../../../assets/images/delete-icon.png")} onClick={() => {

                                                setDeleteId(item.id);
                                                handleToggleDeleteModel()
                                            }} alt="Delete" />
                                        </i>
                                    </span>
                                    <div className="radiobutton" onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleSetDefault(item.id)
                                    }} >
                                        <input
                                            type="radio"
                                            id="test4"
                                            name="radio-group"
                                            defaultChecked={item.is_default === "1"}
                                        />
                                        <label htmlFor="test4">{item.street} {item.street_2} {item.city} {item.region_name} {item.postcode} {item.country_name}</label>
                                    </div>
                                </div>
                            </li>
                        })}
                    </ul>
                </div>
            </div>
        </>
    )
}
