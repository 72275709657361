import React from "react";

import "./index.scss";

function ViewRequestSubscribers({ data }) {
  const renderSubscriber = () => {
    if (data) {
      return (
        <>
          {Object.keys(data).map((key, index) => {
            return (
              <li key={index} className="first">
                <span className="subscriber-name">
                  {data[key].substring(0, 2)}
                </span>
              </li>
            );
          })}
        </>
      );
    }
  };
  return (
    <div className="view-request-subscribers">
      <h3>Subscribers</h3>
      <p>
        {data && data.length > 0 ? data.length : 0} people will be notified when
        someone comments on this message.
      </p>
      <ul className="subscribers-list">{renderSubscriber()}</ul>
    </div>
  );
}

export default ViewRequestSubscribers;
