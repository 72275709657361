import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import "./index.scss";

function LoaderComponent({ showhide, isFileUpload, fileUploadPercentage }) {
  return (
    <div id={showhide ? "overlay" : ""}>
      <div className="loader">
        {isFileUpload? <span id="fileUploadPercentage" style={{display:showhide? "block":"none"}}>{fileUploadPercentage} %</span>: null }
        <Loader
          type="TailSpin"
          color="#00BFFF"
          height={100}
          width={100}
          visible={showhide}
        />
      </div>
    </div>
  );
}

export default LoaderComponent;
