import React, {useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { actions as AuthActions } from '../../redux/reducers/AuthReducer';
import "./index.scss";
import ResetPasswordForm from "../../components/ResetPasswordFrom";
import LoaderComponent from "../../components/Loader";
import { actions as generalActions } from '../../redux/reducers/GeneralReducer';
import ShortUniqueId from "short-unique-id";

function ResetPassword() {
  const dispatch = useDispatch();
  const history = useHistory();
  const uid = new ShortUniqueId();
  const { setSuccessForResetPassword, resetPasswordLoading } = useSelector(state => state.auth);
   useEffect(() => {
   if(setSuccessForResetPassword){
	dispatch(
        generalActions.addToast(
          "Reset Password",
          "Your password has been reset successfully",
          "success",
          uid()
        )
      );
   dispatch(AuthActions.clearResetPasswordFlags());
   history.push("/login");
   }
  }, [setSuccessForResetPassword, dispatch, history]);
  const restClicked = (email,password,confirmPassword,token) => {
    dispatch(AuthActions.resetPassword(email,password,confirmPassword,token));
  }
  return (
    <div>
      <LoaderComponent showhide={resetPasswordLoading} />
        <div className="login-wrapper">
          <div className="login-left">
            <ResetPasswordForm restClicked={(email,password,confirmPassword,token) =>restClicked(email,password,confirmPassword,token)}/>
          </div>
          <div className="login-right">
            <div className="login-title">
              <h2>
                Creating Beautiful<span>Kitchen Designs</span>
              </h2>
              <img
              src={require("../../assets/images/cabinet-img02.svg")}
              alt="Kitchen Cabinet Design"
            />
            </div>
            <div className="kitchen-cabinet">
              <img
                src={require("../../assets/images/kitchen-cabinet.png")}
                alt="Kitchen Cabinet Design"
              />
            </div>
          </div>
        </div>
        <section className="footer-container">
	<div className="footer-bottom">
		<div className="container">
			<div className="footer-bottom-outer">
				<div className="footer-column footer-col1">
					<p className="footer-site-info">COPYRIGHT © 2017-2023, KITCHEN365 INC. ALL RIGHTS RESERVED.</p>
				</div>
				<div className="footer-column footer-col2">
					<div className="footer-nav">
						<ul>
            <li><a href="https://www.kitchen365.com/digital-marketing/">Digital Marketing</a></li>
            <li><a href="https://www.kitchen365.com/kitchen-design/">Design Services</a></li>
							<li><a href="https://www.kitchen365.com/faqs/">FAQs</a></li>
							<li><a href="https://www.kitchen365.com/blog/">Blog</a></li>
							{/* <li><a href="https://www.kitchen365.com/resources/">Resources</a></li> */}
							<li><a href="https://www.kitchen365.com/careers/">Careers</a></li>
							<li><a href="https://www.kitchen365.com/platform-sign-up/">Marketing Platform Sign Up</a></li>
							<li><a href="https://www.kitchen365.com/ecommerce-platform-sign-up/">Ecommerce Platform Sign Up</a></li>
							{/* <li><a href="https://www.kitchen365.com/kbis/">KBIS 2022</a></li> */}
						</ul>
					</div>
				</div>
				<div className="footer-column footer-col3">
					<ul className="social-icons">
						<li>
							<a href="https://www.facebook.com/kitchen365com"><img src={require("../../assets/images/facebook-img.svg")} alt="Facebook" /><img src={require("../../assets/images/facebook-img-h.svg")} alt="Facebook" className="hover-img" /></a>
						</li>
						<li>
							<a href="https://www.linkedin.com/company/kitchen365com/"><img src={require("../../assets/images/linkedin-img.svg")} alt="Linkedin" /><img src={require("../../assets/images/linkedin-img-h.svg")} alt="Linkedin" className="hover-img" /></a>
						</li>
						<li className="twitter-img">
							<a href="https://twitter.com/kitchen365com"><img src={require("../../assets/images/twitter-img.svg")} alt="Twitter" /><img src={require("../../assets/images/twitter-img-h.svg")} alt="Twitter" className="hover-img" /></a>
						</li>
						<li className="instagram-img">
							<a href="https://www.instagram.com/kitchen.365/"><img src={require("../../assets/images/instagram-img.svg")} alt="Instagram" /><img src={require("../../assets/images/instagram-img-h.svg")} alt="Instagram" className="hover-img" /></a>
						</li>
						<li>
							<a href="https://www.pinterest.com/kitchen365/_created/"><img src={require("../../assets/images/pinterest-img.svg")} alt="Pitenrest" /><img src={require("../../assets/images/pinterest-img-h.svg")} alt="Pitenrest" className="hover-img" /></a>
						</li>
						<li>
							<a href="https://www.youtube.com/channel/UCalurDhpLOdbj-PlTpadcuQ"><img src={require("../../assets/images/youtube-img.svg")} alt="Youtube" /><img src={require("../../assets/images/youtube-img-h.svg")} alt="Youtube" className="hover-img" /></a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>
    </div>
  );
}

export default ResetPassword;
