import React from "react";
import { useSelector } from "react-redux";

import { NavLink } from "react-router-dom";

import "./index.scss";

function SubNavBar() {
  const {
    hideNavbar
  } = useSelector((state) => state.myRequest);
  const { user, indicationStatus } = useSelector((state) => state.auth);
  return (
    <div style={{ display: hideNavbar ? "none" : "" }} className="sub-navbar">
      <div className="container cf">
        <ul className="step-list">
        <li>
            <NavLink
              to="/app/client/myrequests/all-requests"
              title="All Requests"
              activeClassName="active"
              className="your-queue"
            >
              All
            </NavLink>
            {indicationStatus && indicationStatus['all-requests'] && indicationStatus['all-requests'] == 1?<span className="indication-tab"></span>:null}
          </li>
          <li>
            <NavLink
              to="/app/client/myrequests/open-requests"
              title="Open Requests"
              activeClassName="active"
              className="your-queue"
            >
              Open Requests
            </NavLink>
            {indicationStatus && indicationStatus['open-requests'] && indicationStatus['open-requests'] == 1?<span className="indication-tab"></span>:null}
          </li>
          <li>
            <NavLink
              to="/app/client/myrequests/wip"
              title="WIP"
              activeClassName="active"
              className="designs-delivered"
            >
              WIP
            </NavLink>
            {indicationStatus && indicationStatus['wip'] && indicationStatus['wip'] == 1?<span className="indication-tab"></span>:null}
          </li>
          <li>
            <NavLink
              to="/app/client/myrequests/needs-information"
              title="Needs Information "
              activeClassName="active"
              className="your-drafts"
            >
              Needs Information 
            </NavLink>
            {indicationStatus && indicationStatus['needs-information'] && indicationStatus['needs-information'] == 1?<span className="indication-tab"></span>:null}
          </li>
          <li>
            <NavLink
              to="/app/client/myrequests/needs-approval"
              title="Needs Approval"
              activeClassName="active"
              className="designs-delivered"
            >
              Needs Approval
            </NavLink>
            {indicationStatus && indicationStatus['needs-approval'] && indicationStatus['needs-approval'] == 1?<span className="indication-tab"></span>:null}
          </li>
          <li>
            <NavLink
              to="/app/client/myrequests/closed"
              title="Closed"
              activeClassName="active"
              className="designs-delivered"
            >
              Closed
            </NavLink>
            {indicationStatus && indicationStatus['closed'] && indicationStatus['closed'] == 1?<span className="indication-tab"></span>:null}
          </li>
          {user?.group_id === 4 ? null : <li>
            <NavLink
              to="/app/client/myrequests/yourdrafts"
              title="Your Drafts"
              activeClassName="active"
              className="your-drafts"
            >
              Your Drafts
            </NavLink>
          </li>}
          {user?.is_all_tab_prioritisation_enabled == 0 ? <li>
            <NavLink
              to="/app/client/myrequests/prioritized"
              title="Prioritized"
              activeClassName="active"
              className="prioritized"
            >
              Prioritized
            </NavLink>
            {indicationStatus && indicationStatus['prioritized'] && indicationStatus['prioritized'] == 1?<span className="indication-tab"></span>:null}
          </li>:null}
        </ul>
      </div>
    </div>
    
  );
}

export default SubNavBar;
