/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as designerRequestActions } from "../../redux/reducers/DesignerRequestReducer";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import LoaderComponent from "../Loader";

import "./index.scss";
import RequestListItem from "../RequestListItem";
import ClientRequestsHeader from "../../components/ClientRequestsHeader";
import { actions as AuthActions } from '../../redux/reducers/AuthReducer';

function PriortizeRequestList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);

  const {
    allRequestsData,
    allRequestDataLoading,
    filters,
    start,
    length,
    clientSelectedData
  } = useSelector((state) => state.designerRequest);
  const { indicationStatus } = useSelector((state) => state.auth);
  const [ratingCopyTimeSpentFlag, setRatingCopyTimeSpentFlag] = useState(false);
  const [hideDeleteIcon, setHideDeleteIcon] = useState(true);
  const [initialStatus, setInitialStatus] = useState(false);
  let intervalID;
  useEffect(() => {
    setInitialStatus(indicationStatus);
    dispatch(designerRequestActions.changeStartFromMyOpenRequest(0));
    dispatch(AuthActions.getTabIndicationStatus(clientSelectedData,cookies.clientAccessToken));
    intervalID = setInterval(() => {
      dispatch(AuthActions.getTabIndicationStatus(clientSelectedData,cookies.clientAccessToken));
    }, 30000);
    dispatch(
      designerRequestActions.getPrioritizeRequestData(
        0,
        length,
        cookies.clientAccessToken
      )
    );
    dispatch(designerRequestActions.changeClientPageActiveTabName("Open Requests"));
    return () => {
      //componentWillUnmount
      clearInterval(intervalID)
      dispatch(designerRequestActions.changeClientPageActiveTabName(""));
    }
  }, []);
  useEffect(()=>{
    if(initialStatus && indicationStatus){
      if(initialStatus['prioritized'] !== indicationStatus['prioritized']){
        dispatch(designerRequestActions.changeStartFromMyOpenRequest(0));
        dispatch(
          designerRequestActions.getPrioritizeRequestData(
            0,
            length,
            cookies.clientAccessToken
          )
        );
        setInitialStatus(indicationStatus);
      }
    }
  },[indicationStatus])

  const viewRequest = (id) => {
    dispatch(designerRequestActions.changeRequestId(id));
  };

  const viewRequestUrl = (id) => {
    return ("/app/designer/allclients/clientrequests/viewrequest/" + id);
  };

  const loadMoreRequest = () => {
    let nextRecord = start + length; //10;
    dispatch(designerRequestActions.changeStartFromMyOpenRequest(nextRecord));
    dispatch(
      designerRequestActions.getPrioritizeRequestData(
        nextRecord,
        length,
        cookies.clientAccessToken
      )
    );
  };
  return (
    <div>
      <LoaderComponent showhide={allRequestDataLoading} />
      <ClientRequestsHeader
        showClientName={true}
        myOpenRequestData={allRequestsData}
        allRequest={true}
        requestSection={'priortize'}
      />
      <RequestListItem
        ratingCopyTimeSpentFlag={ratingCopyTimeSpentFlag}
        data={allRequestsData}
        loadMoreRequest={loadMoreRequest}
        viewRequest={viewRequest}
        viewRequestUrl={viewRequestUrl}
        hideDeleteIcon={hideDeleteIcon}
        requestStatusSection={'designerprioritizedtab'}
        isAllPriortise={false}
      />
    </div>
  );
}

export default PriortizeRequestList;
