import React, {useEffect} from "react";
import {  
  Switch,
  Route,  
  Redirect,
} from "react-router-dom";

import "./index.scss";
import ViewRequestDesigner from "../ViewRequestDesigner";
import { actions as designerRequestActions } from "../../redux/reducers/DesignerRequestReducer";
import { useDispatch } from "react-redux";
import DesignerSubNavBar from "../../components/DesignerSubNavBar";

import MyOpenRequestList from "../../components/MyOpenRequestList";
function MyOpenRequest() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(designerRequestActions.changeStartFromMyOpenRequest(0));
  },[])
  return (
    <div>
      <DesignerSubNavBar from="myrequests" />
      <Switch>
      <RedirectRoute exact path="/app/designer/myrequest" />
      <Route exact path="/app/designer/myrequest/all-requests">
          <MyOpenRequestList requestStatusSection="all-requests" />
        </Route>
        <Route exact path="/app/designer/myrequest/open-requests">
          <MyOpenRequestList requestStatusSection="open-requests" />
        </Route>
        <Route exact path="/app/designer/myrequest/wip">
          <MyOpenRequestList requestStatusSection="wip" />
        </Route>
        {/* <Route exact path="/app/designer/myrequest/priortize">
          <MyOpenRequestList requestStatusSection="priortize" />
        </Route> */}
        <Route exact path="/app/designer/myrequest/needs-information">
          <MyOpenRequestList requestStatusSection="needs-information" />
        </Route>
        <Route exact path="/app/designer/myrequest/closed">
          <MyOpenRequestList requestStatusSection="closed" />
        </Route>
        <Route exact path="/app/designer/myrequest/needs-approval">
          <MyOpenRequestList requestStatusSection="needs-approval" />
        </Route>
        <Route
          path="/app/designer/myrequest/viewrequest/:id"
          component={ViewRequestDesigner}
        />
      </Switch>
    </div>
  );
}

function RedirectRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: "/app/designer/myrequest/all-requests",
            state: { from: location },
          }}
        />
      )}
    />
  );
} 
export default MyOpenRequest;
