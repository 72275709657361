import * as Yup from "yup";

export const formFields = {
    old_password: {
        key: "old_password",
        type: "password",
        name: "old_password",
        className: "form-control",
        placeholder: "Enter your Current Password",
        label: "Current Password",
        htmlFor: "old_password",
        required: true,
    },
    new_password: {
        key: "new_password",
        type: "password",
        className: "form-control",
        name: "new_password",
        placeholder: "Enter your New Password",
        label: "New Password",
        htmlFor: "new_password",
        required: true,
    },
    confirm_password: {
        key: "confirm_password",
        type: "password",
        className: "form-control",
        name: "confirm_password",
        placeholder: "Enter your Re-enter new password",
        label: "Re-enter new password",
        htmlFor: "confirm_password",
        required: true,
    }
}

export const validationSchemaChangePasswordForm = Yup.object().shape({
    old_password: Yup.string().required('Current Password is Required.').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/, "One capital One small and One Numeric."),
    new_password: Yup.string().required('New Password is Required.').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/, "One capital One small and One Numeric."),
    confirm_password: Yup.string().required('Re-enter new password is Required.').oneOf([Yup.ref('new_password'), null], 'Passwords  must match.').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/, "One capital One small and One Numeric."),
});