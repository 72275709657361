/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, memo, useEffect } from 'react'
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { formFields, validationSchemaUserContactInformationForm } from './constant'
import { Input } from '../../../ReusableComponents';
import { actions as AuthActions } from "../../../../redux/reducers/MyAccountReducer";

function UserContactInformation({ handleToggleUserContactInformation }) {
    const [state, setstate] = useState({ name: '', email: '', phone: '', company_name: '', phone_code: "1" });
    const dispatch = useDispatch();
    const [cookies] = useCookies(["clientAccessToken"]);
    const { myAccountReducer: { getAccountInfoResponse: { data: { client_info = {} } = {} } = {} } } = useSelector((state) => state);

    useEffect(() => {
        if (client_info.hasOwnProperty("id")) {
            setstate({ ...state, ...client_info })
        }
    }, [client_info])

    const handleChange = ({ target: { name, value } }) => {
        setstate({ ...state, [name]: value });
    }

    return (
        <div className='left-info-block account-info-contact account-editform-block'>
            <div className='inner-info-block'>
                <div className='info-title'>
                    <h4>Contact Information</h4>
                    <i><img src={require("../../../../assets/images/edit-icon.png")} alt="Edit" /></i>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={state}
                    handleChange={handleChange}
                    validationSchema={validationSchemaUserContactInformationForm}
                    onSubmit={(values, actions) => {
                        dispatch(AuthActions.accountInfoUpdate({ ...values, clientAccessToken: cookies.clientAccessToken }));
                    }}
                >
                    {({ touched, errors, values, handleBlur, handleChange, handleSubmit }) => (
                        <div className='form-outer'>
                            <ul>
                                <li>
                                    <div className="form-group">
                                        <Input
                                            setting={formFields.name}
                                            touched={touched[formFields.name.key]}
                                            errors={errors[formFields.name.key]}
                                            value={values[formFields.name.key]}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <Input
                                            setting={formFields.email}
                                            touched={touched[formFields.email.key]}
                                            errors={errors[formFields.email.key]}
                                            value={values[formFields.email.key]}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <Input
                                            setting={formFields.phone}
                                            touched={touched[formFields.phone.key]}
                                            errors={errors[formFields.phone.key]}
                                            value={values[formFields.phone.key]}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <Input
                                            setting={formFields.company_name}
                                            touched={touched[formFields.company_name.key]}
                                            errors={errors[formFields.company_name.key]}
                                            value={values[formFields.company_name.key]}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </li>
                            </ul>
                            <div className='buttons'>
                                <button className='common-button' onClick={handleSubmit}>update</button>
                                <button onClick={handleToggleUserContactInformation} className='common-button border-btn'>CANCEL</button>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    )
}
export default memo(UserContactInformation)