// Packages Import
import React, { memo, useState, useCallback, useMemo, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Button } from 'react-bootstrap';
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-date-picker";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
// Compnent Imports
import FiltersTag from './FiltersTag';

// Icon
import SettingsSvg from '../../assets/images/settings.svg';

// Style Imports
import "./index.scss";
import { actions as designerRequestActions } from "../../redux/reducers/DesignerRequestReducer";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";

const Filters = (props, ref) => {
    // Variables
    const {
        requestTypeList = {},
        statusList = {},
        createdByList = {},
        assignedToList = {},
        onChangeHandler,
        filterOptions = ['request_title', 'request_number', 'request_type', 'request_status', 'created_by', 'assign_to', 'start_date', 'end_date', 'due_start_date', 'due_end_date', 'client_ids', 'has_updates'], // Expecting - ['request_title', 'request_number', 'request_type', 'request_status', 'created_by', 'assign_to', 'start_date', 'end_date']
        companyList = {},
        allRequest,
        requestStatusSection,
    } = props;
    console.log('requestStatusSection',requestStatusSection)
    const dispatch = useDispatch();
    const [cookies] = useCookies(["clientAccessToken"]);
    const requestTitleVisibility = useMemo(() => !filterOptions.includes('request_title'), [filterOptions]);
    const requestNumberVisibility = useMemo(() => !filterOptions.includes('request_number'), [filterOptions]);
    const requestTypeVisibility = useMemo(() => !filterOptions.includes('request_type'), [filterOptions]);
    const requestStatusVisibility = useMemo(() => !filterOptions.includes('request_status'), [filterOptions]);
    const createdByVisibility = useMemo(() => !filterOptions.includes('created_by'), [filterOptions]);
    const assignToVisibility = useMemo(() => !filterOptions.includes('assign_to'), [filterOptions]);
    const dateRangeVisibility = useMemo(
        () => !(filterOptions.includes('start_date') || filterOptions.includes('end_date')),
        [filterOptions]
    );

    const dueDateRangeVisibility = useMemo(
        () => !(filterOptions.includes('due_start_date') || filterOptions.includes('due_end_date')),
        [filterOptions]
    );

    const companyVisibility = useMemo(() => (!filterOptions.includes('client_ids') || allRequest), [filterOptions]);
    const hasUpdatesVisibility = useMemo(() => (!filterOptions.includes('has_updates')), [filterOptions]);

    // State
    const [getOpen, setOpen] = useState(false);                                                         // Boolean
    const [getFilter, setFilter] = useState({});                                                        // Object - All Filters in object
    const [getAppliedFilters, setAppliedFilters] = useState({});                                        // Object - Actual Applied filters
    const [getRequestType, setRequestType] = useState([]);                                              // Array
    const [getStatus, setStatus] = useState([]);                                                        // Array
    const [getCreatedBy, setCreatedBy] = useState([]);                                                  // Array
    const [getAssignedTo, setAssignedTo] = useState([]);                                                // Array
    const [getCreatedOnStartDate, setCreatedOnStartDate] = useState('');                                // string
    const [getCreatedOnEndDate, setCreatedOnEndDate] = useState('');                                    // string
    const [getRequestTitle, setRequestTitle] = useState('');                                            // string
    const [getRequestNumber, setRequestNumber] = useState('');                                          // string
    const [getChangedStateBoolOnTagRemove, setChangedStateBoolOnTagRemove] = useState(false);           // Boolean - setting `TRUE` if single `tag` close button clicked.
    const [getIsFilteredApplied, setIsFilteredApplied] = useState(false);                               // Boolean - setting `TRUE` if `apply` button clicked
    const [getDueStartDate, setDueStartDate] = useState('');                                // string
    const [getDueEndDate, setDueEndDate] = useState('');  
    const [getCompany, setCompany] = useState([]);
    const [getHasUpdates, setHasUpdates] = useState(0); 
    const { user } = useSelector((state) => state.auth);
    //Helper Functions
    const convertFiltersForAPI = useCallback(
        (filterData) => {
            const filters = {};
            try {
                for (const [key, value] of Object.entries(filterData || getFilter)) {
                    filters[key] = Array.isArray(value) ? value.map(data => data.value) : value;
                }
            } catch (error) {
                console.error('Handled Error!', error);
            }
            return filters;

        },
        [getFilter],
    )

    const getFormatedDate = useCallback(
        (date) => {
            try {
                const dateObj = new Date(date);
                return `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1)}-${dateObj.getDate()}`;
            } catch (error) {
                console.error('Handled Error!', error);
                return '';
            }
        },
        [],
    )

    /**
     * Generating selected filter object by using inner state data
     */
    const generateFiltersObject = () => {
        try {
            const filtersObj = {};
            filterOptions.forEach((option, index) => {
                switch (option) {
                    case 'request_type':
                        if (getRequestType.length) filtersObj[option] = getRequestType;
                        return;
                    case 'request_status':
                        if (getStatus.length) filtersObj[option] = getStatus;
                        return;
                    case 'created_by':
                        if (getCreatedBy.length) filtersObj[option] = getCreatedBy;
                        return;
                    case 'assign_to':
                        if (getAssignedTo.length) filtersObj[option] = getAssignedTo;
                        return;
                    case 'start_date':
                        if (getCreatedOnStartDate) filtersObj[option] = getFormatedDate(getCreatedOnStartDate);
                        return;
                    case 'end_date':
                        if (getCreatedOnEndDate) filtersObj[option] = getFormatedDate(getCreatedOnEndDate);
                        return;
                    case 'request_title':
                        if (getRequestTitle) filtersObj[option] = getRequestTitle;
                        return;
                    case 'request_number':
                        if (getRequestNumber) filtersObj[option] = getRequestNumber;
                        return;
                    case 'due_start_date':
                        if (getDueStartDate) filtersObj[option] = getFormatedDate(getDueStartDate);
                        return;
                    case 'due_end_date':
                        if (getDueEndDate) filtersObj[option] = getFormatedDate(getDueEndDate);
                        return;   
                    case 'client_ids':
                            if (getCompany.length) filtersObj[option] = getCompany;
                            return;  
                    case 'has_updates':
                        if (getHasUpdates) filtersObj[option] = getHasUpdates;
                        return;             
                    default:
                        return;
                }
            });
            setFilter(filtersObj);
        } catch (error) {
            console.error('Handled Error!', error);
        }
    }

    /**
     * Removing perticular filter option from state.
     * @param {string} category 
     * @param {string} label 
     * @returns 
     */
    const onFilterOptionRemove = (category, value) => {
        try {
            switch (category) {
                case 'request_type':
                    setRequestType(prevState => prevState.filter(obj => obj?.value !== value));
                    break;
                case 'request_status':
                    setStatus(prevState => prevState.filter(obj => obj?.value !== value));
                    break;
                case 'created_by':
                    setCreatedBy(prevState => prevState.filter(obj => obj?.value !== value));
                    break;
                case 'assign_to':
                    setAssignedTo(prevState => prevState.filter(obj => obj?.value !== value));
                    break;
                case 'start_date':
                    setCreatedOnStartDate('');
                    setCreatedOnEndDate('');
                    break;
                case 'end_date':
                    setCreatedOnStartDate('');
                    setCreatedOnEndDate('');
                    break;
                case 'request_title':
                    setRequestTitle('');
                    break;
                case 'request_number':
                    setRequestNumber('');
                    break;
                case 'due_start_date':
                    setDueStartDate('');
                    setDueEndDate('');
                    break;
                case 'due_end_date':
                    setDueStartDate('');
                    setDueEndDate('');
                    break;  
                case 'client_ids':
                    setCompany(prevState => prevState.filter(obj => obj?.value !== value));
                    break;
                case 'has_updates':
                    setHasUpdates(0);
                    break;    
                default:
                    break;
            }
            if (getIsFilteredApplied) {
                // Already once filter applied.
                setTimeout(() => {
                    setChangedStateBoolOnTagRemove(true); // this is to indirectly call `onChangeHandler` function with updated filter data. 
                }, 100);
            }
        } catch (error) {
            console.error('Handled Error!', error);
        }
    }

    const clearAllFilters = useCallback(
        (v) => {
            setRequestType([]);
            setStatus([]);
            setCreatedBy([]);
            setAssignedTo([]);
            setCreatedOnStartDate('');
            setCreatedOnEndDate('');
            setRequestTitle('');
            setRequestNumber('');
            onChangeHandler({});
            setAppliedFilters({});
            setIsFilteredApplied(false);
            setDueStartDate('');
            setDueEndDate('');
            setCompany([]);
            setHasUpdates(0);
            if(v !== 1){
                dispatch(designerRequestActions.setFilterNotChangeByApi(true));
            } 
        },
        [],
    )


    useImperativeHandle(ref, () => ({
        clearAllFilters: clearAllFilters
      }));

    //Events
    const handleFilterApplyButton = useCallback(
        () => {
            setIsFilteredApplied(true);
            onChangeHandler(convertFiltersForAPI(getFilter));
            dispatch(designerRequestActions.setFilterNotChangeByApi(true));
            setAppliedFilters(getFilter);
        }, [onChangeHandler, getFilter, convertFiltersForAPI]
    );

    //Effect
    useEffect(generateFiltersObject, [
        getRequestType,
        getStatus,
        getCreatedBy,
        getAssignedTo,
        getCreatedOnStartDate,
        getCreatedOnEndDate,
        getRequestTitle,
        getRequestNumber,
        getDueStartDate,
        getDueEndDate,
        getCompany,
        getHasUpdates,
    ]);                                                                 // regenrating filters on any filter state change.

    useEffect(() => {
        if (getChangedStateBoolOnTagRemove) {
            handleFilterApplyButton();
            setChangedStateBoolOnTagRemove(false);
        }
    }, [getChangedStateBoolOnTagRemove]);                               // Api call on tag removed

    useEffect(() => {
        // componentDidMount
        return () => {
            // componentWillUnmount
            onChangeHandler({}, true);
        }
    }, []);                                                             // lifecycle

    useEffect(()=>{
        if(getCompany.length){
            let temp = [];
            getCompany.forEach(function(i){
                temp.push(i.value)
            });
            dispatch(designerRequestActions.getUsersByCompany(temp.toString(), cookies.clientAccessToken));
        }else{
            if (user && user.group_id) {
                if (user.group_id === 3) {
            dispatch(designerRequestActions.getUsersByCompany("", cookies.clientAccessToken));
                }
            }
        }
    },[getCompany])

    return (
        <>
            <Button
                id="FilterOptionButton"
                onClick={() => setOpen(!getOpen)}
                aria-controls="FilterOptionContainer"
                aria-expanded={getOpen}
            >
                {getOpen ? <span></span> : <img height="20" width="20" src={SettingsSvg} alt='filter icon' />} Filter & Refine
            </Button>
            {getOpen ? (
                <>
                    <div id="FilterOptionContainer">
                        {/* ---------------------------- Request Title --------------------------- */}
                        <InputOption
                            key="RequestTitleFilterOption"
                            Label='Request Title:'
                            Id="RequestTitleFilterOption"
                            Placeholder='Eg. kitchen...'
                            Value={getRequestTitle}
                            OnChange={setRequestTitle}
                            DoNotRender={requestTitleVisibility}
                        />
                        {/* ---------------------------- Request Number --------------------------- */}
                        <InputOption
                            key="RequestNumberFilterOption"
                            Label='Request Number:'
                            Id="RequestNumberFilterOption"
                            Placeholder='Eg. #CP-37-254...'
                            Value={getRequestNumber}
                            OnChange={setRequestNumber}
                            DoNotRender={requestNumberVisibility}
                        />
                        {/* ---------------------------- Request Type --------------------------- */}
                        <DropDownOption
                            key="RequestTypeFilterOption"
                            Label='Request Type:'
                            Id="RequestTypeFilterOption"
                            List={requestTypeList}
                            Value={getRequestType}
                            OnChange={setRequestType}
                            DoNotRender={requestTypeVisibility}
                        />
                        {/* ---------------------------- Status --------------------------- */}
                        {requestStatusSection == "all-requests" &&<DropDownOption
                            key="StatusFilterOption"
                            Label='Status:'
                            Id="StatusFilterOption"
                            List={statusList}
                            Value={getStatus}
                            OnChange={setStatus}
                            DoNotRender={requestStatusVisibility}
                        />}
                        {/* ---------------------------- Company --------------------------- */}
                        <DropDownOption
                            key="CompanyOption"
                            Label='Company:'
                            Id="CompanyOption"
                            List={companyList}
                            Value={getCompany}
                            OnChange={setCompany}
                            DoNotRender={companyVisibility}
                        />
                        {/* ---------------------------- Created By --------------------------- */}
                        <DropDownOption
                            key="CreatedByFilterOption"
                            Label='Created By:'
                            Id="CreatedByFilterOption"
                            List={createdByList}
                            Value={getCreatedBy}
                            OnChange={setCreatedBy}
                            DoNotRender={createdByVisibility}
                        />
                        {/* ---------------------------- Assigned To --------------------------- */}
                        <DropDownOption
                            key="AssignedToFilterOption"
                            Label='Designer'
                            Id="AssignedToFilterOption"
                            List={assignedToList}
                            Value={getAssignedTo}
                            OnChange={setAssignedTo}
                            DoNotRender={assignToVisibility}
                        />
                        {/* ---------------------------- Created On --------------------------- */}
                        <DateRangeSelector
                            key="CreatedOnFilterOption"
                            Label='Created On:'
                            Id="CreatedOnFilterOption"
                            start={getCreatedOnStartDate}
                            end={getCreatedOnEndDate}
                            handleStart={setCreatedOnStartDate}
                            handleEnd={setCreatedOnEndDate}
                            DoNotRender={dateRangeVisibility}
                        />
                        {/* ---------------------------- Due Date --------------------------- */}
                        <DateRangeSelector
                            key="DueDateFilterOption"
                            Label='Due Date:'
                            Id="DueDateFilterOption"
                            start={getDueStartDate}
                            end={getDueEndDate}
                            handleStart={setDueStartDate}
                            handleEnd={setDueEndDate}
                            DoNotRender={dueDateRangeVisibility}
                        />
                        <CheckboxOption
                            key="HasUpdatesOption"
                            Label='Any Updates'
                            Id="HasUpdatesOption"
                            Placeholder='has updates'
                            Value={getHasUpdates}
                            OnChange={setHasUpdates}
                            DoNotRender={hasUpdatesVisibility}
                        />
                        {/* ---------------------------- Apply Button --------------------------- */}
                        <div className="apply-btn-container">
                            <Button
                                id="FilterApplyButton"
                                onClick={handleFilterApplyButton}
                                disabled={isEmpty(getFilter) || isEqual(getFilter, getAppliedFilters)}
                            >
                                Apply
                            </Button>
                        </div>
                    </div>
                </>
            ) : null}
            {getIsFilteredApplied ? (
                <FiltersTag
                    tagsData={getAppliedFilters}
                    filterOptionsNames={filterOptions}
                    onFilterOptionRemove={onFilterOptionRemove}
                    clearAllFilters={clearAllFilters}
                />
            ) : null}
        </>
    );
}

const DropDownOption = memo(({ Id, Label, List, Value, OnChange, DoNotRender }) => {
    if (DoNotRender) {
        return null;
    }

    const options = useMemo(() => {
        if (Array.isArray(List)) {
            return (List.map(item => ({ label: String(item["name"]), value: String(item["id"]) })));
        } else {
            return (Object.keys(List).map(key => ({ label: String(List[key]["name"]), value: String(List[key]["id"]) })));
        }
    }, [List]);

    const valueRenderer = useCallback(
        (selected, _options) => {
            if (!selected.length)
                return 'Select';
            else if (selected.length === _options.length)
                return 'All Selected';
            else if (selected.length === 1)
                return selected[0].label;
            else if (selected.length > 1)
                return `${selected.length} Selected`;
        },
        [],
    );

    return (
        <span id={Id} className="filter-options" style={{maxWidth: Label == "Designer:" ? "18.6%":""}}>
            <label>{Label}</label>
            <MultiSelect
                key={Id}
                options={options}
                value={Value}
                onChange={OnChange}
                labelledBy="Select"
                hasSelectAll
                valueRenderer={valueRenderer}
            />
        </span>
    )
})

const DateRangeSelector = memo(({ Id, Label, start, end, handleStart, handleEnd, DoNotRender }) => {
    if (DoNotRender) {
        return null;
    }

    return (
        <span id={Id} className="filter-options">
            <label>{Label}</label>
            <div className="date-range-container">
                <DatePicker
                    key="createdOnStartDate"
                    value={start}
                    onChange={handleStart}
                    format="y-MM-dd"
                    maxDate={end || undefined}
                />

                <DatePicker
                    key="createdOnEndDate"
                    value={end}
                    onChange={handleEnd}
                    format="y-MM-dd"
                    minDate={start || undefined}
                />
            </div>
        </span>
    );
})

const InputOption = memo(({ Id, Label, Value, Placeholder, OnChange, DoNotRender }) => {
    if (DoNotRender) {
        return null;
    }

    const handleOnChange = useCallback(
        (e) => {
            OnChange(e.target.value)
        },
        [OnChange],
    )

    return (
        <span id={Id} className="filter-options">
            <label>{Label}</label>
            <input
                value={Value}
                title={Value}
                onChange={handleOnChange}
                type="text"
                className="input-text"
                placeholder={Placeholder}
            />
        </span>
    );
})

const CheckboxOption = memo(({ Id, Label, Value, Placeholder, OnChange, DoNotRender }) => {
    if (DoNotRender) {
        return null;
    }

    const handleOnChange = useCallback(
        (e) => {
            OnChange(e.target.checked?1:0)
        },
        [OnChange],
    )

    return (
        <span id={Id} className="filter-options" style={{minWidth: "initial"}}>
            <input
                id="has-option"
                value={Value}
                title={Value}
                onChange={handleOnChange}
                type="checkbox"
                className="input-text"
                placeholder={Placeholder}
                checked={Value == 1}
            />
            <label for="has-option">{Label}</label>
        </span>
    );
})

export default memo(forwardRef(Filters));