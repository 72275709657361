import React, { memo } from 'react'
import PropTypes from 'prop-types';
import Label from '../Label'
import { Error } from '../../CustomComponents'
import { getFieldCSSClasses } from '../../../../Utils/utils';

/**
 * This is  Select function Component. 
 * @param {Object} setting  Setting  Pass Object Value.
 * @param {String} value Value Pass String Value.
 * @param {Function} onChange OnChange Pass Function Value.
 * @param {Function} onBlur OnBlur Pass Function Value.
 * @param {Boolean} touched Touched Pass Boolean Value.
 * @param {String} errors Errors Pass String Value.
 * @returns  Select HTML Element
 * @author Vishal Gohel <vishal.gohel@commercepundit.com>
 * 
 *  Notes:- Jan 07 2022 (Vishal Gohel <vishal.gohel@commercepundit.com>)  Component Created
 */

const Select = ({ setting: { key, name, label, htmlFor, required, className, placeholder, selectkeyObj },
    value, onChange, onBlur, touched, errors, optionData
}) => (
    <>
        {label && <Label label={label} htmlFor={htmlFor} required={required} />}
        <select
            id={key}
            name={name}
            value={value}
            className={`${className ? className : ""} ${getFieldCSSClasses({ touched, errors })}`}
            onChange={onChange}
            onBlur={onBlur} >
               <option value="">{placeholder}</option>
            {optionData.map((item) => {
                return <option key={item[selectkeyObj.value]} value={item[selectkeyObj.value]}>{item[selectkeyObj.text]}</option>
            })}
        </select>
        {touched && <Error label={label} touched={touched} errors={errors} />}
    </>
);

Select.propTypes = {
    setting: PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        className: PropTypes.string,
        label: PropTypes.string,
        htmlFor: PropTypes.string,
        required: PropTypes.bool,
        selectkeyObj:PropTypes.shape({
            value:PropTypes.string.isRequired,
            text:PropTypes.string.isRequired
        })
    }),
    touched: PropTypes.bool,
    errors: PropTypes.string,
    value:  PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    optionData:PropTypes.array,
};

Select.defaultProps = {
    setting: {
        key: "",
        name: "",
        className: "",
        label: "",
        htmlFor: "",
        placeholder:"",
        required: false,
        selectkeyObj:{value:"", text:""}
    },
    touched: false,
    errors: "",
    value: "",
    optionData:[],
    onChange: () => { },
    onBlur: () => { },
}

export default memo(Select)