import * as Yup from "yup";

export const formFields = {
    name: {
        key: "name",
        type: "text",
        name: "name",
        className:"input-text",
        placeholder: "Enter your Name",
        label: "Name",
        htmlFor: "name",
        required: true,
    },
    email: {
        key: "email",
        type: "text",
        className:"input-text",
        name: "email",
        placeholder: "Enter your Email address",
        label: "Email address",
        htmlFor: "email",
        required: true,
    },
    password: {
        key: "password",
        type: "password",
        className:"input-text",
        name: "password",
        placeholder: "Enter your Password",
        label: "Password",
        htmlFor: "password",
        required: true,
    },
    phone: {
        key: "phone",
        type: "text",
        className:"input-text",
        name: "phone",
        placeholder: "Enter your Phone Number",
        label: "Phone",
        htmlFor: "phone",
        required: true,
        maxlength: "10"
    },
    company_name: {
        key: "company_name",
        type: "text",
        className:"input-text",
        name: "company_name",
        placeholder: "Enter your Company",
        label: "Company",
        htmlFor: "company",
        required: true,
    }
}

export const validationSchemaSignupForm = Yup.object().shape({
    name: Yup.string().required('Name is Required.'),
    email: Yup.string().required("Email is required").email("Enter valid email"),
    password: Yup.string().required('Password is Required.'),
    phone: Yup.string().required('Phone is Required.').matches(/^\d+$/, "The field should have digits only.").min(10, "Please enter valid Phone."),
    company_name: Yup.string().required('Company is Required.'),
  });