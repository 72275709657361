import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions as designerRequestActions } from "../../redux/reducers/DesignerRequestReducer";

import "./index.scss";

function DesignerSubNavBar({from}) {
  const dispatch = useDispatch();
  const {
    priorityForAllTab
  } = useSelector((state) => state.designerRequest);
  const changeSortByFilter = (section) =>{
    dispatch(designerRequestActions.changeSortByFilter("created_at"));
    dispatch(designerRequestActions.setActiveSection(section));
  }
  const { user, indicationStatus } = useSelector((state) => state.auth);
  return (
    <div className="designersubnavbar" style={{display: window.location.pathname.includes('viewrequest')?'none':''}}>
      <div className="container cf">
        <ul className="step-list">
          <li>
            <NavLink
              onClick={()=>{changeSortByFilter('all-requests')}}
              to={from?"/app/designer/myrequest/all-requests":"/app/designer/allclients/clientrequests/all-requests"}
              title="All Requests"
              activeClassName="active"
              className="all-requests"
            >
              All
            </NavLink>
            {indicationStatus && indicationStatus['all-requests'] && indicationStatus['all-requests'] == 1?<span className="indication-tab"></span>:null}
          </li>
          <li>
            <NavLink
              onClick={()=>{changeSortByFilter('open-requests')}}
              to={from?"/app/designer/myrequest/open-requests":"/app/designer/allclients/clientrequests/open-requests"}
              title="All Requests"
              activeClassName="active"
              className="all-requests"
            >
              Open Requests
            </NavLink>
            {indicationStatus && indicationStatus['open-requests'] && indicationStatus['open-requests'] == 1?<span className="indication-tab"></span>:null}
          </li>
          <li>
            <NavLink
              onClick={()=>{changeSortByFilter('wip')}}
              to={from?"/app/designer/myrequest/wip":"/app/designer/allclients/clientrequests/wip"}
              title="All Requests"
              activeClassName="active"
              className="all-requests"
            >
              WIP
            </NavLink>
            {indicationStatus && indicationStatus['wip'] && indicationStatus['wip'] == 1?<span className="indication-tab"></span>:null}
          </li>
          <li>
            <NavLink
              onClick={()=>{changeSortByFilter('needs-information')}}
              to={from?"/app/designer/myrequest/needs-information":"/app/designer/allclients/clientrequests/needs-information"}
              title="All Requests"
              activeClassName="active"
              className="all-requests"
            >
              Needs Information
            </NavLink>
            {indicationStatus && indicationStatus['needs-information'] && indicationStatus['needs-information'] == 1?<span className="indication-tab"></span>:null}
          </li>
          <li>
            <NavLink
              onClick={()=>{changeSortByFilter('needs-approval')}}
              to={from?"/app/designer/myrequest/needs-approval":"/app/designer/allclients/clientrequests/needs-approval"}
              title="Needs Approval"
              activeClassName="active"
              className="requests-delivered"
            >
              Needs Approval
            </NavLink>
            {indicationStatus && indicationStatus['needs-approval'] && indicationStatus['needs-approval'] == 1?<span className="indication-tab"></span>:null}
          </li>
          <li>
            <NavLink
              onClick={()=>{changeSortByFilter('closed')}}
              to={from?"/app/designer/myrequest/closed":"/app/designer/allclients/clientrequests/closed"}
              title="All Requests"
              activeClassName="active"
              className="all-requests"
            >
              Closed
            </NavLink>
            {indicationStatus && indicationStatus['closed'] && indicationStatus['closed'] == 1?<span className="indication-tab"></span>:null}
          </li>
          {(!from && priorityForAllTab == false) ?<li>
            <NavLink
              onClick={()=>{changeSortByFilter('priortize')}}
              to={from?"/app/designer/myrequest/priortize":"/app/designer/allclients/clientrequests/priortize"}
              title="Prioritized"
              activeClassName="active"
              className="prioritized"
            >
              Prioritized
            </NavLink>
            {indicationStatus && indicationStatus['prioritized'] && indicationStatus['prioritized'] == 1?<span className="indication-tab"></span>:null}
          </li>:null}
          {!from &&<li>
            <NavLink
              to="/app/designer/allclients/clientrequests/reports"
              title="Reports"
              activeClassName="active"
              className="reports"
            >
              Reports
            </NavLink>
          </li>}
        </ul>
      </div>
    </div>
  );
}

export default DesignerSubNavBar;
