import React, { useState } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import { getBase64DataFromUrl } from "../../redux/apis";

function DownloadWithProgressBar({ fileUrl, fileName, clientAccessToken }) {
  const [loading, setloading] = useState(false);

  const handleDownload = () => {
    const extension = fileUrl.split(".").pop().split(/\#|\?/)[0];
    if(extension === "pdf") {
      window.open(fileUrl, "_blank");
    } else {
    setloading(true);
    getBase64DataFromUrl(fileUrl, clientAccessToken)
      .then((url) => {
        const link = document.createElement("a");
        link.href = url?.data?.data[0];
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.log("Error : ", error))
      .finally(() => {
        setloading(false);
      });
    }
  };

  return (
    <div className="download-progress">
      {!loading ? (
        <button
          type="button"
          className="download-link-text"
          onClick={() => {
            handleDownload();
          }}
        >
          Download
        </button>
      ) : (
        <div className="custom-progress">
          <div className="custom-progress-value"></div>
        </div>
      )}
    </div>
  );
}
DownloadWithProgressBar.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  clientAccessToken: PropTypes.string.isRequired,
};

export default DownloadWithProgressBar;
