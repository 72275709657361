import * as clientReportConstants from '../actionConstants/clientReports.constants'

export const clientProductivityRatio = (clientAccessToken,month) => ({
    type : clientReportConstants.clientProductivityRatio.GET_CLIENT_PRODUCTIVITY_RATIO_INIT,
    payload : {clientAccessToken, month}
})

export const clientReportDetails = (clientAccessToken, group_id, client_id)=> ({
    type: clientReportConstants.clientReportDetails.CLIENT_REPORT_DETAILS_INIT,
    payload:{clientAccessToken, group_id}
})

export const clientTotalRequestSubmitted = (clientAccessToken,start,length,title,status,createdBy, approvedBy, createdFrom,createdTo,
    approvedFrom,approvedTo, sortField,sortOrder) => ({
    type : clientReportConstants.clientTotalRequestSubmitted.GET_CLIENT_TOTAL_REQUESTS_SUBMITTED_INIT,
    payload : {clientAccessToken, start,length,
        title,status,createdBy, approvedBy, createdFrom,createdTo,
        approvedFrom,approvedTo, sortField,sortOrder}
})