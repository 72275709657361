/* eslint-disable no-useless-escape */
import React, {useState} from 'react';
import { useLocation } from 'react-router-dom'
import * as Yup from "yup";

import './index.scss';

function RegisterationForm({restClicked}) {
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const email = decodeURIComponent(location.search.replace("?email=", ""));
  const token = location.pathname.replace(
    "/invite/",
    ""
  ).replace("==", "");
  let SetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "The password must be at least 8 characters.,Password Should have at least 1 lowercase AND 1 uppercase AND 1 number and with Special Characters."
      )
      .required("New Password is required"),
    confirmPassword: Yup.string()
      .required("Re-Type Password is required")
      .oneOf([Yup.ref("password"), ""], "Password must match")
  });
  const handleSubmit =  (e) => {
    e.preventDefault();    
    if (passwordError === '' && confirmPasswordError === '') {
      restClicked(email,password,confirmPassword,token);
      }
    }
  const changePassword = (e) => {
    setPassword(e.target.value);
    SetPasswordSchema.validateAt('password', {password: e.target.value}, {abortEarly: false})
    .then(() => {
      setPasswordError('')
    })
    .catch(function(error) {
      setPasswordError(error.errors);
    })
  }
  const changeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    SetPasswordSchema.validateAt('confirmPassword', {password: password, confirmPassword: e.target.value}, {abortEarly: false})
    .then(() => {
      setConfirmPasswordError('')
    })
    .catch(function(error) {
      setConfirmPasswordError(error.errors);
    })
  }
  return (
    <form className="login-form forget-password-form">
        <div className="login-box">
           <div className="title-text">
              Set your password
           </div>
           <div className="fieldset">
             <div className="field">
               <label className="label required" htmlFor="new_password">New Password</label>
               <input
                id="new_password"
                type="password"
                className="input-text"
                placeholder="New Password"
                name="password"
                onChange={(e) => changePassword(e)}
                value={password}
              />
              {passwordError && passwordError !== '' ? <div className="error">{passwordError}</div> : null}
             </div>
             <div className="field">
               <label className="label required" htmlFor="re_type_password">Re-Type Password</label>
               <input
                id="re_type_password"
                type="password"
                className="input-text"
                placeholder="Re-Type Password"
                name="confirmPassword"
                onChange={(e) => changeConfirmPassword(e)}
                value={confirmPassword}
              />
              {confirmPasswordError && confirmPasswordError !== '' ? <div className="error">{confirmPasswordError}</div> : null}
             </div>
             <div className="action">
               <button className="btn btn-black" onClick={(e) => handleSubmit(e)}>Set Password</button>
             </div>
           </div>
        </div>
      </form>
  );
}

export default RegisterationForm;