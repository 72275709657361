/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { actions as AuthActions } from "../../../../redux/reducers/MyAccountReducer";
import { Input, Select } from '../../../ReusableComponents/ElementComponents'
import { formFields, validationSchemaAddBillingAddressBookForm } from './constant';
import LoaderComponent from "../../../../components/Loader";

function AddBillingAddressBook({ showBillingAddress, handleToggleBillingAddressBook }) {
  const initialValues = {
    street: "",
    street_2: "",
    country_id: "",
    city: "",
    postcode: "",
    state_id: "",
  };
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const { getCountryListResponse,
    setSuccessForAddClientAddress,
    setSuccessForUpdateClientAddress,
    editClientAddressResponse,
    userAuth,
    updateClientAddressLoading,
    getCountryListLoading,
    addClientAddressLoading } = (useSelector(state => ({ ...state?.myAccountReducer, userAuth: state.auth })) || {});

  useEffect(() => {
    dispatch(AuthActions.getCountryList({ clientAccessToken: cookies.clientAccessToken }));
  }, [cookies.clientAccessToken, dispatch]);

  useEffect(() => {
    if (editClientAddressResponse?.data) {
      initialValues.client_id = editClientAddressResponse?.data?.address?.client_id
      initialValues.street = editClientAddressResponse?.data?.address?.street
      initialValues.street_2 = editClientAddressResponse?.data?.address?.street_2
      initialValues.country_id = (editClientAddressResponse?.data?.country[0]?.code || "")
      initialValues.city = editClientAddressResponse?.data?.address?.city
      initialValues.postcode = editClientAddressResponse?.data?.address?.postcode
      initialValues.state_id = (editClientAddressResponse?.data?.region[0]?.id || "")
      initialValues.id = (editClientAddressResponse?.data?.address?.id || "")
    }
  }, [editClientAddressResponse]);

  useEffect(() => {
    if (setSuccessForAddClientAddress || setSuccessForUpdateClientAddress) {
      handleCancel()
    }
  }, [setSuccessForAddClientAddress, setSuccessForUpdateClientAddress])

  const handleOnSubmit = (payload, actions) => {
    const payloadObj = {
      street: payload.street,
      street_2: payload.street_2,
      country_id: payload.country_id,
      city: payload.city,
      postcode: payload.postcode,
      state_id: payload.state_id,
      client_id: userAuth?.user?.parent_id ? userAuth?.user?.parent_id : userAuth?.user?.id,
    }
    const match = getCountryListResponse?.data?.country.find((a) => a.code === payloadObj.country_id);
    if (match) {
      payloadObj.country_id = match.id
    }
    if (showBillingAddress === "add") {
      dispatch(AuthActions.addClientAddress({ payloadObj, clientAccessToken: cookies.clientAccessToken }));
      actions.resetForm();

    } else {
      dispatch(AuthActions.updateClientAddress({ payloadObj, id: payload.id, clientAccessToken: cookies.clientAccessToken }));
    }
  }

  const handleCancel = () => {
    dispatch(AuthActions.clearAddClientAddressFlags());
    dispatch(AuthActions.clearEditClientAddressFlags());
    dispatch(AuthActions.clearUpdateClientAddressFlags());
    handleToggleBillingAddressBook();
  }

  return (
    <>
      <LoaderComponent showhide={(addClientAddressLoading || updateClientAddressLoading || getCountryListLoading)} />
      <form className="add-address-form common-form clearfix">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemaAddBillingAddressBookForm}
          onSubmit={handleOnSubmit}
        >
          {({ touched, errors, values, handleBlur, handleChange, handleSubmit }) => (<>
            <ul>
              <li>
                <div className="form-group">
                  <Input
                    setting={formFields.street}
                    touched={touched[formFields.street.key]}
                    errors={errors[formFields.street.key]}
                    value={values[formFields.street.key]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </li>
              <li>
                <div className="form-group">&nbsp;
                  &nbsp;
                  <Input
                    setting={formFields.street_2}
                    touched={touched[formFields.street_2.key]}
                    errors={errors[formFields.street_2.key]}
                    value={values[formFields.street_2.key]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </li>
              <li>
                <div className="form-group">
                  <Select
                    setting={formFields.country_id}
                    touched={touched[formFields.country_id.key]}
                    errors={errors[formFields.country_id.key]}
                    value={values[formFields.country_id.key]}
                    optionData={getCountryListResponse?.data?.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </li>
              <li>
                <div className="form-group">
                  <Input
                    setting={formFields.city}
                    touched={touched[formFields.city.key]}
                    errors={errors[formFields.city.key]}
                    value={values[formFields.city.key]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </li>
              <li>
                <div className="form-group">
                  <Input
                    setting={formFields.postcode}
                    touched={touched[formFields.postcode.key]}
                    errors={errors[formFields.postcode.key]}
                    value={values[formFields.postcode.key]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </li>
              <li>
                <div className="form-group">
                  <Select
                    setting={formFields.state_id}
                    touched={touched[formFields.state_id.key]}
                    errors={errors[formFields.state_id.key]}
                    value={values[formFields.state_id.key]}
                    optionData={values.country_id ? getCountryListResponse?.data?.region.filter((a) => a?.country_code === values.country_id) : []}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </li>
            </ul>
            <div className="buttons">
              <button className="common-button border-btn" onClick={handleCancel} >CANCEL</button>
              <button className="common-button" onClick={handleSubmit}>{showBillingAddress === 'add' ? "SAVE" : "UPDATE"}</button>
            </div>
          </>)}
        </Formik>
      </form>
    </>

  )
}
export default AddBillingAddressBook