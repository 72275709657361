import React, { useState, useRef, memo, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import DropDownOptions from "./DropDownOptions";
import useOnClickOutside from "../../hooks/useOnClickOutside";

import './index.scss';
/**
 * SearchableDropDown - Bootstrap drop down with search functionality.
 * @param {Array} data // Data for generating menu options.
 * @param {Object} dataMapingString // {value: "key name of which we have to use value as display text", key: "Using for Value"}
 * @param {string/number} value // active key value(for selection).
 * @param {boolean} showSearch // Search option visibility
 * @param {Function} onChange // onChange event
 * @param {String} classNames // Classes
 * @returns {React.ReactElement}
 */
const SearchableDropDown = (props) => {
  const {
    data = [],
    dataMapingString = { value: "value", key: "key" },
    value,
    showSearch = true,
    onChange,
    classNames = ""
  } = props;

  const [getValue, setValue] = useState('');
  const [getVisibility, setVisibility] = useState(false);
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setVisibility(false));

  useEffect(() => {
    if (value && getValue != value) {
      const selectedObject = data.filter((object) => object[dataMapingString.key] == value);
      if(selectedObject.length){
        setValue(selectedObject[0][dataMapingString.value]);
      }
    }else{
      setValue('');
    }
  }, [value])

  const onSelect = (selectedObject, event) => {
    if (selectedObject[dataMapingString.key] !== value) {
      if (typeof onChange === "function")
        onChange({ target: { value: selectedObject[dataMapingString.key] } });

      setValue(selectedObject[dataMapingString.value]);
    }
    setVisibility((prevstate) => !prevstate)
  }

  return (
    <Dropdown tabIndex={props.tabindex} ref={dropdownRef} id="searchable-dropdown" className={classNames}>
      <a style={{overflow: "hidden", height: "26px"}} onClick={() => {
        setVisibility((prevstate) => !prevstate)
      }}
        bsrole="toggle"
        className='dd-toggle-btn'
      >
        {getValue || 'Select'}
      </a>

      {getVisibility ? <DropDownOptions bsrole="menu" showSearch={showSearch && data.length > 1}>
        {data.map((object, index) => (
          <Dropdown.Item
            key={index}
            eventKey={object[dataMapingString.key]}
            onSelect={onSelect.bind(null, object)}
            className={`dd-options ${object[dataMapingString.key] === value ? 'Selected' : ''}`}
          >
            {object[dataMapingString.value]}
          </Dropdown.Item>
        ))}
      </DropDownOptions> : null}
    </Dropdown>
  );
};

export default memo(SearchableDropDown);
