import ShortUniqueId from "short-unique-id";
const uid = new ShortUniqueId();
export const actionTypes = {
  ADD_TOAST: "ADD_TOAST",
  REMOVE_TOAST: "REMOVE_TOAST",
  CLEAR_TOASTS: "CLEAR_TOASTS",
  LOGIN_FAILED: "LOGIN_FAILED_NEW",
};

const initialGeneralState = {
  toasts: [],
  loginFailed: false,
  loginErrorMessage: "",
};

export const reducer = (state = initialGeneralState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_FAILED: {
      const { flag, errorMessage } = action.payload;
      return {
        ...state,
        loginFailed: flag,
        loginErrorMessage: errorMessage,
      };
    }
    case actionTypes.ADD_TOAST: {
      const { heading, text, type, id } = action.payload;
      const toast = {
        heading,
        text,
        type,
        id,
      };
      const toasts = [...state.toasts, toast];
      return {
        ...state,
        toasts: toasts,
      };
    }

    case actionTypes.REMOVE_TOAST: {
      const { id } = action.payload;
      const toasts = [...state.toasts];
      let toastIndex = toasts.findIndex((toast) => toast.id === id);
      if (toastIndex !== -1) {
        toasts.splice(toastIndex, 1);
      }
      return {
        ...state,
        toasts: toasts,
      };
    }

    case actionTypes.CLEAR_TOASTS: {
      return {
        ...state,
        toasts: [],
      };
    }

    default:
      return state;
  }
};

export const actions = {
  loginFailure: (flag, errorMessage) => ({
    type: actionTypes.LOGIN_FAILED,
    payload: { flag, errorMessage },
  }),
  addToast: (heading, text, type, id=uid()) => ({
    type: actionTypes.ADD_TOAST,
    payload: { heading, text, type, id },
  }),
  removeToast: (id) => ({ type: actionTypes.REMOVE_TOAST, payload: { id } }),
  clearToasts: () => ({ type: actionTypes.CLEAR_TOASTS }),
};
