/* eslint-disable react-hooks/exhaustive-deps */
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector} from 'react-redux'
import LoaderComponent from "../Loader"
import {designerProductivityRatio} from '../../redux/actions/designerReports.actions'
import "./index.scss";

function DesignersProductivityRatio() {

  let history = useHistory();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const [currentMonth,setCurrentMonth] = useState(new Date().getMonth()+1)

  const {designerProductivityRatioFetchProcess } = useSelector(state => state.designerReportsReducer)
  const { clientSelectedData  } = useSelector((state) => state.designerRequest);

  const {isFetchingProductivityRatio,
    productivityRatioFetchedData, } = designerProductivityRatioFetchProcess

    const isDataLoaded = Object.keys(productivityRatioFetchedData).length > 0

  useEffect(() => {
    dispatch(designerProductivityRatio(cookies.clientAccessToken,clientSelectedData,new Date().getMonth()+1))
  },[])

  const selectChangeHandler = e=>{
    setCurrentMonth(~~e.target.value)
    dispatch(designerProductivityRatio(cookies.clientAccessToken,clientSelectedData,~~e.target.value))


  }

  const {
    months,
    utilization_percentage,
    total_request_submitted,
    total_hours_spent,
    total_creators_involved,
    total_designer_involved
   } = productivityRatioFetchedData

   const utilPercent = Math.ceil(parseFloat(utilization_percentage))

  return (
    <div>
      <LoaderComponent showhide={isFetchingProductivityRatio} />
      <div className="container">
        <div className="box">
          <div className="box-header prod_ratio_header">
            <h2 className="box-title">Productivity Ratio</h2>
            <div className="form-group month-group">
              <label htmlFor="status">Month:</label>
              <select
                className="form-control"
                id="month"
                placeholder="Queued"
                onChange={selectChangeHandler}
                value={currentMonth}
              >
                {isDataLoaded && months.map((month) => (
                    <option key={month.id} value={month.id}>
                      {month.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          {/* /.box-header */}
          <div className="box-body prod_ratio_body">
            <ul>
              <li>
                <h3>Utilization percentage</h3>
                <div className="stats">
                  <div className={`progress-circle ${utilPercent > 49 ?'over50':""} p${utilPercent}`}>
                    <span>
                      {isDataLoaded && utilization_percentage}
                    </span>
                    <div className="left-half-clipper">
                      <div className="first50-bar"></div>
                      <div className="value-bar"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <h3>
                  <span>
                    <i className="fas fa-tasks"></i>
                  </span>{" "}
                  Total requests Submitted
                </h3>
                <div className="stats">
                  {isDataLoaded && total_request_submitted}
                </div>
              </li>
              <li>
                <h3>
                  <span>
                    <i className="fas fa-history"></i>
                  </span>{" "}
                  Total Hours Spent
                </h3>
                <div className="stats">
                  {isDataLoaded && total_hours_spent}
                </div>
              </li>
              <li>
                <h3>
                  <span>
                    <i className="fas fa-user-tie"></i>
                  </span>{" "}
                  Total Creators Involved
                </h3>
                <div className="stats">
                  {isDataLoaded && total_creators_involved}
                </div>
              </li>
              <li>
                <h3>
                  <span>
                    <i className="fas fa-bezier-curve"></i>
                  </span>{" "}
                  Total Designers Involved
                </h3>
                <div className="stats">
                  {isDataLoaded && total_designer_involved}
                </div>
              </li>{" "}
            </ul>
          </div>
          <div className="paginate prod_ratio_footer">
            <div className="back_eport">
              <button className="btn back" onClick={() => history.goBack()}>
                Back
              </button>
            </div>
          </div>
          {/* /.box-body */}
        </div>
      </div>
    </div>
  );
}

export default DesignersProductivityRatio;
