import React from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { Input } from "../ReusableComponents";
import { formFields, validationSchemaSignupForm } from "./constant";
import "./index.scss";

function SignupForm({ signupClicked }) {
  return (
    <form className="login-form">
      <div className="login-box">
        <a href="https://www.kitchen365.com/kitchen-design/">
          <span className="title-text">
            <img src={require("../../assets/images/Black-Logo-V3.svg")} alt="KitchenDesign365" />
          </span>
        </a>
        <div className="create-new-customer-account-pass">
          Create New Customer Account
        </div>
        <div className="fieldset">
          <Formik
            initialValues={{ email: '', company_name: '', name: '', phone: '', password: '', phone_code: "1" }}
            validationSchema={validationSchemaSignupForm}
            onSubmit={(values, actions) => {
              values['phone'] = "1"+values['phone']
              signupClicked(values)
            }}
          >
            {({ touched, errors, values, handleBlur, handleChange, handleSubmit }) => (
              <>
                <div className="field">
                  <Input
                    setting={formFields.name}
                    touched={touched[formFields.name.key]}
                    errors={errors[formFields.name.key]}
                    value={values[formFields.name.key]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="field">
                  <Input
                    setting={formFields.email}
                    touched={touched[formFields.email.key]}
                    errors={errors[formFields.email.key]}
                    value={values[formFields.email.key]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="field">
                  <Input
                    setting={formFields.password}
                    touched={touched[formFields.password.key]}
                    errors={errors[formFields.password.key]}
                    value={values[formFields.password.key]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="field">
                  <Input
                    setting={formFields.phone}
                    touched={touched[formFields.phone.key]}
                    errors={errors[formFields.phone.key]}
                    value={values[formFields.phone.key]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="field">
                  <Input
                    setting={formFields.company_name}
                    touched={touched[formFields.company_name.key]}
                    errors={errors[formFields.company_name.key]}
                    value={values[formFields.company_name.key]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="action">
                  <button
                    onClick={handleSubmit}
                    className="btn btn-black"
                  >
                    sign up
                  </button>
                </div>
              </>)}
          </Formik>
          <div className="sign-in-pass">
            <Link to="/login">Already a registered user? SIGN IN</Link>
          </div>
        </div>
      </div>
      <div className="forgot-pass">
        <Link to="/forgotpassword">Forgot password?</Link>
      </div>
    </form>
  );
}

export default SignupForm;
