import React, { memo } from 'react'
import PropTypes from 'prop-types';

/**
 * This is  RenderLi function Component. 
 * @param {className} className  className  Pass String Value.
 * @param {onClick} onClick onClick Pass Function Value.
 * @param {tabName} tabName tabName Pass String Value.
 * @returns  RenderLi HTML Element
 * @author Vishal Gohel <vishal.gohel@commercepundit.com>
 * 
 *  Notes:- Dec 28 2021 (Vishal Gohel <vishal.gohel@commercepundit.com>)  Component Created
 */

export const RenderLi = memo(({ className, onClick, tabName }) =>
    (<li className={className} onClick={onClick} >{tabName}</li>));

RenderLi.propTypes = {
    className: PropTypes.string,
    tabName: PropTypes.string,
    onClick: PropTypes.func,
};

RenderLi.defaultProps = {
    className: "",
    onClick: () => { },
    tabName: ""
};
