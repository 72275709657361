/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as designerRequestActions } from "../../redux/reducers/DesignerRequestReducer";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import LoaderComponent from "../Loader";

import "./index.scss";
import RequestListItem from "../RequestListItem";
import ClientRequestsHeader from "../../components/ClientRequestsHeader";
import { actions as AuthActions } from '../../redux/reducers/AuthReducer';

function ClientsRequestList({requestStatusSection}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  
  const {
    allRequestsData,
    allRequestDataLoading,
    filters,
    start,
    length,
    sortByFilter,
    clientSelectedData,
    priorityForAllTab,
  } = useSelector((state) => state.designerRequest);
  const { indicationStatus } = useSelector((state) => state.auth);
  const [ratingCopyTimeSpentFlag, setRatingCopyTimeSpentFlag] = useState(false);
  const [hideDeleteIcon, setHideDeleteIcon] = useState(true);
  const [initialStatus, setInitialStatus] = useState(false);
  let intervalID;
  useEffect(() => {
    
    setInitialStatus(indicationStatus);
    intervalID = setInterval(() => {
      dispatch(AuthActions.getTabIndicationStatus(clientSelectedData,cookies.clientAccessToken));
    }, 30000);
    return () => {
     
      clearInterval(intervalID)
    }
  },[]);
  useEffect(()=>{
    if(initialStatus && indicationStatus){
      if(initialStatus[requestStatusSection] !== indicationStatus[requestStatusSection]){
        getRequestList();
        setInitialStatus(indicationStatus);
      }
    }
  },[indicationStatus])
  useEffect(() => {
    dispatch(AuthActions.getTabIndicationStatus(clientSelectedData,cookies.clientAccessToken));
    getRequestList();
    if (requestStatusSection === "closed") {
      setRatingCopyTimeSpentFlag(true);
    } else {
      setRatingCopyTimeSpentFlag(false);
    }
    return () => {
      //componentWillUnmount
      dispatch(designerRequestActions.changeClientPageActiveTabName(""));
    }
  }, [requestStatusSection]);

  const getRequestList = ()=>{
    dispatch(AuthActions.setUnderClientSection(true));
    dispatch(designerRequestActions.changeStartFromMyOpenRequest(0));
    if(requestStatusSection == "open-requests"){
      dispatch(
        designerRequestActions.getAllRequestData(
          {},
          0,
          length,
          cookies.clientAccessToken
        )
      );
      dispatch(designerRequestActions.changeClientPageActiveTabName("Open Requests"));
    }else if(requestStatusSection == "all-requests"){
      dispatch(
        designerRequestActions.getAllRequestList(
          {},
          0,
          length,
          cookies.clientAccessToken
        )
      );
      dispatch(designerRequestActions.changeClientPageActiveTabName("Open Requests"));
    }else if(requestStatusSection == "wip"){
      dispatch(
        designerRequestActions.getWipRequestList(
          {},
          0,
          length,
          cookies.clientAccessToken
        )
      );
      dispatch(designerRequestActions.changeClientPageActiveTabName("Open Requests"));
    }else if(requestStatusSection == "needs-information"){
      dispatch(
        designerRequestActions.getNeedsInfoRequestList(
          {},
          0,
          length,
          cookies.clientAccessToken
        )
      );
      dispatch(designerRequestActions.changeClientPageActiveTabName("Open Requests"));
    }else if(requestStatusSection == "closed"){
      dispatch(
        designerRequestActions.getClosedRequestList(
          {},
          0,
          length,
          cookies.clientAccessToken
        )
      );
      dispatch(designerRequestActions.changeClientPageActiveTabName("Open Requests"));
    }
  }

  const viewRequest = (id) => {
    dispatch(designerRequestActions.changeRequestId(id));
    // history.push("/app/designer/allclients/clientrequests/viewrequest/" + id);
  };

  const viewRequestUrl = (id) => {
    return ("/app/designer/allclients/clientrequests/viewrequest/" + id);
  };

  const loadMoreRequest = () => {
    let nextRecord = start + length; //10;
    dispatch(designerRequestActions.changeStartFromMyOpenRequest(nextRecord));
    if(requestStatusSection == "open-requests"){
      dispatch(
        designerRequestActions.getAllRequestData(
          filters,
          nextRecord,
          length,
          cookies.clientAccessToken,
        )
      );
      dispatch(designerRequestActions.changeClientPageActiveTabName("Open Requests"));
    }else if(requestStatusSection == "all-requests"){
      dispatch(
        designerRequestActions.getAllRequestList(
          filters,
          nextRecord,
          length,
          cookies.clientAccessToken,
        )
      );
      dispatch(designerRequestActions.changeClientPageActiveTabName("Open Requests"));
    }else if(requestStatusSection == "wip"){
      dispatch(
        designerRequestActions.getWipRequestList(
          filters,
          nextRecord,
          length,
          cookies.clientAccessToken,
        )
      );
      dispatch(designerRequestActions.changeClientPageActiveTabName("Open Requests"));
    }else if(requestStatusSection == "needs-information"){
      dispatch(
        designerRequestActions.getNeedsInfoRequestList(
          filters,
          nextRecord,
          length,
          cookies.clientAccessToken,
        )
      );
      dispatch(designerRequestActions.changeClientPageActiveTabName("Open Requests"));
    }else if(requestStatusSection == "closed"){
      dispatch(
        designerRequestActions.getClosedRequestList(
          filters,
          nextRecord,
          length,
          cookies.clientAccessToken,
        )
      );
      dispatch(designerRequestActions.changeClientPageActiveTabName("Open Requests"));
    }
  };
  
  return (
    <div>
      <LoaderComponent showhide={allRequestDataLoading} />
      <ClientRequestsHeader
        showClientName={true}
        myOpenRequestData={allRequestsData}
        allRequest={true}
        requestStatusSection={requestStatusSection}
      />
      <RequestListItem
        ratingCopyTimeSpentFlag={ratingCopyTimeSpentFlag}
        data={allRequestsData}
        loadMoreRequest={loadMoreRequest}
        viewRequest={viewRequest}
        viewRequestUrl={viewRequestUrl}
        hideDeleteIcon={hideDeleteIcon}
        requestStatusSection={'designerprioritized'}
        isAllPriortise={priorityForAllTab}
      />
    </div>
  );
}

export default ClientsRequestList;
