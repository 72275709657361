import React from 'react';

import './index.scss';
import NewRequestProgressBar from '../../components/NewRequestProgressBar';
import NewRequestContainer from '../../components/NewRequestContainer';
import NewRequest from '../../components/NewRequest';

function SubmitNewRequest() {
  return (
    <div>
      {/* <NewRequestProgressBar />
      <NewRequestContainer/> */}
      <NewRequest />
    </div>
  );
}

export default SubmitNewRequest;