import * as Yup from "yup";

export const formFields = {
    name: {
        key: "name",
        type: "text",
        className: "form-control",
        name: "name",
        placeholder: "Enter your Name",
        label: "Name",
        htmlFor: "name",
        required: true,
    },
    email: {
        key: "email",
        type: "text",
        className: "form-control",
        name: "email",
        placeholder: "Enter your Email address",
        label: "Email",
        htmlFor: "email",
        required: true,
    },
    phone: {
        key: "phone",
        type: "text",
        className: "form-control",
        name: "phone",
        placeholder: "Enter a valid 10 digit number",
        label: "Phone",
        htmlFor: "phone",
        required: true,
        disableDropdown: true,
        maxlength: "11",
        from: true
    },
    company_name: {
        key: "company_name",
        type: "text",
        className: "form-control",
        name: "company_name",
        placeholder: "Enter your Company name",
        label: "Company Name",
        htmlFor: "company_name",
        required: true,
    },
  
   
}

export const validationSchemaUserContactInformationForm = Yup.object().shape({
    name: Yup.string().required('Name is Required.'),
    email: Yup.string().required('Email is Required.').email("Enter valid email"),
    phone: Yup.string().required('Phone is Required.').matches(/^\d+$/, "The field should have digits only.").min(11, "Please enter valid Phone."),
    company_name: Yup.string().required('Company Name is Required.'),
});