import React from "react";
import { Switch, Route } from "react-router-dom";
import "./index.scss";
import ReportsList from "../../components/ReportsList";
import SubmittedRequest from "../../components/SubmittedRequest";
import ProductivityRatio from "../../components/ProductivityRatio";
import DesignersReportsList from "../../components/DesignersReportsList";
import DesignersSubmittedRequest from "../../components/DesignersSubmittedRequest";
import DesignersProductivityRatio from "../../components/DesignersProductivityRatio";

function Reports() {
    
  return (
    <div>
        <Switch>
        <Route exact path="/app/client/reports">
          <ReportsList />
        </Route>
        <Route path="/app/client/reports/submittedrequest">
          <SubmittedRequest />
        </Route>
        {/* <Route path="/app/client/reports/productivityratio">
          <ProductivityRatio />
        </Route> */}
        <Route exact path="/app/designer/allclients/clientrequests/reports">
          <DesignersReportsList/>
        </Route>
        <Route path="/app/designer/allclients/clientrequests/reports/submittedrequest">
          <DesignersSubmittedRequest />
        </Route>
        <Route path="/app/designer/allclients/clientrequests/reports/productivityratio">
          <DesignersProductivityRatio />
        </Route>
      </Switch>
    </div>
  );
}


export default Reports;
