import React, { memo } from 'react'
import PropTypes from 'prop-types';

/**
 * This is  Error function Component. 
 * @param {String} label  Label Pass String Value.
 * @param {Boolean} touched Touched Pass Boolean Value.
 * @param {String} error Error Pass String Value.
 * @returns Error  HTML Element
 * @author Vishal Gohel <vishal.gohel@commercepundit.com>
 * 
 *  Notes:- Dec 9 2021 (Vishal Gohel <vishal.gohel@commercepundit.com>)  Component Created
 */

const Error = ({ label, touched, errors }) => {
    if (touched && errors) {
        return <div className="error">{errors}</div>;
    }

    // if (touched && !errors && label) {
    //     return <div className="valid-feedback">{label} was entered correct</div>;
    // }
    return null
}

Error.propTypes = {
    label: PropTypes.string,
    errors: PropTypes.string,
    touched: PropTypes.bool,
};

Error.defaultProps = {
    label: "",
    touched: false,
    errors: "",
}

export default memo(Error)
