import React, { useState, useEffect } from "react";
import './index.scss';
import SearchableDropDown from '../searchableDropDown';
import { Link, useHistory } from "react-router-dom";
import DatePicker from "react-date-picker";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { actions as clientRequestActions } from "../../redux/reducers/NewRequestReducer";
import { actions as myRequestActions } from "../../redux/reducers/MyRequestReducer";
import { useCookies } from "react-cookie";
import { quillOptionsCongif } from "../../Utils/utils";
import ShortUniqueId from "short-unique-id";
import PdfIcon from "../../assets/images/PDF.svg";
import DocIcon from "../../assets/images/Doc.svg";
import KitIcon from "../../assets/images/kit.png";
import FileIcon from "../../assets/images/file.png";
import PptIcon from "../../assets/images/PPT.svg";
import XlsIcon from "../../assets/images/XLS.svg";
import CsvIcon from "../../assets/images/CSV.svg";
import TsvIcon from "../../assets/images/TSV.svg";
import { actions as generalActions } from "../../redux/reducers/GeneralReducer";
import { postApiTokenFormData } from "../../redux/apis";
import apiUrl from "../../config/urls";
import LoaderComponent from "../Loader";
import AppliancePopup from "./AppliancePopup";
import 'quill-paste-smart';

function NewRequest() {
  const [showPopup, setShowPopup] = useState(false);
  const [applianceObject, setApplianceObject] = useState(null);
  const [looseApplianceObject, setLooseApplianceObject] = useState(null);
  const uid = new ShortUniqueId();
  const history = useHistory();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const { requestDetailData, clientRequestName, step3OptionValues, step3OptionObject, requestTypes, dueDate, kitchenMeasurementImages, deletedMedia, 
    requestId, removedImages, clientRequestLoading, additionalNotes, redirectToPlanPage, redirectToSuccess, redirectToDraft, isMeasurementOptional} = useSelector(
    (state) => state.clientRequest
  );
  const {
    copyRequestId,copyAction,
  } = useSelector((state) => state.myRequest);
  const { user } = useSelector((state) => state.auth);
  
  const [secondaryDoorStyleCheked, setSecondaryDoorStyleCheked] = useState(false);
  const [showTab, setShowTab] = useState(1);
  const [showRequestTypeTooltip, setShowRequestTypeTooltip] = useState(true);
  useEffect(() => {
    if(copyRequestId && copyAction){
      dispatch(
        clientRequestActions.getRequestDetails(cookies.clientAccessToken,copyRequestId)
      );
    }else if(requestId){
      dispatch(
        clientRequestActions.getRequestDetails(cookies.clientAccessToken,requestId)
      );
    }else{
      dispatch(
        clientRequestActions.getRequestDetails(cookies.clientAccessToken)
      );
    }    
  }, []);
  useEffect(() => {
    if (step3OptionObject) {
      Object.keys(step3OptionObject).map((key) => {
        if (key === "appliances") {
          setApplianceObject(step3OptionObject[key]);
        }
        if(key === "loose-appliances"){
          setLooseApplianceObject(step3OptionObject[key]);
        }
      });
    }
  }, [applianceObject, looseApplianceObject, step3OptionObject]);
  useEffect(() => {
    if(step3OptionValues && step3OptionValues['secondary-door-style'] && step3OptionValues['secondary-door-style']['data'] && step3OptionValues['secondary-door-style']['data'].length &&
    step3OptionValues['secondary-door-style']['data'][0]["category_option_value"] !== ""){
      setSecondaryDoorStyleCheked(true);
    }
  }, [step3OptionValues]);
  useEffect(() => {
    if(redirectToPlanPage){
      dispatch(
        clientRequestActions.redirectPageToPlan(false)
      );
      history.push("/app/client/payment")
    }
  }, [dispatch, history, redirectToPlanPage]);
  useEffect(()=>{ 
    if(redirectToSuccess){
      dispatch(
        clientRequestActions.redirectToSuccess(false)
      );
      history.push("/app/client/requestsuccess");
    }
  },[dispatch, history, redirectToSuccess])
  useEffect(()=>{ 
    if(redirectToDraft){
      dispatch(
        clientRequestActions.redirectToDraft(false)
      );
      history.push("/app/client/myrequests/yourdrafts");
    }
  },[dispatch, history, redirectToDraft])
  const changeRequestType = (e) => {
    setShowRequestTypeTooltip(true);
    dispatch(clientRequestActions.changeRequestTypes(e.target.value));
  };
  const changeRequestTitle = (e) => {
    dispatch(clientRequestActions.changeClientRequestName(e.target.value));
  };
  const customHandleFormValueChange = (value, categoryName, optionId, type) => {
    dispatch(
      clientRequestActions.changeStep3OptionsValue(
        value,
        categoryName,
        optionId,
        type
      )
    );
    dispatch(
      clientRequestActions.getLatestDoorDetails(
        optionId,
        cookies.clientAccessToken
      )
    );
  };
  const openPopup = () => {
    setShowPopup(true);
  };
  const closePopup = () => {
    setShowPopup(false);
  };
  const handleFormValueChange = (value, categoryName, optionId, type, childtype, isPopup) => {
    if (optionId == 6 && categoryName === "soffits") {
      if (value == true) {
        dispatch(
          clientRequestActions.changeStep3OptionsValue(
            "",
            "soffits",
            7,
            "input-text"
          )
        );
        dispatch(
          clientRequestActions.changeStep3OptionsValue(
            "",
            "soffits",
            8,
            "input-text"
          )
        );
      }
    }
    dispatch(
      clientRequestActions.changeStep3OptionsValue(
        value,
        categoryName,
        optionId,
        type,
        childtype,
        isPopup,
      )
    );
  };

  let dropdownOptionsCabinateBrand = [];
  let dropdownOptionsDoorStyle = [];
  let dropdownOptionsDecoration = [];
  let dropdownOptionsSink = [];
  if(requestDetailData['dynamic_form_data'] && step3OptionObject){
    dropdownOptionsCabinateBrand = requestDetailData['dynamic_form_data']['cabinet-brand']['data'].filter(
      (x) => x.category_option_type === "dropdown"
    );  
    dropdownOptionsDoorStyle = step3OptionObject['door-style']['data'].filter(
      (x) => x.category_option_type === "dropdown"
    ); 
    dropdownOptionsDecoration = requestDetailData['dynamic_form_data']['decoration'] && requestDetailData['dynamic_form_data']['decoration']['data'].filter(
      (x) => x.category_option_type === "dropdown"
    ); 
    dropdownOptionsSink = requestDetailData['dynamic_form_data']['sink'] && requestDetailData['dynamic_form_data']['sink']['data'].filter(
      (x) => x.category_option_type === "dropdown"
    ); 
    
  }
  let keyForElement = 0;
  const renderCheckbox = (options, valueOptions, key) => {
    if (options && options.length !== 0) {
      let formOptions = [...options];
      let formValueOptions = [...valueOptions];
      return (
        <>
          {
          formOptions && formOptions.length !== 0
          ? formOptions.map((data, index) => {
              let valueData = null;
              let valueDataIndex = formValueOptions.findIndex(
                (x) => x.category_option_id === data.category_option_id
              );
              if (valueDataIndex !== -1) {
                valueData = formValueOptions[valueDataIndex];
              }
              let datai = null;
              let dataOther = null;
              let getIndexWidth;
              if(valueData && valueData['child_data']){
                datai = valueData['child_data'].filter(
                  (x) => x && x.name == "Width"
                );
              }
              
              if(valueData && valueData['child_data'] && valueData['child_data'].length){
                dataOther = valueData['child_data'].filter(
                  (x) => x && x.is_others == "1"
                );
              }else{
                if(valueData && valueData.is_others == "1"){
                  dataOther = valueData['others_value'];
                }
              }
                
              let valueDropdown = [];
              if(valueData && valueData['child_data']){
                valueDropdown = valueData['child_data'].filter(
                  (x) => x && x.category_option_type == "dropdown"
                );
              }
             
              let subCategoryDropDown = null;
              if(data && data['child_data']){
                subCategoryDropDown = data['child_data'].filter(
                  (x) => x && x.category_option_type == "dropdown"
                );
              }


              keyForElement++;
              return (
                <div key={key+keyForElement} className="description-box check-box three-control-list standard-block">
                      <div className="multi-checkbox">
                        <input id="7" className="custom-checkbox" type="checkbox"
                            checked={
                              valueData && valueData.category_option_value == true
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              handleFormValueChange(
                                e.target.checked,
                                key,
                                data.category_option_id,
                                "checkbox"
                              )
                            }
                        ></input>
                        <label className="label">{data.category_option_name}</label>
                        {data.is_others == "1" && data.child_data && data.child_data.length == "0" && valueData && valueData.category_option_value == true ?<input
                            placeholder="Enter"
                            style={{width:"100%",marginLeft: "0"}}
                            id={data.id}
                            className="input-box inch-input select-input-box"
                            type="text"
                            value={dataOther} 
                            onChange={(e) =>
                              handleFormValueChange(
                                e.target.value,
                                key,
                                data.category_option_id,
                                "others"
                              )
                            }
                        />: null}
                        {data.is_others == "1" && data.child_data && data.child_data.length && valueData && valueData.category_option_value == true ?<input
                            placeholder="Enter"
                            style={{width:"100%",marginLeft: "0"}}
                            id={data.id}
                            className="input-box inch-input select-input-box"
                            type="text"
                            value={dataOther.length && dataOther[0].value ? dataOther[0].value: ""} 
                            onChange={(e) =>
                              handleFormValueChange(
                                e.target.value,
                                key,
                                data.category_option_id,
                                ""+key+"",
                                valueData['child_data'].findIndex(
                                  (x) => x.is_others == "1"
                                ),
                                true
                              )
                            }
                        />: null}
                        {data.child_data && data.child_data.length && valueData && valueData.category_option_value == true? <div className="select-input-box">
                          {data.child_data.filter(
                              (x) => x.category_option_type === "dropdown"
                            ).length ? <SearchableDropDown 
                            key={key}
                            data={subCategoryDropDown}
                            dataMapingString={{value: "name", key: "id"}}
                            onChange={(e) => {
                                handleFormValueChange(
                                  e.target.value,
                                  key,
                                  data.category_option_id,
                                  key,
                                );
                            }}
                            classNames="select-box input-box"
                            value={
                              valueDropdown && valueDropdown.length && valueDropdown[0]["selected"]
                                ? valueDropdown[0]["id"]
                                : ""
                            }
                          />:null}
                          {data.is_others == "0" ? <input
                            placeholder="size"
                            style={{width:"70px",marginLeft: "5px"}}
                            id={data.id}
                            className="form-control input-box inch-input"
                            type="number"
                            min="0"
                            disabled={
                              valueData && valueData['child_data'].length
                                ? false
                                : true
                            }
                            value={datai.length && datai[0].value ? datai[0].value: ""} 
                            onChange={(e) =>
                              handleFormValueChange(
                                e.target.value
                                  ? Number(e.target.value)
                                  : e.target.value,
                                key,
                                data.category_option_id,
                                ""+key+"",
                                valueData['child_data'].findIndex(
                                  (x) => x.name == "Width"
                                ),
                                true
                              )
                            }
                        />:null}
                        </div>:""}
                      </div>
                  </div>
              );
          }):null}
      
        </>
      );
        }else{
          return null;
        }
    
  };

  const seconDaryDoorStyleCheckbox = (val) =>{
    setSecondaryDoorStyleCheked(val);
  } 

  const changeDueDate = (d) => {
    dispatch(clientRequestActions.changeDueDate(d));
  };
  let imageInstance;
  const removeFile = (e) => {
    let temparr = e.target.id.split("-");
    imageInstance = JSON.parse(JSON.stringify(kitchenMeasurementImages));
    let removedImagesTemp = JSON.parse(JSON.stringify(removedImages));

    const found = removedImagesTemp[temparr[0]].some(
      (el) => el.id === imageInstance[temparr[0]][temparr[1]]["id"]
    );
    if (found) {
    } else {
      removedImagesTemp[temparr[0]].push({
        id: imageInstance[temparr[0]][temparr[1]]["id"],
      });
    }

    dispatch(clientRequestActions.changeRemovedImages(removedImagesTemp));
    dispatch(clientRequestActions.changeRequestDeletedMedia(removedImagesTemp));
    imageInstance[temparr[0]].splice(temparr[1], 1);
    dispatch(clientRequestActions.setKitchenImages(imageInstance));
    // setImages(imageInstance);
  };
  const addKitchenMeasurement = (e) => {

    let imgArr = [];
    imgArr.push(e.target.files);
    let fileArr = kitchenMeasurementImages[e.target.id];
    let fileExtention;
    let filevalidation = false;
    let fileExtentionvalidation = { type: "", flag: false };
    let allowedFileExtention = ["pdf", "png", "jpg", "jpeg", "kit","doc","xls","xlsx","csv","tsv","ppt","pptx","docx","webp","heic"];

    if(!isMeasurementOptional){
      for (let i = 0; i < imgArr[0].length; i++) {
        fileExtention = /[.]/.exec(imgArr[0][i].name)
          ? /[^.]+$/.exec(imgArr[0][i].name)
          : undefined;        
        var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        var j = parseInt(
          Math.floor(Math.log(imgArr[0][i].size) / Math.log(1024))
        );
        if (Math.round(imgArr[0][i].size / 1024) > 51200) {
          filevalidation = true;
        } else if (!allowedFileExtention.includes(fileExtention[0].toLowerCase())) {        
          fileExtentionvalidation.type = fileExtention[0];
          fileExtentionvalidation.flag = true;
        }
      }
    }

    if (filevalidation) {
      dispatch(
        generalActions.addToast(
          "File Size",
          "Max File Size for each media file: 50 MB",
          "danger",
          uid()
        )
      );
    } else if (fileExtentionvalidation.flag) {
      dispatch(
        generalActions.addToast(
          "File Type",
          fileExtentionvalidation.type + " file not allowed for upload",
          "danger",
          uid()
        )
      );
    }else{
      dispatch(clientRequestActions.clientRequestStepLoading(true));
      // dispatch(clientRequestActions.changeKitchenMeasurement(fileArr));
      // e.target.value = null;
      let formData = new FormData();
      if(requestId){
        formData.append("request_id", requestId);
      }
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append("kitchen_measurements[]", e.target.files[i]);
      }
      postApiTokenFormData(
        apiUrl("CLIENT_REQUEST_KITCHEN_MEDIA_UPLOAD_URL"),
        cookies.clientAccessToken,
        formData
      )
        .then((resp) => resp.json())
        .then(function (response) {
          if (response.data.length > 0) {
            dispatch(clientRequestActions.clientRequestStepLoading(false));
            dispatch(clientRequestActions.changeRequestId(response.data[0]['request_id']));
              let takeResponse = JSON.parse(JSON.stringify(response.data))
              const results = takeResponse[0]["kitchen_measurement"].filter(({ id: id1 }) => !removedImages["kitchen_measurement"].some(({ id: id2 }) => id2 == id1));
              takeResponse[0]["kitchen_measurement"] = results;
              dispatch(clientRequestActions.setKitchenImages(takeResponse[0]));
              dispatch(
                clientRequestActions.changeRequestUpdatedMedia(takeResponse[0])
              );
            e.target.value = null;
          }else{
            e.target.value = null;
            dispatch(clientRequestActions.clientRequestStepLoading(false));
            dispatch(generalActions.addToast(
              "Media Upload Error",
              response.message,
              "danger",
              uid()
            ))
          }
        }).catch(error => {
          e.target.value = null;
          console.log("Error : ", error)
          dispatch(clientRequestActions.clientRequestStepLoading(false));
          dispatch(generalActions.addToast(
          "Media Upload Error",
          error?.response?.data?.message,
          "danger",
          uid()
        ))})
    }
    // clientRequestStepLoading
    
  };
  const onDragOver = (e) =>{
    e.preventDefault();
  }
  const dropFiles = (e) => {
    e.preventDefault();
    let imgArr = [];
    let dt = e.dataTransfer
    let files = dt.files
    imgArr.push(files);
    let fileArr = kitchenMeasurementImages[e.target.id];
    let fileExtention;
    let filevalidation = false;
    let fileExtentionvalidation = { type: "", flag: false };
    let allowedFileExtention = ["pdf", "png", "jpg", "jpeg", "kit","doc","xls","xlsx","csv","tsv","ppt","pptx","docx","webp","heic"];

    for (let i = 0; i < imgArr[0].length; i++) {
      fileExtention = /[.]/.exec(imgArr[0][i].name)
        ? /[^.]+$/.exec(imgArr[0][i].name)
        : undefined;        
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      var j = parseInt(
        Math.floor(Math.log(imgArr[0][i].size) / Math.log(1024))
      );
      if (Math.round(imgArr[0][i].size / 1024) > 51200) {
        filevalidation = true;
      } else if (!allowedFileExtention.includes(fileExtention[0].toLowerCase())) {        
        fileExtentionvalidation.type = fileExtention[0];
        fileExtentionvalidation.flag = true;
      }
    }
    if (filevalidation) {
      dispatch(
        generalActions.addToast(
          "File Size",
          "Max File Size for each media file: 50 MB",
          "danger",
          uid()
        )
      );
    } else if (fileExtentionvalidation.flag) {
      dispatch(
        generalActions.addToast(
          "File Type",
          fileExtentionvalidation.type + " file not allowed for upload",
          "danger",
          uid()
        )
      );
    }else{
      dispatch(clientRequestActions.clientRequestStepLoading(true));
      // dispatch(clientRequestActions.changeKitchenMeasurement(fileArr));
      // e.target.value = null;
      let formData = new FormData();
      if(requestId){
        formData.append("request_id", requestId);
      }
      for (let i = 0; i < files.length; i++) {
        formData.append("kitchen_measurements[]", files[i]);
      }
      postApiTokenFormData(
        apiUrl("CLIENT_REQUEST_KITCHEN_MEDIA_UPLOAD_URL"),
        cookies.clientAccessToken,
        formData
      )
        .then((resp) => resp.json())
        .then(function (response) {
          if (response.data.length > 0) {
            dispatch(clientRequestActions.clientRequestStepLoading(false));
            dispatch(clientRequestActions.changeRequestId(response.data[0]['request_id']));
              let takeResponse = JSON.parse(JSON.stringify(response.data))
              const results = takeResponse[0]["kitchen_measurement"].filter(({ id: id1 }) => !removedImages["kitchen_measurement"].some(({ id: id2 }) => id2 == id1));
              takeResponse[0]["kitchen_measurement"] = results;
              dispatch(clientRequestActions.setKitchenImages(takeResponse[0]));
              dispatch(
                clientRequestActions.changeRequestUpdatedMedia(takeResponse[0])
              );
            e.target.value = null;
          }else{
            e.target.value = null;
            dispatch(clientRequestActions.clientRequestStepLoading(false));
            dispatch(generalActions.addToast(
              "Media Upload Error",
              response.message,
              "danger",
              uid()
            ))
          }
        }).catch(error => {
          e.target.value = null;
          console.log("Error : ", error)
          dispatch(clientRequestActions.clientRequestStepLoading(false));
          dispatch(generalActions.addToast(
          "Media Upload Error",
          error?.response?.data?.message,
          "danger",
          uid()
        ))})
    }
    // clientRequestStepLoading
    
  };
  
  const renderKitchenMeasurement = (val) => {
    
      var fileSrc;
      return (
        <ul className="kitchen-measurement-list" onDrop={dropFiles} onDragOver={onDragOver}>
          
          {kitchenMeasurementImages && Object.keys(kitchenMeasurementImages[val]).map((key, index) => {
            var fileExtention = /[.]/.exec(kitchenMeasurementImages[val][key]["url"])
            ? /[^.]+$/.exec(kitchenMeasurementImages[val][key]["url"])
            : undefined;
            if ((fileExtention[0]).toLowerCase() === "pdf") {
              fileSrc = PdfIcon;
            } else if((fileExtention[0]).toLowerCase() === "doc" || (fileExtention[0]).toLowerCase() === "docx"){
              fileSrc = DocIcon;
            } else if((fileExtention[0]).toLowerCase() === "xls" || (fileExtention[0]).toLowerCase() === "xlsx"){
              fileSrc = XlsIcon;
            } else if ((fileExtention[0]).toLowerCase() === "kit") {
              fileSrc = KitIcon;
            } else if((fileExtention[0]).toLowerCase() === "png" || (fileExtention[0]).toLowerCase() === "jpg" || (fileExtention[0]).toLowerCase() === "jpeg"){
              fileSrc = kitchenMeasurementImages[val][key]["url"];
            } else if((fileExtention[0]).toLowerCase() === "ppt" || (fileExtention[0]).toLowerCase() === "pptx") {
              fileSrc = PptIcon;
            } else if ((fileExtention[0]).toLowerCase() === "csv") {
              fileSrc = CsvIcon;
            } else if ((fileExtention[0]).toLowerCase() === "tsv") {
              fileSrc = TsvIcon;
            } else {
              fileSrc = FileIcon;
            }
            return (
              <li key={key}>
                <div className="kitchen-measurement-box">
                  <div className="measurement-imgbox">
                      <img
                        src={fileSrc}
                        alt="Kitchen-Measurment"
                      />
                      <span className="complete-mark"></span>
                      <span
                        className="close-btn"
                        id={val + "-" + index}
                        onClick={removeFile}
                      ></span>
                      <span className="file-size">
                        Completed <br /> {kitchenMeasurementImages[val][key]["size"]}
                      </span>
                  </div>
                </div>
                <span className="title">
                  {kitchenMeasurementImages[val][key]["name"]}
                </span>
              </li>
            );
          })}
          <li>
            <div className="kitchen-measurement-box add">
              <label htmlFor="file-upload" className="add-measurement"></label>
              <input
                style={{ display: "none" }}
                id="file-upload"
                type="file"
                accept="image/jpeg,image/jpg,image/png,application/pdf,.kit,.KIT,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.doc,.docx,.webp,.heic"
                onChange={addKitchenMeasurement}
                multiple
              />
            </div>
          </li>
        </ul>
      );
    
  };

  const submitRequest = (val) =>{
    let requiredFilevalidation = false,
      kitfilevalidation = false,
      requiredValidation = false,
      requiredDueDate = false;
    if (isMeasurementOptional || (kitchenMeasurementImages && kitchenMeasurementImages['kitchen_measurement'].length)) {
      requiredFilevalidation = false;
    } else {
      requiredFilevalidation = true;
    }
    if (clientRequestName) {
      requiredValidation = false;
    } else {
      requiredValidation = true;
    }
    if (dueDate) {
      requiredDueDate = false;
    } else {
      requiredDueDate = true;
    }
    Object.keys(step3OptionValues).map((key, index) => {
      if (step3OptionValues[key]["option"]["is_required"]) {
        if(key == "door-style"){
          
          if(step3OptionObject[key]["data"] && step3OptionObject[key]["data"][0]['category_option_value']){
            if (step3OptionValues[key]["data"].length > 0) {
            } else {
              requiredValidation = true;
            }
          }
        }else{
          if (step3OptionValues[key]["data"].length > 0) {
          } else {
            requiredValidation = true;
          }
        }
      }
    });
    var date = new Date(dueDate),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    let submitData = {
      "client_id": user.parent_id ? user.parent_id : user.id,
      "request_type": requestTypes,
      "request_id": requestId,
      "status": 1,
      "is_all_section_properly_labeled":true,
      "deleted_media": removedImages,
      "request_title": clientRequestName,
      "due_date": [date.getFullYear(), mnth, day].join("-"),
      "additional_note": additionalNotes,
      "is_draft": val == "Draft" ? true: false,
      "dynamic_form_data": step3OptionValues,
    }
    if (requiredFilevalidation || requiredValidation || requiredDueDate) {
      if (requiredFilevalidation) {
        dispatch(
          generalActions.addToast(
            "Required field",
            "Please upload required file",
            "danger",
            uid()
          )
        );
        setShowTab(1)
      }
      if (requiredValidation) {
        dispatch(
          generalActions.addToast(
            "Required field",
            "Please enter required field",
            "danger",
            uid()
          )
        );
        setShowTab(1)
      }
      
      if(requiredDueDate){
        dispatch(
          generalActions.addToast(
            "Required field",
            "Please enter required field",
            "danger",
            uid()
          )
        );
        setShowTab(2)
      }
    } else {
        dispatch(
          clientRequestActions.createNewRequest(
            submitData,
            cookies.clientAccessToken
          )
        );
    }   
  }

  return (
    
    <div className="container cf request-container">
      <AppliancePopup
          show={showPopup}
          closePopup={closePopup}
          applianceObject={applianceObject}
          step3OptionValues={step3OptionValues}
          looseApplianceObject={looseApplianceObject}
        />
       <LoaderComponent showhide={clientRequestLoading} />
      <div className={showTab == 1?"progressbar-line":"progressbar-line full-line"}></div>

        <div className="request-create-outer">
          <div className={showTab == 1?"request-accordian":"request-accordian success-icon"}>
            <div className={showTab == 1?"accordian-title active":"accordian-title"}>
              <div className="left-content" 
                   onClick={() => {if(showTab == 1){
                    setShowTab(2)
                  }else{
                    setShowTab(1)
                  }}}>
                <h4>Required Information</h4>
                <p>Request Type, Cabinet brand, Door style, Ceiling height, Digital assets</p>
              </div>
              <div className="right-symbol">
                <a href={void(0)} className="circle-inside" onClick={() => {
                  if(showTab == 1){
                    setShowTab(2)
                  }else{
                    setShowTab(1)
                  }
                  }}></a>
              </div>
            </div>
            <div className={showTab == 1?"accordian-content":"accordian-content hide-accordian-slide"}>
              <ul>
                <li>
                  <div className="form-group required">
                    <label>Request Title</label>
                    <input tabindex="1" type="text" placeholder="Enter request title" className="form-control" onChange={changeRequestTitle} value={clientRequestName}></input>
                  </div>
                </li>
                <li className="request-type-select">
                <div className="form-group required">
                <label>Select Request Type</label>
                <select
                      tabindex="2"
                      className="select-box"
                      onChange={changeRequestType}
                      value={requestTypes}
                    >
                      <option
                          key={0}
                          value={""}
                              >
                                Select
                              </option>
                      {requestDetailData &&
                        Object.keys(requestDetailData["request_type_dropdown"]).map(
                          (key, index) => {
                            return (
                              <option
                                key={key}
                                value={requestDetailData["request_type_dropdown"][key]["id"]}
                              >
                                {requestDetailData["request_type_dropdown"][key]["name"]}
                              </option>
                            );
                          }
                        )}
                </select>
                {requestTypes && requestDetailData && requestDetailData["request_type_dropdown"].filter(
                                  (x) => x && x.id == requestTypes
                                )[0]['description'] !== null ? <div className="tooltip-block">
                <div className="tooltip-link" onClick={()=>{setShowRequestTypeTooltip(!showRequestTypeTooltip)}} 
                     onMouseOver={()=>{setShowRequestTypeTooltip(true)}} onMouseOut={()=>{setShowRequestTypeTooltip(false)}}>
                  <i>i</i>
                  <div className="tooltip-content" style={{display:showRequestTypeTooltip?"block":"none"}}>
                <p>{requestDetailData && requestDetailData["request_type_dropdown"].filter(
                                  (x) => x && x.id == requestTypes
                                )[0]['description'] }</p>
                </div>
                </div>
                
                </div>:null}
                </div>
                </li>
                {dropdownOptionsCabinateBrand && <li>
                  <div className="form-group required">
                    <label>Select Cabinet Brand</label>
                    
                    <SearchableDropDown 
                      tabindex="3"
                      key={'cabinet-brand'}
                      data={dropdownOptionsCabinateBrand}
                      dataMapingString={{value: "category_option_name", key: "category_option_id"}}
                      onChange={(e) =>
                        {
                          customHandleFormValueChange(
                            e.target.value,
                            'cabinet-brand',
                            e.target.value,
                            "dropdown"
                          );           
                      }
                      }
                      classNames="select-box input-box"
                      value={
                        step3OptionValues && step3OptionValues['cabinet-brand'] && step3OptionValues['cabinet-brand']['data'] && step3OptionValues['cabinet-brand']['data'].length
                          ? step3OptionValues['cabinet-brand']['data'][0]["category_option_id"]
                          : ""
                      }
                    />
                  </div>
                </li>}
                <li>
                {dropdownOptionsDoorStyle && dropdownOptionsDoorStyle[0] && dropdownOptionsDoorStyle[0]['category_name']?<div className="form-group required">
                <label>Select Door Style</label>
                <SearchableDropDown 
                  tabindex="4"
                  key={'door-style'}
                  data={dropdownOptionsDoorStyle}
                  dataMapingString={{value: "category_option_name", key: "category_option_id"}}
                  onChange={(e) =>
                    {
                      
                       handleFormValueChange(
                       e.target.value,
                       'door-style',
                       e.target.value,
                       "dropdown"
                     )
                                
                  }
                  }
                  classNames="select-box input-box"
                  value={
                    step3OptionValues && step3OptionValues['door-style'] && step3OptionValues['door-style']['data'] && step3OptionValues['door-style']['data'].length
                      ? step3OptionValues['door-style']['data'][0]["category_option_id"]
                      : ""
                  }
                />
                </div>:null}
                </li>
                {step3OptionValues && step3OptionValues['ceiling'] && <li className="celling-input">
                  <div className="form-group">
                    <label>Ceiling Height</label>
                    <input tabIndex="5" type="number" min="0" placeholder="Size" className="form-control" 
                          value={step3OptionValues && step3OptionValues['ceiling'] && step3OptionValues['ceiling']['data'] && step3OptionValues['ceiling']['data'].length ? 
                          step3OptionValues['ceiling']['data'][0]["category_option_value"] : ""}
                          onChange={(e) =>
                            handleFormValueChange(
                              e.target.value? Number(e.target.value) : Number(e.target.value),
                              'ceiling',
                              step3OptionObject['ceiling']['data'][0]["category_option_id"],
                              "input-text"
                            )}
                            />
                    <span>inches</span>
                  </div>
                </li>}
                {step3OptionValues && step3OptionValues['wall-cabinet-height'] && <li className="wall-cabinet-height-input celling-input">
                  <div className="form-group">
                    <label>Wall Cabinet Height</label>
                    <input tabIndex="5" type="number" min="0" placeholder="Size" className="form-control" 
                          value={step3OptionValues && step3OptionValues['wall-cabinet-height'] && step3OptionValues['wall-cabinet-height']['data'] && step3OptionValues['wall-cabinet-height']['data'].length ? 
                          step3OptionValues['wall-cabinet-height']['data'][0]["category_option_value"] : ""}
                          onChange={(e) =>
                            handleFormValueChange(
                              e.target.value? Number(e.target.value) : Number(e.target.value),
                              'wall-cabinet-height',
                              step3OptionObject['wall-cabinet-height']['data'][0]["category_option_id"],
                              "input-text"
                            )}
                            />
                    <span>inches</span>
                  </div>
                </li>}
                <li className="full-width-control">
                <div className={`kitchen-measurement kitchen-files ${!isMeasurementOptional?'required':''}`}>
                  <label>Measurements</label>
                  <p>Accepted file types are jpg, jpeg, png, webp, heic, pdf, xls, xlsx, csv, tsv, ppt, pptx, doc, docx and .Kit file</p>
                  {renderKitchenMeasurement('kitchen_measurement')}
                  </div>
                </li>
              </ul>
            </div>
          </div>


          <div className="request-accordian">
            <div className={showTab == 2?"accordian-title active":"accordian-title"}>
              <div className="left-content" onClick={() => {if(showTab == 1){
                    setShowTab(2)
                  }else{
                    setShowTab(1)
                  }}}>
                <h4>Additional Information</h4>
                <p>Appliances, Molding & Door, Notes, Storage</p>
              </div>
              <div className="right-symbol">
                <a href={void(0)} className="circle-inside" onClick={() => {
                  if(showTab == 1){
                    setShowTab(2)
                  }else{
                    setShowTab(1)
                  }
                  }}></a>
              </div>
            </div>
            <div className={showTab == 2?"accordian-content":"accordian-content hide-accordian-slide"}>
              <div className="additional-info-block">
              <ul>
               {step3OptionObject && step3OptionObject['secondary-door-style'] && <li className="half-list">
                  <div className="description-box check-box">
                      <input id="6" className="custom-checkbox" type="checkbox" 
                          checked={secondaryDoorStyleCheked} 
                          onChange={(e) =>
                            seconDaryDoorStyleCheckbox(
                              e.target.checked,
                            )
                          }
                          ></input>
                      <label className="label">Second Door Style and Finish</label>
                      {secondaryDoorStyleCheked && <div className="check-input">
                        <input type="text" placeholder="Secondary Door Style and Finish" className="form-control"
                              value={step3OptionValues && step3OptionValues['secondary-door-style'] && step3OptionValues['secondary-door-style']['data'] && step3OptionValues['secondary-door-style']['data'].length ? 
                              step3OptionValues['secondary-door-style']['data'][0]["category_option_value"] : ""}
                              onChange={(e) =>
                                handleFormValueChange(
                                  e.target.value,
                                  'secondary-door-style',
                                  step3OptionObject['secondary-door-style']['data'][0]["category_option_id"],
                                  "input-text"
                                )}
                        ></input>
                      </div>}
                  </div>
                </li>}

                {step3OptionObject && step3OptionObject['soffits'] && <li className="half-list celling-input">
                  <div className="description-box check-box">
                      <input id="6" className="custom-checkbox" type="checkbox" checked={
                            step3OptionValues && step3OptionValues['soffits'] && step3OptionValues['soffits']['data'] && 
                          step3OptionValues['soffits']['data'].filter(
                            (x) => x && x.category_option_type == "checkbox"
                          ).length && step3OptionValues['soffits']['data'].filter(
                            (x) => x && x.category_option_type == "checkbox"
                          )[0]['category_option_value'] == true
                          ? true : false
                          } 
                          onChange={(e) =>
                            handleFormValueChange(
                              e.target.checked,
                              "soffits",
                              step3OptionObject['soffits']['data'][0]["category_option_id"],
                              "checkbox"
                            )
                          }></input>
                      <label className="label">Soffits</label>
                      {step3OptionValues && step3OptionValues['soffits'] && step3OptionValues['soffits']['data'] && 
                          step3OptionValues['soffits']['data'].filter(
                            (x) => x && x.category_option_type == "checkbox"
                          ).length && step3OptionValues['soffits']['data'].filter(
                            (x) => x && x.category_option_type == "checkbox"
                          )[0]['category_option_value'] == true ? <div><div className="form-group check-input input-box-control">
                        <label>Width:</label>
                        <input type="number" className="form-control" 
                          value={step3OptionValues && step3OptionValues['soffits'] && 
                          step3OptionValues['soffits']['data'].filter(
                            (x) => x && x.category_option_name == "Width"
                          ).length
                          ? step3OptionValues['soffits']['data'].filter(
                            (x) => x && x.category_option_name == "Width"
                          )[0]["category_option_value"] : ""}
                          onChange={(e) =>
                            handleFormValueChange(
                              e.target.value
                                ? Number(e.target.value)
                                : e.target.value,
                                "soffits",
                                step3OptionObject['soffits']['data'].filter(
                                  (x) => x && x.category_option_name == "Width"
                                )[0]["category_option_id"],
                              "input-text"
                            )
                          }
                          disabled={step3OptionValues && step3OptionValues['soffits'] && step3OptionValues['soffits']['data'] && step3OptionValues['soffits']['data'].filter(
                            (x) => x && x.category_option_type == "checkbox"
                          ).length? false : true}></input>
                        <span>inches</span>
                      </div>
                      <div className="form-group check-input input-box-control last">
                      <label>Height:</label>
                      <input type="number" className="form-control" value={step3OptionValues && step3OptionValues['soffits'] && 
                        step3OptionValues['soffits']['data'].filter(
                          (x) => x && x.category_option_name == "Height"
                        ).length
                        ? step3OptionValues['soffits']['data'].filter(
                          (x) => x && x.category_option_name == "Height"
                        )[0]["category_option_value"] : ""}
                        onChange={(e) =>
                          handleFormValueChange(
                            e.target.value
                              ? Number(e.target.value)
                              : e.target.value,
                              "soffits",
                              step3OptionObject['soffits']['data'].filter(
                                (x) => x && x.category_option_name == "Height"
                              )[0]["category_option_id"],
                            "input-text"
                          )
                        }
                        disabled={step3OptionValues && step3OptionValues['soffits'] && step3OptionValues['soffits']['data'] && step3OptionValues['soffits']['data'].filter(
                          (x) => x && x.category_option_type == "checkbox"
                        ).length? false : true}
                        ></input>
                      <span>inches</span>
                    </div>
                    </div>:null
                      }
                      
                  </div>
                </li>}

                {step3OptionValues && step3OptionValues['door-finish'] && <li className="half-list celling-input">
                  <h4 className="title-control">Door Finish</h4>
                  <div className="description-box check-box">
                      <div className="form-group check-input input-box-control">
                        <label>Wall:</label>
                        <input type="text" className="form-control"
                            value={step3OptionValues && step3OptionValues['door-finish'] && 
                            step3OptionValues['door-finish']['data'].filter(
                              (x) => x && x.category_option_name == "Wall"
                            ).length
                            ? step3OptionValues['door-finish']['data'].filter(
                              (x) => x && x.category_option_name == "Wall"
                            )[0]["category_option_value"] : ""}
                            onChange={(e) =>
                              handleFormValueChange(
                                e.target.value,
                                  "door-finish",
                                  step3OptionObject['door-finish']['data'].filter(
                                    (x) => x && x.category_option_name == "Wall"
                                  )[0]["category_option_id"],
                                "input-text"
                              )
                            }
                        ></input>
                      </div>
                      <div className="form-group check-input input-box-control">
                        <label>Base:</label>
                        <input type="text" className="form-control"
                          value={step3OptionValues && step3OptionValues['door-finish'] && 
                          step3OptionValues['door-finish']['data'].filter(
                            (x) => x && x.category_option_name == "Base"
                          ).length
                          ? step3OptionValues['door-finish']['data'].filter(
                            (x) => x && x.category_option_name == "Base"
                          )[0]["category_option_value"] : ""}
                          onChange={(e) =>
                            handleFormValueChange(
                              e.target.value,
                                "door-finish",
                                step3OptionObject['door-finish']['data'].filter(
                                  (x) => x && x.category_option_name == "Base"
                                )[0]["category_option_id"],
                              "input-text"
                            )
                          }
                        ></input>
                      </div>
                      <div className="form-group check-input input-box-control last">
                        <label>Island:</label>
                        <input type="text" className="form-control"
                          value={step3OptionValues && step3OptionValues['door-finish'] && 
                          step3OptionValues['door-finish']['data'].filter(
                            (x) => x && x.category_option_name == "Island"
                          ).length
                          ? step3OptionValues['door-finish']['data'].filter(
                            (x) => x && x.category_option_name == "Island"
                          )[0]["category_option_value"] : ""}
                          onChange={(e) =>
                            handleFormValueChange(
                              e.target.value,
                                "door-finish",
                                step3OptionObject['door-finish']['data'].filter(
                                  (x) => x && x.category_option_name == "Island"
                                )[0]["category_option_id"],
                              "input-text"
                            )
                          }
                        ></input>
                      </div>
                  </div>
                </li>}

                {dropdownOptionsDecoration && <li className="half-list decorate-field">
                 <div className="description-box check-box three-control-list standard-block">
                  <h4 className="title-control">Decoration</h4>
                  <SearchableDropDown 
                      key={'decoration'}
                      data={dropdownOptionsDecoration}
                      dataMapingString={{value: "category_option_name", key: "category_option_id"}}
                      onChange={(e) =>
                        {
                          
                          handleFormValueChange(
                          e.target.value,
                          'decoration',
                          e.target.value,
                          "dropdown"
                        )
                                    
                      }
                      }
                      classNames="select-box input-box"
                      value={
                        step3OptionValues && step3OptionValues['decoration'] && step3OptionValues['decoration']['data'] && step3OptionValues['decoration']['data'].length
                          ? step3OptionValues['decoration']['data'][0]["category_option_id"]
                          : ""
                      }
                  />
                  </div>
                </li>}

                <li className="half-list decorate-field multiplier-controller sink-controll-box">
                {dropdownOptionsSink && <div className="sink-control">
                  <label className="title-control">Sink</label>
                  <SearchableDropDown 
                      key={'sink'}
                      data={dropdownOptionsSink}
                      dataMapingString={{value: "category_option_name", key: "category_option_id"}}
                      onChange={(e) =>
                        {
                          
                          handleFormValueChange(
                          e.target.value,
                          'sink',
                          e.target.value,
                          "dropdown"
                        )
                                    
                      }
                      }
                      classNames="select-box input-box sink-dropdown"
                      value={
                        step3OptionValues && step3OptionValues['sink'] && step3OptionValues['sink']['data'] && step3OptionValues['sink']['data'].length
                          ? step3OptionValues['sink']['data'][0]["category_option_id"]
                          : ""
                      }
                  />
                  {step3OptionValues && step3OptionValues['sink'] && step3OptionValues['sink']['data'] && step3OptionValues['sink']['data'].length
                              && step3OptionValues['sink']['data'][0]["is_others"] == "1" ? <input placeholder="Enter" type="text" className="form-control sink-others"
                              value={step3OptionValues && step3OptionValues['sink'] && step3OptionValues['sink']['data'] && step3OptionValues['sink']['data'].length
                              ? step3OptionValues['sink']['data'][0]["others_value"]
                              : ""}
                              onChange={(e) =>
                                handleFormValueChange(
                                  e.target.value,
                                  'sink',
                                  step3OptionObject['sink']['data'][0]["category_option_id"],
                                  "others"
                                )}
                        ></input>:null}
                  </div>}
                </li>

               {step3OptionObject && step3OptionObject["molding-door-enhancement"] && <li className="full-width-control">
                  <h4 className="title-control">Molding & Door Enhancement</h4>
                  {step3OptionObject && step3OptionValues && renderCheckbox(
                      step3OptionObject["molding-door-enhancement"]["data"],
                      step3OptionValues["molding-door-enhancement"]["data"],
                      "molding-door-enhancement"
                    )} 
                </li>}

                {step3OptionObject && step3OptionObject["storage-organizer"] && <li className="full-width-control">
                  <h4 className="title-control">Storage & Organizer</h4>
                  {step3OptionObject && step3OptionValues && renderCheckbox(
                      step3OptionObject["storage-organizer"]["data"],
                      step3OptionValues["storage-organizer"]["data"],
                      "storage-organizer"
                    )} 
                </li>}

                {step3OptionObject && step3OptionObject["appliances"] && <li className="full-width-control">
                <h4 className="title-control">Standard Appliances</h4>
                {step3OptionObject && step3OptionValues && renderCheckbox(
                      step3OptionObject["appliances"]["data"],
                      step3OptionValues["appliances"]["data"],
                      "appliances"
                    )} 
                </li>}

                {step3OptionObject && step3OptionObject["loose-appliances"] && <li className="full-width-control">
                <h4 className="title-control">Loose Appliances</h4>
                {step3OptionObject && step3OptionValues && renderCheckbox(
                      step3OptionObject["loose-appliances"]["data"],
                      step3OptionValues["loose-appliances"]["data"],
                      "loose-appliances"
                    )} 
                </li>}

                <p className="if-text">
                    If you have any non-standard appliances, please{" "}
                    <a onClick={openPopup} href="#" title="click here">
                      click here
                    </a>{" "}
                    and submit the detailed measurements.
                  </p>

                <li className="full-width-control sink-control autowidth-control addtional-controls">
                  <div className="description-box check-box three-control-list standard-block">
                  {step3OptionObject && step3OptionObject["multiplier"] && <div className="addtional-multiplier">
                  <h4 className="title-control">Multiplier</h4>
                      <div className="check-input multiplier">
                        <input type="number" className="form-control"
                              value={step3OptionValues && step3OptionValues['multiplier'] && step3OptionValues['multiplier']['data'] && step3OptionValues['multiplier']['data'].length ? 
                              step3OptionValues['multiplier']['data'][0]["category_option_value"] : ""}
                              onChange={(e) =>
                                handleFormValueChange(
                                  e.target.value,
                                  'multiplier',
                                  step3OptionObject['multiplier']['data'][0]["category_option_id"],
                                  "input-text"
                                )}
                        ></input>
                      </div>
                      </div>}
                      {step3OptionObject && step3OptionObject["freshsales-url"] && <div className="addtional-urls">
                        <h4 className="title-control">Freshsales Url</h4>
                        <div className="check-input freshsales-url">
                          <input type="text" className="form-control"
                                value={step3OptionValues && step3OptionValues['freshsales-url'] && step3OptionValues['freshsales-url']['data'] && step3OptionValues['freshsales-url']['data'].length ? 
                                step3OptionValues['freshsales-url']['data'][0]["category_option_value"] : ""}
                                onChange={(e) =>
                                  handleFormValueChange(
                                    e.target.value,
                                    'freshsales-url',
                                    step3OptionObject['freshsales-url']['data'][0]["category_option_id"],
                                    "input-text"
                                  )}
                          ></input>
                        </div>
                      </div>}
                      
                  <div className="required">
                    <label>Expected Date</label>
                    <DatePicker minDate={new Date()} onChange={changeDueDate} value={dueDate}/>
                  </div>
                  
                  </div>

                  <div className="description-box check-box three-control-list">
                  <label className="title-control">Additional Notes</label>
                  <ReactQuill 
                    modules={quillOptionsCongif.modules}
                    formats={quillOptionsCongif.formats}
                    value={additionalNotes}
                    onChange={(e) => {
                      dispatch(clientRequestActions.setAdditionalNotes(e));
                    }}
                  />
                  </div>
                </li>
              </ul>
              </div>
            </div>
          </div>
        </div> 

        <div className="actions-row">
          <button className="btn back" onClick={()=>{
            history.push("/app/client/myrequests/all-requests")
            dispatch(myRequestActions.changeCopyRequestId(""));
            dispatch(myRequestActions.changeCopyAction(false));
            }}>Back</button>
          <button type="button" className="btn btn-black next" onClick={()=>{
            submitRequest('Next')}}>{user && user.client_type == "standard" ? "Next" : "Submit"}</button>
          <button type="button" className="btn btn-b-blue save-draft" onClick={()=>{
            submitRequest('Draft')}}>Save as Draft</button>
          </div> 
        
    </div>
  );
}

export default NewRequest;