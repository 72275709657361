/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import "./index.scss";
import { actions as generalActions } from "../../redux/reducers/GeneralReducer";

function Toast({ type, heading, text, id }) {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch(generalActions.removeToast(id));
    }, 5000);
  }, []);
  return (
    <div className={`toast-message ${type ? type : "info"}`}>
      <span className="icon"></span>
      <div className="message-text">
        <span className="title">{heading}</span>
        <span className="sub-text">{text}</span>
      </div>
      <span
        className="close"
        onClick={() => dispatch(generalActions.removeToast(id))}
      ></span>
    </div>
  );
}

export default Toast;
