/* eslint-disable eqeqeq */
import React from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { actions as myRequestActions } from "../../../redux/reducers/MyRequestReducer";
import { convertUTCToLocale } from "../../../Utils/utils";

function SoldInfo({ soldData = {}, is_sold_allowed = false }) {
  const {
    //cabinet_brand_id,
    cabinet_brand_name = "",
    city,
    //client_request_id,
    created_at,
    created_by_name = "",
    customer_name,
    //door_style_id,
    door_style_name = "",
    email,
    id,
    //order_date,
    order_date_name,
    order_value,
    phone,
    state,
    street,
    updated_at,
    zipcode
  } = soldData
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);


  const handlePopup = () => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      dispatch(myRequestActions.markAsSoldPopup({ open: true, editSold: true }))
    }
  }

  return (
    <div className="tab-content sold-info-content">
      <div className="sold-info">
        {id && is_sold_allowed ?
          <div className="row">
            <div className="col">
              <button className="edit-button" type="button" onClick={handlePopup}>
                <img src={require("../../../assets/images/edit-icon.png")} alt="Edit" />
              </button>
            </div>
          </div>
          : null}
        <div className="row first-row">
          <div className="col col-lg-3">
            <b>Created By:</b> {created_by_name}
          </div>
          <div className="col col-lg-3">
            <b>Created On:</b> {created_at ? convertUTCToLocale(created_at)?.dateAndTime : ""}
          </div>
          <div className="col col-lg-3">
            <b>Updated On:</b> {updated_at ? convertUTCToLocale(updated_at)?.dateAndTime : ""}
          </div>
          <div>
          </div>
        </div>

        <div className="row second-row">
          <div className="col col-md-6">
            <div className="row">
              <div className="col">
                <h3>General Info</h3>
              </div>
              <div className="col">
                <b>Cabinet Brand:</b> {cabinet_brand_name}
              </div>
              <div className="col">
                <b>Door Style:</b> {door_style_name}
              </div>
              <div className="col">
                <b>Order Date :</b> {order_date_name}
              </div>
              <div className="col">
                <b>Order Value:</b> {Number(order_value) > 0 ? `$${order_value}` : ""}
              </div>
            </div>
          </div>
          <div className="col col-md-6">
            <div className="row">
              <div className="col">
                <h3>Customer Details</h3>
              </div>
              <div className="col">
                <b>Customer Name:</b> {customer_name}
              </div>
              <div className="col">
                <b>Email:</b> {email}
              </div>
              <div className="col">
                <b>Phone:</b> {phone}
              </div>
              <div className="col">
                <b>Street:</b> {street}
              </div>
              <div className="col">
                <b>City:</b> {city}
              </div>
              <div className="col">
                <b>State:</b> {state}
              </div>
              <div className="col">
                <b>Zipcode:</b> {zipcode}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SoldInfo;
