import React, { memo } from "react";

import './index.scss';

const ForbiddenPage = () => {
    return (
        <div id="forbiddenPage" className='forbidden-page container'>
            <h2>403</h2>
            <h3>Access forbidden</h3>
            <h5>You don't have permission to access this page.</h5>
        </div>
    );
}

export default memo(ForbiddenPage);