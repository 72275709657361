import {clientReportDetails, clientProductivityRatio, clientTotalRequestSubmitted} from "../actionConstants/clientReports.constants";

const initialState = {
  clientReportDetailsProcess: {
    isFetchingClientReportDetails: false,
    clientReportDetailsSuccessResponse: {},
    isClientReportDetailsFailed: false,
    clientReportDetailsFailedMessage: ''
  },

  clientProductivityRatioFetchProcess: {
      isFetchingProductivityRatio: false,
      productivityRatioFetchedData : {},
      isProductivityRatioFetchFailed : false,
      productivityRatioFetchFailedMessage:""
  },

  clientTotalRequestSubmittedFetchProcess: {
    isFetchingTotalRequestSubmitted: false,
    totalRequestSubmittedFetchedData : {},
    isTotalRequestSubmittedFetchFailed : false,
    totalRequestSubmittedFetchFailedMessage:""
}
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case clientProductivityRatio.GET_CLIENT_PRODUCTIVITY_RATIO_INIT: {
      return {
        ...state,
        clientProductivityRatioFetchProcess: {
          ...state.clientProductivityRatioFetchProcess,
          isFetchingProductivityRatio: true,        
          isProductivityRatioFetchFailed: false,
          productivityRatioFetchFailedMessage: "",
        },
      };
    }
    case clientProductivityRatio.GET_CLIENT_PRODUCTIVITY_RATIO_SUCCESS: {
    return {
      ...state,
      clientProductivityRatioFetchProcess: {
        ...state.clientProductivityRatioFetchProcess,
        isFetchingProductivityRatio: false,
        productivityRatioFetchedData: payload,
        isProductivityRatioFetchFailed: false,
        productivityRatioFetchFailedMessage: "",
      },
    };
  }
  case clientProductivityRatio.GET_CLIENT_PRODUCTIVITY_RATIO_FAILURE: {
    return {
      ...state,
      clientProductivityRatioFetchProcess: {
        ...state.clientProductivityRatioFetchProcess,
        isFetchingProductivityRatio: false,
        productivityRatioFetchedData: {},
        isProductivityRatioFetchFailed: true,
        productivityRatioFetchFailedMessage: payload,
      },
    };
  }
  case clientReportDetails.CLIENT_REPORT_DETAILS_INIT :{
    return {
      ...state,
      clientReportDetailsProcess:{
        ...state.clientReportDetailsProcess,
        isFetchingClientReportDetails : true,
        clientReportDetailsSuccessResponse : [],
        isClientReportDetailsFailed :false,
        clientReportDetailsFailedMessage : ""
      },
    }
  }
  case clientReportDetails.CLIENT_REPORT_DETAILS_SUCCESS :{
    return {
      ...state,
      clientReportDetailsProcess:{
        ...state.clientReportDetailsProcess,
        isFetchingClientReportDetails : false,
        clientReportDetailsSuccessResponse : payload,
        isClientReportDetailsFailed :false,
        clientReportDetailsFailedMessage : ""
      },
    }
  }
  case clientReportDetails.CLIENT_REPORT_DETAILS_FAILURE :{
    return {
      ...state,
      clientReportDetailsProcess:{
        ...state.clientReportDetailsProcess,
        isFetchingClientReportDetails : false,
        clientReportDetailsSuccessResponse : [],
        isClientReportDetailsFailed :true,
        clientReportDetailsFailedMessage : payload
      },
    }
  }
  case clientTotalRequestSubmitted.GET_CLIENT_TOTAL_REQUESTS_SUBMITTED_INIT: {
    return {
      ...state,
      clientTotalRequestSubmittedFetchProcess: {
        ...state.clientTotalRequestSubmittedFetchProcess,
        isFetchingTotalRequestSubmitted: true,
        isTotalRequestSubmittedFetchFailed : false,
        totalRequestSubmittedFetchFailedMessage:""
      },
    };
  }
  case clientTotalRequestSubmitted.GET_CLIENT_TOTAL_REQUESTS_SUBMITTED_SUCCESS: {
    return {
      ...state,
      clientTotalRequestSubmittedFetchProcess: {
        ...state.clientTotalRequestSubmittedFetchProcess,
        isFetchingTotalRequestSubmitted: false,
        totalRequestSubmittedFetchedData : payload,
        isTotalRequestSubmittedFetchFailed : false,
        totalRequestSubmittedFetchFailedMessage:""
      },
    };
  }

  case clientTotalRequestSubmitted.GET_CLIENT_TOTAL_REQUESTS_SUBMITTED_FAILURE: {
    return {
      ...state,
      clientTotalRequestSubmittedFetchProcess: {
        ...state.clientTotalRequestSubmittedFetchProcess,
        isFetchingTotalRequestSubmitted: false,
        totalRequestSubmittedFetchedData : {},
        isTotalRequestSubmittedFetchFailed : true,
        totalRequestSubmittedFetchFailedMessage:payload
      },
    };
  }
    default:
      return state;
  }
};

export default reducer;
