import React, { memo } from 'react'
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { formFields, validationSchemaChangePasswordForm } from './constant';
import { Input } from '../../../../ReusableComponents';
import { actions as AuthActions } from "../../../../../redux/reducers/MyAccountReducer";

function ChangePassword({ handleToggleChangePassword }) {
    const dispatch = useDispatch();
    const [cookies] = useCookies(["clientAccessToken"]);

    return (
        <Formik
            initialValues={{ old_password: '', new_password: '', confirm_password: '' }}
            validationSchema={validationSchemaChangePasswordForm}
            onSubmit={(values, actions) => {
                dispatch(AuthActions.changePassword({ ...values, clientAccessToken: cookies.clientAccessToken }));
                actions.resetForm();
            }}
        >
            {({ touched, errors, values, handleBlur, handleChange, handleSubmit }) => (
                <div className='form-outer'>
                    <ul>
                        <li>
                            <div className="form-group">
                                <Input
                                    setting={formFields.old_password}
                                    touched={touched[formFields.old_password.key]}
                                    errors={errors[formFields.old_password.key]}
                                    value={values[formFields.old_password.key]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </li>
                        <li>
                            <div className="form-group">
                                <Input
                                    setting={formFields.new_password}
                                    touched={touched[formFields.new_password.key]}
                                    errors={errors[formFields.new_password.key]}
                                    value={values[formFields.new_password.key]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </li>
                        <li>
                            <div className="form-group">
                                <Input
                                    setting={formFields.confirm_password}
                                    touched={touched[formFields.confirm_password.key]}
                                    errors={errors[formFields.confirm_password.key]}
                                    value={values[formFields.confirm_password.key]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </li>
                    </ul>
                    <div className='buttons'>
                        <button onClick={handleSubmit} className='common-button'>SAVE</button>
                        <button onClick={handleToggleChangePassword} className='common-button border-btn'>CANCEL</button>
                    </div>
                    {/* <p className="change-email">
                        To change your email address please contact Kitchen Design 365 at <a href="sales@kitchen365.com">sales@kitchen365.com</a>
                    </p> */}
                </div>)}
        </Formik>
    )
}
export default memo(ChangePassword)