import React from 'react'
import { CustomTab } from '../ReusableComponents'
import AccountInformation from './AccountInformation';
import AddressBook from './AddressBook';
import PaymentMethod from './PaymentMethod';
import MyInvoices from './MyInvoices';
import MySubscription from './MySubscription';
import "./index.scss";

export default function MyAccount() {
    
    return (
        <div className='account-container'>
            <div className='container cf'>
                <CustomTab
                    tabTitle="My Account"
                    defaultPreselectTab={1}
                    componentList={[{
                        id: 1,
                        tabName: "Account Information",
                        component: <AccountInformation />
                    },
                    {
                        id: 2,
                        tabName: "Address Book",
                        component: <AddressBook />
                    },
                    {
                        id: 3,
                        tabName: "My Subscription",
                        component:<MySubscription />
                    },
                    {
                        id: 4,
                        tabName: "My Invoices",
                        component:<MyInvoices />
                    }
                    , {
                        id: 5,
                        tabName: "Saved Cards",
                        component:<PaymentMethod />
                    },
                ]}

                />
            </div>
        </div>

    )
}

