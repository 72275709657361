// Packages Import
import React, { memo } from 'react';
import { CloseButton } from 'react-bootstrap';

// Compnent Imports

// Style Imports
import "./index.scss";

const Tag = (props) => {
    // Variables
    const {
        tagLabel = '',
        onClickHandler
    } = props;

    return (
        <span id="tag">
            <span className="tag-label">{tagLabel}</span>
            <CloseButton
                onClick={onClickHandler}
                className='tag-close'
            />
        </span>
    );
}

export default memo(Tag)