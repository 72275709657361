/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import {  
  Switch,
  Route,
  Redirect,useLocation, useHistory
} from "react-router-dom";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { useDispatch} from "react-redux";

import "./index.scss";
import ClientView from "../ClientView";
import DesignerView from "../DesignerView";
import { actions as AuthActions } from "../../redux/reducers/AuthReducer";

function Main() {
  const [cookies, setCookie,removeCookie] = useCookies(["clientAccessToken"]);
  const dispatch = useDispatch();
const location = useLocation();
const history = useHistory();
const { logoutSuccess } = useSelector(
  (state) => state.auth
);
//when cookies.clientAccessToken has value or there is already a client loggedin
  useEffect(()=>{
if(location.state){
let acccessToken =location.state.from.search.replace("?clientAccessToken=", "")
if(acccessToken){
  if(cookies.clientAccessToken){
    dispatch(AuthActions.setAccessToken(acccessToken));
    dispatch(AuthActions.logout(cookies.clientAccessToken));
  }
}
}
  },[cookies.clientAccessToken, dispatch])

// when logout is successfull from line no 32
  useEffect(() => {
    if (logoutSuccess) {
      removeCookie("clientAccessToken", { path: "/" });
      dispatch(AuthActions.clearLogoutFlags());
      history.push("/login");
    }
  },[logoutSuccess])
  
  return (
    <div className="request-inner-applications">
      <Switch>
        <RedirectRoute exact path="/app" />

        <Route path="/app/client" component={ClientView} />

        <Route path="/app/designer" component={DesignerView} />
      </Switch>
    </div>
  );
}

function RedirectRoute({ children, ...rest }) {
  const [cookies] = useCookies(["clientAccessToken"]);
  const { user } = useSelector((state) => state.auth);

  const path = () => {
    if (cookies.clientAccessToken) {
      if (user && user.group_id) {
        if (user.group_id === 3) {
          return "/app/designer";
        } else {
          return "/app/client";
        }
      } else {
        return "/login";
      }
    } else {
      return "/login";
    }
  };

  return (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: path(),
            state: { from: location },
          }}
        />
      )}
    />
  );
}

export default Main;
