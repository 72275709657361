/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
import { put, takeLatest, call, all, select } from "redux-saga/effects";
import { actions as authActions } from "./AuthReducer";
import { actions as generalActions } from "./GeneralReducer";
import ShortUniqueId from "short-unique-id";
import apiUrl from "../../config/urls";
import config from "../../config/config";
import {
  getApiToken,
  postApiToken,
  postApiTokenJson,
  postApiTokenFormData,
  fetchDoorDetails,
  putApiTokenJson,
  getApiTokenSlash,
  postApiTokenSlash,
  putApiToken,
} from "../apis";
import queryString from "query-string";
import PdfIcon from "../../assets/images/Antu_acrobat.svg";
const uid = new ShortUniqueId();

export const actionTypes = {
  CLIENT_REQUEST_STEP_1_SUBMIT: "CLIENT_REQUEST_STEP_1_SUBMIT",
  CLIENT_REQUEST_STEP_LOADING: "CLIENT_REQUEST_STEP_1_LOADING",
  CHANGE_CLIENT_REQUEST_NAME: "CHANGE_CLIENT_REQUEST_NAME",
  STEP_1_COMPLETE: "STEP_1_COMPLETE",
  CHANGE_CLIENT_REQUEST_TYPE: "CHANGE_CLIENT_REQUEST_TYPE",
  CLIENT_REQUEST_STEP_2_SUBMIT: "CLIENT_REQUEST_STEP_2_SUBMIT",
  STEP_2_COMPLETE: "STEP_2_COMPLETE",
  SET_REQUEST_ID: "SET_REQUEST_ID",
  GET_CLIENT_REQUEST_STEP_3_OPTIONS: "GET_CLIENT_REQUEST_STEP_3_OPTIONS",
  SET_CLIENT_REQUEST_STEP_3_OPTIONS: "SET_CLIENT_REQUEST_STEP_3_OPTIONS",
  CHANGE_STEP_3_OPTIONS_VALUE: "CHANGE_STEP_3_OPTIONS_VALUE",
  CLIENT_REQUEST_STEP_3_SUBMIT: "CLIENT_REQUEST_STEP_3_SUBMIT",
  STEP_3_COMPLETE: "STEP_3_COMPLETE",
  CHANGE_KITCHEN_MEASUREMENT: "CHANGE_KITCHEN_MEASUREMENT",
  CHANGE_KITCHEN_PHOTOS: "CHANGE_KITCHEN_PHOTOS",
  CHANGE_KITCHEN_KIT: "CHANGE_KITCHEN_KIT",
  CHANGE_SECTION_PROPERLY_LABELED: "CHANGE_SECTION_PROPERLY_LABELED",
  STEP_4_COMPLETE: "STEP_4_COMPLETE",
  CLIENT_REQUEST_STEP_4_SUBMIT: "CLIENT_REQUEST_STEP_4_SUBMIT",
  STEP_5_COMPLETE: "STEP_5_COMPLETE",
  CHANGE_TEXT_EDITOR_DATA: "CHANGE_TEXT_EDITOR_DATA",
  CHANGE_DUE_DATE: "CHANGE_DUE_DATE",
  CHANGE_NOTE: "CHANGE_NOTE",
  CLIENT_REQUEST_STEP_5_SUBMIT: "CLIENT_REQUEST_STEP_5_SUBMIT",
  CHANGE_TEXT_EDITOR_DATA_JSON: "CHANGE_TEXT_EDITOR_DATA_JSON",
  CLEAR_REQUEST_DATA: "CLEAR_REQUEST_DATA",
  SUCCESS_REQUEST_ID: "SUCCESS_REQUEST_ID",
  GET_STEP_DATA: "GET_STEP_DATA",
  SET_STEP_3_VALUES: "SET_STEP_3_VALUES",
  DELETE_KITCHEN_MEDIA: "DELETE_KITCHEN_MEDIA",
  CHANGE_REQUEST_TYPES: "CHANGE_REQUEST_TYPES",
  NAVIGATE_TO_DRAFT_PAGE: "NAVIGATE_TO_DRAFT_PAGE",
  CLEAR_NAVIGATE_TO_DRAFT_FLAG: "CLEAR_NAVIGATE_TO_DRAFT_FLAG",
  GET_DOOR_DETAILS_INIT: "GET_DOOR_DETAILS_INIT",
  GET_DOOR_DETAILS_SUCCESS: "GET_DOOR_DETAILS_SUCCESS",
  GET_DOOR_DETAILS_FAILURE: "GET_DOOR_DETAILS_FAILURE",
  GET_CREATE_NEW_REQUEST_DETAILS: "GET_CREATE_NEW_REQUEST_DETAILS",
  SET_REQUEST_DETAIL: "SET_REQUEST_DETAIL",
  CHANGE_REQUEST_DELETED_MEDIA: "CHANGE_REQUEST_DELETED_MEDIA",
  CHANGE_REQUEST_UPDATED_MEDIA: "CHANGE_REQUEST_UPDATED_MEDIA",
  CHANGE_REMOVED_IMAGES: "CHANGE_REMOVED_IMAGES",
  CREATE_NEW_REQUEST: "CREATE_NEW_REQUEST",
  SET_KITCHEN_IMAGES: "SET_KITCHEN_IMAGES",
  SET_ADDITIONAL_NOTES: "SET_ADDITIONAL_NOTES",
  REDIRECT_PAGE_TO_PLAN: "REDIRECT_PAGE_TO_PLAN",
  SET_PLANS: "SET_PLANS",
  SET_SELECTED_PLAN: "SET_SELECTED_PLAN",
  GET_ADDRESS_LIST: "GET_ADDRESS_LIST",
  ADD_NEW_ADDRESS: "ADD_NEW_ADDRESS",
  SET_ADDRESS: "SET_ADDRESS",
  SET_COUNTRY_DROPDOWN: "SET_COUNTRY_DROPDOWN",
  GET_COUNTRY_DROPDOWN: "GET_COUNTRY_DROPDOWN",
  SET_STATE_DROPDOWN: "SET_STATE_DROPDOWN",
  SET_ALL_STATE: "SET_ALL_STATE",
  GET_ADDRESS_LIST: "GET_ADDRESS_LIST",
  SET_ADDRESS_LIST: "SET_ADDRESS_LIST",
  IS_ADDRESS_SAVED: "IS_ADDRESS_SAVED",
  GET_ADDRESS: "GET_ADDRESS",
  GET_ADDRESS_LOADING: "GET_ADDRESS_LOADING",
  SET_DEFAULT_ADDRESS: "SET_DEFAULT_ADDRESS_PLAN",
  CHANGE_DEFAULT_ADDRESS: "CHANGE_DEFAULT_ADDRESS",
  SET_PAYMENT_SUBMIT_DATA: 'SET_PAYMENT_SUBMIT_DATA',
  SUBMIT_PAYMENT_DATA: 'SUBMIT_PAYMENT_DATA',
  SELECT_BILLING_ID: 'SELECT_BILLING_ID',
  REDIRECT_TO_SUCCESS: 'REDIRECT_TO_SUCCESS',
  REDIRECT_TO_DRAFT: 'REDIRECT_TO_DRAFT',
  GET_CARD_LIST: 'GET_CARD_LIST',
  SET_CARD_LIST: 'SET_CARD_LIST',
  SUBMIT_PAYMENT_DATA_SAVED_CARD: 'SUBMIT_PAYMENT_DATA_SAVED_CARD',
  CARD_LIST_LOADING: 'CARD_LIST_LOADING',
  UPDATE_ADDRESS: 'UPDATE_ADDRESS',
  IS_MEASUREMENT_OPTIONAL: 'IS_MEASUREMENT_OPTIONAL',
};

const initialState = {
  clientRequestName: "",
  clientRequestLoading: false,
  step1Complete: false,
  clientRequestType: "1",
  step2Complete: false,
  requestId: null,
  step3OptionObject: null,
  step3OptionValues: null,
  step3Complete: false,
  kitchenMeasurement: [],
  kitchenPhotos: [],
  kitchenKit: [],
  allSectionProperlyLabeled: false,
  step4Complete: false,
  step5Complete: false,
  textEditorData: "",
  dueDate: new Date(),
  note: "",
  textEditorDataJson: "",
  requestIdSuccess: "",
  deletedMedia: { kitchen_measurements: [], kitchen_photos: [], kit_files: [] },
  requestTypes: "",
  navigateToDraftPage: false,
  doorDetailsFetch: {
    isFetchingDoorDetails: false,
    doorDetailsData: [],
    isFetchingDoorDetailsFailed: false,
    isFetchingDoorDetailsFailedMessage: "",
  },
  isMeasurementOptional:0,
  requestDetailData: "",
  removedImages: {
    kitchen_measurement: [],
  },
  requestDeletedMedia: "",
  requestUpdatedMedia: "",
  kitchenMeasurementImages: "",
  additionalNotes:"",
  redirectToPlanPage: false,
  plans: [{
          created_at: "2021-12-29T09:13:15.000000Z",
          description: "<ul><li>3 Iterations</li><li>Includes 2020 .KIT File</li><li>Blueprints &amp; Floorplan</li><li>CSV File for Easy Import</li></ul>",
          id: 1,
          label: "Plan 1",
          plan_days: null,
          plan_request_count: "10",
          plan_type: "request",
          status: "active",
          updated_at: "2021-12-29T09:13:15.000000Z",
          price: 0.00,
        },{
          created_at: "2021-12-29T09:13:15.000000Z",
          description: "<ul><li>3 Iterations</li><li>Includes 2020 .KIT File</li><li>Blueprints &amp; Floorplan</li><li>CSV File for Easy Import</li></ul>",
          id: 2,
          label: "Plan 1",
          plan_days: null,
          plan_request_count: "10",
          plan_type: "request",
          status: "active",
          updated_at: "2021-12-29T09:13:15.000000Z",
          price: 0.00,
        },{
          created_at: "2021-12-29T09:13:15.000000Z",
          description: "<ul><li>Dedicated Designer</li><li>60 Designs a Month</li><li>Kitchen &amp; Full House Designs</li><li>2020 .KIT File, Blueprints &amp; Floorplan</li><li>3D Render &amp; .CSV File</li></ul>",
          id: 3,
          label: "Plan 1",
          plan_days: null,
          plan_request_count: "10",
          plan_type: "both",
          status: "active",
          updated_at: "2021-12-29T09:13:15.000000Z",
          price: 0.00,
        }],
  selectedPlan: null,     
  addressSubmit:{client_id:"",street:"",street_2:"",city:"",country_id:"",state_id:"",postcode:""}, 
  countryDropDown: [],
  stateDropDown: [],
  allStates:[],
  addressList:[],
  isAddressSaved: false,
  getAddressLoading: false,
  defaultAddress: "",
  paymentSubmitData: {card_id:"",client_id:"",request_id:"",billing_address_id:"",plan_id:"",is_save_detail:true,card_number:"",expiration_date:"",cvv:"",email:"",amount:"0.00",month:"",year:"",payment_method:""},
  billingAddressId:"",
  redirectToSuccess: false,
  redirectToDraft: false,
  cardList: [],
  cardListLoading: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_REQUEST_DATA: {
      return {
        ...state,
        clientRequestName: "",
        clientRequestLoading: false,
        step1Complete: false,
        clientRequestType: "1",
        step2Complete: false,
        requestId: "",
        // step3OptionObject: null,
        step3OptionValues: null,
        step3Complete: false,
        kitchenMeasurement: [],
        kitchenPhotos: [],
        kitchenKit: [],
        allSectionProperlyLabeled: false,
        step4Complete: false,
        step5Complete: false,
        textEditorData: "",
        dueDate: new Date(),
        note:"",
        textEditorDataJson: "",
        deletedMedia: {
          kitchen_measurements: [],
          kitchen_photos: [],
          kit_files: [],
        },
        requestTypes: "",
        navigateToDraftPage: false,
        isMeasurementOptional:0,
        requestDetailData: "",
        removedImages: {
          kitchen_measurement: [],
        },
        requestDeletedMedia: "",
        requestUpdatedMedia: "",
        kitchenMeasurementImages: "",
        additionalNotes:"",
        redirectToPlanPage: false,
        selectedPlan: null,
        addressSubmit:{client_id:"",street:"",street_2:"",city:"",country_id:"",state_id:"",postcode:""}, 
        countryDropDown: [],
        stateDropDown: [],
        allStates:[],
        addressList:[],
        isAddressSaved: false,
        getAddressLoading: false,
        defaultAddress: "",
        paymentSubmitData: {client_id:"",request_id:"",billing_address_id:"",plan_id:"",is_save_detail:"",card_number:"",expiration_date:"",cvv:"",email:"",amount:"0.00"},
        billingAddressId:"",
        redirectToSuccess: false,
        redirectToDraft: false,
        cardList: [],
        cardListLoading: false,
      };
    }

    case actionTypes.CLIENT_REQUEST_STEP_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        clientRequestLoading: flag,
      };
    }

    case actionTypes.CHANGE_CLIENT_REQUEST_NAME: {
      const { data } = action.payload;
      return {
        ...state,
        clientRequestName: data,
      };
    }

    case actionTypes.CHANGE_REQUEST_TYPES: {
      const { data } = action.payload;
      return {
        ...state,
        requestTypes: data,
      };
    }

    case actionTypes.DELETE_KITCHEN_MEDIA: {
      const { data } = action.payload;
      return {
        ...state,
        deletedMedia: data,
      };
    }

    case actionTypes.SET_STEP_3_VALUES: {
      const { data } = action.payload;
      return {
        ...state,
        step3OptionValues: data,
      };
    }

    case actionTypes.CHANGE_CLIENT_REQUEST_TYPE: {
      const { data } = action.payload;
      return {
        ...state,
        clientRequestType: data,
      };
    }

    case actionTypes.STEP_1_COMPLETE: {
      const { flag } = action.payload;
      return {
        ...state,
        step1Complete: flag,
      };
    }

    case actionTypes.STEP_2_COMPLETE: {
      const { flag } = action.payload;
      return {
        ...state,
        step2Complete: flag,
      };
    }

    case actionTypes.STEP_3_COMPLETE: {
      const { flag } = action.payload;
      return {
        ...state,
        step3Complete: flag,
      };
    }

    case actionTypes.STEP_4_COMPLETE: {
      const { flag } = action.payload;
      return {
        ...state,
        step4Complete: flag,
      };
    }

    case actionTypes.STEP_5_COMPLETE: {
      const { flag } = action.payload;
      return {
        ...state,
        step5Complete: flag,
      };
    }

    case actionTypes.SET_REQUEST_ID: {
      const { data } = action.payload;
      return {
        ...state,
        requestId: data,
      };
    }

    case actionTypes.SUCCESS_REQUEST_ID: {
      const { data } = action.payload;
      return {
        ...state,
        requestIdSuccess: data,
      };
    }

    case actionTypes.SET_CLIENT_REQUEST_STEP_3_OPTIONS: {
      const { data, values, isCreate } = action.payload;
      
      let valueData = {};
      let dropdownOption = [];
      Object.keys(data).map((key, index) => {
        if (key === "appliances" || key === "loose-appliances") {
          dropdownOption = [];
          if (values[key] && values[key]["data"]) {
            for (var i = 0; i < data[key]["data"].length; i++) {
              for (var j = 0; j < values[key]["data"].length; j++) {
                if (
                  data[key]["data"][i]["category_option_id"] ===
                  values[key]["data"][j]["category_option_id"]
                ) {
                  if (values[key]["data"][j]["child_data"].length) {
                    data[key]["data"][i]["category_option_value"] =
                      values[key]["data"][j]["category_option_value"];
                      dropdownOption = values[key]["data"][j]["child_data"].filter(
                        (x) => x && x.category_option_type == "dropdown"
                      );
                  } else {
                    values[key]["data"][j]["child_data"] =
                      data[key]["data"][i]["child_data"];
                    data[key]["data"][i]["category_option_value"] =
                      values[key]["data"][j]["category_option_value"];
                  }
                    
                    let width = values[key]["data"][j]["child_data"].filter(
                      (x) => x && x.name == "Width"
                    );
                    let brand = values[key]["data"][j]["child_data"].filter(
                      (x) => x && x.name == "Brand & Model"
                    );
                    let depth = values[key]["data"][j]["child_data"].filter(
                      (x) => x && x.name == "Depth"
                    );
                    let height = values[key]["data"][j]["child_data"].filter(
                      (x) => x && x.name == "Height"
                    );
                    
                    if(brand.length){
                      values[key]["data"][j]["child_data"][0] = brand[0];
                    }else{
                      values[key]["data"][j]["child_data"][0] = data[key]["data"][i]["child_data"][0];
                    }
                    if(depth.length){
                      values[key]["data"][j]["child_data"][1] = depth[0];
                    }else{
                      values[key]["data"][j]["child_data"][1] = data[key]["data"][i]["child_data"][1];
                    }
                    if(width.length){
                      values[key]["data"][j]["child_data"][2] = width[0];
                    }else{
                      values[key]["data"][j]["child_data"][2] = data[key]["data"][i]["child_data"][2];
                    }
                    if(height.length){
                      values[key]["data"][j]["child_data"][3] = height[0];
                    }else{
                      values[key]["data"][j]["child_data"][3] = data[key]["data"][i]["child_data"][3];
                    }
                    if(!isCreate){
                      if(dropdownOption.length){
                        dropdownOption[0]['selected'] = true;
                        values[key]["data"][j]["child_data"][4] = dropdownOption[0];
                      }
                    }
                    
                    
                    // for (
                    //   var l = 0;
                    //   l < values[key]["data"][j]["child_data"].length;
                    //   l++
                    // ) {
                    //   if (
                    //     data[key]["data"][i]["child_data"][k]["id"] ===
                    //     values[key]["data"][j]["child_data"][l]["id"]
                    //   ) {
                    //     data[key]["data"][i]["child_data"][k] =
                    //       values[key]["data"][j]["child_data"][l];
                    //   }
                    // }
                  
                }
              }
            }
          }

          valueData[key] = {
            option: data[key]["option"],
            data: values[key] && values[key]["data"] && !isCreate ? values[key]["data"] : [],
          };
        } else {
          valueData[key] = {
            option: data[key]["option"],
            data: values[key] && values[key]["data"] && !isCreate ? values[key]["data"] : [],
          };
        }
      });
      return {
        ...state,
        step3OptionObject: data,
        step3OptionValues: valueData,
      };
    }

    case actionTypes.CHANGE_STEP_3_OPTIONS_VALUE: {
      const { value, categoryName, optionId, type, indexChild, isPopup } =
        action.payload;
      const optionObject = JSON.parse(JSON.stringify(state.step3OptionValues));
      const mainObject = JSON.parse(JSON.stringify(state.step3OptionObject));
      switch (type) {
        case "dropdown":
          let categoryObject = optionObject[categoryName];
          let categoryObjectData = categoryObject["data"];
          let dropdownValueIndex = categoryObjectData.findIndex(
            (x) => x.category_option_type === "dropdown"
          );
          if (value !== "Select") {
            let object = {};
            let dropdownObjectIndex = mainObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === Number(optionId));
            if (dropdownObjectIndex !== -1) {
              object = mainObject[categoryName]["data"][dropdownObjectIndex];
            }
            if (dropdownValueIndex !== -1) {
              optionObject[categoryName]["data"][dropdownValueIndex] = object;
            } else {
              optionObject[categoryName]["data"].push(object);
            }
          } else {
            if (dropdownValueIndex !== -1) {
              optionObject[categoryName]["data"].splice(dropdownValueIndex, 1);
            }
          }
          break;

        case "input-text":
          if (value !== "") {
            let object = {};
            let inputTextObjectIndex = mainObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === optionId);
            if (inputTextObjectIndex !== -1) {
              object = mainObject[categoryName]["data"][inputTextObjectIndex];
              object["category_option_value"] = value;
              let inputTextValueObjectIndex = optionObject[categoryName][
                "data"
              ].findIndex((x) => x.category_option_id === optionId);
              if (inputTextValueObjectIndex !== -1) {
                optionObject[categoryName]["data"][inputTextValueObjectIndex][
                  "category_option_value"
                ] = value;
              } else {
                optionObject[categoryName]["data"].push(object);
              }
            }
          } else {
            let inputTextObjectIndex = optionObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === optionId);
            if (inputTextObjectIndex !== -1) {
              optionObject[categoryName]["data"].splice(
                inputTextObjectIndex,
                1
              );
            }
          }
          break;
        case "checkbox":
          if (value) {
            let object = {};
            let checkboxObjectIndex = mainObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === optionId);

            if (checkboxObjectIndex !== -1) {
              object = mainObject[categoryName]["data"][checkboxObjectIndex];
              object["category_option_value"] = value;
              let checkboxObjectValueIndex = optionObject[categoryName][
                "data"
              ].findIndex((x) => x.category_option_id === optionId);
              if (checkboxObjectValueIndex !== -1) {
                optionObject[categoryName]["data"][checkboxObjectValueIndex] =
                  object;
              } else {
                optionObject[categoryName]["data"].push(object);
              }
            }
          } else {
            let checkboxObjectIndex = optionObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === optionId);
            if (checkboxObjectIndex !== -1) {
              optionObject[categoryName]["data"].splice(checkboxObjectIndex, 1);
            }
          }
          break;
          case "appliances":
            if(isPopup){                   
                let applianceObjectIndex = mainObject[categoryName][
                  "data"
                ].findIndex((x) => x.category_option_id === optionId);
                if (applianceObjectIndex !== -1) {
                  let applianceValueObjectIndex = optionObject[categoryName][
                    "data"
                  ].findIndex((x) => x.category_option_id === optionId);
                  if (applianceValueObjectIndex !== -1) {
                    optionObject[categoryName]["data"][applianceValueObjectIndex][
                      "child_data"
                    ][indexChild]["value"] = value;
                  }
                }
            }else{
              let object = {};
              let dropdownObjectIndex = mainObject[categoryName][
                "data"
              ].findIndex((x) => x.category_option_id === Number(optionId));
              let dropdownObjectIndexi = optionObject[categoryName][
                "data"
              ].findIndex((x) => x.category_option_id === Number(optionId));
              
              if (value !== "Select") {
                if (dropdownObjectIndex !== -1) {
                  
                  object = JSON.parse(JSON.stringify(mainObject[categoryName]["data"][dropdownObjectIndex]));
                  
                  let dropdownValueOptions = object['child_data'].filter(
                    (x) => x.category_option_type === "dropdown"
                  );
                  let inputNumberText = object['child_data'].filter(
                    (x) => x.category_option_type === "input-number" || x.category_option_type === "input-text"
                  );
                  if(optionObject[categoryName]['data'].length){
                    let inputNumberTextValue = optionObject[categoryName]['data'][dropdownObjectIndexi]['child_data'].filter(
                      (x) => x.category_option_type === "input-number" || x.category_option_type === "input-text"
                    );
                    inputNumberText.forEach(function(v,i){
                      inputNumberText[i] = inputNumberTextValue[i]
                    });
                    
                  }
                  let getSelectedDropdown;
                  dropdownValueOptions.forEach(function(v,i){
                    if(v.id == Number(value)){
                      v['selected'] = true;
                      v['value'] = v['id'];
                      getSelectedDropdown = v;
                    }
                  });
                  inputNumberText.push(getSelectedDropdown);
                  optionObject[categoryName]["data"][dropdownObjectIndexi]['child_data'] = inputNumberText;
                }
              } else {
                if (dropdownObjectIndexi !== -1) {
                  optionObject[categoryName]["data"][dropdownObjectIndexi]['child_data'] = [];
                }
              }
            }
            break;
        case "loose-appliances":
          if(isPopup){                   
            let applianceObjectIndex = mainObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === optionId);
            if (applianceObjectIndex !== -1) {
              let applianceValueObjectIndex = optionObject[categoryName][
                "data"
              ].findIndex((x) => x.category_option_id === optionId);
              if (applianceValueObjectIndex !== -1) {
                optionObject[categoryName]["data"][applianceValueObjectIndex][
                  "child_data"
                ][indexChild]["value"] = value;
              }
            }
          }else{
            let object = {};
            let dropdownObjectIndex = mainObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === Number(optionId));
            let dropdownObjectIndexi = optionObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === Number(optionId));
            
            if (value !== "Select") {
              if (dropdownObjectIndex !== -1) {
                
                object = JSON.parse(JSON.stringify(mainObject[categoryName]["data"][dropdownObjectIndex]));
                
                let dropdownValueOptions = object['child_data'].filter(
                  (x) => x.category_option_type === "dropdown"
                );
                let inputNumberText = object['child_data'].filter(
                  (x) => x.category_option_type === "input-number" || x.category_option_type === "input-text"
                );
                if(optionObject[categoryName]['data'].length){
                  let inputNumberTextValue = optionObject[categoryName]['data'][dropdownObjectIndexi]['child_data'].filter(
                    (x) => x.category_option_type === "input-number" || x.category_option_type === "input-text"
                  );
                  inputNumberText.forEach(function(v,i){
                    inputNumberText[i] = inputNumberTextValue[i]
                  });
                  
                }
                let getSelectedDropdown;
                dropdownValueOptions.forEach(function(v,i){
                  if(v.id == Number(value)){
                    v['selected'] = true;
                    v['value'] = v['id'];
                    getSelectedDropdown = v;
                  }
                });
                inputNumberText.push(getSelectedDropdown);
                optionObject[categoryName]["data"][dropdownObjectIndexi]['child_data'] = inputNumberText;
              }
            } else {
              if (dropdownObjectIndexi !== -1) {
                optionObject[categoryName]["data"][dropdownObjectIndexi]['child_data'] = [];
              }
            }
          }
          break;
        case "others":
          if (value) {
            if(categoryName == "sink"){
              optionObject[categoryName]["data"][0]['others_value'] = value;
            }else{
              let object = {};
              let checkboxObjectIndex = mainObject[categoryName][
                "data"
              ].findIndex((x) => x.category_option_id === optionId);
  
              if (checkboxObjectIndex !== -1) {
                object = mainObject[categoryName]["data"][checkboxObjectIndex];
                object["category_option_value"] = true;
                object["others_value"] = value;
                let checkboxObjectValueIndex = optionObject[categoryName][
                  "data"
                ].findIndex((x) => x.category_option_id === optionId);
                if (checkboxObjectValueIndex !== -1) {
                  optionObject[categoryName]["data"][checkboxObjectValueIndex] =
                    object;
                } else {
                  optionObject[categoryName]["data"].push(object);
                }
              }
            }
            
          } else {
            if(categoryName == "sink"){
              optionObject[categoryName]["data"][0]['others_value'] = "";
            }
            let checkboxObjectIndex = optionObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === optionId);
            if (checkboxObjectIndex !== -1) {
              optionObject[categoryName]["data"].splice(checkboxObjectIndex, 1);
            }
          }
          break;
        default:
      }
      return {
        ...state,
        step3OptionValues: optionObject,
      };
    }

    case actionTypes.CHANGE_KITCHEN_MEASUREMENT: {
      const { data } = action.payload;
      return {
        ...state,
        kitchenMeasurement: data,
      };
    }

    case actionTypes.CHANGE_KITCHEN_PHOTOS: {
      const { data } = action.payload;
      return {
        ...state,
        kitchenPhotos: data,
      };
    }

    case actionTypes.CHANGE_KITCHEN_KIT: {
      const { data } = action.payload;
      return {
        ...state,
        kitchenKit: data,
      };
    }

    case actionTypes.CHANGE_SECTION_PROPERLY_LABELED: {
      const { flag } = action.payload;
      return {
        ...state,
        allSectionProperlyLabeled: flag,
      };
    }

    case actionTypes.CHANGE_TEXT_EDITOR_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        textEditorData: data,
      };
    }

    case actionTypes.CHANGE_TEXT_EDITOR_DATA_JSON: {
      const { data } = action.payload;
      return {
        ...state,
        textEditorDataJson: data,
      };
    }

    case actionTypes.CHANGE_DUE_DATE: {
      const { data } = action.payload;
      return {
        ...state,
        dueDate: data,
      };
    }
    case actionTypes.CHANGE_NOTE: {
      const { data } = action.payload;
      return {
        ...state,
        note: data,
      };
    }
    case actionTypes.NAVIGATE_TO_DRAFT_PAGE: {
      const { flag } = action.payload;
      return {
        ...state,
        navigateToDraftPage: flag,
      };
    }
    case actionTypes.CLEAR_NAVIGATE_TO_DRAFT_FLAG: {
      return {
        ...state,
        navigateToDraftPage: false,
      };
    }

    case actionTypes.GET_DOOR_DETAILS_INIT: {
      return {
        ...state,
        step3OptionValues: {
          ...state.step3OptionValues,
          "door-style": { ...state.step3OptionValues["door-style"], data: [] },
        },
        doorDetailsFetch: {
          ...state.doorDetailsFetch,
          isFetchingDoorDetails: true,
          doorDetailsData: [],
          isFetchingDoorDetailsFailed: false,
          isFetchingDoorDetailsFailedMessage: "",
        },
      };
    }

    case actionTypes.GET_DOOR_DETAILS_SUCCESS: {
      return {
        ...state,
        step3OptionObject: {
          ...state.step3OptionObject,
          "door-style": {
            ...state.step3OptionObject["door-style"],
            data:
              action.payload?.length > 0
                ? action.payload
                : [{ category_option_type: "dropdown" }],
          },
        },
        doorDetailsFetch: {
          ...state.doorDetailsFetch,
          isFetchingDoorDetails: false,
          doorDetailsData: action.payload,
          isFetchingDoorDetailsFailed: false,
          isFetchingDoorDetailsFailedMessage: "",
        },
      };
    }

    case actionTypes.GET_DOOR_DETAILS_FAILURE: {
      return {
        ...state,
        doorDetailsFetch: {
          ...state.doorDetailsFetch,
          isFetchingDoorDetails: false,
          doorDetailsData: [],
          isFetchingDoorDetailsFailed: true,
          isFetchingDoorDetailsFailedMessage: action.payload,
        },
      };
    }

    case actionTypes.SET_REQUEST_DETAIL: {
      const { data } = action.payload;
      return {
        ...state,
        requestDetailData: data,
      };
    }

    case actionTypes.CHANGE_REMOVED_IMAGES: {
      const { data } = action.payload;
      return {
        ...state,
        removedImages: data,
      };
    }

    case actionTypes.CHANGE_REQUEST_DELETED_MEDIA: {
      const { data } = action.payload;
      return {
        ...state,
        requestDeletedMedia: data,
      };
    }

    case actionTypes.CHANGE_REQUEST_UPDATED_MEDIA: {
      const { data } = action.payload;
      return {
        ...state,
        requestUpdatedMedia: data,
      };
    }

    case actionTypes.SET_KITCHEN_IMAGES: {
      const { data } = action.payload;
      return {
        ...state,
        kitchenMeasurementImages: data,
      };
    }

    case actionTypes.SET_ADDITIONAL_NOTES: {
      const { data } = action.payload;
      return {
        ...state,
        additionalNotes: data,
      };
    }

    case actionTypes.REDIRECT_PAGE_TO_PLAN: {
      const { flag } = action.payload;
      return {
        ...state,
        redirectToPlanPage: flag,
      };
    }

    case actionTypes.SET_PLANS: {
      const { data } = action.payload;
      return {
        ...state,
        plans: data,
      };
    }

    case actionTypes.SET_SELECTED_PLAN: {
      const { data } = action.payload;
      return {
        ...state,
        selectedPlan: data,
      };
    }

    case actionTypes.SET_ADDRESS: {
      const { data } = action.payload;
      return {
        ...state,
        addressSubmit: data,
      };
    }

    case actionTypes.SET_COUNTRY_DROPDOWN: {
      const { data } = action.payload;
      return {
        ...state,
        countryDropDown: data,
      };
    }

    case actionTypes.SET_STATE_DROPDOWN: {
      const { data } = action.payload;
      return {
        ...state,
        stateDropDown: data,
      };
    }

    case actionTypes.SET_ALL_STATE: {
      const { data } = action.payload;
      return {
        ...state,
        allStates: data,
      };
    }

    case actionTypes.SET_ADDRESS_LIST: {
      const { data } = action.payload;
      return {
        ...state,
        addressList: data,
      };
    }

    case actionTypes.IS_ADDRESS_SAVED: {
      const { flag } = action.payload;
      return {
        ...state,
        isAddressSaved: flag,
      };
    }

    case actionTypes.GET_ADDRESS_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        getAddressLoading: flag,
      };
    }

    case actionTypes.SET_DEFAULT_ADDRESS: {
      const { data } = action.payload;
      return {
        ...state,
        defaultAddress: data,
      };
    }

    case actionTypes.SET_PAYMENT_SUBMIT_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        paymentSubmitData: data,
      };
    }

    case actionTypes.CHANGE_REQUEST_TYPES: {
      const { data } = action.payload;
      return {
        ...state,
        requestTypes: data,
      };
    }

    case actionTypes.SELECT_BILLING_ID: {
      const { data } = action.payload;
      return {
        ...state,
        billingAddressId: data,
      };
    }

    case actionTypes.REDIRECT_TO_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        redirectToSuccess: flag,
      };
    }

    case actionTypes.REDIRECT_TO_DRAFT: {
      const { flag } = action.payload;
      return {
        ...state,
        redirectToDraft: flag,
      };
    }

    case actionTypes.SET_CARD_LIST: {
      const { data } = action.payload;
      return {
        ...state,
        cardList: data,
      };
    }

    case actionTypes.CARD_LIST_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        cardListLoading: flag,
      };
    }

    case actionTypes.IS_MEASUREMENT_OPTIONAL: {
      const { is_measurement_optional } = action.payload;
      return {
        ...state,
        isMeasurementOptional: is_measurement_optional,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  changeRequestTypes: (data) => ({
    type: actionTypes.CHANGE_REQUEST_TYPES,
    payload: { data },
  }),
  changeDeleteKitchenMedia: (data) => ({
    type: actionTypes.DELETE_KITCHEN_MEDIA,
    payload: { data },
  }),
  setStep3Values: (data) => ({
    type: actionTypes.SET_STEP_3_VALUES,
    payload: { data },
  }),
  clearRequestData: () => ({
    type: actionTypes.CLEAR_REQUEST_DATA,
  }),
  changeClientRequestType: (data) => ({
    type: actionTypes.CHANGE_CLIENT_REQUEST_TYPE,
    payload: { data },
  }),
  changeClientRequestName: (data) => ({
    type: actionTypes.CHANGE_CLIENT_REQUEST_NAME,
    payload: { data },
  }),
  clientRequestStep1Submit: (data, clientAccessToken) => ({
    type: actionTypes.CLIENT_REQUEST_STEP_1_SUBMIT,
    payload: { data, clientAccessToken },
  }),
  clientRequestStepLoading: (flag) => ({
    type: actionTypes.CLIENT_REQUEST_STEP_LOADING,
    payload: { flag },
  }),
  step1Complete: (flag) => ({
    type: actionTypes.STEP_1_COMPLETE,
    payload: { flag },
  }),
  step2Complete: (flag) => ({
    type: actionTypes.STEP_2_COMPLETE,
    payload: { flag },
  }),
  step3Complete: (flag) => ({
    type: actionTypes.STEP_3_COMPLETE,
    payload: { flag },
  }),
  step4Complete: (flag) => ({
    type: actionTypes.STEP_4_COMPLETE,
    payload: { flag },
  }),
  step5Complete: (flag) => ({
    type: actionTypes.STEP_5_COMPLETE,
    payload: { flag },
  }),
  clientRequestStep2Submit: (data, clientAccessToken, isDraft) => ({
    type: actionTypes.CLIENT_REQUEST_STEP_2_SUBMIT,
    payload: { data, clientAccessToken, isDraft },
  }),
  changeRequestId: (data) => ({
    type: actionTypes.SET_REQUEST_ID,
    payload: { data },
  }),
  changeRequestIdSuccess: (data) => ({
    type: actionTypes.SUCCESS_REQUEST_ID,
    payload: { data },
  }),
  changeStep3Option: (data, values, isCreate) => ({
    type: actionTypes.SET_CLIENT_REQUEST_STEP_3_OPTIONS,
    payload: { data, values, isCreate },
  }),
  getStep3Option: (requestId, clientAccessToken) => ({
    type: actionTypes.GET_CLIENT_REQUEST_STEP_3_OPTIONS,
    payload: { requestId, clientAccessToken },
  }),
  changeStep3OptionsValue: (
    value,
    categoryName,
    optionId,
    type,
    indexChild = null,
    isPopup = null,
  ) => ({
    type: actionTypes.CHANGE_STEP_3_OPTIONS_VALUE,
    payload: { value, categoryName, optionId, type, indexChild, isPopup },
  }),
  clientRequestStep3Submit: (data, clientAccessToken, isDraft) => ({
    type: actionTypes.CLIENT_REQUEST_STEP_3_SUBMIT,
    payload: { data, clientAccessToken, isDraft },
  }),
  changeKitchenMeasurement: (data) => ({
    type: actionTypes.CHANGE_KITCHEN_MEASUREMENT,
    payload: { data },
  }),
  changeKitchenPhotos: (data) => ({
    type: actionTypes.CHANGE_KITCHEN_PHOTOS,
    payload: { data },
  }),
  changeKitchenKit: (data) => ({
    type: actionTypes.CHANGE_KITCHEN_KIT,
    payload: { data },
  }),
  changeSectionProperlyLabeled: (flag) => ({
    type: actionTypes.CHANGE_SECTION_PROPERLY_LABELED,
    payload: { flag },
  }),
  clientRequestStep4Submit: (clientAccessToken, isDraft) => ({
    type: actionTypes.CLIENT_REQUEST_STEP_4_SUBMIT,
    payload: { clientAccessToken, isDraft },
  }),
  changeTextEditorData: (data) => ({
    type: actionTypes.CHANGE_TEXT_EDITOR_DATA,
    payload: { data },
  }),
  changeTextEditorDataJson: (data) => ({
    type: actionTypes.CHANGE_TEXT_EDITOR_DATA_JSON,
    payload: { data },
  }),
  changeDueDate: (data) => ({
    type: actionTypes.CHANGE_DUE_DATE,
    payload: { data },
  }),
  changeNote: (data) => ({
    type: actionTypes.CHANGE_NOTE,
    payload: { data },
  }),
  clientRequestStep5Submit: (currentComment,clientAccessToken, isDraft) => ({
    type: actionTypes.CLIENT_REQUEST_STEP_5_SUBMIT,
    payload: { clientAccessToken, isDraft,currentComment },
  }),
  getStepData: (step, requestID, clientAccessToken) => ({
    type: actionTypes.GET_STEP_DATA,
    payload: { step, requestID, clientAccessToken },
  }),
  navigateToDraftPage: (flag) => ({
    type: actionTypes.NAVIGATE_TO_DRAFT_PAGE,
    payload: { flag },
  }),
  clearNavigateToDraftFlag: () => ({
    type: actionTypes.CLEAR_NAVIGATE_TO_DRAFT_FLAG,
  }),
  getLatestDoorDetails: (doorId, clientAccessToken) => ({
    type: actionTypes.GET_DOOR_DETAILS_INIT,
    payload: { doorId, clientAccessToken },
  }),
  getRequestDetails: (clientAccessToken, id) => ({
    type: actionTypes.GET_CREATE_NEW_REQUEST_DETAILS,
    payload: { clientAccessToken, id },
  }),
  setRequestDetails: (data) => ({
    type: actionTypes.SET_REQUEST_DETAIL,
    payload: { data },
  }),
  changeRemovedImages: (data) => ({
    type: actionTypes.CHANGE_REMOVED_IMAGES,
    payload: { data },
  }),
  changeRequestDeletedMedia: (data) => ({
    type: actionTypes.CHANGE_REQUEST_DELETED_MEDIA,
    payload: { data },
  }),
  changeRequestUpdatedMedia: (data) => ({
    type: actionTypes.CHANGE_REQUEST_UPDATED_MEDIA,
    payload: { data },
  }),
  createNewRequest: (data, clientAccessToken) => ({
    type: actionTypes.CREATE_NEW_REQUEST,
    payload: { data, clientAccessToken },
  }),
  setKitchenImages: (data) => ({
    type: actionTypes.SET_KITCHEN_IMAGES,
    payload: { data },
  }),
  setAdditionalNotes: (data) => ({
    type: actionTypes.SET_ADDITIONAL_NOTES,
    payload: { data },
  }),
  redirectPageToPlan: (flag) => ({
    type: actionTypes.REDIRECT_PAGE_TO_PLAN,
    payload: { flag },
  }),
  setPlans: (data) => ({
    type: actionTypes.SET_PLANS,
    payload: { data },
  }),
  setSelectedPlans: (data) => ({
    type: actionTypes.SET_SELECTED_PLAN,
    payload: { data },
  }),
  addNewAddress: (data, clientAccessToken) => ({
    type: actionTypes.ADD_NEW_ADDRESS,
    payload: { data, clientAccessToken },
  }),
  setAddress: (data) => ({
    type: actionTypes.SET_ADDRESS,
    payload: { data },
  }),
  setCountryDropdown: (data) => ({
    type: actionTypes.SET_COUNTRY_DROPDOWN,
    payload: { data },
  }),
  getCountryDropdown: (clientAccessToken) => ({
    type: actionTypes.GET_COUNTRY_DROPDOWN,
    payload: { clientAccessToken },
  }),
  setStateDropdown: (data) => ({
    type: actionTypes.SET_STATE_DROPDOWN,
    payload: { data },
  }),
  setAllStates: (data) => ({
    type: actionTypes.SET_ALL_STATE,
    payload: { data },
  }),
  setAddressList: (data) => ({
    type: actionTypes.SET_ADDRESS_LIST,
    payload: { data },
  }),
  getAddressList: (clientAccessToken) => ({
    type: actionTypes.GET_ADDRESS_LIST,
    payload: { clientAccessToken },
  }),
  setAddressSaved: (flag) => ({
    type: actionTypes.IS_ADDRESS_SAVED,
    payload: { flag },
  }),
  getAddress: (id, clientAccessToken) =>({
    type: actionTypes.GET_ADDRESS,
    payload: { id,clientAccessToken },
  }),
  getAddressLoading: (flag) =>({
    type: actionTypes.GET_ADDRESS_LOADING,
    payload: { flag },
  }),
  setDefaultAddress: (data) => ({
    type: actionTypes.SET_DEFAULT_ADDRESS,
    payload: { data },
  }),
  changeDefaultAddress: (id, clientAccessToken) => ({
    type: actionTypes.CHANGE_DEFAULT_ADDRESS,
    payload: { id, clientAccessToken },
  }),
  setPaymentSubmitData: (data) => ({
    type: actionTypes.SET_PAYMENT_SUBMIT_DATA,
    payload: { data },
  }),
  submitPaymentData: (data, clientAccessToken) => ({
    type: actionTypes.SUBMIT_PAYMENT_DATA,
    payload: { data, clientAccessToken },
  }),
  selectBillingId: (data) => ({
    type: actionTypes.SELECT_BILLING_ID,
    payload: { data },
  }),
  redirectToSuccess: (flag) =>({
    type: actionTypes.REDIRECT_TO_SUCCESS,
    payload: { flag },
  }),
  redirectToDraft: (flag) =>({
    type: actionTypes.REDIRECT_TO_DRAFT,
    payload: { flag },
  }),
  setCardList: (data) =>({
    type: actionTypes.SET_CARD_LIST,
    payload: { data },
  }),
  getCardList: (clientAccessToken) =>({
    type: actionTypes.GET_CARD_LIST,
    payload: { clientAccessToken },
  }),
  submitPaymentDataSavedCard: (data, clientAccessToken) => ({
    type: actionTypes.SUBMIT_PAYMENT_DATA_SAVED_CARD,
    payload: { data, clientAccessToken },
  }),
  setCardListLoading: (flag) =>({
    type: actionTypes.CARD_LIST_LOADING,
    payload: { flag },
  }),
  updateAddress: (data, id, clientAccessToken) => ({
    type: actionTypes.UPDATE_ADDRESS,
    payload: { data, id, clientAccessToken },
  }),
  setMesurementOptional: (is_measurement_optional) => ({
    type: actionTypes.IS_MEASUREMENT_OPTIONAL,
    payload: { is_measurement_optional },
  }),
};

export function* saga() {
  yield takeLatest(
    actionTypes.CLIENT_REQUEST_STEP_1_SUBMIT,
    clientRequestStep1SubmitSaga
  );
  yield takeLatest(
    actionTypes.CLIENT_REQUEST_STEP_2_SUBMIT,
    clientRequestStep2SubmitSaga
  );
  yield takeLatest(
    actionTypes.GET_CLIENT_REQUEST_STEP_3_OPTIONS,
    getStep3OptionsSaga
  );
  yield takeLatest(
    actionTypes.CLIENT_REQUEST_STEP_3_SUBMIT,
    clientRequestStep3SubmitSaga
  );
  yield takeLatest(
    actionTypes.CLIENT_REQUEST_STEP_4_SUBMIT,
    clientRequestStep4SubmitSaga
  );
  yield takeLatest(
    actionTypes.CLIENT_REQUEST_STEP_5_SUBMIT,
    clientRequestStep5SubmitSaga
  );
  yield takeLatest(actionTypes.GET_STEP_DATA, getStepDataSaga);
  yield takeLatest(actionTypes.GET_DOOR_DETAILS_INIT, getLatestDoorDetailsSaga);
  yield takeLatest(actionTypes.GET_CREATE_NEW_REQUEST_DETAILS, getRequestDetailsSaga);
  yield takeLatest(actionTypes.CREATE_NEW_REQUEST, createNewRequestSaga);
  yield takeLatest(actionTypes.ADD_NEW_ADDRESS, addNewAddressSaga);
  yield takeLatest(actionTypes.GET_COUNTRY_DROPDOWN, getCountryDropdownSaga);
  yield takeLatest(actionTypes.GET_ADDRESS_LIST, getAddressListSaga);
  yield takeLatest(actionTypes.GET_ADDRESS, getAddressSaga);
  yield takeLatest(actionTypes.CHANGE_DEFAULT_ADDRESS, changeDefaultAddressSaga);
  yield takeLatest(actionTypes.SUBMIT_PAYMENT_DATA, submitPaymentDataSaga);
  yield takeLatest(actionTypes.GET_CARD_LIST, getCardListSaga);
  yield takeLatest(actionTypes.SUBMIT_PAYMENT_DATA_SAVED_CARD, submitPaymentDataSavedCardSaga);
  yield takeLatest(actionTypes.UPDATE_ADDRESS, updateAddressSaga);
}

function* clientRequestStep1SubmitSaga(action) {
  let { data, clientAccessToken } = action.payload;
  const { user } = yield select((state) => state.auth);
  let submitData = queryString.stringify({
    client_id: user.parent_id ? user.parent_id : user.id,
    step: 1,
    request_title: data,
  });

  yield all([put(actions.clientRequestStepLoading(true))]);
  try {
    const response = yield call(
      postApiToken,
      apiUrl("CLIENT_REQUEST_STEP_1_URL"),
      clientAccessToken,
      submitData
    );
    if (response.status === 200) {
      yield all([
        put(actions.clientRequestStepLoading(false)),
        put(actions.step1Complete(true)),
      ]);
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Client Request Step 1",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Client Request Step 1",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* clientRequestStep2SubmitSaga(action) {
  let { data, clientAccessToken, isDraft } = action.payload;
  const { requestId } = yield select((state) => state.clientRequest);
  const { user } = yield select((state) => state.auth);
  let submitData;
  if (requestId) {
    submitData = queryString.stringify({
      client_id: user.parent_id ? user.parent_id : user.id,
      step: 2,
      request_title: data.request_title,
      request_type: data.request_type,
      request_id: requestId,
      is_draft: isDraft,
    });
  } else {
    submitData = queryString.stringify({
      client_id: user.parent_id ? user.parent_id : user.id,
      step: 2,
      request_title: data.request_title,
      request_type: data.request_type,
      is_draft: isDraft,
    });
  }

  yield all([put(actions.clientRequestStepLoading(true))]);
  try {
    const response = yield call(
      postApiToken,
      apiUrl("CLIENT_REQUEST_STEP_2_URL"),
      clientAccessToken,
      submitData
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (isDraft) {
          if (requestId) {
            yield all([put(actions.clientRequestStepLoading(false))]);
          } else {
            yield all([
              put(actions.changeRequestId(responseData.data.request_id)),
              put(actions.clientRequestStepLoading(false)),
            ]);
          }
          yield put(actions.navigateToDraftPage(true));
          yield put(actions.clearRequestData());
        } else {
          if (requestId) {
            yield all([
              put(actions.clientRequestStepLoading(false)),
              put(actions.step2Complete(true)),
            ]);
          } else {
            yield all([
              put(actions.changeRequestId(responseData.data.request_id)),
              put(actions.clientRequestStepLoading(false)),
              put(actions.step2Complete(true)),
            ]);
          }
        }
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Client Request Step 2",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Client Request Step 2",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* getStep3OptionsSaga(action) {
  let { requestId, clientAccessToken } = action.payload;
  yield all([put(actions.clientRequestStepLoading(true))]);
  const { user } = yield select((state) => state.auth);
  const filterParams = {
    step: 3,
    // client_id: user.id,
    request_id: requestId,
  };

  try {
    const response = yield call(
      getApiToken,
      apiUrl("CLIENT_REQUEST_STEP_3_GET_URL"),
      queryString.stringify(filterParams),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.clientRequestStepLoading(false)),
          put(
            actions.changeStep3Option(
              responseData.data.dynamic_form_data,
              responseData.data.current_form_data
            )
          ),
        ]);
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Client Request Step 3",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Client Request Step 3",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* getStepDataSaga(action) {
  let { step, requestID, clientAccessToken } = action.payload;
  yield all([put(actions.clientRequestStepLoading(true))]);
  const { user } = yield select((state) => state.auth);
  let filterParams = {
    step: step,
    // client_id: user.id,
    request_id: requestID,
  };
  if (requestID) {
    filterParams = {
      step: step,
      // client_id: user.id,
      request_id: requestID,
    };
  } else {
    filterParams = {
      step: step,
      // client_id: user.id,
    };
  }
  try {
    const response = yield call(
      getApiToken,
      apiUrl("CLIENT_REQUEST_STEP_3_GET_URL"),
      queryString.stringify(filterParams),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (step === 1) {
          if (requestID && responseData.data.current_form_data) {
            yield all([
              put(
                actions.changeClientRequestName(
                  responseData.data.current_form_data.request_title
                )
              ),
            ]);
          }
        }
        if (step === 2) {
          if (requestID) {
            if (
              responseData.data.current_form_data &&
              responseData.data.current_form_data.request_type
            ) {
              yield all([
                put(
                  actions.changeClientRequestType(
                    responseData.data.current_form_data.request_type.toString()
                  )
                ),
                put(
                  actions.changeRequestTypes(responseData.data.request_types)
                ),
              ]);
            }
            yield all([
              put(actions.changeRequestTypes(responseData.data.request_types)),
            ]);
          } else {
            yield all([
              put(actions.changeRequestTypes(responseData.data.request_types)),
            ]);
          }
        }

        if (step === 4) {
          let kitchenPhotostemp = [],
            kitchenKittemp = [],
            kitchenMeasurementtemp = [];
          if (requestID) {
            if (responseData.data.kit_file.length) {
              Object.keys(responseData.data.kit_file).map((keyi, indexi) => {
                kitchenKittemp.push({
                  type: "ext",
                  name: responseData.data.kit_file[keyi]["name"],
                  size: "",
                  id: responseData.data.kit_file[keyi]["id"],
                  data: responseData.data.kit_file[keyi]["url"],
                  blob: "../../assets/images/Text_Page_Icon.svg",
                });
              });
              yield all([put(actions.changeKitchenKit(kitchenKittemp))]);
            }
            if (responseData.data.kitchen_photo.length) {
              Object.keys(responseData.data.kitchen_photo).map(
                (keyi, indexi) => {
                  kitchenPhotostemp.push({
                    type: "ext",
                    name: responseData.data.kitchen_photo[keyi]["name"],
                    size: "",
                    id: responseData.data.kitchen_photo[keyi]["id"],
                    data: responseData.data.kitchen_photo[keyi]["url"],
                    blob: responseData.data.kitchen_photo[keyi]["url"],
                  });
                }
              );
              yield all([put(actions.changeKitchenPhotos(kitchenPhotostemp))]);
            }
            if (responseData.data.kitchen_measurement.length) {
              Object.keys(responseData.data.kitchen_measurement).map(
                (keyi, indexi) => {
                  let fileExtention = /[.]/.exec(
                    responseData.data.kitchen_measurement[keyi]["url"]
                  )
                    ? /[^.]+$/.exec(
                        responseData.data.kitchen_measurement[keyi]["url"]
                      )
                    : undefined;
                  kitchenMeasurementtemp.push({
                    type: "ext",
                    name: responseData.data.kitchen_measurement[keyi]["name"],
                    size: "",
                    id: responseData.data.kitchen_measurement[keyi]["id"],
                    data: responseData.data.kitchen_measurement[keyi]["url"],
                    blob:
                      fileExtention[0] === "pdf"
                        ? PdfIcon
                        : responseData.data.kitchen_measurement[keyi]["url"],
                  });
                }
              );
              yield all([
                put(actions.changeKitchenMeasurement(kitchenMeasurementtemp)),
              ]);
            }

            yield all([
              put(
                actions.changeSectionProperlyLabeled(
                  // eslint-disable-next-line eqeqeq
                  responseData.data.is_all_section_properly_labeled == 1
                    ? true
                    : false
                )
              ),
            ]);
          }
        }
        if (step === 5) {
          if (requestID) {
            if (responseData.data.current_form_data.due_date) {
              yield all([
                put(
                  actions.changeDueDate(responseData.data.current_form_data.due_date
                  )
                ),
                put(
                  actions.changeNote(responseData.data.current_form_data.additional_note
                  )
                ),
              ]);
            }        
          }
        }
        yield all([put(actions.clientRequestStepLoading(false))]);
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Client Request Step Data",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Client Request Step Data",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* clientRequestStep3SubmitSaga(action) {
  let { clientAccessToken, isDraft } = action.payload;
  const { requestId, step3OptionValues } = yield select(
    (state) => state.clientRequest
  );
  const { user } = yield select((state) => state.auth);
  let submitData = {
    client_id: user.parent_id ? user.parent_id : user.id,
    step: 3,
    dynamic_form_data: step3OptionValues,
    request_id: requestId,
    is_draft: isDraft,
  };
  yield all([put(actions.clientRequestStepLoading(true))]);
  try {
    const response = yield call(
      postApiTokenJson,
      apiUrl("CLIENT_REQUEST_STEP_3_URL"),
      clientAccessToken,
      JSON.stringify(submitData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (isDraft) {
          yield all([put(actions.clientRequestStepLoading(false))]);
          yield put(actions.navigateToDraftPage(true));
          yield put(actions.clearRequestData());
        } else {
          yield all([
            put(actions.clientRequestStepLoading(false)),
            put(actions.step3Complete(true)),
          ]);
        }
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Client Request Step 3",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Client Request Step 3",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* clientRequestStep4SubmitSaga(action) {
  let { clientAccessToken, isDraft } = action.payload;
  const {
    requestId,
    allSectionProperlyLabeled,
    kitchenMeasurement,
    kitchenPhotos,
    kitchenKit,
    clientRequestType,
    deletedMedia,
    requestTypes,
  } = yield select((state) => state.clientRequest);
  let kitchenMeasurementtemp = [];
  let kitchenPhotostemp = [];
  let kitchenKittemp = [];
  let checkUrl;
  function isURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(str);
  }
  for (var i = 0; i < kitchenMeasurement.length; i++) {
    checkUrl = isURL(kitchenMeasurement[i]["data"]);
    if (checkUrl) {
    } else {
      kitchenMeasurementtemp.push(kitchenMeasurement[i]["data"]);
    }
  }
  for (var i = 0; i < kitchenPhotos.length; i++) {
    checkUrl = isURL(kitchenPhotos[i]["data"]);
    if (checkUrl) {
    } else {
      kitchenPhotostemp.push(kitchenPhotos[i]["data"]);
    }
  }

 
  for (var i = 0; i < kitchenKit.length; i++) {
    checkUrl = isURL(kitchenKit[i]["data"]);
    if (checkUrl) {
    } else {
      kitchenKittemp.push(kitchenKit[i]["data"]);
    }
  }
  

  var formData = new FormData();
  const { user } = yield select((state) => state.auth);

  formData.append("deleted_media", JSON.stringify(deletedMedia));
  formData.append("client_id", user.parent_id ? user.parent_id : user.id);
  formData.append("is_draft", isDraft);
  formData.append("step", 4);
  formData.append("request_id", requestId);
  formData.append("request_type", clientRequestType);
  formData.append(
    "is_all_section_properly_labeled",
    allSectionProperlyLabeled ? 1 : 0
  );

  for (var i = 0; i < kitchenMeasurementtemp.length; i++) {
    formData.append("kitchen_measurements[]", kitchenMeasurementtemp[i]);
  }
  for (var i = 0; i < kitchenPhotostemp.length; i++) {
    formData.append("kitchen_photos[]", kitchenPhotostemp[i]);
  }
  
  for (var i = 0; i < kitchenKittemp.length; i++) {
    formData.append("kit_files[]", kitchenKittemp[i]);
  }
  

  yield all([put(actions.clientRequestStepLoading(true))]);
  try {
    const response = yield call(
      postApiTokenFormData,
      apiUrl("CLIENT_REQUEST_STEP_4_URL"),
      clientAccessToken,
      formData
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (isDraft) {
          yield all([put(actions.clientRequestStepLoading(false))]);
          yield put(actions.navigateToDraftPage(true));
          yield put(actions.clearRequestData());
        } else {
          yield all([
            put(actions.clientRequestStepLoading(false)),
            put(actions.step4Complete(true)),
            put(actions.changeKitchenKit([])),
            put(actions.changeKitchenMeasurement([])),
            put(actions.changeKitchenPhotos([])),
          ]);
        }
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Client Request Step 4",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Client Request Step 4",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* clientRequestStep5SubmitSaga(action) {
  let { clientAccessToken, isDraft,currentComment } = action.payload;
  const { requestId, dueDate } =
    yield select((state) => state.clientRequest);
  var date = new Date(dueDate),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
    var due_date = [date.getFullYear(), mnth, day].join("-");
    if(!isDraft) {
      var date1 = new Date(),
      mnth1 = ("0" + (date1.getMonth() + 1)).slice(-2),
      day1 = ("0" + date1.getDate()).slice(-2);
      var temp_date = [date1.getFullYear(), mnth1, day1].join("-");

      if(due_date < temp_date) {
        due_date = temp_date;
      }
    }
  const { user } = yield select((state) => state.auth);
  let submitData = {
    client_id: user.parent_id ? user.parent_id : user.id,
    step: 5,
    request_id: requestId,
    due_date: due_date,
    additional_note: currentComment,
    is_draft: isDraft,
  };
  yield all([put(actions.clientRequestStepLoading(true))]);
  try {
    const response = yield call(
      postApiToken,
      apiUrl("CLIENT_REQUEST_STEP_5_URL"),
      clientAccessToken,
      queryString.stringify(submitData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (isDraft) {
          yield all([put(actions.clientRequestStepLoading(false))]);
          yield put(actions.navigateToDraftPage(true));
          yield put(actions.clearRequestData());
        } else {
          yield all([
            put(actions.clientRequestStepLoading(false)),
            put(actions.step5Complete(true)),
            put(
              actions.changeRequestIdSuccess(responseData.data.request_number)
            ),
            put(actions.clearRequestData()),
          ]);
        }
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Client Request Step 5",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Client Request Step 5",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* getLatestDoorDetailsSaga(action) {
  let { doorId, clientAccessToken } = action.payload;
  yield all([put(actions.clientRequestStepLoading(true))]);
  try {
    let response = yield call(
      fetchDoorDetails,
      apiUrl("CLIENT_GET_DOOR_STYLE"),
      doorId,
      clientAccessToken
    );
    let responseData = yield call([response, response.json]);
    if (response) {
      yield all([put(actions.clientRequestStepLoading(false))]);
      yield put({
        type: actionTypes.GET_DOOR_DETAILS_SUCCESS,
        payload: responseData?.data,
      });      
    }
  } catch (e) {
    yield all([put(actions.clientRequestStepLoading(false))]);
    yield put({
      type: actionTypes.GET_DOOR_DETAILS_FAILURE,
      payload: e,
    });
  }
}

function* getRequestDetailsSaga(action) {
  let { clientAccessToken, id } = action.payload;
  const { user } = yield select((state) => state.auth);
  let submitData = {
    client_id: user.parent_id ? user.parent_id : user.id,
  }
  let getDetail = {};
  try {
    yield all([put(actions.clientRequestStepLoading(true))]);
    if(id){
      const filterParams = {
        request_id: id,
      };
      const response = yield call(
        getApiToken,
        apiUrl("CLIENT_REQUEST_DETAIL_VIEW"),
        queryString.stringify(filterParams),
        clientAccessToken
      );
      if (response.status === 200) {
        let responseData = yield call([response, response.json]);
        if (responseData && responseData.data) {
          yield put(actions.changeRequestId(id));
          yield put(actions.changeClientRequestName(responseData.data['request_title']));
          yield put(actions.changeRequestTypes(responseData.data['request_type'][0]['id']));
          getDetail['request_type_dropdown'] = responseData.data['request_type_dropdown'];
          getDetail['dynamic_form_data'] = responseData.data['dynamic_form_data'];
          yield put(actions.setRequestDetails(getDetail));
          yield put(
            actions.changeStep3Option(
              responseData.data.dynamic_form_data,
              responseData.data.category_data,
              false,
            )
          );
          yield put(actions.setKitchenImages(responseData.data['images']));
          yield put(actions.changeDueDate(new Date(responseData.data['due_date'])));
          yield put(actions.setAdditionalNotes(responseData.data['note']));
          yield put(actions.clientRequestStepLoading(false));
        }
      } else if (response.status === 403) {
        yield put(actions.clientRequestStepLoading(false));
        let errorData = yield call([response, response.json]);
        if (errorData && errorData.statusCode && errorData.statusCode === 403) {
          yield put(authActions.logout());
        } else {
          yield all([put(actions.clientRequestStepLoading(false))]);
          yield put(
            generalActions.addToast(
              "Client Request Step Data",
              "Something went wrong!",
              "danger",
              uid()
            )
          );
        }
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        let errorData = yield call([response, response.json]);
        yield put(
          generalActions.addToast(
            "Client Request Step Data",
            errorData.message,
            "danger",
            uid()
          )
        );
      }
    }else{
      const response = yield call(
        getApiToken,
        apiUrl("CLIENT_REQUEST_STEP_3_GET_URL"),
        queryString.stringify(submitData),
        clientAccessToken
      );
      if (response.status === 200) {
        let responseData = yield call([response, response.json]);
        if (responseData && responseData.data) {
          getDetail['request_type_dropdown'] = responseData.data['request_types'];
          getDetail['dynamic_form_data'] = responseData.data['dynamic_form_data'];
          
          yield put(actions.setRequestDetails(getDetail));
          yield put(
            actions.changeStep3Option(
              responseData.data['dynamic_form_data'],
              responseData.data['dynamic_form_data'],
              true,
            )
          );
          yield put(actions.clientRequestStepLoading(false));
          const isMeasurementOptional = responseData.data['is_measurement_optional'] || 0
          yield put(actions.setMesurementOptional(isMeasurementOptional));
        }
      } else if (response.status === 403) {
        yield put(actions.clientRequestStepLoading(false));
        let errorData = yield call([response, response.json]);
        if (errorData && errorData.statusCode && errorData.statusCode === 403) {
          yield put(authActions.logout());
        } else {
          yield all([put(actions.clientRequestStepLoading(false))]);
          yield put(
            generalActions.addToast(
              "Client Request Step Data",
              "Something went wrong!",
              "danger",
              uid()
            )
          );
        }
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        let errorData = yield call([response, response.json]);
        yield put(
          generalActions.addToast(
            "Client Request Step Data",
            errorData.message,
            "danger",
            uid()
          )
        );
      }
    }
   
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* createNewRequestSaga(action) {
  let { data, clientAccessToken } = action.payload;
  yield all([put(actions.clientRequestStepLoading(true))]);
  const {
    paymentSubmitData,
  } = yield select((state) => state.clientRequest);
  let submitPaymentData = paymentSubmitData;
  try {
    const response = yield call(
      postApiTokenJson,
      apiUrl("CREATE_NEW_REQUEST"),
      clientAccessToken,
      JSON.stringify(data),
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if(responseData.data.plans){
          if(responseData.data['request_id']){
            yield all([
              put(actions.changeRequestId(responseData.data['request_id']))
            ]);
          }
          yield all([
            put(actions.setPlans(responseData.data.plans)),
            put(actions.redirectPageToPlan(true)),
          ]);
          // if(responseData.data.plans.length){
          //   submitPaymentData['amount'] = responseData.data.plans[0]['price'];
          //   yield all([
          //     put(actions.setPaymentSubmitData(submitPaymentData)),
          //   ]);
          // }
        }else{
          if(data.is_draft){
            yield all([
              put(actions.redirectPageToPlan(false)),
              put(
                actions.changeRequestIdSuccess(responseData.data.request_number)
              ),
              put(actions.clearRequestData()),
              put(actions.redirectToDraft(true)),
            ]);
          }else{
            yield all([
              put(actions.redirectPageToPlan(false)),
              put(
                actions.changeRequestIdSuccess(responseData.data.request_number)
              ),
              put(actions.clearRequestData()),
              put(actions.redirectToSuccess(true)),
            ]);
          }
          
        }
        yield all([
          put(actions.clientRequestStepLoading(false)),
          // put(actions.redirectPage(true)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.clientRequestStepLoading(false))]);
        // yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Request Create",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Request Create",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* addNewAddressSaga(action) {
  let { data, clientAccessToken } = action.payload;
  const { requestId, dueDate } =
  yield select((state) => state.clientRequest);
  yield all([put(actions.clientRequestStepLoading(true))]);
  try {
    const response = yield call(
      postApiToken,
      apiUrl("CREATE_NEW_ADDRESS"),
      clientAccessToken,
      queryString.stringify(data)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
          yield all([
            put(actions.clientRequestStepLoading(false)),
            put(actions.setAddressSaved(true)),
          ]);
          yield put(
            generalActions.addToast(
              "Add new address",
              "Address added successfully",
              "success",
              uid()
            )
          );
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Add new address",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Add new address",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* getCountryDropdownSaga(action) {
  let { clientAccessToken } = action.payload;
  yield all([put(actions.clientRequestStepLoading(true))]);
  try {
    const response = yield call(
      getApiToken,
      apiUrl("GET_COUNTRY_STATE"),
      null,
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.clientRequestStepLoading(false)),
          put(actions.setCountryDropdown(responseData.data.country)),
          put(actions.setAllStates(responseData.data.region)),
        ]);
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Country Dropdown",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Country Dropdown",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* getAddressListSaga(action) {
  let { clientAccessToken } = action.payload;
  yield all([put(actions.clientRequestStepLoading(true))]);
  try {
    const response = yield call(
      getApiToken,
      apiUrl("GET_ADDRESS_LIST"),
      null,
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data && responseData.data.dataTable) {
        if(responseData.data.dataTable.data.length){
          let hasDefaultAdress = {};
          responseData.data.dataTable.data.forEach(function(v){
            if(v.is_default == 1){
              hasDefaultAdress["id"] = v.id;
            }
          })
          if(hasDefaultAdress["id"]){
            yield all([put(actions.setDefaultAddress(hasDefaultAdress["id"])),put(actions.selectBillingId(hasDefaultAdress["id"]))]);
          }else{
            yield all([put(actions.setDefaultAddress(responseData.data.dataTable.data[0]['id'])),put(actions.selectBillingId(responseData.data.dataTable.data[0]['id']))]);
          }
        }
        yield all([
          put(actions.clientRequestStepLoading(false)),
          put(actions.setAddressList(responseData.data.dataTable.data)),
        ]);
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Address List",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Address List",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* getAddressSaga(action) {
  let { id, clientAccessToken } = action.payload;
  yield all([put(actions.clientRequestStepLoading(true))]);
  try {
    const response = yield call(
      getApiTokenSlash,
      apiUrl("GET_ADDRESS"),
      id,
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data && responseData.data) {
        let address = {client_id:"",street:"",street_2:"",city:"",country_id:"",state_id: 1,postcode:""};
        address['client_id'] = responseData.data.address['client_id'];
        address['street'] = responseData.data.address['street'];
        address['street_2'] = responseData.data.address['street_2'];
        address['city'] = responseData.data.address['city'];
        address['postcode'] = responseData.data.address['postcode'];
        address['country_id'] = responseData.data.address['country']['id'];
        address['state_id'] = responseData.data.address['region']['id'];
        yield all([
          put(actions.clientRequestStepLoading(false)),
          put(actions.setAddress(address)),
          put(actions.getAddressLoading(true)),
        ]);
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Address List",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Address Lis",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* changeDefaultAddressSaga(action) {
  let { id, clientAccessToken } = action.payload;
  
  yield all([put(actions.clientRequestStepLoading(true))]);
  try {
    const response = yield call(
      postApiTokenSlash,
      apiUrl("CHANGE_DEFAULT_ADDRESS"),
      clientAccessToken,
      id,
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
          yield all([
            put(actions.clientRequestStepLoading(false)),
            put(actions.getAddressList(clientAccessToken)),
          ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Change default address",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Change default address",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* submitPaymentDataSaga(action) {
  let { data, clientAccessToken } = action.payload;
  
  const { user } = yield select((state) => state.auth);
  const {
    requestId,
    selectedPlan,
    billingAddressId,
    plans,
  } = yield select((state) => state.clientRequest);
  let planamount = plans.filter(function (el) {
    return el.id == selectedPlan;
  });
  let month;
  if(Number(data.month) < 10){
    month = '0'+data.month;
  }else{
    month = data.month;
  }
  
  let submitData = queryString.stringify({
    client_id: user.parent_id ? user.parent_id : user.id,
    request_id: requestId,
    billing_address_id: billingAddressId,
    plan_id:selectedPlan,
    is_save_detail: data.is_save_detail?1:0,
    card_number: data.card_number,
    expiration_date: data.year+"-"+month,
    cvv: data.cvv,
    email: user.email,
    amount: planamount[0].price,
    payment_method: data.payment_method,
  });
  
  yield all([put(actions.clientRequestStepLoading(true))]);
  try {
    const response = yield call(
      postApiToken,
      apiUrl("SUBMIT_PAYMENT_DATA"),
      clientAccessToken,
      submitData
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      yield all([
        put(actions.clientRequestStepLoading(false)),
        put(
          actions.changeRequestIdSuccess(responseData.data.request_number)
        ),
        put(actions.redirectToSuccess(true)),
        put(actions.clearRequestData()),
      ]);
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Payment",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Payment",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* getCardListSaga(action) {
  let { clientAccessToken } = action.payload;
  yield all([put(actions.setCardListLoading(true))]);
  const { user } = yield select((state) => state.auth);
  let submitData = queryString.stringify({
    client_id: user.parent_id ? user.parent_id : user.id,
  });
  
  try {
    const response = yield call(
      postApiToken,
      apiUrl("SAVED_CARDS"),
      clientAccessToken,
      submitData
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data && responseData.data) {
        yield all([
          put(actions.setCardListLoading(false)),
          put(actions.setCardList(responseData.data)),
        ]);
      } else {
        yield all([put(actions.setCardListLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.setCardListLoading(false))]);
        yield put(
          generalActions.addToast(
            "Card List",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.setCardListLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Card List",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.setCardListLoading(false))]);
  }
}

function* submitPaymentDataSavedCardSaga(action) {
  let { data, clientAccessToken } = action.payload;
  
  const { user } = yield select((state) => state.auth);
  const {
    requestId,
    selectedPlan,
    billingAddressId,
    plans,
  } = yield select((state) => state.clientRequest);
  let planamount = plans.filter(function (el) {
    return el.id == selectedPlan;
  });  
  
  let submitData = queryString.stringify({
    client_id: user.parent_id ? user.parent_id : user.id,
    request_id: requestId,
    billing_address_id: billingAddressId,
    plan_id:selectedPlan,
    amount: planamount[0].price,
    id: data['card_id'],
    payment_method_id: data['card_id'],
  });
  
  yield all([put(actions.clientRequestStepLoading(true))]);
  try {
    const response = yield call(
      postApiToken,
      apiUrl("SUBMIT_PAYMENT_DATA_SAVED_CARD"),
      clientAccessToken,
      submitData
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      yield all([
        put(actions.clientRequestStepLoading(false)),
        put(
          actions.changeRequestIdSuccess(responseData.data.request_number)
        ),
        put(actions.redirectToSuccess(true)),
        put(actions.clearRequestData()),
      ]);
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Payment",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Payment",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* updateAddressSaga(action) {
  let { data, id, clientAccessToken } = action.payload;
  const { requestId, dueDate } =
  yield select((state) => state.clientRequest);
  yield all([put(actions.clientRequestStepLoading(true))]);
  try {
    const response = yield call(
      putApiToken,
      apiUrl("UPDATE_ADDRESS")+'/'+id,
      queryString.stringify(data),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
          yield all([
            put(actions.clientRequestStepLoading(false)),
            put(actions.setAddressSaved(true)),
          ]);
          yield put(
            generalActions.addToast(
              "Update Address",
              "Update address successfully",
              "success",
              uid()
            )
          );
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Update Address",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Update address",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}
