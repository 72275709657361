/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import UserContactInformation from './UserContactInformation'
import DesignRequests from './DesignRequests'
import ContactInformation from './ContactInformation'
import { actions as AuthActions } from "../../../redux/reducers/MyAccountReducer";
import LoaderComponent from "../../../components/Loader";

function AccountInformation() {
    const dispatch = useDispatch();
    const [cookies] = useCookies(["clientAccessToken"]);
    const { auth: { user }, myAccountReducer: { setSuccessForAccountInfoUpdate,
        setSuccessForChangePassword, accountInfoUpdateLoading, changePasswordLoading, getAccountInfoLoading } } = useSelector((state) => state);
    const [userContactInformationShow, setUserContactInformationShow] = useState(true)

    const handleToggleUserContactInformation = () => {
        setUserContactInformationShow(!userContactInformationShow)
    }

    useEffect(() => {
        if (user?.id) {
            dispatch(AuthActions.getAccountInfo({ client_id: user?.id, clientAccessToken: cookies.clientAccessToken }));
        }
    }, [user])


    useEffect(() => {
        if (setSuccessForAccountInfoUpdate || setSuccessForChangePassword) {
            if (setSuccessForChangePassword) {
                dispatch(AuthActions.clearChangePasswordFlags());
            }
            if (setSuccessForAccountInfoUpdate) {
                dispatch(AuthActions.clearAccountInfoUpdateFlags());
            }
            setUserContactInformationShow(true)
        }
    }, [setSuccessForChangePassword, setSuccessForAccountInfoUpdate])

    return (
        <div className={`account-info-block ${userContactInformationShow ? "" : "contactedit-info"}`}>
            <LoaderComponent showhide={(accountInfoUpdateLoading || changePasswordLoading || getAccountInfoLoading)} />
            {userContactInformationShow && <ContactInformation
                handleToggleUserContactInformation={handleToggleUserContactInformation}
            />}
            {!userContactInformationShow && <UserContactInformation
                handleToggleUserContactInformation={handleToggleUserContactInformation}
            />}
            {userContactInformationShow && <DesignRequests />}
        </div>
    )
}
// export default  memo(AccountInformation)
export default AccountInformation