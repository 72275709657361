import React from 'react';
import {  
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./index.scss";
import NavbarDesigner from "../../components/NavbarDesigner";
import MyOpenRequest from "../MyOpenRequest";
import AllClients from "../AllClients";


function DesignerView() {
  return (
    <div>
      <NavbarDesigner />
      <Switch>
          <RedirectRoute exact path="/app/designer" />
          <Route path="/app/designer/myrequest">
            <MyOpenRequest />
          </Route>
          <Route path="/app/designer/allclients">
            <AllClients />
          </Route>
      </Switch>
      </div>
  );
}
function RedirectRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: "/app/designer/myrequest",
            state: { from: location },
          }}
        />
      )}
    />
  );
}
export default DesignerView;
