const dev = {
  API_ENDPOINT: "http://127.0.0.1:8000/",
  AUTHORIZATION_TYPE: "Bearer",
  RECAPTCHA_SITE_KEY: "6LeJdCoaAAAAADqzSeExaipW4L_9Iz_6hldcQN1F",
  STRIPE_KEY: "pk_test_oiH1pnfJzlg4hK0g6j2A55uV"
};

const stage = {
  API_ENDPOINT: "https://api.kitchen365test.com/",
  AUTHORIZATION_TYPE: "Bearer",
  RECAPTCHA_SITE_KEY: "6LfbJtodAAAAABw0QkzxwDzqcfsu6DXeabg1lD9h",
  STRIPE_KEY: "pk_test_oiH1pnfJzlg4hK0g6j2A55uV"
};

const prod = {
  API_ENDPOINT: "https://api.kitchen365.com/",
  AUTHORIZATION_TYPE: "Bearer",
  RECAPTCHA_SITE_KEY: "6LcP0jIeAAAAAE8TdMOq68wGCNOe1i9AvWbL01B-",
  STRIPE_KEY: "pk_live_hKXT1tonh4wAqqljfWDDo7j1002YHHvE4F"
};

function getConfig() {
  if (process.env.NODE_ENV === "production" && process.env.REACT_APP_ENV === "staging") {
    return stage;
  } else if (process.env.NODE_ENV === "production" && process.env.REACT_APP_ENV === "production") {
    return prod;
  } else {
    return dev;
  }
}

const config = getConfig();

export default config;