// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { memo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Pagination } from "../../CustomComponents";
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

function ReactBootstrapTable({ defaultProperty, entities, listLoading, columns, paginationOptions, handleTableChange, handleCallBackSearch = () => { }, sortState = null }) {
    const [searchValue, onSearch] = useState((defaultProperty?.searchValue || ""))
    return (<>
        {defaultProperty?.searchable &&
            <div className="search-control-box">
                <label>Search</label>
                <input type="text" name="search" onChange={({ target: { value } }) => {
                    handleCallBackSearch({
                        page: paginationOptions.page,
                        searchText: value,
                        sizePerPage: 10,
                        sortField: sortState.dataField,
                        sortOrder: sortState.order
                    })
                    onSearch(value)
                }} value={searchValue} />
            </div>}
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
                return (
                    <Pagination
                        isLoading={listLoading}
                        paginationProps={paginationProps}
                    >
                        <BootstrapTable
                            remote
                            sort={sortState}
                            wrapperClasses="table-responsive"
                            bordered={false}
                            classes="table table-head-custom table-vertical-center overflow-hidden"
                            bootstrap4
                            keyField="id"
                            data={entities === null ? [] : entities}
                            columns={columns}
                            onTableChange={(type, newState) => {
                                handleTableChange(type, newState)
                            }}
                            noDataIndication={() => !listLoading && <div>No Data Found...</div>}
                            {...paginationTableProps}
                        />
                    </Pagination>
                );
            }}
        </PaginationProvider>
    </>
    );
}
export default memo(ReactBootstrapTable)