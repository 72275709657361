/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as designerRequestActions } from "../../redux/reducers/DesignerRequestReducer";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import LoaderComponent from "../Loader";
import ShortUniqueId from "short-unique-id";

import "./index.scss";
import RequestListItem from "../RequestListItem";
import { actions as AuthActions } from '../../redux/reducers/AuthReducer';
import ClientRequestsHeader from "../ClientRequestsHeader";

function ClientRequestNeedsApprovalList({ requestStatusSection }) {
  const uid = new ShortUniqueId();
  const history = useHistory();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);

  const {
    requestDeliveredData,
    requestDeliveredDataLoading,
    filters,
    start,
    length,
    clientSelectedData,
  } = useSelector((state) => state.designerRequest);
  const { indicationStatus } = useSelector((state) => state.auth);
  const [ratingCopyTimeSpentFlag, setRatingCopyTimeSpentFlag] = useState(false);
  const [hideDeleteIcon, setHideDeleteIcon] = useState(true);
  const [initialStatus, setInitialStatus] = useState(false);
  let intervalID;
  useEffect(() => {
    setInitialStatus(indicationStatus);
    dispatch(designerRequestActions.clearRequestDeliveredData());
    dispatch(AuthActions.getTabIndicationStatus(clientSelectedData, cookies.clientAccessToken));
    intervalID = setInterval(() => {
      dispatch(AuthActions.getTabIndicationStatus(clientSelectedData, cookies.clientAccessToken));
    }, 30000);
    dispatch(
      designerRequestActions.getNeedsApprovalData(
        {},
        0,
        length,
        cookies.clientAccessToken
      )
    );
    dispatch(designerRequestActions.changeClientPageActiveTabName("Requests Delivered"));
    return () => {
      //componentWillUnmount
      clearInterval(intervalID)
      dispatch(designerRequestActions.changeClientPageActiveTabName(""));
    }
  }, []);

  useEffect(() => {
    if (initialStatus && indicationStatus) {
      if (initialStatus['designdelivered'] !== indicationStatus['designdelivered']) {
        dispatch(designerRequestActions.changeStartFromMyOpenRequest(0));
        dispatch(
          designerRequestActions.getRequestDeliveredData(
            filters,
            0,
            length,
            cookies.clientAccessToken
          )
        );
        setInitialStatus(indicationStatus);
      }
    }
  }, [indicationStatus])

  const viewRequest = (id) => {
    dispatch(designerRequestActions.changeRequestId(id));
    // history.push("/app/designer/allclients/clientrequests/viewrequest/" + id);
  };
  const viewRequestUrl = (id) => {
    return ("/app/designer/allclients/clientrequests/viewrequest/" + id);
  };
  const loadMoreRequest = () => {
    let nextRecord = start + length //10;
    dispatch(designerRequestActions.changeStartFromMyOpenRequest(nextRecord));
    dispatch(
      designerRequestActions.getNeedsApprovalData(
        filters,
        nextRecord,
        length,
        cookies.clientAccessToken
      )
    );
  };
  const filterOnchange = (id, isSold = false) => {
    let tempFilter = filters;
    if (id) {
      tempFilter['request_status'] = id;
      tempFilter['is_sold'] = isSold ? 1 : 0
    } else {
      delete tempFilter.request_status;
      delete tempFilter.is_sold;
    }
    dispatch(designerRequestActions.updateFilters(tempFilter));
    dispatch(designerRequestActions.changeStartFromMyOpenRequest(0));
    dispatch(
      designerRequestActions.getNeedsApprovalData(
        filters,
        0,
        length,
        cookies.clientAccessToken
      )
    );
  }
  return (
    <div>
      <LoaderComponent showhide={requestDeliveredDataLoading} />
      {requestDeliveredData['request_status'] && requestDeliveredData['request_status'].length ? <div className="container cf sub-status" style={{ marginBottom: "40px", marginTop: "0" }}>
        {requestDeliveredData['request_status'] && requestDeliveredData['request_status'].length ?
          <button className={"btn" + (filters?.request_status === "" || !filters['request_status'] ? " active" : "")} onClick={() => { filterOnchange("") }}>All</button> : null}
        {requestDeliveredData?.request_status?.map((data, index) => (
          <>
            <button
              key={"status" + data.id}
              className={"btn" + (filters && filters['request_status'] === data.id && !filters?.is_sold ? " active" : "")}
              onClick={() => { filterOnchange(data.id) }}>
              {data.name === 'Approve' ? 'Approved' : data.name == 'Close' ? 'Closed' : data.name}
            </button>
            {data?.name === 'Approve' ?
              <button
                key={"status" + data.id}
                className={"btn" + (filters?.request_status === data.id && filters?.is_sold ? " active" : "")}
                onClick={() => { filterOnchange(data.id, true) }}>
                SOLD
              </button>
              : null
            }
          </>
        ))}
      </div> : null}
      <ClientRequestsHeader
        showClientName={true}
        myOpenRequestData={requestDeliveredData}
        requestDelivered={true}
        requestStatusSection={requestStatusSection}
        allRequest={true}
      />
      <RequestListItem
        ratingCopyTimeSpentFlag={ratingCopyTimeSpentFlag}
        data={requestDeliveredData}
        loadMoreRequest={loadMoreRequest}
        viewRequest={viewRequest}
        viewRequestUrl={viewRequestUrl}
        hideDeleteIcon={hideDeleteIcon}
        isAllPriortise={false}
      />
    </div>
  );
}

export default ClientRequestNeedsApprovalList;
