/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as myRequestActions } from "../../redux/reducers/MyRequestReducer";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import LoaderComponent from "../../components/Loader";
import ShortUniqueId from "short-unique-id";
import { actions as generalActions } from "../../redux/reducers/GeneralReducer";

import "./index.scss";

function RequestApprove(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const {    
    myRequestListingLoading,
    isCommentRatingAdded,
    requestDetailData
  } = useSelector((state) => state.myRequest);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const uid = new ShortUniqueId();
  const { location:{search} } = props;
  useEffect(() => {
    dispatch(myRequestActions.changeRedirectPageReview(false));
    dispatch(myRequestActions.hideSubNavbar(true));
    dispatch(
      myRequestActions.getRequestDetail(
        atob(props.match.params.reviewFor),
        cookies.clientAccessToken
      )
    );
  }, [dispatch]);
  useEffect(() => {
    if(Object.keys(requestDetailData).length > 0 && Number(requestDetailData.rating) > 0){
      history.push("/app/client/myrequests/viewrequest/" + atob(props.match.params.reviewFor));      
    }
  },[requestDetailData])
  useEffect(() => {
    if (isCommentRatingAdded) {
      history.push("/app/client/myrequests/viewrequest/" + atob(props.match.params.reviewFor));
    }
  }, [dispatch, history, isCommentRatingAdded]);
  const changeComment = (e) => {
    setComment(e.target.value);
  };
  const changeRating = (e) => {
    setRating(e.target.id);
  };
  const submitCommentRating = () => {
      if (rating) {
        let data = {};
        data.rating = rating;
        data.comment = comment;
        dispatch(
          myRequestActions.submitClientRatingComment(
            data,
            atob(props.match.params.reviewFor),
            cookies.clientAccessToken
          )
        );
      } else {
        dispatch(
          generalActions.addToast(
            "Required field",
            "Please add rating",
            "danger",
            uid()
          )
        );
      }
  };
  const renderRatingStar = (ratingCount) => {
    const ratingArray = [0, 0, 0, 0, 0];
    for (let i = 0; i < ratingArray.length; i++) {
      if (i < ratingCount) {
        ratingArray[i] = 1;
      } else {
        ratingArray[i] = 0;
      }
    }

    return (
      <div className="ratting">
        {ratingArray.map((data, index) => {
          return (
            <label
              onClick={changeRating}
              key={index}
              id={index + 1}
              className={data === 1 ? "star active" : "star"}
            ></label>
          );
        })}
      </div>
    );
  };

  return (
    <div className="request-approve-page">
      <LoaderComponent showhide={myRequestListingLoading} />

      <div className="container">
        <div className="request-approve-inner">
          <img
            className="successfully-img"
            src={require("../../assets/images/approving.png")}
            alt="Your request has been submitted successfully"
          />
         {search === "?onApprove=true" && <h1>Thank you for approving the request.</h1>}
          <p>We would love to hear from you! Kindly Rate Us</p>

          {renderRatingStar(rating)}
          <form className="your-comments">
            <div className="field">
              <textarea
                value={comment}
                onChange={changeComment}
                className="input-box"
                placeholder="Kindly let us know how can we improve ( optional )"
              ></textarea>
            </div>
            <button
              type="button"
              onClick={submitCommentRating}
              className="btn submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RequestApprove;
