
import React, { memo } from 'react'
import PropTypes from 'prop-types';
/**
 * This is  RenderComponent function Component. 
 * @param {item} item  item  Pass Object Value.
 * @param {state} state state Pass Number Value.
 * @returns  RenderComponent HTML Element
 * @author Vishal Gohel <vishal.gohel@commercepundit.com>
 * 
 *  Notes:- Dec 28 2021 (Vishal Gohel <vishal.gohel@commercepundit.com>)  Component Created
 */

export const RenderComponent = memo(({ state, item }) =>
    (state === item.id && <div className="tab_content">{item.component}</div>));

RenderComponent.propTypes = {
    state: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
};

RenderComponent.defaultProps = {
    item: {},
    state: 0,
};
