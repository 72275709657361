import * as designerReportConstants from '../actionConstants/designerReports.constants'

export const designerProductivityRatio = (clientAccessToken,designerId,month) => ({
    type : designerReportConstants.designerProductivityRatio.GET_DESIGNER_PRODUCTIVITY_RATIO_INIT,
    payload : {clientAccessToken,designerId,month}
})

export const designerTotalRequestSubmitted = (clientAccessToken,clientId,start,length,title,status,createdBy, approvedBy, createdFrom,createdTo,
    approvedFrom,approvedTo,sortField,sortOrder) => ({
    type: designerReportConstants.designerTotalRequestSubmitted.GET_DESIGNER_TOTAL_REQUEST_SUBMITTED_INIT,
    payload : {
        clientAccessToken,clientId,start,length,title,
        status,createdBy, approvedBy, createdFrom,createdTo,
        approvedFrom,approvedTo,sortField,sortOrder
    }
})