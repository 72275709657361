/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { actions as myRequestActions } from "../../redux/reducers/MyRequestReducer";
import { useCookies } from "react-cookie";
import {
  convertUTCToLocale, quillOptionsConfigForComment,
  getFileTypeIcon, downloadFile, convertStringToHtml,
} from "../../Utils/utils";
import { ImageUploadComponent } from "../ViewRequestForum";
import AddCommentSection from "../AddCommentSection";
import DownloadWithProgressBar from "../Loader/downloadProgress";
import { getExtensionByUrl } from "../../Utils/CommonHelper";
const NoComments = _ => (<p>No comments submitted</p>)

const Comments = (props) => {

  const { data, requestdata } = props;

  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);

  const [isEditing, setIsEditing] = useState(false);
  const [currentEditingComment, setCurrentEditingComment] = useState([]);

  const toogleEditCommentView = () => setIsEditing(!isEditing);

  const editComment = (comment) => {
    setCurrentEditingComment(comment);
    toogleEditCommentView();
  };

  const deleteCommentById = (commentId) => {
    if (window.confirm("Are you sure want to delete this comment?")) {
      dispatch(
        myRequestActions.deleteComment(commentId, cookies.clientAccessToken)
      );
    }
  };

  return isEditing ? (
    <EditComment
      commentData={currentEditingComment}
      closeCommentView={toogleEditCommentView}
      cookies={cookies.clientAccessToken}
    />
  ) : (
    <ViewComment
      commentData={data}
      editComment={editComment}
      deleteCommentById={deleteCommentById}
      cookies={cookies.clientAccessToken}
      requestdata={requestdata}
    />
  );
};

const ViewComment = ({ commentData, editComment, deleteCommentById, cookies, requestdata }) => {
  const { commenterName, lastUpdated, comment, commentId, commentMedia, commenterId } = commentData;

  const { user: { id } } = useSelector((state) => state.auth);
  return (
    <div className="name-date-content">
      <div className="delete-edit">
        {commenterId === id && (requestdata && requestdata["request_status"]["status"][0]["slug"] !== "approved") && (
          <>
            <button
              onClick={() => deleteCommentById(commentId)}
              className="delete-btn"
              title="Delete"
            />
            <button
              onClick={() => editComment(commentData)}
              className="edit-btn"
              title="Edit"
            />
          </>
        )}
      </div>

      <div className="name-date">
        <strong>{commenterName}</strong>
        {convertUTCToLocale(lastUpdated)?.dateAndTime}
      </div>

      <div
        className="ql-editor"
        dangerouslySetInnerHTML={{ __html: convertStringToHtml(comment) }}
      />

      <div className="uploaded-file-list">
        {commentMedia?.map((media,i) => {

          const orgFileName = media?.media_name;
          const orgFileExtName = getExtensionByUrl(media?.media_url);
          let mediaUrl = media?.media_url;
          // let fileName = media?.media_url.match(/([^\/]+)$/gm)[0];
          let fileName = `${orgFileName}.${orgFileExtName}`

          return (
            <div className="uploaded-file" key={i}>
              <img src={getFileTypeIcon(media?.media_type)} alt="file" />
              <div className="filename">{fileName}</div>

              <DownloadWithProgressBar {...{fileUrl:mediaUrl, fileName, clientAccessToken:cookies}}/>
              {/* <span
                className="download-text"
                onClick={() => downloadFile(mediaUrl, fileName, cookies)}
              >
                Download
              </span> */}
            </div>
          );
        })}
      </div>

    </div>
  );
};

const EditComment = ({ closeCommentView, commentData, cookies }) => {

  const dispatch = useDispatch();
  const editor = useRef(null);
  const { myRequestListingLoading } = useSelector((state) => state.myRequest);
  const { comment, requestId, commentMedia, commentId } = commentData;

  const [currentComment, setCurrentComment] = useState(convertStringToHtml(comment));
  const [mediaUploads, setMediaUploads] = useState(commentMedia);
  const [mediaDelete, setMediaDelete] = useState([]);

  useEffect(() => {
    if (myRequestListingLoading) {
      closeCommentView();
    }
  }, [myRequestListingLoading]);

  const updateComment = () => {
    if (editor.current?.getEditor().getLength() > 1) {
      dispatch(
        myRequestActions.updateCommentData(commentId, currentComment, mediaUploads, mediaDelete, cookies)
      );
    }
  }

  return (
    <div className="view-request-forum">

      <div className="comment-box">
        <ReactQuill
          modules={quillOptionsConfigForComment.modules}
          formats={quillOptionsConfigForComment.formats}
          value={currentComment}
          onChange={(e) => setCurrentComment(e)}
          ref={editor}
        />
        <ImageUploadComponent
          imageUploadState={mediaUploads}
          imageUploadSetState={setMediaUploads}
          imageDeleteState={mediaDelete}
          imageDeleteSetState={setMediaDelete}
          requestId={requestId}
          commentId={commentId}
        />
      </div>

      <div className="actions-row">
        <button
          type="button"
          className="btn btn-b-blue cancel"
          title="Cancel"
          onClick={closeCommentView}
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={updateComment}
          className="btn btn-black update"
          disabled={editor.current?.getEditor().getLength() < 2}
        >
          Update
        </button>
      </div>

    </div>
  );
};

const Discussion = ({ data, requestdata, requestId }) => {
  const [touchedData, setTouchedData] = useState([]);

  let isDataLoaded = data && Object.keys(data).length > 0;

  useEffect(() => {
    if (isDataLoaded) {
      let tempData = data?.datatable?.data.map((commentDetails) => ({
        commentId: commentDetails.id,
        comment: commentDetails.note,
        lastUpdated: commentDetails.updated_at,
        commenterId: commentDetails.updated_by,
        commenterName: commentDetails?.user_created_by?.name,
        commenterEmail: commentDetails?.user_created_by?.email,
        commentMedia: commentDetails?.media?.media_url?.comment,
        requestId: commentDetails.client_request_id,
      }));

      setTouchedData(tempData);
    }
  }, [isDataLoaded, data]);

  return (
    <div className="tab-content discussion-conttent">
      {/* <AddCommentSection data={requestdata} requestId={requestId} /> */}
      {isDataLoaded && touchedData.length > 0 ?
        touchedData.map((commentData) => (
          <Comments key={commentData.commentId} data={commentData} requestdata={requestdata} />
        )) : <NoComments />}
    </div>
  );
};

export default Discussion;
