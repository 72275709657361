/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as myRequestActions } from "../../redux/reducers/MyRequestReducer";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import ReactQuill, { Quill } from 'react-quill';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import ShortUniqueId from "short-unique-id";
import { quillOptionsConfigForComment, getFileTypeIcon } from "../../Utils/utils"
import "./index.scss";
import Discussion from "../../components/Discussion";
import History from "../../components/History";
// import { uploadCommentMedia } from '../../redux/apis'
import { actions as generalActions } from "../../redux/reducers/GeneralReducer";
import LoaderComponent from "../../components/Loader";
import PdfIcon from "../../assets/images/PDF.svg";
import DocIcon from "../../assets/images/Doc.svg";
import KitIcon from "../../assets/images/kit.png";
import FileIcon from "../../assets/images/file.png";
import PptIcon from "../../assets/images/PPT.svg";
import XlsIcon from "../../assets/images/XLS.svg";
import CsvIcon from "../../assets/images/CSV.svg";
import TsvIcon from "../../assets/images/TSV.svg";
import ZipIcon from "../../assets/images/ZIP.svg";
import axios from 'axios';
import apiUrl, { getApiUrl, reportUrls } from '../../config/urls'
import SoldInfo from "../ViewRequestContent/SoldInfo";

Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);
export const ImageUploadComponent = ({ imageUploadState, imageUploadSetState, imageDeleteState, imageDeleteSetState, requestId, commentId }) => {
  const chooseMedia = useRef(null);
  const [cookies] = useCookies(["clientAccessToken"]);
  const dispatch = useDispatch();
  const uid = new ShortUniqueId();
  const [isLoading, setIsLoading] = useState(false)
  const [isFirstTimeUpload, setFirstTimeUpload] = useState(imageUploadState.length > 0 ? "no" : "yes")
  const { fileUploadPercentage } = useSelector(
    (state) => state.myRequest
  );

  const uploadCommentMedia = (media, requestId, clientAccessToken, deletedMedia) => {
    let commentMedia = new FormData()
    commentMedia.set("request_id", requestId)
    commentMedia.set("is_first_file", isFirstTimeUpload)
    if (commentId) {
      commentMedia.set("request_comment_id", commentId)
    }
    for (let i = 0; i < media.length; i++) {
      commentMedia.append("file[]", media[i]);
    }
    for (let i = 0; i < deletedMedia.length; i++) {
      commentMedia.append("deleted_media[]", deletedMedia[i]['media_id']);
    }
    // commentMedia.set("file",media)
    return axios.post(
      apiUrl('UPLOAD_COMMENT_MEDIA'),
      commentMedia,
      {
        headers: {
          Platform: "spa",
          Authorization: `Bearer ${clientAccessToken}`,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: progress => {
          let { loaded, total } = progress
          let percentageProgress = Math.floor((loaded / total) * 100)
          dispatch(myRequestActions.changeFileUploadPercentage(percentageProgress));
        },
      }
    );
  }

  const checkFileValidation = (files) => {
    let fileExtention;
    let filevalidation = false;
    let fileExtentionvalidation = { type: "", flag: false };
    let allowedFileExtention = ["pdf", "png", "jpg", "jpeg", "kit","KIT", "xls", "xlsx", "csv", "tsv", "zip", "ppt", "pptx", "doc","docx","webp","heic"];
    for (let i = 0; i < files.length; i++) {
      fileExtention = /[.]/.exec(files[i].name)
        ? /[^.]+$/.exec(files[i].name)
        : undefined;
      if (Math.round(files[i].size / 1024) > 204800) {
        filevalidation = true;
      } else if (!allowedFileExtention.includes(fileExtention[0].toLowerCase())) {
        fileExtentionvalidation.type = fileExtention[0];
        fileExtentionvalidation.flag = true;
      }
    }
    if (filevalidation) {
      dispatch(
        generalActions.addToast(
          "File Size",
          "Max File Size for each media file: 200 MB",
          "danger",
          uid()
        )
      );
      chooseMedia.current.value = null
      return false;
    } else if (fileExtentionvalidation.flag) {
      dispatch(
        generalActions.addToast(
          "File Type",
          fileExtentionvalidation.type + " file not allowed for upload",
          "danger",
          uid()
        )
      );
      chooseMedia.current.value = null
      return false;
    } else {
      return true;
    }
  }

  const uploadHandler = (e) => {
    if (checkFileValidation(e.target.files)) {
      setIsLoading(true)
      uploadCommentMedia(e.target.files, requestId, cookies.clientAccessToken, imageDeleteState).then(response => {
        if (response.status === 200) {
          setIsLoading(false)
          // let tempArr = imageUploadState;
          // tempArr = tempArr.concat(response?.data?.data[0]['temporaty_file']);
          imageUploadSetState(response?.data?.data[0]['temporaty_file'])
          dispatch(myRequestActions.changeFileUploadPercentage(0));
          chooseMedia.current.value = null
          setFirstTimeUpload("no");
        }
      }).catch(error => {
        dispatch(myRequestActions.changeFileUploadPercentage(0));
        setIsLoading(false)
        chooseMedia.current.value = null
        dispatch(
          generalActions.addToast(
            "Media Upload Failed",
            error.response?.data?.message,
            "danger",
            uid()
          )
        );
      })
    }
  }

  const onDragOver = (e) => {
    e.preventDefault();
  }

  const dropHandler = (e) => {
    e.preventDefault();
    let dt = e.dataTransfer
    if (checkFileValidation(dt.files)) {
      setIsLoading(true)
      uploadCommentMedia(dt.files, requestId, cookies.clientAccessToken, imageDeleteState).then(response => {
        if (response.status === 200) {
          setIsLoading(false)
          // let tempArr = imageUploadState;
          // tempArr = tempArr.concat(response?.data?.data[0]['temporaty_file']);
          imageUploadSetState(response?.data?.data[0]['temporaty_file'])
          chooseMedia.current.value = null
          dispatch(myRequestActions.changeFileUploadPercentage(0));
          setFirstTimeUpload("no");
        }
      }).catch(error => {
        dispatch(myRequestActions.changeFileUploadPercentage(0));
        setIsLoading(false)
        chooseMedia.current.value = null
        dispatch(
          generalActions.addToast(
            "Media Upload Failed",
            error.response.data?.message,
            "danger",
            uid()
          )
        );
      })
    }
  }

  const removeMediaFromUpload = (media) => {
    imageDeleteSetState((prevState) => [...prevState, media])
    imageUploadSetState((prevState) => prevState.filter(file => file.media_id !== media.media_id))
    if (imageUploadState.length == 1) {
      setFirstTimeUpload("yes");
    }
  }

  return (
    <div className="upload-files" onDrop={dropHandler} onDragOver={onDragOver}>
      <LoaderComponent showhide={isLoading} isFileUpload={true} fileUploadPercentage={fileUploadPercentage} />
      <ul>
        {imageUploadState.map(file => {
          var fileExtention = /[.]/.exec(file.media_url)
            ? /[^.]+$/.exec(file.media_url)
            : undefined;
          var fileSrc;
          if ((fileExtention[0]).toLowerCase() === "pdf") {
            fileSrc = PdfIcon;
          } else if ((fileExtention[0]).toLowerCase() === "doc" || (fileExtention[0]).toLowerCase() === "docx") {
            fileSrc = DocIcon;
          } else if ((fileExtention[0]).toLowerCase() === "xls" || (fileExtention[0]).toLowerCase() === "xlsx") {
            fileSrc = XlsIcon;
          } else if ((fileExtention[0]).toLowerCase() === "kit") {
            fileSrc = KitIcon;
          } else if ((fileExtention[0]).toLowerCase() === "png" || (fileExtention[0]).toLowerCase() === "jpg" || (fileExtention[0]).toLowerCase() === "jpeg") {
            fileSrc = file.media_url;
          } else if ((fileExtention[0]).toLowerCase() === "ppt" || (fileExtention[0]).toLowerCase() === "pptx") {
            fileSrc = PptIcon;
          } else if ((fileExtention[0]).toLowerCase() === "zip") {
            fileSrc = ZipIcon;
          } else if ((fileExtention[0]).toLowerCase() === "csv") {
            fileSrc = CsvIcon;
          } else if ((fileExtention[0]).toLowerCase() === "tsv") {
            fileSrc = TsvIcon;
          } else {
            fileSrc = FileIcon;
          }

          const orgFileName = file.media_name
          let fileName = `${orgFileName}.${fileExtention}`

          return (<li key={file?.media_id} className="file-icon-name">
            <img alt='file'
              src={fileSrc}
            />

            <div className="filename">{fileName}</div>
            <span onClick={() => removeMediaFromUpload(file)} className="close-btn"></span>
          </li>)
        })}
      </ul>
      <div className="browsefile">
        <input accept="image/jpeg,image/jpg,image/png,application/pdf,.kit,.KIT,.xls,.xlsx,.csv,.tsv,.zip,.ppt,.pptx,.doc,.docx,.webp,.heic"
          type="file"
          onChange={uploadHandler}
          ref={chooseMedia}
          id="fileupload"
          style={{ display: 'none' }} multiple />
        <label htmlFor='fileupload'>Upload Files</label>
      </div>
      <p>Accepted file types are jpg, jpeg, png, webp, heic, pdf, xls, xlsx, ppt, pptx, doc, docx, csv, tsv, zip and .Kit file</p>
    </div>
  )
}

function ViewRequestForum({ data, requestId }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [cookies] = useCookies(["clientAccessToken"]);
  const editor = useRef(null);
  const { discussionData } = useSelector(
    (state) => state.myRequest
  );
  const { user } = useSelector((state) => state.auth);
  const { requestIdDesigner } = useSelector((state) => state.designerRequest);
  useEffect(() => {
    dispatch(myRequestActions.changeDiscussionData(data["discussion"]));
  }, [data]);

  const [showCommentBox, setShowCommentBox] = useState(false);
  const [activeDiscussionTab, setActiveDiscussionTab] = useState(true);
  const [soldInfo, setSoldInfo] = useState(false);
  const [mediaUploads, setMediaUploads] = useState([])
  const [mediaDelete, setMediaDelete] = useState([])
  const [activeHistoryTab, setActiveHistoryTab] = useState(false);
  const [comment, setComment] = useState("");

  const isRequestApprove = Object.keys(data).length > 0 && data["request_status"]["status"][0]["slug"] === "approved"

  const addComment = () => {
    if (editor.current?.getEditor().getLength() > 1) {
      if (user.group_id == 3) {
        dispatch(
          myRequestActions.addCommentData(
            requestIdDesigner,
            comment,
            mediaUploads,
            mediaDelete,
            cookies.clientAccessToken
          )
        );
        setComment("")
        setMediaUploads([])
      } else {
        dispatch(
          myRequestActions.addCommentData(
            requestId,
            comment,
            mediaUploads,
            mediaDelete,
            cookies.clientAccessToken
          )
        );
        setComment("")
        setMediaUploads([])
      }
    }
  };
  const renderRatingStar = (ratingCount) => {
    const ratingArray = [0, 0, 0, 0, 0];
    for (let i = 0; i < ratingArray.length; i++) {
      if (i < ratingCount) {
        ratingArray[i] = 1;
      } else {
        ratingArray[i] = 0;
      }
    }

    return (
      <>
        {ratingArray.map((data, index) => {
          return (
            <span
              key={index}
              className={data === 1 ? "star active" : "star"}
            ></span>
          );
        })}
      </>
    );
  };
  const addButtonClick = () => {
    setShowCommentBox(true);
    setActiveDiscussionTab(true);
    setActiveHistoryTab(false);
    setSoldInfo(false);
  };
  const setActiveTab = (e) => {
    if (e.target.id === "discussion") {
      setActiveHistoryTab(false);
      setSoldInfo(false);
      setActiveDiscussionTab(true);
    } else if (e.target.id === "history") {
      setActiveDiscussionTab(false);
      setSoldInfo(false);
      setActiveHistoryTab(true);
    } else if (e.target.id === "sold-info") {
      setActiveDiscussionTab(false);
      setActiveHistoryTab(false);
      setSoldInfo(true);
    }
  };

  const navigateToReview = () => {
    history.push(`/app/client/myrequests/review-submit/${btoa(requestId)}`)
  }

  const scrollToChangeStatus = (currPageXOffset, currPageYOffset) => {
    const element = document.getElementById('change-status');
    element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    currPageXOffset = window.pageXOffset;
    currPageYOffset = window.pageYOffset;
    var scrollDone = setInterval(function () {
      if (currPageXOffset == window.pageXOffset && currPageYOffset == window.pageYOffset) {
        clearInterval(scrollDone);

        element.classList.add("highlight-change-status");

        setTimeout(() => {
          element.classList.remove("highlight-change-status");
        }, 3000);
        console.log('I have finished scrolling');
      }
      currPageXOffset = window.pageXOffset;
      currPageYOffset = window.pageYOffset;
    }, 50);
  }

  const renderAddComment = () => {
    if (data && data["request_status"]["status"][0]["slug"] !== "approved" && data["request_status"]["status"][0]["slug"] !== "closed") {
      return (
        <div className="view-request-forum">
          <h3 className="add-new-comment-title">
            <span>Add New Comment</span>
          </h3>

          <div
            style={{ display: showCommentBox ? "" : "none" }}
            className="comment-box"
          >
            <ReactQuill
              modules={quillOptionsConfigForComment.modules}
              formats={quillOptionsConfigForComment.formats}
              value={comment}
              onChange={(e) => {
                setComment(e)
              }}
              ref={editor}
              id={requestId}
            />
            {showCommentBox && <ImageUploadComponent
              imageUploadState={mediaUploads}
              imageUploadSetState={setMediaUploads}
              imageDeleteState={mediaDelete}
              imageDeleteSetState={setMediaDelete}
              requestId={requestId} />}
          </div>
          <div className="actions-row" style={{ marginBottom: "25px" }}>
            <button
              style={{ float: "left" }}
              type="button"
              onClick={scrollToChangeStatus}
              className="btn submit status-btn"
              title="Submit"
            >
              Change Status
            </button>
            <button
              style={{ display: showCommentBox ? "" : "none" }}
              className="btn btn-b-blue cancel"
              title="Cancel"
              onClick={() => setShowCommentBox(false)}
            >
              Cancel
            </button>
            <button
              style={{ display: showCommentBox ? "" : "none" }}
              type="button"
              onClick={addComment}
              className="btn submit"
              title="Submit"
              disabled={editor.current?.getEditor().getLength() < 2}
            >
              Submit
            </button>
            <button
              style={{ display: showCommentBox ? "none" : "" }}
              type="button"
              onClick={addButtonClick}
              className="btn submit"
              title="Submit"
              id="add-comment"
            >
              Add Comment
            </button>
          </div>
        </div>
      );
    }
  };
  return (
    <>
      <div className="viewrequeststatus cf">
        <div className="discussion-history">
          <div
            id="discussion"
            onClick={setActiveTab}
            className={activeDiscussionTab ? "tab-label active" : "tab-label"}
          >
            Discussion
          </div>
          <Discussion
            data={discussionData}
            requestdata={data}
            requestId={requestId}
          />

          {data?.sold && data?.sold_id > 0 ?
            <>
              <div
                id="sold-info"
                onClick={setActiveTab}
                className={soldInfo ? "tab-label active" : "tab-label"}
              >
                Sold Info
              </div>
              <SoldInfo soldData={data?.sold} is_sold_allowed={data?.is_sold_allowed} />
            </> : null
          }

          <div
            id="history"
            onClick={setActiveTab}
            className={activeHistoryTab ? "tab-label active" : "tab-label"}
          >
            History
          </div>
          <History data={data["history"]} />
        </div>
      </div>
      {renderAddComment()}

      {isRequestApprove && data?.review === true && (
        <div className="ratingtext viewrequeststatus">
          <h3 className="request-status-title">Feedback and Rating</h3>
          <div className="rating">{renderRatingStar(data?.rating)}</div>
          <p className="commennt">{data?.review_text}</p>
        </div>
      )}

      {isRequestApprove && data?.review === false && (
        <div className="ratingtext viewrequeststatus">
          <h3 className="request-status-title">Feedback and Rating</h3>

          <div className="feedback-ratingtext">
            <div className="approve-img">
              <img
                src={require("../../assets/images/approving.png")}
                alt="approve"
              />
            </div>
            <div className="rating-content">
              <p>
                We would love to hear your experince with this request
                <br />
                Please rate your experince.
              </p>
              <button onClick={navigateToReview} className="btn" type="submit">
                Submit Review
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ViewRequestForum;
