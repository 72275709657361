import React from "react";
import { useHistory } from "react-router-dom";

import "./index.scss";

function MyRequestAddRequest({accountType}) {
  const history = useHistory();
  const newRequest = () => {    
    history.push("/app/client/submitrequest/requestname");
  };
  return (
    <div className="add-design-request">
      <div className="container">
        <div className="outer-design-request">
          <div className="inner-design-request">
            <div className="request-add-box">
              {(accountType === 1 || accountType === 2) && <button
                type="button"
                onClick={newRequest}
                className="add-request"
              ></button>}
              {<div className="design-request-text">
                {(accountType === 1 || accountType === 2) ? (
                <>Add your <br /> Design Request</>):(
                <>
                <div className="no-request-assigned">You have no request assigned
                  <br />
                  Please check with your assign client or project manager for further allocations
                </div>
                </>)}
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyRequestAddRequest;
