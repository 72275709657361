export const designerProductivityRatio = {
    GET_DESIGNER_PRODUCTIVITY_RATIO_INIT : "GET_DESIGNER_PRODUCTIVITY_RATIO_INIT",
    GET_DESIGNER_PRODUCTIVITY_RATIO_SUCCESS : "GET_DESIGNER_PRODUCTIVITY_RATIO_SUCCESS",
    GET_DESIGNER_PRODUCTIVITY_RATIO_FAILURE : "GET_DESIGNER_PRODUCTIVITY_RATIO_FAILURE"
}

export const designerTotalRequestSubmitted = {
    GET_DESIGNER_TOTAL_REQUEST_SUBMITTED_INIT: "GET_DESIGNER_TOTAL_REQUEST_SUBMITTED_INIT",
    GET_DESIGNER_TOTAL_REQUEST_SUBMITTED_SUCCESS: "GET_DESIGNER_TOTAL_REQUEST_SUBMITTED_SUCCESS",
    GET_DESIGNER_TOTAL_REQUEST_SUBMITTED_FAILURE: "GET_DESIGNER_TOTAL_REQUEST_SUBMITTED_FAILURE",
}