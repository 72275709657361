/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { clientReportDetails } from "../../redux/actions/clientReports.actions";
import { useCookies } from "react-cookie";
import LoaderComponent from "../Loader";
import "./index.scss";

function DesignersReportsList() {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const { user:{group_id}} = useSelector(state => state.auth)
  const { clientReportDetailsProcess } = useSelector(state=>state.clientReportsReducer);
  const{allRequestsData} = useSelector(state=>state.designerRequest)

  const { isFetchingClientReportDetails, clientReportDetailsSuccessResponse} = clientReportDetailsProcess

  const isDataLoaded =
    Object.keys(clientReportDetailsSuccessResponse).length > 0;

  const { report_one, report_two } = clientReportDetailsSuccessResponse;

  useEffect(() => {
      dispatch(clientReportDetails(cookies.clientAccessToken, group_id));
  }, []);

  return (
    <div>
      <LoaderComponent showhide={isFetchingClientReportDetails} />
      <div className="clients-list-page">
        <div id="">
          <div className="loader"></div>
        </div>
      
        <div className="container">
        <h1 className="client-name">
          {allRequestsData?.Client??""}
        </h1>
          {isDataLoaded && (
            <ul className="clients-list reports-card">
              <li>
                <Link
                  to="/app/designer/allclients/clientrequests/reports/submittedrequest"
                  className="view_link"
                >
                  View
                </Link>
                <div className="clients-title">
                  <Link to="/app/designer/allclients/clientrequests/reports/submittedrequest">
                    <h3>{report_one.title}</h3>
                  </Link>
                  <span className="name">{report_one.month}</span>
                </div>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${report_one.total}%` }}
                  >
                    <span>{report_one.total}</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="clients-title">
                  <Link to="/app/designer/allclients/clientrequests/reports/productivityratio">
                    <h3>{report_two.title}</h3>
                  </Link>
                  <span className="name">{report_two.month}</span>
                </div>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${report_two.total}%` }}
                  >
                    <span>{report_two.total}</span>
                  </div>
                </div>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default DesignersReportsList;
