/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { actions as myRequestActions } from "../../redux/reducers/MyRequestReducer";
import { actions as designerRequestActions } from "../../redux/reducers/DesignerRequestReducer";
import { useHistory } from "react-router-dom";
import { actions as generalActions } from "../../redux/reducers/GeneralReducer";
import ShortUniqueId from "short-unique-id";
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import "./index.scss";

function ViewRequestStatus({ data }) {
  const history = useHistory();
  const uid = new ShortUniqueId();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const { requestId, setUpdateStatusForMyRequest,clientRequestUpdateProcess, redirectToreview } = useSelector(
    (state) => state.myRequest
  );

  const {isUpdatingStatus,updatedStatusResponse,
    isUpdateRequestFailed } = clientRequestUpdateProcess

  const { requestIdDesigner, myOpenRequestDataLoading, updateStatusFlag, updateAssigneeFlag } = useSelector(
    (state) => state.designerRequest
  );
  const { user } = useSelector((state) => state.auth);

  const [statusId, setStatusId] = useState('1');
  // const [selectStatusId, setSelectStatusId] = useState('1');
  const [statusSlug, setStatusSlug] = useState("");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [assignee, setAssignee] = useState("1");
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [dateTime, setDateTime] = useState(new Date());
  const [uploadFiles, setUploadFiles] = useState([]);
  const [commentForRevision, setCommentForRevision] = useState("");
  const [clientName, setClientName] = useState("");
  
  const changeStatus = (e) => {
    setStatusId(e.target.value);
    for (var i = 0; i < data["request_status_dropdown"].length; i++) {
      if (~~e.target.value === data["request_status_dropdown"][i]["id"]) {
        setStatusSlug(data["request_status_dropdown"][i]["slug"]);
      }
    }
  };

  useEffect(() => {
    if (data["designer"] && data["designer"].length) {
      setAssignee(1);
    }
    if (data && data["request_status"]) {
      setStatusId(1);
    }
  }, [data]);

  // useEffect(() => {
  //   let isResponseAvailable = isUpdatingStatus === false 
  //   && Object.keys(updatedStatusResponse).length > 0

  //   if(isResponseAvailable && updatedStatusResponse.statusChangedTo === 'approved'){
  //     dispatch(myRequestActions.resetApproveStatus())
  //     history.push(`/app/client/myrequests/review-submit/${btoa(updatedStatusResponse.requestedId)}?onApprove=true`);
  //   }
  // },[isUpdatingStatus,isUpdateRequestFailed,updatedStatusResponse])
  useEffect(()=>{
    dispatch(myRequestActions.changeCommentRatingAdded(false));
  },[])
  useEffect(() => {
    if(redirectToreview && rating){
      dispatch(myRequestActions.getRequestDetail(requestId, cookies.clientAccessToken));
    }
    console.log('redirectToreview',redirectToreview,rating)
    if(redirectToreview && !rating){
      history.push(`/app/client/myrequests/review-submit/${btoa(requestId)}?onApprove=true`);
    }
  },[redirectToreview])

  useEffect(() => {
    if (myOpenRequestDataLoading) {
    } else {
      setMinutes("");
      setHours("");
    }
  }, [myOpenRequestDataLoading]);
  useEffect(() => {
    if (setUpdateStatusForMyRequest || updateStatusFlag) {
      setStatusId(1);
    } 
  }, [setUpdateStatusForMyRequest, updateStatusFlag]); 


  useEffect(() => {
    if (updateAssigneeFlag) {
      setAssignee(1);
      dispatch(designerRequestActions.clearUpdateAssigneeFlag());
    } 
  }, [dispatch, updateAssigneeFlag]); 

  const updateStatus = () => {
    if(statusId ===1){

    }else {
    if (user.group_id === 3) {
      dispatch(
        designerRequestActions.updateStatusDesigner(
          requestIdDesigner,
          statusId,
          cookies.clientAccessToken
        )
      );
    } else {
      dispatch(
        myRequestActions.updateStatusClient(
          requestId,
          statusId,
          statusSlug,
          rating,
          cookies.clientAccessToken
        )
      );
      let data = {};
      data.rating = rating;
      data.comment = comment;
      if(rating){
        dispatch(
          myRequestActions.submitClientRatingComment(
            data,
            requestId,
            cookies.clientAccessToken
          )
        );
      }
    }
  }
  };
  const updateWorkLog = () => {
    if (hours || minutes) {
      let tempMinutes = Number(hours) * 60;
      tempMinutes = Number(tempMinutes) + Number(minutes);
      dispatch(
        designerRequestActions.updateWorkLog(
          requestIdDesigner,
          tempMinutes,
          data["client_id"],
          cookies.clientAccessToken
        )
      );
    } else {
      dispatch(
        generalActions.addToast(
          "Required field",
          "Please enter value in worklog",
          "danger",
          uid()
        )
      );
    }
  };
  const changeAssignee = (e) => {
    setAssignee(e.target.value);
  };
  const updateAssignee = () => {
    if(assignee === 1){

    }else{
    dispatch(
      designerRequestActions.updateAssignee(
        requestIdDesigner,
        assignee,
        data["client_id"],
        cookies.clientAccessToken
      )
    );
      }
  };

  const changeHours = (e) => {
    setHours(e.target.value);
  };
  const changeMinutes = (e) => {
    if (e.target.value > 59) {
      setMinutes(59);
    } else {
      setMinutes(e.target.value);
    }
  };
  const changeRating = (e) => {
    if(rating == e.target.id){
      setRating(0);
    }else{
      setRating(e.target.id);
    }
  };
  const changeComment = (e) => {
    setComment(e.target.value);
  };
  const renderRatingStar = (ratingCount) => {
    const ratingArray = [0, 0, 0, 0, 0];
    for (let i = 0; i < ratingArray.length; i++) {
      if (i < ratingCount) {
        ratingArray[i] = 1;
      } else {
        ratingArray[i] = 0;
      }
    }

    return (
      <div className="ratting">
        {ratingArray.map((data, index) => {
          return (
            <label
              onClick={changeRating}
              key={index}
              id={index + 1}
              className={data === 1 ? "star active" : "star"}
            ></label>
          );
        })}
      </div>
    );
  };
  const renderUpdateRequest = () => {
    if (data && data["request_status"]["status"][0]["slug"] !== "approved" && data["request_status"]["status"][0]["slug"] !== "closed") {
      return (
        <li>
          <span className="status-list-title">Change Status</span>
          <div className="change-status">
            <select
              value={statusId}
              onChange={changeStatus}
              className="select-box input-box"
              placeholder ="select"
              id="change-status"
            >
              <option value={1} >Select</option>
              {data &&
                data["request_status_dropdown"] &&
                Object.keys(data["request_status_dropdown"]).map(
                  (key, index) => {
                    return (
                      <option
                        key={key}
                        value={data["request_status_dropdown"][key]["id"]}
                      >
                        {data["request_status_dropdown"][key]["name"]}
                      </option>
                    );
                  }
                )}
            </select>
           {data && data["request_status_dropdown"] && data["request_status_dropdown"].filter(
                                  (x) => x && x.id == statusId
                                ).length && data["request_status_dropdown"].filter(
                                  (x) => x && x.id == statusId
                                )[0]['name'] !== 'Approve' ? <button onClick={updateStatus} className="btn btn-black update">
              Update
            </button>:null}
          </div>
          {data && data["request_status_dropdown"] && data["request_status_dropdown"].filter(
                                  (x) => x && x.id == statusId
                                ).length && data["request_status_dropdown"].filter(
                                  (x) => x && x.id == statusId
                                )[0]['name'] == 'Approve' ?  <div id="submit-review">
                  <h3>We would love to hear from you! Kindly Rate Us</h3>
                  {renderRatingStar(rating)}
                  {rating?<div className="field">
                    <textarea
                      value={comment}
                      onChange={changeComment}
                      className="input-box"
                      placeholder="Kindly let us know how can we improve ( optional )"
                    ></textarea>
                  </div>:null}
                  <button onClick={updateStatus} className="btn btn-black update">
              Approve
            </button>
            </div>:null}
        </li>
      );
    }
  };
  const renderAssignedToPart = () => {
    if (data && data["position"] === "Project Manager") {
      if (data && data["designer"] && data["designer"].length > 0) {
        return (
          <li>
            <span className="status-list-title">Designer</span>
            <div className="change-status">
              <select
              value={assignee}
                onChange={changeAssignee}
                className="select-box input-box"
              >
                <option value={1} >Select</option>
                {data &&
                  data["designer"] &&
                  Object.keys(data["designer"]).map((key, index) => {
                    return (
                      <option
                        key={key}                        
                        value={data["designer"][key]["id"]}
                      >
                        {data["designer"][key]["name"]}
                      </option>
                    );
                  })}
              </select>
              <button
                type="button"
                onClick={updateAssignee}
                className="btn btn-black update"
              >
                Update
              </button>
            </div>
          </li>
        );
      } else {
        return (
          <li>
            <span className="status-list-title">Designer</span>
            <div className="change-status">
              <select
                onChange={changeAssignee}
                className="select-box input-box"
              >
              </select>
              <button
                type="button"
                onClick={updateAssignee}
                className="btn btn-black update"
                disabled
              >
                Update
              </button>
            </div>
          </li>
        );
      }
    }
  };
  const renderDesignerPart = () => {
    if (
      user.group_id === 3 &&
      data &&
      data["request_status"] &&
      data["request_status"]["status"][0]["slug"] !== "approved"
    ) {
      return (
        <ul className="request-status-list cf">
          {/* <li className="log-work-box">
            <span className="status-list-title">Log Work</span>
            <div className="log-work-update change-status">
              <input
                value={hours}
                className="input-box hours"
                type="number"
                placeholder="HH"
                min="0"
                onChange={changeHours}
              />
              <span className="seprater">:</span>
              <input
                value={minutes}
                className="input-box minute"
                type="number"
                placeholder="MM"
                min="0"
                max="59"
                onChange={changeMinutes}
              />
              <button
                type="button"
                onClick={updateWorkLog}
                className="btn btn-black update"
              >
                Update
              </button>
            </div>
          </li> */}
          {renderAssignedToPart()}
        </ul>
      );
    }
  };
  const chaneDateTime = (d) => {
    setDateTime(d);
  };
  const submitHandler = () =>{

    if(!clientName){
      dispatch(
        generalActions.addToast(
          "Required field",
          "Please enter client name",
          "danger",
          uid()
        )
      );
      return;
    }

    if(!commentForRevision){
      dispatch(
        generalActions.addToast(
          "Required field",
          "Please enter additional comment",
          "danger",
          uid()
        )
      );
      return;
    }
    
    let formData = new FormData();
    formData.append("request_id",requestIdDesigner);
    formData.append("client_name",clientName);
    formData.append("additional_comments",commentForRevision);
    if(dateTime){
      formData.append("discussion_date_time",dateTime?moment(dateTime).format('DD/MM/YYYY hh:mm A'):"");
    }
    
    for (let i = 0; i < uploadFiles.length; i++) {
      formData.append("images[]", uploadFiles[i]);
    }
    setShowPopup(false);
    dispatch(myRequestActions.rollBackRevisionRequest(formData,requestIdDesigner, cookies.clientAccessToken));
  }
  const changeUploadFiles =(e)=>{
    let fileExtentionvalidation = { type: "", flag: false };
    let allowedFileExtention = ["png", "jpg", "jpeg"];
    let fileExtention;
    for (let i = 0; i < e.target.files.length; i++) {
      fileExtention = /[.]/.exec(e.target.files[i].name)
        ? /[^.]+$/.exec(e.target.files[i].name)
        : undefined;        
      if (!allowedFileExtention.includes(fileExtention[0].toLowerCase())) {        
        fileExtentionvalidation.type = fileExtention[0];
        fileExtentionvalidation.flag = true;
      }
    }

    if(fileExtentionvalidation.flag){
      dispatch(
        generalActions.addToast(
          "File Type",
          fileExtentionvalidation.type + " file not allowed for upload",
          "danger",
          uid()
        )
      );
      return;
    }

    let data = [];
    for (let i = 0; i < e.target.files.length; i++) {
      data.push(e.target.files[i]);
    }
    setUploadFiles([...data]);
  }
  return (
    <div className="viewrequeststatus cf">
       <div className={`image-popup-outer spo-popup rollbak-revision-popup ${showPopup ? "show" : ""}`}>
            <div className="image-popup-inner">
              <span
                className="close-btn"
                onClick={(e) => {
                  setShowPopup(!showPopup)
                  e.stopPropagation();
                }}
              ></span>
              <div className="image-box">
              <div className="myprofile">
      
              <div className="myprofile-inner">
                <div className="myprofilebox">
                  <span className="title-text">
                    Roll Back Revision Due to Design Error
                  </span>
                  <div className="fieldset">
                    <div className="field field-name">
                      <label className="label required" htmlFor="client-name">
                        Client Name
                      </label>
                      <input
                        id="client-name"
                        type="text"
                        name="client-name"
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                        className="input-text"
                        placeholder="Enter client name"
                      />
                    </div>
                    <div className="field field-name">
                      <label className="label required" htmlFor="comments">
                        Additional Comments
                      </label>
                      <textarea
                        id="comments"
                        type="text"
                        name="comments"
                        value={commentForRevision}
                        onChange={(e) => setCommentForRevision(e.target.value)}
                        className="input-text"
                        placeholder="Enter comment"
                      />
                    </div>
                    <div className="field field-name">
                      <label className="label" htmlFor="email">
                        Discussion Date & Time
                      </label>
                      <DateTimePicker
                      amPmAriaLabel="Select AM/PM"
                      maxDate={new Date()}
                      onChange={chaneDateTime} value={dateTime}/>
                    </div>
                    <div className="field field-name fileupload-field">
                      <label className="label uploadfilelabel" htmlFor="file">
                        Upload Files <span>(Supported file types are jpg, jpeg, png)</span>
                      </label>
                      <input
                        id="file"
                        accept=".png,.jpg,.jpeg"
                        type="file"
                        name="file"
                        onChange={changeUploadFiles}
                        className="input-text"
                        multiple
                      />
                     {uploadFiles.length?<div className="imagename-block">
                      {uploadFiles && uploadFiles.map((data, index) =>  {
                         var fileExtention = /[.]/.exec(data.name)
                         ? /[^.]+$/.exec(data.name)
                         : undefined;
                        return(
                        <span>{data.name.substring(0,8)+"..."+fileExtention}
                        <a href={void(0)} onClick={()=>{
                          uploadFiles.splice(index, 1);
                          setUploadFiles([...uploadFiles]);
                        }}><img src={require("../../assets/images/close-icon1.svg")} alt="Close" /></a></span>
                      )})}
                      </div>:null}
                    </div>
                    <div className="action">
                        <button
                          className="btn btn-black"
                          onClick={submitHandler}
                        >
                          {" "}
                          Submit{" "}
                        </button>
                      </div>
                  </div>
                </div>
              </div>
              </div>
              </div>
            </div>
          </div>
      <h3 className="request-status-title">Request Status</h3>
      <ul className="request-status-list cf" style={{marginBottom:0}}>
        <li>
          <span className="status-list-title">Current Status</span>
          <span
            className={"request-status "+
              (data && data["request_status"]
                ?data && data["request_status"] && data["request_status"]["status"]
                &&data["request_status"]["status"][0]["name"] == "To be Continued" && (user.group_id == 1 || user.group_id == 2 || user.group_id == 4 ||user.group_id == 5)?("In Progress").toLowerCase()
                .replace(/\s+/g, "-"): data["request_status"]["status"][0]["name"]
                    .toLowerCase()
                    .replace(/\s+/g, "-")
                : "")
            }
          >
            {data && data["request_status"]
              ? data["request_status"]["status"][0]["name"]== "To be Continued" && (user.group_id == 1 || user.group_id == 2 || user.group_id == 4 ||user.group_id == 5)?"In Progress":data["request_status"]["status"][0]["name"]
              : ""}
          </span>
        </li>
        <li>
          <span className="status-list-title">Revisions</span>
          <span className="revisions-no">
            {data && data["request_status"]
              ? data["request_status"]["revision"]
              : ""}
          </span>
        </li>
        <li>
          <span className="status-list-title">Revision Due To Design Error</span>
          <span className="revisions-no">
            {data && data["request_status"]
              ? data["request_status"]["revision_design_error"]
              : ""}
          </span>
          {data && data["request_status"] && data["request_status"]["status"] && data["request_status"]["status"][0]["name"]== "Revision Due to Design Error"
          &&data["position"] === "Project Manager"
          ?<button className="btn btn-black roll-back-btn" onClick={()=>setShowPopup(!showPopup)}>Roll Back</button>:null}
        </li>
        <li className="timespent">
          <span className="status-list-title">Time Spent</span>
          <span className="time">
            {data && data["request_status"]
              ? data["request_status"]["time_spent"]
              : ""}
          </span>
        </li>
        {renderUpdateRequest()}
      </ul>
      {renderDesignerPart()}
    </div>
  );
}

export default ViewRequestStatus;
