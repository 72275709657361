import { put, takeLatest, call, all, select } from "redux-saga/effects";
import { actions as authActions } from "./AuthReducer";
import { actions as generalActions } from "./GeneralReducer";

import ShortUniqueId from "short-unique-id";

import apiUrl from "../../config/urls";
import {
  getApiToken,
  postApiToken,
  /*   putApiToken,
    deleteApiToken,
    postApiTokenJson,
    postApiTokenFormData, */
} from "../apis";
import queryString from "query-string";

const uid = new ShortUniqueId();

export const actionTypes = {
  GET_MYOPEN_REQUEST_DATA: "GET_MYOPEN_REQUEST_DATA",
  SET_MYOPEN_REQUEST_DATA: "SET_MYOPEN_REQUEST_DATA",
  MYOPEN_REQUEST_DATA_LOADING: "MYOPEN_REQUEST_DATA_LOADING",
  CLEAR_MYOPEN_REQUEST_DATA: "CLEAR_MYOPEN_REQUEST_DATA",
  CHANGE_START_FROM_MYOPEN_REQUEST: "CHANGE_START_FROM_MYOPEN_REQUEST",
  GET_REQUEST_DETAIL: "GET_REQUEST_DETAIL_DESIGNER",
  CHANGE_CLIENT_REQUEST_ID: "CHANGE_CLIENT_REQUEST_ID_DESIGNER",
  CHANGE_REQUEST_DETAIL: "CHANGE_REQUEST_DETAIL_DESIGNER",
  UPDATE_WORK_LOG: "UPDATE_WORK_LOG",
  UPDATE_ASSIGNEE: "UPDATE_ASSIGNEE",
  UPDATE_STATUS_DESIGNER: "UPDATE_STATUS_DESIGNER",
  ADD_COMMENT: "ADD_COMMENT_DESIGNER",
  GET_ALL_REQUEST_DATA: "GET_ALL_REQUEST_DATA",
  SET_ALL_REQUEST_DATA: "SET_ALL_REQUEST_DATA",
  ALL_REQUEST_DATA_LOADING: "ALL_REQUEST_DATA_LOADING",
  CLEAR_ALL_REQUEST_DATA: "CLEAR_ALL_REQUEST_DATA",
  SET_CLIENT_SELECTED_DATA: "SET_CLIENT_SELECTED_DATA",
  GET_REQUEST_DELIVERED_DATA: "GET_REQUEST_DELIVERED_DATA",
  SET_REQUEST_DELIVERED_DATA: "SET_REQUEST_DELIVERED_DATA",
  REQUEST_DELIVERED_DATA_LOADING: "REQUEST_DELIVERED_DATA_LOADING",
  CLEAR_REQUEST_DELIVERED_DATA: "CLEAR_REQUEST_DELIVERED_DATA",
  SET_UPDATE_STATUS_FLAG: 'SET_UPDATE_STATUS_FLAG',
  CLEAR_UPDATE_STATUS_FLAG: 'CLEAR_UPDATE_STATUS_FLAG',
  SET_UPDATE_ASSIGNEE_FLAG: 'SET_UPDATE_ASSIGNEE_FLAG',
  CLEAR_UPDATE_ASSIGNEE_FLAG: 'CLEAR_UPDATE_ASSIGNEE_FLAG',
  SAVE_ACTIVE_REQUEST_SECTION_DATA: 'DESIGNER_REQUEST/SAVE_ACTIVE_REQUEST_SECTION_DATA',
  SAVE_START_FROM: 'DESIGNER_REQUEST/SAVE_START_FROM',
  SET_COUNT: 'DESIGNER_REQUEST/SET_COUNT',
  CHANGE_CLIENT_PAGE_ACTIVE_TAB_NAME: 'DESIGNER_REQUEST/CHANGE_CLIENT_PAGE_ACTIVE_TAB_NAME',
  UPDATE_FILTERS: 'DESIGNER_REQUEST/UPDATE_FILTERS',
  UPDATE_FILTERS_DROPDONW_DATA: 'DESIGNER_REQUEST/UPDATE_FILTERS_DROPDONW_DATA',
  SORT_BY_FILTER : 'SORT_BY_FILTER',
  GET_USER_BY_COMPANY : 'GET_USER_BY_COMPANY',
  DESIGNER_OPEN_REQUEST_LIST:'DESIGNER_OPEN_REQUEST_LIST',
  DESIGNER_WIP_REQUEST_LIST:'DESIGNER_WIP_REQUEST_LIST',
  DESIGNER_NEEDS_INFO_REQUEST_LIST:'DESIGNER_NEEDS_INFO_REQUEST_LIST',
  DESIGNER_CLOSED_LIST:'DESIGNER_CLOSED_LIST',
  DESIGNER_DELIVERED_REQUEST_LIST:'DESIGNER_DELIVERED_REQUEST_LIST',
  GET_PRIORITIZE_REQUEST_DATA: 'GET_PRIORITIZE_REQUEST_DATA',
  DESIGNER_MY_WIP_REQUEST_LIST: 'DESIGNER_MY_WIP_REQUEST_LIST',
  DESIGNER_MY_NEEDS_INFO_REQUEST_LIST:'DESIGNER_MY_NEEDS_INFO_REQUEST_LIST',
  DESIGNER_MY_DELIVERED_REQUEST_LIST:'DESIGNER_MY_DELIVERED_REQUEST_LIST',
  DESIGNER_MY_CLOSED_REQUEST_LIST:'DESIGNER_MY_CLOSED_REQUEST_LIST',
  SET_ACTIVE_SECTION:'SET_ACTIVE_SECTION',
  FILTER_NOT_CHANGE_BY_API:'FILTER_NOT_CHANGE_BY_API',
  DESIGNER_MYALL_REQUEST_LIST: 'DESIGNER_MYALL_REQUEST_LIST',
  GET_ALL_REQUEST_LIST: 'GET_ALL_REQUEST_LIST',
  SET_PRIORITY_ALL_TAB: 'SET_PRIORITY_ALL_TAB',
  CHANGE_SORT_DIR: 'CHANGE_SORT_DIR',
};

const initialState = {
  myOpenRequestData: "",
  myOpenRequestDataLoading: false,
  start: 0,
  length: 20,   //10
  requestDetailData: "",
  requestIdDesigner: "",
  allRequestDataLoading: false,
  allRequestsData: "",
  clientSelectedData: localStorage?.getItem('clientSelectedData') || "",
  requestDeliveredData: "",
  requestDeliveredDataLoading: false,
  updateStatusFlag: false,
  updateAssigneeFlag: false,
  saveActiveSectionData: "",
  count: 1,
  savedStartFrom: 0,
  clientPageActiveTabName: '',
  filters: {},
  filtersDropdownData: {
    assign_to: [],
    created_by: [],
    request_status: [],
    request_types: [],
    client_ids: [],
  },
  sortByFilter: "created_at",
  activeSection: "open-requests",
  filterNotChangeByApi: false,
  priorityForAllTab: false,
  sortDir: "ASC",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_MYOPEN_REQUEST_DATA: {
      return {
        ...state,
        myOpenRequestData: "",
        myOpenRequestDataLoading: false,
      };
    }
    case actionTypes.MYOPEN_REQUEST_DATA_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        myOpenRequestDataLoading: flag,
      };
    }

    case actionTypes.SET_MYOPEN_REQUEST_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        myOpenRequestData: data,
      };
    }
    case actionTypes.CHANGE_START_FROM_MYOPEN_REQUEST: {
      const { count } = action.payload;
      return {
        ...state,
        start: count,
      };
    }

    case actionTypes.CHANGE_CLIENT_REQUEST_ID: {
      const { data } = action.payload;
      return {
        ...state,
        requestIdDesigner: data,
      };
    }

    case actionTypes.CHANGE_REQUEST_DETAIL: {
      const { data } = action.payload;
      return {
        ...state,
        requestDetailData: data,
      };
    }
    case actionTypes.SET_ALL_REQUEST_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        allRequestsData: data,
      };
    }
    case actionTypes.CLEAR_ALL_REQUEST_DATA: {
      return {
        ...state,
        allRequestsData: "",
        allRequestDataLoading: false,
      };
    }
    case actionTypes.ALL_REQUEST_DATA_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        allRequestDataLoading: flag,
      };
    }
    case actionTypes.SET_CLIENT_SELECTED_DATA: {
      const { data } = action.payload;
      localStorage && localStorage.setItem('clientSelectedData', data);
      return {
        ...state,
        clientSelectedData: data,
      };
    }
    case actionTypes.SET_REQUEST_DELIVERED_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        requestDeliveredData: data,
      };
    }
    case actionTypes.CLEAR_REQUEST_DELIVERED_DATA: {
      return {
        ...state,
        requestDeliveredData: "",
        requestDeliveredDataLoading: false,
      };
    }
    case actionTypes.REQUEST_DELIVERED_DATA_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        requestDeliveredDataLoading: flag,
      };
    }
    case actionTypes.SET_UPDATE_STATUS_FLAG: {
      const { flag } = action.payload;
      return {
        ...state,
        updateStatusFlag: flag
      };
    }
    case actionTypes.CLEAR_UPDATE_STATUS_FLAG: {
      return {
        ...state,
        updateStatusFlag: false
      };
    }
    case actionTypes.SET_UPDATE_ASSIGNEE_FLAG: {
      const { flag } = action.payload;
      return {
        ...state,
        updateAssigneeFlag: flag
      };
    }
    case actionTypes.CLEAR_UPDATE_ASSIGNEE_FLAG: {
      return {
        ...state,
        updateAssigneeFlag: false
      };
    }
    case actionTypes.SAVE_ACTIVE_REQUEST_SECTION_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        saveActiveSectionData: data,
      };
    }
    case actionTypes.SAVE_START_FROM: {
      const { data } = action.payload;
      return {
        ...state,
        savedStartFrom: data,
      };
    }
    case actionTypes.SET_COUNT: {
      const { data } = action.payload;
      return {
        ...state,
        count: data,
      };
    }
    case actionTypes.CHANGE_CLIENT_PAGE_ACTIVE_TAB_NAME: {
      return {
        ...state,
        clientPageActiveTabName: action.payload,
      }
    }
    case actionTypes.UPDATE_FILTERS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case actionTypes.UPDATE_FILTERS_DROPDONW_DATA: {
      return {
        ...state,
        filtersDropdownData: action.payload,
      }
    }
    case actionTypes.SORT_BY_FILTER: {
      const { data } = action.payload;
      return {
        ...state,
        sortByFilter: data,
      };
    }
    case actionTypes.SET_ACTIVE_SECTION: {
      const { data } = action.payload;
      return {
        ...state,
        activeSection: data,
      };
    }
    case actionTypes.FILTER_NOT_CHANGE_BY_API: {
      const { data } = action.payload;
      return {
        ...state,
        filterNotChangeByApi: data,
      };
    }
     case actionTypes.SET_PRIORITY_ALL_TAB: {
      const { data } = action.payload;
      localStorage && localStorage.setItem('priorityForAllTab', data);
      return {
        ...state,
        priorityForAllTab: data,
      };
    }
    case actionTypes.CHANGE_SORT_DIR: {
      const { data } = action.payload;
      return {
        ...state,
        sortDir: data,
      };
    }
    default: {
      return state;
    }
  }
};

export const actions = {
  addCommentData: (id, comment, clientAccessToken) => ({
    type: actionTypes.ADD_COMMENT,
    payload: { id, comment, clientAccessToken },
  }),
  updateFiltersDropdonwData: (data) => ({
    type: actionTypes.UPDATE_FILTERS_DROPDONW_DATA,
    payload: { ...data },
  }),
  clearMyopenRequestData: () => ({
    type: actionTypes.CLEAR_MYOPEN_REQUEST_DATA,
  }),
  getMyopenRequestData: (
    filters,
    start,
    length,
    clientAccessToken
  ) => ({
    type: actionTypes.GET_MYOPEN_REQUEST_DATA,
    payload: { filters, start, length, clientAccessToken },
  }),
  setMyopenRequestData: (data) => ({
    type: actionTypes.SET_MYOPEN_REQUEST_DATA,
    payload: { data },
  }),
  myOpenRequestDataLoading: (flag) => ({
    type: actionTypes.MYOPEN_REQUEST_DATA_LOADING,
    payload: { flag },
  }),
  changeStartFromMyOpenRequest: (count) => ({
    type: actionTypes.CHANGE_START_FROM_MYOPEN_REQUEST,
    payload: { count },
  }),
  getRequestDetail: (requestId, clientAccessToken) => ({
    type: actionTypes.GET_REQUEST_DETAIL,
    payload: { requestId, clientAccessToken },
  }),
  changeRequestId: (data) => ({
    type: actionTypes.CHANGE_CLIENT_REQUEST_ID,
    payload: { data },
  }),
  changeRequestDetail: (data) => ({
    type: actionTypes.CHANGE_REQUEST_DETAIL,
    payload: { data },
  }),
  updateWorkLog: (id, time, clientID, clientAccessToken) => ({
    type: actionTypes.UPDATE_WORK_LOG,
    payload: { id, time, clientID, clientAccessToken },
  }),
  updateAssignee: (id, resourceId, clientID, clientAccessToken) => ({
    type: actionTypes.UPDATE_ASSIGNEE,
    payload: { id, resourceId, clientID, clientAccessToken },
  }),
  updateStatusDesigner: (id, statusId, clientAccessToken) => ({
    type: actionTypes.UPDATE_STATUS_DESIGNER,
    payload: { id, statusId, clientAccessToken },
  }),
  clearAllRequestData: () => ({
    type: actionTypes.CLEAR_ALL_REQUEST_DATA,
  }),
  getAllRequestData: (
    filters,
    start,
    length,
    clientAccessToken
  ) => ({
    type: actionTypes.GET_ALL_REQUEST_DATA,
    payload: { filters, start, length, clientAccessToken },
  }),
  setAllRequestData: (data) => ({
    type: actionTypes.SET_ALL_REQUEST_DATA,
    payload: { data },
  }),
  allRequestDataLoading: (flag) => ({
    type: actionTypes.ALL_REQUEST_DATA_LOADING,
    payload: { flag },
  }),
  setClientSelectedData: (data) => ({
    type: actionTypes.SET_CLIENT_SELECTED_DATA,
    payload: { data },
  }),
  getNeedsApprovalData: (
    filters,
    start,
    length,
    clientAccessToken
  ) => ({
    type: actionTypes.GET_REQUEST_DELIVERED_DATA,
    payload: { filters, start, length, clientAccessToken },
  }),
  setRequestDeliveredData: (data) => ({
    type: actionTypes.SET_REQUEST_DELIVERED_DATA,
    payload: { data },
  }),
  requestDeliveredDataLoading: (flag) => ({
    type: actionTypes.REQUEST_DELIVERED_DATA_LOADING,
    payload: { flag },
  }),
  clearRequestDeliveredData: () => ({
    type: actionTypes.CLEAR_REQUEST_DELIVERED_DATA,
  }),
  setUpdateStatusFlag: (flag) => ({
    type: actionTypes.SET_UPDATE_STATUS_FLAG,
    payload: { flag },
  }),
  clearUpdateStatusFlag: () => ({
    type: actionTypes.CLEAR_UPDATE_STATUS_FLAG,
  }),
  setUpdateAssigneeFlag: (flag) => ({
    type: actionTypes.SET_UPDATE_ASSIGNEE_FLAG,
    payload: { flag },
  }),
  clearUpdateAssigneeFlag: () => ({
    type: actionTypes.CLEAR_UPDATE_ASSIGNEE_FLAG,
  }),
  changeActiveSectionData: (data) => ({
    type: actionTypes.SAVE_ACTIVE_REQUEST_SECTION_DATA,
    payload: { data },
  }),
  changeSavedStartFrom: (data) => ({
    type: actionTypes.SAVE_START_FROM,
    payload: { data },
  }),
  changeCount: (data) => ({
    type: actionTypes.SET_COUNT,
    payload: { data },
  }),
  changeClientPageActiveTabName: (data) => ({
    type: actionTypes.CHANGE_CLIENT_PAGE_ACTIVE_TAB_NAME,
    payload: data,
  }),
  updateFilters: (data) => ({
    type: actionTypes.UPDATE_FILTERS,
    payload: {
      filters: data,
    },
  }),
  changeSortByFilter: (data) => ({
    type: actionTypes.SORT_BY_FILTER,
    payload: { data },
  }),
  getUsersByCompany: (id, clientAccessToken) => ({
    type: actionTypes.GET_USER_BY_COMPANY,
    payload: { id, clientAccessToken },
  }),
  getOpenRequestList: (
    filters,
    start,
    length,
    clientAccessToken
  ) => ({
    type: actionTypes.DESIGNER_OPEN_REQUEST_LIST,
    payload: { filters, start, length, clientAccessToken },
  }),
  getWipRequestList: (
    filters,
    start,
    length,
    clientAccessToken
  ) => ({
    type: actionTypes.DESIGNER_WIP_REQUEST_LIST,
    payload: { filters, start, length, clientAccessToken },
  }),
  getNeedsInfoRequestList: (
    filters,
    start,
    length,
    clientAccessToken
  ) => ({
    type: actionTypes.DESIGNER_NEEDS_INFO_REQUEST_LIST,
    payload: { filters, start, length, clientAccessToken },
  }),
  getClosedRequestList: (
    filters,
    start,
    length,
    clientAccessToken
  ) => ({
    type: actionTypes.DESIGNER_CLOSED_LIST,
    payload: { filters, start, length, clientAccessToken },
  }),
  getDeliveredRequestList: (
    filters,
    start,
    length,
    clientAccessToken
  ) => ({
    type: actionTypes.DESIGNER_DELIVERED_REQUEST_LIST,
    payload: { filters, start, length, clientAccessToken },
  }),
  getPrioritizeRequestData: (
    start,
    length,
    clientAccessToken
  ) => ({
    type: actionTypes.GET_PRIORITIZE_REQUEST_DATA,
    payload: { start, length, clientAccessToken },
  }),
  getMyWipRequestList: (
    filters,
    start,
    length,
    clientAccessToken
  ) => ({
    type: actionTypes.DESIGNER_MY_WIP_REQUEST_LIST,
    payload: { filters, start, length, clientAccessToken },
  }),
  getMyNeedsInfoRequestList: (
    filters,
    start,
    length,
    clientAccessToken
  ) => ({
    type: actionTypes.DESIGNER_MY_NEEDS_INFO_REQUEST_LIST,
    payload: { filters, start, length, clientAccessToken },
  }),
  getNeedsApprovalRequestList: (
    filters,
    start,
    length,
    clientAccessToken
  ) => ({
    type: actionTypes.DESIGNER_MY_DELIVERED_REQUEST_LIST,
    payload: { filters, start, length, clientAccessToken },
  }),
  getMyClosedRequestList: (
    filters,
    start,
    length,
    clientAccessToken
  ) => ({
    type: actionTypes.DESIGNER_MY_CLOSED_REQUEST_LIST,
    payload: { filters, start, length, clientAccessToken },
  }),
  setActiveSection: (data) => ({
    type: actionTypes.SET_ACTIVE_SECTION,
    payload: { data },
  }),
  setFilterNotChangeByApi: (data) => ({
    type: actionTypes.FILTER_NOT_CHANGE_BY_API,
    payload: { data },
  }),
  getMyAllRequestList: (
    filters,
    start,
    length,
    clientAccessToken
  ) => ({
    type: actionTypes.DESIGNER_MYALL_REQUEST_LIST,
    payload: { filters, start, length, clientAccessToken },
  }),
  getAllRequestList: (
    filters,
    start,
    length,
    clientAccessToken
  ) => ({
    type: actionTypes.GET_ALL_REQUEST_LIST,
    payload: { filters, start, length, clientAccessToken },
  }),
  setPriorityForAllTab: (data) => ({
    type: actionTypes.SET_PRIORITY_ALL_TAB,
    payload: { data },
  }),
  changeSortDir: (data) => ({
    type: actionTypes.CHANGE_SORT_DIR,
    payload: { data },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.GET_MYOPEN_REQUEST_DATA, getMyopenRequestDataSaga);
  yield takeLatest(actionTypes.GET_REQUEST_DETAIL, getRequestDetailSaga);
  yield takeLatest(actionTypes.UPDATE_WORK_LOG, updateWorkLogSaga);
  yield takeLatest(actionTypes.UPDATE_ASSIGNEE, updateAssigneeSaga);
  yield takeLatest(actionTypes.UPDATE_STATUS_DESIGNER, updateStatusDesignerSaga);
  yield takeLatest(actionTypes.ADD_COMMENT, addCommentDataSaga);
  yield takeLatest(actionTypes.GET_ALL_REQUEST_DATA, getAllRequestDataSaga);
  yield takeLatest(actionTypes.GET_REQUEST_DELIVERED_DATA, getNeedsApprovalDataSaga);
  yield takeLatest(actionTypes.GET_USER_BY_COMPANY, getUsersByCompanySaga);
  yield takeLatest(actionTypes.DESIGNER_OPEN_REQUEST_LIST, getMyopenRequestDataSaga);
  yield takeLatest(actionTypes.DESIGNER_WIP_REQUEST_LIST, getWipRequestListSaga);
  yield takeLatest(actionTypes.DESIGNER_NEEDS_INFO_REQUEST_LIST, getNeedsInfoRequestListSaga);
  yield takeLatest(actionTypes.DESIGNER_CLOSED_LIST, getClosedRequestListSaga);
  yield takeLatest(actionTypes.DESIGNER_DELIVERED_REQUEST_LIST, getMyopenRequestDataSaga);
  yield takeLatest(actionTypes.GET_PRIORITIZE_REQUEST_DATA, getPrioritizeRequestDataSaga);
  yield takeLatest(actionTypes.DESIGNER_MY_WIP_REQUEST_LIST, getMyWipRequestDataSaga);
  yield takeLatest(actionTypes.DESIGNER_MY_NEEDS_INFO_REQUEST_LIST, getMyNeedsInfoRequestListSaga);
  yield takeLatest(actionTypes.DESIGNER_MY_DELIVERED_REQUEST_LIST, getNeedsApprovalRequestListSaga);
  yield takeLatest(actionTypes.DESIGNER_MY_CLOSED_REQUEST_LIST, getMyClosedRequestListSaga);
  yield takeLatest(actionTypes.DESIGNER_MYALL_REQUEST_LIST, getMyAllRequestDataSaga);
  yield takeLatest(actionTypes.GET_ALL_REQUEST_LIST, getAllRequestListSaga);
}

function* getMyopenRequestDataSaga(action) {
  let {
    filters,
    start,
    length,
    clientAccessToken,
  } = action.payload;
  yield all([put(actions.myOpenRequestDataLoading(true))]);
  const { myOpenRequestData, sortByFilter, sortDir } = yield select((state) => state.designerRequest);
  if(filters['client_ids']){
    filters['client_ids'] = filters['client_ids'].toString();
  }
  const filterParams = {
    ...filters,
    start: start,
    length: length,
    sort_by: sortByFilter,
    sort_dir: sortDir,
  };
  try {
    const response = yield call(
      getApiToken,
      apiUrl("DESIGNER_MYOPEN_REQUEST_URL"),
      queryString.stringify(filterParams, {arrayFormat: 'bracket'}),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        let temparrComp = responseData.data?.companies;
        let companies = [];
        temparrComp.forEach(function(i){
          companies.push({id:i.client_id,name:i.company_name})
        })
        if (start) {
          let temparr = myOpenRequestData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.myOpenRequestDataLoading(false)),
            put(actions.setMyopenRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
              client_ids: companies || [],
            })),
          ]);
        } else {
          yield all([
            put(actions.myOpenRequestDataLoading(false)),
            put(actions.setMyopenRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
              client_ids: companies || [],
            })),
          ]);
        }
      } else {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get MyopenRequest Data",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myOpenRequestDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Get MyopenRequest Data",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myOpenRequestDataLoading(false))]);
  }
}

function* getRequestDetailSaga(action) {
  let { requestId, clientAccessToken } = action.payload;
  yield all([put(actions.myOpenRequestDataLoading(true))]);
  const filterParams = {
    request_id: requestId,
  };

  try {
    const response = yield call(
      getApiToken,
      apiUrl("CLIENT_REQUEST_DETAIL_VIEW"),
      queryString.stringify(filterParams),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.myOpenRequestDataLoading(false)),
          put(actions.changeRequestDetail(responseData.data)),
        ]);
      } else {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myOpenRequestDataLoading(false))]);

        yield put(authActions.logout());
      } else {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Request Detail View",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myOpenRequestDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Request Detail View",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myOpenRequestDataLoading(false))]);
  }
}

function* updateWorkLogSaga(action) {
  let { id, time, clientID, clientAccessToken } = action.payload;

  let submitData = {
    client_request_id: id,
    time: time,
    client_id: clientID,
  };
  yield all([put(actions.myOpenRequestDataLoading(true))]);

  try {
    const response = yield call(
      postApiToken,
      apiUrl("DESIGNER_UPDATE_WORKLOG_URL"),
      clientAccessToken,
      queryString.stringify(submitData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield put(
          generalActions.addToast(
            "Update Status",
            "Request Updated Successfully",
            "success",
            uid()
          )
        );
        yield all([
          put(actions.myOpenRequestDataLoading(false)),
          put(actions.getRequestDetail(id, clientAccessToken)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Designer WorkLog",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myOpenRequestDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Designer WorkLog",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myOpenRequestDataLoading(false))]);
  }
}

function* updateAssigneeSaga(action) {
  let { id, resourceId, clientID, clientAccessToken } = action.payload;

  let submitData = {
    request_id: id,
    resource_id: resourceId,
    client_id: clientID,
  };
  yield all([put(actions.myOpenRequestDataLoading(true))]);

  try {
    const response = yield call(
      postApiToken,
      apiUrl("DESIGNER_UPDATE_ASSIGNEE_URL"),
      clientAccessToken,
      queryString.stringify(submitData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Designer Update Assignee",
            "Request Assign to Designer",
            "success",
            uid()
          )
        );
        yield all([put(actions.setUpdateAssigneeFlag(true))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Designer Update Assignee",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myOpenRequestDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Designer Update Assignee",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myOpenRequestDataLoading(false))]);
  }
}

function* updateStatusDesignerSaga(action) {
  let { id, statusId, clientAccessToken } = action.payload;

  let submitData = {
    request_status_id: statusId,
    request_id: id,
  };

  yield all([put(actions.myOpenRequestDataLoading(true))]);
  // const { requestId } = yield select((state) => state.myRequest);
  try {
    const response = yield call(
      postApiToken,
      apiUrl("DESIGNER_REQUEST_UPDATE_STATUS_URL"),
      clientAccessToken,
      queryString.stringify(submitData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield put(
          generalActions.addToast(
            "Update Status",
            "Request Updated Successfully",
            "success",
            uid()
          )
        );
        yield all([
          put(actions.setUpdateStatusFlag(true)),
          put(actions.myOpenRequestDataLoading(false)),
          put(actions.getRequestDetail(id, clientAccessToken)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Update Status",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myOpenRequestDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Update Status",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myOpenRequestDataLoading(false))]);
  }
}

function* addCommentDataSaga(action) {
  let { id, comment, clientAccessToken } = action.payload;

  let submitData = {
    client_request_id: id,
    note: JSON.stringify(comment),
  };
  yield all([put(actions.myOpenRequestDataLoading(true))]);

  try {
    const response = yield call(
      postApiToken,
      apiUrl("DESIGNER_REQUEST_ADD_COMMENT_URL"),
      clientAccessToken,
      queryString.stringify(submitData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.myOpenRequestDataLoading(false)),
          put(actions.changeAddCommentData("")),
          put(actions.getDiscussionData(id, clientAccessToken)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Add Comment",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myOpenRequestDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Add Comment",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myOpenRequestDataLoading(false))]);
  }
}
function* getAllRequestDataSaga(action) {
  let {
    filters,
    start,
    length,
    clientAccessToken,
  } = action.payload;
  yield all([put(actions.allRequestDataLoading(true))]);
  const { allRequestsData, clientSelectedData,sortByFilter,sortDir } = yield select(
    (state) => state.designerRequest
  );
  const filterParams = {
    client_id: clientSelectedData,
    ...filters,
    start: start,
    length: length,
    sort_by: sortByFilter,
    sort_dir: sortDir,
  };
  try {
    const response = yield call(
      getApiToken,
      apiUrl("DESIGNER_ALLREQUEST_URL"),
      queryString.stringify(filterParams, {arrayFormat: 'bracket'}),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([put(actions.allRequestDataLoading(false))]);
        if (start) {
          let temparr = allRequestsData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.allRequestDataLoading(false)),
            put(actions.setAllRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        } else {
          yield all([
            put(actions.allRequestDataLoading(false)),
            put(actions.setAllRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        }
      } else {
        yield all([put(actions.allRequestDataLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.allRequestDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get AllRequest Data",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.allRequestDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Get AllRequest Data",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.allRequestDataLoading(false))]);
  }
}
function* getNeedsApprovalDataSaga(action) {
  let {
    filters,
    start,
    length,
    clientAccessToken,
  } = action.payload;
  yield all([put(actions.requestDeliveredDataLoading(true))]);
  const { requestDeliveredData, clientSelectedData, sortByFilter, sortDir } = yield select(
    (state) => state.designerRequest
  );
  const filterParams = {
    client_id: clientSelectedData,
    ...filters,
    start: start,
    length: length,
    sort_by: sortByFilter,
    sort_dir: sortDir,
  };
  try {
    const response = yield call(
      getApiToken,
      apiUrl("DESIGNER_CLIENT_NEEDS_APPROVAL_REQUEST_URL"),
      queryString.stringify(filterParams, {arrayFormat: 'bracket'}),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([put(actions.requestDeliveredDataLoading(false))]);
        if (start) {
          let temparr = requestDeliveredData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.requestDeliveredDataLoading(false)),
            put(actions.setRequestDeliveredData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        } else {
          yield all([
            put(actions.requestDeliveredDataLoading(false)),
            put(actions.setRequestDeliveredData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        }
      } else {
        yield all([put(actions.requestDeliveredDataLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.requestDeliveredDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get RequestDelivered Data",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.requestDeliveredDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Get RequestDelivered Data",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.requestDeliveredDataLoading(false))]);
  }
}

function* getUsersByCompanySaga(action) {
  let { id, clientAccessToken } = action.payload;

  let submitData = {
    client_ids: id,
  };
  const { filtersDropdownData } = yield select(
    (state) => state.designerRequest
  );
  yield all([put(actions.myOpenRequestDataLoading(true))]);

  try {
    const response = yield call(
      getApiToken,
      apiUrl("GET_USERS_BY_COMPANY"),
      queryString.stringify(submitData),
      clientAccessToken
    );
    
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([put(actions.myOpenRequestDataLoading(false)), put(actions.updateFiltersDropdonwData({
          ...filtersDropdownData,
          created_by: responseData.data || [],
        }))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get Users By Company",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myOpenRequestDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Get Users By Company",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myOpenRequestDataLoading(false))]);
  }
}

function* getPrioritizeRequestDataSaga(action) {
  let {
    start,
    length,
    clientAccessToken,
  } = action.payload;
  yield all([put(actions.allRequestDataLoading(true))]);
  const { allRequestsData, clientSelectedData } = yield select(
    (state) => state.designerRequest
  );
  const filterParams = {
    client_id: clientSelectedData,
    start: start,
    length: length,
  };
  try {
    const response = yield call(
      getApiToken,
      apiUrl("DESIGNER_REQUEST_PRIORITIZE_LIST_URL"),
      queryString.stringify(filterParams),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([put(actions.allRequestDataLoading(false))]);
        if (start) {
          let temparr = allRequestsData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.allRequestDataLoading(false)),
            put(actions.setAllRequestData(responseData.data)),
          ]);
        } else {
          yield all([
            put(actions.allRequestDataLoading(false)),
            put(actions.setAllRequestData(responseData.data)),
          ]);
        }
      } else {
        yield all([put(actions.allRequestDataLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.allRequestDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Request Prioritize",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.allRequestDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Request Prioritize",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.allRequestDataLoading(false))]);
  }
}

function* getWipRequestListSaga(action) {
  let {
    filters,
    start,
    length,
    clientAccessToken,
  } = action.payload;
  yield all([put(actions.allRequestDataLoading(true))]);
  const { allRequestsData, clientSelectedData,sortByFilter, sortDir } = yield select(
    (state) => state.designerRequest
  );
  const filterParams = {
    client_id: clientSelectedData,
    ...filters,
    start: start,
    length: length,
    sort_by: sortByFilter,
    sort_dir: sortDir,
  };
  try {
    const response = yield call(
      getApiToken,
      apiUrl("DESIGNER_WIP_REQUEST_URL"),
      queryString.stringify(filterParams, {arrayFormat: 'bracket'}),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([put(actions.allRequestDataLoading(false))]);
        if (start) {
          let temparr = allRequestsData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.allRequestDataLoading(false)),
            put(actions.setAllRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        } else {
          yield all([
            put(actions.allRequestDataLoading(false)),
            put(actions.setAllRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        }
      } else {
        yield all([put(actions.allRequestDataLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.allRequestDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get WIP Request Data",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.allRequestDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Get WIP Request Data",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.allRequestDataLoading(false))]);
  }
}

function* getClosedRequestListSaga(action) {
  let {
    filters,
    start,
    length,
    clientAccessToken,
  } = action.payload;
  yield all([put(actions.allRequestDataLoading(true))]);
  const { allRequestsData, clientSelectedData,sortByFilter, sortDir } = yield select(
    (state) => state.designerRequest
  );
  const filterParams = {
    client_id: clientSelectedData,
    ...filters,
    start: start,
    length: length,
    sort_by: sortByFilter,
    sort_dir: sortDir
  };
  try {
    const response = yield call(
      getApiToken,
      apiUrl("DESIGNER_CLOSED_REQUEST_URL"),
      queryString.stringify(filterParams, {arrayFormat: 'bracket'}),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([put(actions.allRequestDataLoading(false))]);
        if (start) {
          let temparr = allRequestsData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.allRequestDataLoading(false)),
            put(actions.setAllRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        } else {
          yield all([
            put(actions.allRequestDataLoading(false)),
            put(actions.setAllRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        }
      } else {
        yield all([put(actions.allRequestDataLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.allRequestDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get Closed Request Data",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.allRequestDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Get Closed Request Data",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.allRequestDataLoading(false))]);
  }
}

function* getNeedsInfoRequestListSaga(action) {
  let {
    filters,
    start,
    length,
    clientAccessToken,
  } = action.payload;
  yield all([put(actions.allRequestDataLoading(true))]);
  const { allRequestsData, clientSelectedData,sortByFilter, sortDir } = yield select(
    (state) => state.designerRequest
  );
  const filterParams = {
    client_id: clientSelectedData,
    ...filters,
    start: start,
    length: length,
    sort_by: sortByFilter,
    sort_dir: sortDir,
  };
  try {
    const response = yield call(
      getApiToken,
      apiUrl("DESIGNER_NEEDS_INFO_REQUEST_URL"),
      queryString.stringify(filterParams, {arrayFormat: 'bracket'}),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([put(actions.allRequestDataLoading(false))]);
        if (start) {
          let temparr = allRequestsData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.allRequestDataLoading(false)),
            put(actions.setAllRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        } else {
          yield all([
            put(actions.allRequestDataLoading(false)),
            put(actions.setAllRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        }
      } else {
        yield all([put(actions.allRequestDataLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.allRequestDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get Closed Request Data",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.allRequestDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Get Closed Request Data",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.allRequestDataLoading(false))]);
  }
}

function* getMyWipRequestDataSaga(action) {
  let {
    filters,
    start,
    length,
    clientAccessToken,
  } = action.payload;
  yield all([put(actions.myOpenRequestDataLoading(true))]);
  const { myOpenRequestData, sortByFilter, sortDir } = yield select((state) => state.designerRequest);
  if(filters['client_ids']){
    filters['client_ids'] = filters['client_ids'].toString();
  }
  const filterParams = {
    ...filters,
    start: start,
    length: length,
    sort_by: sortByFilter,
    sort_dir: sortDir,
  };
  try {
    const response = yield call(
      getApiToken,
      apiUrl("DESIGNER_MY_WIP_REQUEST_URL"),
      queryString.stringify(filterParams, {arrayFormat: 'bracket'}),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        let temparrComp = responseData.data?.companies;
        let companies = [];
        temparrComp.forEach(function(i){
          companies.push({id:i.client_id,name:i.company_name})
        })
        if (start) {
          let temparr = myOpenRequestData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.myOpenRequestDataLoading(false)),
            put(actions.setMyopenRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
              client_ids: companies || [],
            })),
          ]);
        } else {
          yield all([
            put(actions.myOpenRequestDataLoading(false)),
            put(actions.setMyopenRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
              client_ids: companies || [],
            })),
          ]);
        }
      } else {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get My WIP Request Data",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myOpenRequestDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Get My WIP Request Data",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myOpenRequestDataLoading(false))]);
  }
}

function* getMyNeedsInfoRequestListSaga(action) {
  let {
    filters,
    start,
    length,
    clientAccessToken,
  } = action.payload;
  yield all([put(actions.myOpenRequestDataLoading(true))]);
  const { myOpenRequestData, sortByFilter, sortDir } = yield select((state) => state.designerRequest);
  if(filters['client_ids']){
    filters['client_ids'] = filters['client_ids'].toString();
  }
  const filterParams = {
    ...filters,
    start: start,
    length: length,
    sort_by: sortByFilter,
    sort_dir: sortDir,
  };
  try {
    const response = yield call(
      getApiToken,
      apiUrl("DESIGNER_MY_NEEDS_INFO_REQUEST_URL"),
      queryString.stringify(filterParams, {arrayFormat: 'bracket'}),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        let temparrComp = responseData.data?.companies;
        let companies = [];
        temparrComp.forEach(function(i){
          companies.push({id:i.client_id,name:i.company_name})
        })
        if (start) {
          let temparr = myOpenRequestData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.myOpenRequestDataLoading(false)),
            put(actions.setMyopenRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
              client_ids: companies || [],
            })),
          ]);
        } else {
          yield all([
            put(actions.myOpenRequestDataLoading(false)),
            put(actions.setMyopenRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
              client_ids: companies || [],
            })),
          ]);
        }
      } else {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get My Needs Info Request Data",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myOpenRequestDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Get My Needs Info Request Data",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myOpenRequestDataLoading(false))]);
  }
}

function* getNeedsApprovalRequestListSaga(action) {
  let {
    filters,
    start,
    length,
    clientAccessToken,
  } = action.payload;
  yield all([put(actions.myOpenRequestDataLoading(true))]);
  const { myOpenRequestData, sortByFilter, sortDir } = yield select((state) => state.designerRequest);
  if(filters['client_ids']){
    filters['client_ids'] = filters['client_ids'].toString();
  }
  const filterParams = {
    ...filters,
    start: start,
    length: length,
    sort_by: sortByFilter,
    sort_dir: sortDir,
  };
  try {
    const response = yield call(
      getApiToken,
      apiUrl("DESIGNER_NEEDS_APPROVAL_REQUEST_URL"),
      queryString.stringify(filterParams, {arrayFormat: 'bracket'}),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        let temparrComp = responseData.data?.companies;
        let companies = [];
        temparrComp.forEach(function(i){
          companies.push({id:i.client_id,name:i.company_name})
        })
        if (start) {
          let temparr = myOpenRequestData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.myOpenRequestDataLoading(false)),
            put(actions.setMyopenRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
              client_ids: companies || [],
            })),
          ]);
        } else {
          yield all([
            put(actions.myOpenRequestDataLoading(false)),
            put(actions.setMyopenRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
              client_ids: companies || [],
            })),
          ]);
        }
      } else {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get My Delivered Request Data",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myOpenRequestDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Get My Delivered Request Data",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myOpenRequestDataLoading(false))]);
  }
}

function* getMyClosedRequestListSaga(action) {
  let {
    filters,
    start,
    length,
    clientAccessToken,
  } = action.payload;
  yield all([put(actions.myOpenRequestDataLoading(true))]);
  const { myOpenRequestData, sortByFilter, sortDir } = yield select((state) => state.designerRequest);
  if(filters['client_ids']){
    filters['client_ids'] = filters['client_ids'].toString();
  }
  const filterParams = {
    ...filters,
    start: start,
    length: length,
    sort_by: sortByFilter,
    sort_dir: sortDir,
  };
  try {
    const response = yield call(
      getApiToken,
      apiUrl("DESIGNER_MY_CLOSED_REQUEST_URL"),
      queryString.stringify(filterParams, {arrayFormat: 'bracket'}),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        let temparrComp = responseData.data?.companies;
        let companies = [];
        temparrComp.forEach(function(i){
          companies.push({id:i.client_id,name:i.company_name})
        })
        if (start) {
          let temparr = myOpenRequestData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.myOpenRequestDataLoading(false)),
            put(actions.setMyopenRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
              client_ids: companies || [],
            })),
          ]);
        } else {
          yield all([
            put(actions.myOpenRequestDataLoading(false)),
            put(actions.setMyopenRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
              client_ids: companies || [],
            })),
          ]);
        }
      } else {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get My Closed Request Data",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myOpenRequestDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Get My Closed Request Data",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myOpenRequestDataLoading(false))]);
  }
}

function* getMyAllRequestDataSaga(action) {
  let {
    filters,
    start,
    length,
    clientAccessToken,
  } = action.payload;
  yield all([put(actions.myOpenRequestDataLoading(true))]);
  const { myOpenRequestData, sortByFilter, sortDir } = yield select((state) => state.designerRequest);
  if(filters['client_ids']){
    filters['client_ids'] = filters['client_ids'].toString();
  }
  const filterParams = {
    ...filters,
    start: start,
    length: length,
    sort_by: sortByFilter,
    sort_dir: sortDir,
  };
  try {
    const response = yield call(
      getApiToken,
      apiUrl("DESIGNER_MYALL_REQUEST_URL"),
      queryString.stringify(filterParams, {arrayFormat: 'bracket'}),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        let temparrComp = responseData.data?.companies;
        let companies = [];
        temparrComp.forEach(function(i){
          companies.push({id:i.client_id,name:i.company_name})
        })
        if (start) {
          let temparr = myOpenRequestData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.myOpenRequestDataLoading(false)),
            put(actions.setMyopenRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
              client_ids: companies || [],
            })),
          ]);
        } else {
          yield all([
            put(actions.myOpenRequestDataLoading(false)),
            put(actions.setMyopenRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
              client_ids: companies || [],
            })),
          ]);
        }
      } else {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myOpenRequestDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get MyopenRequest Data",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myOpenRequestDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Get MyopenRequest Data",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myOpenRequestDataLoading(false))]);
  }
}

function* getAllRequestListSaga(action) {
  let {
    filters,
    start,
    length,
    clientAccessToken,
  } = action.payload;
  yield all([put(actions.allRequestDataLoading(true))]);
  const { allRequestsData, clientSelectedData,sortByFilter,sortDir } = yield select(
    (state) => state.designerRequest
  );
  const filterParams = {
    client_id: clientSelectedData,
    ...filters,
    start: start,
    length: length,
    sort_by: sortByFilter,
    sort_dir: sortDir,
  };
  try {
    const response = yield call(
      getApiToken,
      apiUrl("DESIGNER_ALL_REQUEST_URL"),
      queryString.stringify(filterParams, {arrayFormat: 'bracket'}),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([put(actions.allRequestDataLoading(false))]);
        if (start) {
          let temparr = allRequestsData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.allRequestDataLoading(false)),
            put(actions.setAllRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        } else {
          yield all([
            put(actions.allRequestDataLoading(false)),
            put(actions.setAllRequestData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        }
      } else {
        yield all([put(actions.allRequestDataLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.allRequestDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get AllRequest Data",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.allRequestDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Get AllRequest Data",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.allRequestDataLoading(false))]);
  }
}