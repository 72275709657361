/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link, useHistory } from "react-router-dom";
import { actions as AuthActions } from "../../redux/reducers/AuthReducer";
import { actions as designerRequestActions } from "../../redux/reducers/DesignerRequestReducer";
import ReactTooltip from "react-tooltip";
import Myprofile from "../MyProfile";
import "./index.scss";

function NavbarDesigner() {
  const dropDownRef = useRef();
  const { user } = useSelector((state) => state.auth);
  const [showDropdown, setShowDropdown] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["clientAccessToken"]);
  const { logoutSuccess, logoutOnTokenExpire, logoutFailure } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = useState(false);

  const logoutClick = (e) => {
    e.preventDefault();
    setShowDropdown(false);
    dispatch(AuthActions.logout(cookies.clientAccessToken));
  };

  const handleProfileClick = (e) => { e.preventDefault(); setShow(true); };

  useEffect(() => {
    if (logoutSuccess || logoutOnTokenExpire) {
      removeCookie("clientAccessToken", { path: "/" });
      dispatch(AuthActions.clearLogoutFlags());
      history.push("/login");
    }
    if (logoutFailure) {
      removeCookie("clientAccessToken", { path: "/" });
      dispatch(AuthActions.clearLogoutFlags());
      history.push("/login");
    }
  }, [logoutSuccess, logoutOnTokenExpire, logoutFailure]);

  const handleClick = (e) => {
    if (dropDownRef.current && !dropDownRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, []);

  const clearFilter =()=>{
    dispatch(designerRequestActions.updateFilters({}));
  }

  return (
    <header className="header">
      <div className="container cf">
        <span className="nav-toggle">
          <span>Toggle Nav</span>
        </span>
        <Link to="/app" className="mainlogo" title="KitchenDesign365">
          <img
            src={require("../../assets/images/Black-Logo-V3.svg")}
            alt="KitchenDesign365"
          />
        </Link>
        <div
          ref={dropDownRef}
          onClick={() => setShowDropdown(true)}
          className="right-btn profile_ddown"
        >
          <a data-tip={user.email} href="#" className="user-name">
            {user && user.name ? user.name.substring(0, 2):""}
          </a>
          <ReactTooltip />
          <ul className={`account-linkd${showDropdown ? " active" : ""}`}>
            {user?.client_type !== "standard" && <li><a href="#" onClick={handleProfileClick} title="My Profile">My Profile</a></li>}
            {user?.client_type !== "enterprise" && <li><Link to="/app/client/myaccount" className="view_link">My Account</Link></li>}
            <li>
              <a href="#" title="Sign Out" onClick={logoutClick}>
                Sign Out
              </a>
            </li>
          </ul>
          <div className={`image-popup-outer ${show ? "show" : ""}`}>
            <div className="image-popup-inner">
              <span className="close-btn" onClick={(e) => { setShow(!show); e.stopPropagation() }}></span>
              <div className="image-box">
                <Myprofile popupStatus={show} popupStatusHandler={setShow} />
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar">
          <ul>
            <li>
              <NavLink
                to="/app/designer/myrequest/"
                title="My Requests"
                activeClassName="active-link"
                onClick={clearFilter}
              >
                My Requests
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/app/designer/allclients/"
                title="All Clients"
                activeClassName="active-link"
                onClick={clearFilter}
              >
                All Clients
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default NavbarDesigner;
