import React, { memo } from 'react'
import moment from 'moment'
import { useSelector } from "react-redux";

function DesignRequests() {
    const { myAccountReducer: { getAccountInfoResponse: { data: { no_of_request_created, plan_type, remaining_request, plan_valid_till, free_sample_designs } = {}, message = "" } = {} } } = useSelector((state) => state);
    return (
        <div className='right-info-block account-info-contact'>
            <div className='inner-info-block'>
                <div className='info-title'>
                    <h4>2020 Design Requests Subscription Stats</h4>
                </div>
                <ul className='contact-info'>
                    {(plan_type === "request" || plan_type === "both") && <li><span>No. of Requests Created: <i>({no_of_request_created})</i></span></li>}
                    {(plan_type === "request" || plan_type === "both") && <li><span>No. of Requests Remaining: <i>({remaining_request})</i></span></li>}
                    {(plan_type === "days" || plan_type === "both") && <li><span>Valid Till : <i>{moment(plan_valid_till).format('DD/MM/YYYY')}</i></span></li>}
                    {plan_type === "" && <li><span>{message}</span></li>}
                </ul>
                {/* <a href='#' className='common-button'>CREATE REQUEST</a> */}
            </div>
            {free_sample_designs && free_sample_designs.status==1 &&<div className='inner-info-block'>  
                <div className='info-title'>
                    <h4>Free Sample Design</h4>
                </div>
                <ul className='contact-info'>
                   <li>Trial Mode- {free_sample_designs.data.trial_mode}</li>
                   <li>Design Submitted- {free_sample_designs.data.statistics.remaining}/{free_sample_designs.data.statistics.total}</li>
                </ul>
                {/* <a href='#' className='common-button'>CREATE REQUEST</a> */}
            </div>}
        </div>
    )
}
export default memo(DesignRequests)