/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import AddBillingAddressBook from './AddBillingAddressBook'
import PreviouslySavedAddresses from './PreviouslySavedAddresses'
import { actions as AuthActions } from "../../../redux/reducers/MyAccountReducer";

export default function AddressBook() {
    const dispatch = useDispatch();
    const [cookies] = useCookies(["clientAccessToken"]);
    const [showBillingAddress, setShowBillingAddress] = useState("");

    const {  getClientAddressResponse  } = (useSelector(state => state?.myAccountReducer) || {});

    const getClientAddressData = () => {
        dispatch(AuthActions.getClientAddress({ clientAccessToken: cookies.clientAccessToken }));
    }

    useEffect(() => {
        getClientAddressData();
        return ()=>{
            dispatch(AuthActions.clearEditClientAddressFlags());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleToggleBillingAddressBook = (type = "") => {
        setShowBillingAddress(type)
    }
    return (
        <div className='account-info-block address-book-block'>
            <div className='left-info-block account-info-contact'>
                {!showBillingAddress && <button className={`common-button ${getClientAddressResponse.data?.dataTable?.data[0] ?"":'center-button'}`} onClick={() => { handleToggleBillingAddressBook("add") }} >ADD NEW</button>}
                {showBillingAddress && <AddBillingAddressBook handleToggleBillingAddressBook={()=>{handleToggleBillingAddressBook()}} showBillingAddress={showBillingAddress} />}
                {!showBillingAddress && <PreviouslySavedAddresses handleToggleBillingAddressBook={()=>{handleToggleBillingAddressBook("edit")}} />}
            </div>
        </div>
    )
}
