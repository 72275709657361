import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import moment from 'moment'
import { actions as AuthActions } from "../../../redux/reducers/MyAccountReducer";
import LoaderComponent from "../../../components/Loader";
import { ReactBootstrapTable } from '../../ReusableComponents';
import Viewinvoice from './Viewinvoice';

const currentSeletedRowRow = (key) => key.hasOwnProperty("order");

function headerFormatter(column, colIndex, p) {
    const { sortElement, filterElement } = p;
    return (
        <div className={`${currentSeletedRowRow(sortElement.props) ? "sort-active" : ""}`}>
            {filterElement}
            <span>{column.text}</span>
            {sortElement}
        </div>
    );
}

function MyInvoices() {
    const dispatch = useDispatch();
    const [cookies] = useCookies(["clientAccessToken"]);
    const { getInvoicesLoading, getInvoicesResponse } = (useSelector(state => state?.myAccountReducer) || {});
    const [currentPage, setCurrentPage] = useState(1)
    const [sortState, setSortState] = useState({ dataField: "created_at", order: "desc" })
    const [isOpen, setShow] = useState(false);

    const handleToggleModel = () => {
        setShow(!isOpen)
    }

    const tableColumns = [
        {
            dataField: "id",
            text: "Invoice No",
            sort: true,
            headerFormatter

        },
        {
            dataField: "name",
            text: "Plan",
            sort: true,
            headerFormatter,
            formatter: (cellContent, row) => {
                return <div>{(row?.name || "")}</div>
            }
        },
        {
            dataField: "price",
            text: "Amount Paid",
            sort: true,
            headerFormatter,
            formatter: (cellContent, row) => {
                return row?.price ? <div>${(row?.price || "")}</div> : ""
            }
        },
        {
            dataField: "created_at",
            text: "Created On",
            sort: true,
            headerFormatter,
            formatter: (cellContent, row) => {
                return row.created_at ? <div>{moment(row.created_at).format('MM/DD/YYYY')}</div> : ""
            }
        },
        {
            dataField: "action",
            text: "Action",
            formatter: (cellContent, row) => {
                return (
                    <div className='invoice-action'>
                        <button className='invoice-link'
                            onClick={() => {
                                dispatch(AuthActions.viewInvoice({ id: row.id, clientAccessToken: cookies.clientAccessToken }));
                                handleToggleModel();
                            }}
                        >View Invoice</button>
                        {/* <a href=""> */}
                        <img src={require("../../../assets/images/download-icon.svg")} alt="Download"
                            onClick={() => {
                                dispatch(AuthActions.generateInvoice({ id: row.id, clientAccessToken: cookies.clientAccessToken }));
                            }}
                        />
                        {/* </a> */}
                    </div>
                );
            },

        },

    ];

    return (
        <div>
            <LoaderComponent showhide={getInvoicesLoading} />
            <Viewinvoice
                isOpen={isOpen}
                handleToggleModel={handleToggleModel}
            />
            <ReactBootstrapTable
                listLoading={false}
                entities={(getInvoicesResponse?.data?.dataTable?.data || [])}
                columns={tableColumns}
                // defaultProperty={defaultProperty}
                paginationOptions={{
                    custom: true,
                    page: currentPage,
                    paginationStartIndex: 1,
                    sizePerPage: 10,
                    totalSize: getInvoicesResponse?.data?.dataTable?.recordsTotal
                }}
                // handleCallBackSearch={(b) => {
                //     let searchText = (b.searchText || "").trim()
                //     if (searchText.length === 10 && searchText.split('/').length === 3) {
                //         dispatch(AuthActions.getInvoices({
                //             tableObj: {
                //                 ...b, length: b.sizePerPage, searchText: moment(searchText).format('YYYY-DD-MM')
                //             }, clientAccessToken: cookies.clientAccessToken
                //         }));
                //     } else {
                //         dispatch(AuthActions.getInvoices({ tableObj: { ...b, length: b.sizePerPage, searchText }, clientAccessToken: cookies.clientAccessToken }));

                //     }
                // }}
                handleTableChange={(a, b) => {
                    setSortState({ dataField: b.sortField, order: b.sortOrder })
                    setCurrentPage(b.page)
                    dispatch(AuthActions.getInvoices({ tableObj: { ...b, length: b.sizePerPage }, clientAccessToken: cookies.clientAccessToken }));
                }}
                sortState={sortState}
            />
        </div>
    )
}
export default MyInvoices