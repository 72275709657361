import React, {useEffect} from "react";
import { useSelector,useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { actions as AuthActions } from '../../redux/reducers/AuthReducer';
import "./index.scss";
import ResetPasswordForm from "../../components/RegisterationForm";
import LoaderComponent from "../../components/Loader";

function Registeration() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { setSuccessForPassword, setPasswordLoading } = useSelector(state => state.auth);
   useEffect(() => {
   if(setSuccessForPassword){
   dispatch(AuthActions.clearSetPasswordFlags());
   history.push("/login");
   }
  });
  const restClicked = (email,password,confirmPassword,token) => {
    dispatch(AuthActions.setPasswordForRegisteration(email,password,confirmPassword,token));
  }
  return (
    <div>
      <LoaderComponent showhide={setPasswordLoading} />
        <div className="login-wrapper">
          <div className="login-left">
            <ResetPasswordForm restClicked={(email,password,confirmPassword,token) =>restClicked(email,password,confirmPassword,token)}/>
          </div>
          <div className="login-right">
            <div className="login-title">
              <h2>
                Creating Beautiful<span>Kitchen Designs</span>
              </h2>
              <img
              src={require("../../assets/images/cabinet-img02.svg")}
              alt="Kitchen Cabinet Design"
            />
            </div>
            <div className="kitchen-cabinet">
              <img
                src={require("../../assets/images/kitchen-cabinet.png")}
                alt="Kitchen Cabinet Design"
              />
            </div>
          </div>
        </div>
    </div>
  );
}

export default Registeration;
