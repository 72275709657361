/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as designerRequestActions } from "../../redux/reducers/DesignerRequestReducer";
import { useCookies } from "react-cookie";

import "./index.scss";
import Filters from "../Filters";

function ClientRequestsHeader({
  showClientName,
  allRequest,
  requestDelivered,
  requestStatusSection,
  requestSection,
}) {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const {
    filtersDropdownData,
    length,
    allRequestsData,
    sortByFilter,
    filters,
    //activeSection,
    filterNotChangeByApi,
    priorityForAllTab,
    sortDir,
  } = useSelector((state) => state.designerRequest);
  const { user, indicationStatus } = useSelector((state) => state.auth);
  const filterComponentRef = useRef();
  // const [getIsComponentMounted, setIsComponentMounted] = useState(false);
  useEffect(() => {
    if (filterComponentRef.current && filterComponentRef.current.clearAllFilters && typeof filterComponentRef.current.clearAllFilters === 'function') {
      if (filters.request_status) {
        delete filters.request_status;
      }
      dispatch(designerRequestActions.updateFilters(filters));
      filterComponentRef.current.clearAllFilters(1);
    }
  }, [requestStatusSection]);
  const handleFilterChange = (filtersData, isUnmountCall = false) => {
    if (filters.request_status && requestStatusSection !== "all-requests") {
      filtersData['request_status'] = filters.request_status
    }
    dispatch(designerRequestActions.updateFilters(filtersData));
    if (!isUnmountCall) {
      dispatch(designerRequestActions.changeStartFromMyOpenRequest(0));

    }
  };

  useEffect(() => {
    if (filterNotChangeByApi) {
      getRequestList(filters);
      dispatch(designerRequestActions.setFilterNotChangeByApi(false));
    }
  }, [filterNotChangeByApi]);

  useEffect(() => {
    if (allRequest && requestStatusSection !== "closed" && priorityForAllTab) {
      dispatch(designerRequestActions.changeSortByFilter('prioritise_order'));
    } else {
      dispatch(designerRequestActions.changeSortByFilter('created_at'));
    }
  }, []);

  useEffect(() => {
    if (allRequest && requestStatusSection !== "closed" && priorityForAllTab) {
      dispatch(designerRequestActions.changeSortByFilter('prioritise_order'));
    } else {
      dispatch(designerRequestActions.changeSortDir("ASC"));
      dispatch(designerRequestActions.changeSortByFilter('created_at'));
    }
  }, [requestStatusSection])

  useEffect(() => {
    if (allRequest && requestStatusSection !== "closed" && priorityForAllTab) {
      dispatch(designerRequestActions.changeSortByFilter('prioritise_order'));
      getRequestList(filters);
    } else {

      dispatch(designerRequestActions.changeSortByFilter('created_at'));
      getRequestList(filters);
    }
  }, [priorityForAllTab])

  useEffect(() => {
    if (indicationStatus && indicationStatus.is_request_order_changed && allRequest) {
      dispatch(designerRequestActions.changeStartFromMyOpenRequest(0));
      getRequestList(filters);
    }
  }, [indicationStatus])

  const sortByChange = (e) => {
    if (e.target.value === "updated_at") {
      dispatch(designerRequestActions.changeSortDir("DESC"));
    } else {
      dispatch(designerRequestActions.changeSortDir("ASC"));
    }
    dispatch(designerRequestActions.changeStartFromMyOpenRequest(0));
    dispatch(designerRequestActions.changeSortByFilter(e.target.value));
    getRequestList(filters);
  }

  let filterOptions = ['request_title', 'request_number', 'request_type', 'request_status', 'created_by', 'assign_to', 'start_date', 'end_date', 'due_start_date', 'due_end_date', 'client_ids', 'has_updates'];
  if (user?.position_id === 3 || user?.position_id === 4 || user?.position_id === 5) {
    // Client position is `Sr. Designer` OR `Designer` OR `Jr. Designer`.
    filterOptions = ['request_title', 'request_number', 'request_type', 'request_status', 'created_by', 'start_date', 'end_date', 'due_start_date', 'due_end_date', 'client_ids', 'has_updates']
  }
  const getRequestList = (appliedFilter) => {
    if (allRequest && requestStatusSection === "open-requests") {

      dispatch(
        designerRequestActions.getAllRequestData(
          appliedFilter,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (allRequest && requestStatusSection === "all-requests") {
      dispatch(
        designerRequestActions.getAllRequestList(
          appliedFilter,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (allRequest && requestStatusSection === "wip") {
      dispatch(
        designerRequestActions.getWipRequestList(
          appliedFilter,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (allRequest && requestStatusSection === "needs-information") {
      dispatch(
        designerRequestActions.getNeedsInfoRequestList(
          appliedFilter,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (allRequest && requestStatusSection === "closed") {
      dispatch(
        designerRequestActions.getClosedRequestList(
          appliedFilter,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (requestStatusSection === "open-requests") {

      dispatch(
        designerRequestActions.getMyopenRequestData(
          appliedFilter,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (requestStatusSection === "all-requests") {

      dispatch(
        designerRequestActions.getMyAllRequestList(
          appliedFilter,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (requestStatusSection === "wip") {
      dispatch(
        designerRequestActions.getMyWipRequestList(
          appliedFilter,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    }
    else if (requestStatusSection === "needs-information") {
      dispatch(
        designerRequestActions.getMyNeedsInfoRequestList(
          appliedFilter,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    }
    else if (requestStatusSection === "priortize") {
      dispatch(
        designerRequestActions.getMyNeedsInfoRequestList(
          appliedFilter,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (requestSection) {
      dispatch(
        designerRequestActions.getPrioritizeRequestData(
          0,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (requestStatusSection === "closed") {
      dispatch(
        designerRequestActions.getMyClosedRequestList(
          appliedFilter,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    }
    else if (requestStatusSection === "needs-approval") {
      dispatch(
        designerRequestActions.getNeedsApprovalRequestList(
          appliedFilter,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    }
    else if (requestDelivered) {
      dispatch(
        designerRequestActions.getNeedsApprovalData(
          appliedFilter,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    }
  }
  const filterOnchange = (id, isSold = false) => {
    let tempFilter = filters;
    if (id) {
      tempFilter['request_status'] = id;
      tempFilter['is_sold'] = isSold ? 1 : 0
    } else {
      delete tempFilter.request_status;
      delete tempFilter.is_sold;
    }
    dispatch(designerRequestActions.updateFilters(tempFilter));
    dispatch(designerRequestActions.changeStartFromMyOpenRequest(0));
    getRequestList(tempFilter);
  }
  const changeOrder = (v) => {
    if (v === "ASC") {
      dispatch(designerRequestActions.changeSortDir("DESC"));
      getRequestList(filters);
    } else {
      dispatch(designerRequestActions.changeSortDir("ASC"));
      getRequestList(filters);
    }
  }
  return (
    <>
      {!requestDelivered && showClientName && allRequestsData['request_status'] && allRequestsData['request_status'].length && requestStatusSection !== "all-requests" ? <div className="container cf sub-status" style={{ marginBottom: "40px", marginTop: 0 }}>
        {allRequestsData['request_status'] && allRequestsData['request_status'].length && requestStatusSection !== "all-requests" ?
          <button className={"btn" + (filters?.request_status === "" || !filters['request_status'] ? " active" : "")} onClick={() => { filterOnchange("") }}>All</button> : null}
        {allRequestsData['request_status'] && allRequestsData['request_status'].map((data, index) => (
          <>
            <button
              key={"status" + data.id}
              className={"btn" + (filters?.request_status === data.id && !filters?.is_sold ? " active" : "")}
              onClick={() => { filterOnchange(data.id) }}>
              {data.name === 'Approve' ? 'Approved' : data.name === 'Close' ? 'Closed' : data.name}
            </button>
            {data?.name === 'Approve' ?
              <button
                key={"status" + data.id}
                className={"btn" + (filters?.request_status === data.id && filters?.is_sold ? " active" : "")}
                onClick={() => { filterOnchange(data.id, true) }}>
                SOLD
              </button>
              : null
            }
          </>

        ))}
      </div> : <div style={{ marginBottom: "25px" }}></div>}
      <div
        style={{ marginTop: showClientName ? "0px" : "40px" }}
        className="clientrequestsheader cf"
      >
        <div className="container">
          <h1
            style={{ display: showClientName ? "" : "none" }}
            className="client-name"
          >
            {allRequestsData.Client}
          </h1>
          {!requestSection && requestStatusSection !== "priortize" && <Filters
            ref={filterComponentRef}
            onChangeHandler={handleFilterChange}
            requestTypeList={filtersDropdownData.request_types}
            statusList={filtersDropdownData.request_status}
            createdByList={filtersDropdownData.created_by}
            assignedToList={filtersDropdownData.assign_to}
            filterOptions={filterOptions}
            companyList={filtersDropdownData.client_ids}
            allRequest={requestDelivered ? true : allRequest}
            requestStatusSection={requestStatusSection}
          />}
          {sortByFilter !== "prioritise_order" && !requestSection && requestStatusSection !== "priortize" && <div className="headermonthselect" style={{ display: 'inline' }}>
            <button style={{ float: "right", marginLeft: "10px" }} type="button" className="sort-dir-btn" onClick={() => { changeOrder(sortDir) }} value={sortDir}>{sortDir === "ASC" ?
              <>
                <img src={require("../../assets/images/arrow-up.svg")} alt="arrow-up" />
                <img src={require("../../assets/images/arrow-down.svg")} alt="arrow-down" />
              </>
              :
              <>
                <img src={require("../../assets/images/arrow-up-black.svg")} alt="arrow-up" />
                <img src={require("../../assets/images/arrow-down-orange.svg")} alt="arrow-down" />
              </>}
            </button>
          </div>}
          {!requestSection && requestStatusSection !== "priortize" && <div className="headermonthselect" style={{ display: 'inline' }}>
            <select
              className="form-control"
              id="month"
              onChange={sortByChange}
              value={sortByFilter}
              style={{ float: 'right', width: 'auto' }}
            >
              {allRequest && requestStatusSection !== 'closed' && priorityForAllTab ? <option key="prioritise_order" value="prioritise_order">
                Sequence
              </option> : null}
              <option key="created_at" value="created_at">
                Created Date
              </option>
              <option key="updated_at" value="updated_at">
                Updated Date
              </option>
              <option key="due_date" value="due_date">
                Due Date
              </option>
            </select>
            <span className="sort-by">Sort By: </span>
          </div>}

        </div>
      </div>
    </>
  );
}

export default ClientRequestsHeader;