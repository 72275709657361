/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import "./index.scss";

function EditRequestHeader({ data, requestId }) {
  const history = useHistory();
  const closeEditRequest = () => {
    history.push("/app/client/myrequests/viewrequest/" + requestId);
  };
  useEffect(() => {
    if (data) {
      if (data["request_status"]["status"][0]["slug"] == "approved") {
        history.push("/app/client/404");
      }
    }
  }, [data]);

  return (
    <div className="editrequestheader">
      <h1>
        Edit Request: <span>#{data.request_number}</span>
      </h1>
      <span onClick={closeEditRequest} className="close"></span>
    </div>
  );
}

export default EditRequestHeader;
