/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-date-picker";
import ReactQuill from "react-quill";
import { useCookies } from "react-cookie";
import ShortUniqueId from "short-unique-id";
import { useHistory } from "react-router-dom";
import { actions as myRequestActions } from "../../redux/reducers/MyRequestReducer";
import { actions as generalActions } from "../../redux/reducers/GeneralReducer";
import { quillOptionsCongif, convertStringToHtml } from "../../Utils/utils";
import 'quill-paste-smart';
import "./index.scss";

/* import apiUrl from "../../config/urls";
import { postApiTokenFormData } from "../../redux/apis";
import uploadIcon from "../../assets/images/upload.jpg"; */

function EditRequestAdditionalNotes({ data, requestId }) {

  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const history = useHistory();

  const [currentComment, setCurrentComment] = useState("");

  const uid = new ShortUniqueId();

  const {
    editRequestTitle, editRequestType,
    editRequestUpdatedMedia, editRequestDeletedMedia, editRequestDueDate,
    isAllsectionproperlyLabeled, dynamicFormValues, redirect,dynamicFormdata
  } = useSelector((state) => state.myRequest);

  const { user } = useSelector((state) => state.auth);

  const changeDueDate = (d) => {
    dispatch(myRequestActions.changeEditRequestDueDate(d));
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      if(data.note){
        setCurrentComment(convertStringToHtml(data?.note))
      }else{
        setCurrentComment(convertStringToHtml(""))
      }
    }
    if (data && data["due_date"]) {
      dispatch(
        myRequestActions.changeEditRequestDueDate(new Date(data["due_date"]))
      );
    }
  }, [data]);

  useEffect(() => {
    if (redirect) {
      history.push("/app/client/myrequests/viewrequest/" + requestId);
      dispatch(myRequestActions.redirectPage(false));
    }
  }, [redirect]);

  const updateRequestDetail = () => {
    var date = new Date(editRequestDueDate),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    var updatedMedia = {
      kitchen_measurement: [],
      kitchen_photo: [],
      kit_file: [],
    };
    Object.keys(editRequestUpdatedMedia).map((key, index) => {
      if (editRequestUpdatedMedia[key].length) {
        for (var i = 0; i < editRequestUpdatedMedia[key].length; i++) {
          if(updatedMedia[key]){
            updatedMedia[key].push({ id: editRequestUpdatedMedia[key][i]["id"] });
          }
        }
      }
    });
    let submitData = {
      client_id: user.parent_id ? user.parent_id : user.id,
      request_type: editRequestType,
      request_id: requestId,
      updated_media: updatedMedia,
      deleted_media: editRequestDeletedMedia,
      request_title: editRequestTitle,
      due_date: [date.getFullYear(), mnth, day].join("-"),
      dynamic_form_data: dynamicFormValues,
      additional_note: currentComment,
      is_all_section_properly_labeled: isAllsectionproperlyLabeled,
      is_direct: false,
    };
    let requiredFilevalidation = false,
      kitfilevalidation = false,
      requiredValidation = false,
      requiredDueDate = false;
    // if (updatedMedia["kitchen_measurement"].length) {
    //   requiredFilevalidation = false;
    // } else {
    //   requiredFilevalidation = true;
    // }
    // for (var i = 0; i < data["request_type_dropdown"].length; i++) {
    //   if (editRequestType == data["request_type_dropdown"][i]["id"]) {
    //     if (data["request_type_dropdown"][i]["slug"] === "marketing-packet") {
    //       if (updatedMedia["kit_file"].length) {
    //         kitfilevalidation = false;
    //       } else {
    //         kitfilevalidation = true;
    //       }
    //     }
    //   }
    // }

    if (editRequestTitle) {
      requiredValidation = false;
    } else {
      requiredValidation = true;
    }
    Object.keys(dynamicFormValues).map((key, index) => {
      if (dynamicFormValues[key]["option"]["is_required"]) {
        // if (dynamicFormValues[key]["data"].length > 0) {
        // } else {
        //   requiredValidation = true;
        // }
        if(key == "door-style"){
          
          if(dynamicFormdata[key]["data"] && dynamicFormdata[key]["data"][0]['category_option_value']){
            if (dynamicFormValues[key]["data"].length > 0) {
            } else {
              requiredValidation = true;
            }
          }
        }else{
          if (dynamicFormValues[key]["data"].length > 0) {
          } else {
            requiredValidation = true;
          }
        }
      }
    });

    if(editRequestDueDate){
      requiredDueDate = false;
    }else{
      requiredDueDate = true;
    }

    if (requiredFilevalidation || kitfilevalidation || requiredValidation || requiredDueDate) {
      if (kitfilevalidation || requiredFilevalidation) {
        dispatch(
          generalActions.addToast(
            "Required field",
            "Please upload required file",
            "danger",
            uid()
          )
        );
      }
      if (requiredValidation) {
        dispatch(
          generalActions.addToast(
            "Required field",
            "Please enter required field",
            "danger",
            uid()
          )
        );
      }
      if (requiredDueDate) {
        dispatch(
          generalActions.addToast(
            "Required field",
            "Please enter required field",
            "danger",
            uid()
          )
        );
      }
    } else {
      dispatch(
        myRequestActions.updateRequestDetail(
          submitData,
          cookies.clientAccessToken
        )
      );
    }
  };
  const cancel = () => {
    history.push("/app/client/myrequests/viewrequest/" + requestId);
  };
  return (
    <div className="edit-request-additional-notes">
      <ul>
        <li>
          <strong className="title">Additional Notes</strong>
          <div className="content">
            <ReactQuill
              modules={quillOptionsCongif.modules}
              formats={quillOptionsCongif.formats}
              value={currentComment}
              onChange={(e) => setCurrentComment(e)}
            />
          </div>
        </li>
        <li>
          <strong className="title required">Expected Due Date</strong>
          <div className="content">
            <DatePicker minDate={editRequestDueDate} onChange={changeDueDate} value={editRequestDueDate} />
          </div>
        </li>
      </ul>
      <div className="actions-row">
        <button
          className="btn cancel btn-b-blue"
          title="Cancel"
          onClick={cancel}
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={updateRequestDetail}
          className="btn btn-black update-request"
          title="Update Request"
        >
          Update Request
        </button>
      </div>
    </div>
  );
}

export default EditRequestAdditionalNotes;
