/* eslint-disable eqeqeq */
import React from "react";
import { useSelector } from "react-redux";
import { convertUTCToLocale } from "../../Utils/utils"
import "./index.scss";

function History({ data }) {
  const { historyData } = useSelector((state) => state.myRequest);
  const { user } = useSelector((state) => state.auth);

  const renderDiscussion = () => {
    if (historyData) {
      return (
        <>
          {Object.keys(historyData["datatable"]["data"]).map((key, index) => {
            return (
              <li key={key}>
                <div className="action-details">
                  <span className="date-time">
                    {convertUTCToLocale(historyData["datatable"]["data"][key]["created_at"])?.dateAndTime}
                  </span>
                </div>
                <dl className="history-type">
                  <dt>{historyData["datatable"]["data"][key]["key"]}</dt>
                  <dd>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: historyData["datatable"]["data"][key]["value"],
                      }}
                    />
                  </dd>
                </dl>
              </li>
            );
          })}
        </>
      );
    } else if (data && data["datatable"]) {
      return (
        <>
          {Object.keys(data["datatable"]["data"]).map((key, index) => {
            return (
              <li key={key}>
                <div className="action-details">
                  <span className="date-time">
                    {convertUTCToLocale(data["datatable"]["data"][key]["created_at"])?.dateAndTime}
                  </span>
                </div>
                <dl className="history-type">
                  <dt>{data["datatable"]["data"][key]["key"]}</dt>
                  <dd>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data["datatable"]["data"][key]["value"],
                      }}
                    />
                  </dd>
                </dl>
              </li>
            );
          })}
        </>
      );
    }
  };
  return (
    <div className="tab-content history-conttent">
      <ul className="history-list">
        {renderDiscussion()}
      </ul>
    </div>
  );
}

export default History;
