import * as Yup from "yup";

export const formFields = {
    street: {
        key: "street",
        type: "text",
        name: "street",
        className: "form-control",
        placeholder: "Address Line 1",
        label: "Street Address",
        htmlFor: "street",
        required: true,
    },
    street_2: {
        key: "street_2",
        type: "text",
        className: "form-control",
        name: "street_2",
        placeholder: "Address Line 2",
        // label: "Street Address",
        htmlFor: "street_2",
        required: true,
    },
    country_id: {
        key: "country_id",
        className: "select-box",
        name: "country_id",
        placeholder: "Please select your Country",
        label: "Country",
        htmlFor: "country_id",
        selectkeyObj:{value:"code", text:"name"},
        required: true,
    },
    city: {
        key: "city",
        type: "text",
        name: "city",
        className: "form-control",
        placeholder: "Enter your City Name",
        label: "City",
        htmlFor: "city",
        required: true,
    },
    postcode: {
        key: "postcode",
        type: "text",
        name: "postcode",
        className: "form-control",
        placeholder: "Enter your zip code",
        label: "Zip/Postal Code",
        htmlFor: "postcode",
        required: true,
    },
    state_id: {
        key: "state_id",
        className: "select-box",
        name: "state_id",
        placeholder: "Please select State/Province",
        label: "State/Province",
        htmlFor: "state_id",
        selectkeyObj:{value:"id", text:"name"},
        required: true,
    },

}

export const validationSchemaAddBillingAddressBookForm = Yup.object().shape({
    street: Yup.string().required('Street Address is Required.'),
    // street_2: Yup.string().required('Current Password is Required.'),
    country_id: Yup.string().required('Country is Required.'),
    city: Yup.string().required('City is Required.'),
    postcode: Yup.string().required('Zip/Postal Code is Required.'),
    state_id: Yup.string().required('State/Provinceis Required.'),
   
});