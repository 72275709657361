import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { actions as AuthActions } from "../../redux/reducers/AuthReducer";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../config/config";

function LoginForm({ signInClicked }) {
  const {
    loginWithGoogleSuccessFlag,
    loginWithGoogleUrl,
    setFlagForGoogleCallback,
  } = useSelector((state) => state.auth);
  const { loginFailed, loginErrorMessage } = useSelector(
    (state) => state.general
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const recaptcha = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  let LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Enter valid email"),
    password: Yup.string().required("Password is required"),
  });

  const changeEmail = (e) => {
    setEmail(e.target.value);
    LoginSchema.validateAt(
      "email",
      { email: e.target.value },
      { abortEarly: false }
    )
      .then(() => {
        setEmailError("");
      })
      .catch(function (error) {
        console.log("error: ", error);
        setEmailError(error.errors);
      });
  };

  const changePassword = (e) => {
    setPassword(e.target.value);
    LoginSchema.validateAt(
      "password",
      { password: e.target.value },
      { abortEarly: false }
    )
      .then(() => {
        setPasswordError("");
      })
      .catch(function (error) {
        setPasswordError(error.errors);
      });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();    
    if (passwordError === "" && emailError === "") {
      let token = await recaptcha.current.executeAsync();
      if (token) {
        signInClicked(email, password);
      }
    } else {
      recaptcha.current.reset();
    }
  };

  const showLoginAlert = () => {
    let finalMessage = loginErrorMessage.split(',');
    return (
      <div className="field">
        <div className="alert-message">
          <span className="icon"></span>
          <div className="message-text">
            <span className="sub-text">{finalMessage && finalMessage[0]}</span><br/>
            <span className="sub-text">{ finalMessage && finalMessage[1]}</span>
          </div>
          {/* <span className="close"></span> */}
        </div>
      </div>
    );
  };

  const callGoogleAPI = () => {
    dispatch(AuthActions.loginWithGoogle());
  };

  useEffect(() => {
    if (loginWithGoogleSuccessFlag) {
      window.open(loginWithGoogleUrl, "_self");
      dispatch(AuthActions.clearGoogleLoginFlags());
    }    
    if (
      setFlagForGoogleCallback &&
      location.pathname === "/login/google/callback"
    ) {
      const url = location.search.replace("?", "");
      dispatch(AuthActions.loginWithGoogleCallback(url));
      dispatch(AuthActions.clearGoogleCallbackFlag(false));
    }
  });

  useEffect(() => {
    if (loginFailed) {
      recaptcha.current.reset();
    }
  }, [loginFailed]);

  return (
    <form className="login-form">
      <div className="login-box">
        <a href="https://www.kitchen365.com/kitchen-design/">
          <span className="title-text">
            <img src={require("../../assets/images/Black-Logo-V3.svg")} alt="KitchenDesign365" />
          </span>
        </a>
        {/* <div>
          <button
            className="google-text"
            type="button"
            onClick={() => callGoogleAPI()}
          >
            Sign In with google
          </button>
        </div>
        <div className="signin-with">
          <span>Or sign in with</span>
        </div> */}
        <div className="fieldset">
          {loginFailed ? showLoginAlert() : null}
          <div className="field">
            <label htmlFor="email" className="label required">
              Email address
            </label>
            <input
              id="email"
              type="email"
              name="email"
              className="input-text"
              placeholder="Enter your email"
              onChange={(e) => changeEmail(e)}
              value={email}
            />
            {emailError && emailError !== "" ? (
              <div className="error">{emailError}</div>
            ) : null}
          </div>
          <div className="field">
            <label htmlFor="pass" className="label required">
              Password
            </label>
            <input
              id="pass"
              type="password"
              name="password"
              className="input-text"
              placeholder="Enter your password"
              onChange={(e) => changePassword(e)}
              value={password}
            />
            {passwordError && passwordError !== "" ? (
              <div className="error">{passwordError}</div>
            ) : null}
          </div>
          <div className="action">
            <button
              onClick={(e) => handleLoginSubmit(e)}
              className="btn btn-black"
            >
              Sign in
            </button>
            <ReCAPTCHA
              ref={recaptcha}
              sitekey={config.RECAPTCHA_SITE_KEY}
              size="invisible"
            />
          </div>
          <div className="sign-up-pass">
            <Link to="/signup">Are you a new user? SIGN UP</Link> 
          </div>
        </div>
      </div>
      <div className="forgot-pass">
        <Link to="/forgotpassword">Forgot password?</Link>
      </div>
    </form>
  );
}

export default LoginForm;
