import React, { useState, useEffect, useRef, memo } from "react";
import { FormControl } from "react-bootstrap";

const DropDownOptions = (props) => {
  const [getState, setState] = useState({
    value: "",
    dropdownHeight: 330, //px 
    openAt: "BOTTOM", //BOTTOM | TOP
  });
  const dropDownListContainer = useRef();

  useEffect(() => {
    if (dropDownListContainer?.current) {
      const element = dropDownListContainer?.current
      const boundries = element.getBoundingClientRect();
      const availableBottomSpace = window.innerHeight - (boundries?.top || boundries?.y || 0);
      setState((prevState) => ({
        ...prevState,
        openAt: availableBottomSpace > boundries?.height ? "BOTTOM" : "TOP",
        dropdownHeight: boundries?.height
      }))
    }
  }, []);

  const handleChange = (e) => {
    setState((prevState) => ({ ...prevState, value: e.target.value }));
  }

  const { children, showSearch = true } = props;
  const { value, openAt, dropdownHeight } = getState;

  return (
    <div
      className={`options-container ${openAt}`}
      style={openAt === "TOP" ? {
        transform: "translateY(-" + dropdownHeight + "px)",
        minHeight: dropdownHeight,
      } : {}
      }
      ref={dropDownListContainer}>
      {showSearch ? <FormControl
        type="text"
        placeholder="Search..."
        onChange={handleChange}
        value={value}
      /> : null}
      <ul className="list-unstyled">
        {React.Children.toArray(children).filter(
          (child) =>
            !value.trim() || (child.props.children?.toLowerCase() || "").indexOf(value?.toLowerCase() || "") !== -1
        )}
      </ul>
    </div>
  );
}

export default memo(DropDownOptions);