import React from "react";
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import "./index.scss";
import NavBar from "../../components/NavBar";
import MyRequests from "../MyRequests";
import SubmitNewRequest from "../SubmitNewRequest";
import Reports from "../Reports";
import RequestSuccess from "../../components/RequestSuccess";
import ErrorPage from "../../components/ErrorPage";
import ForbiddenPage from "../../components/ForbiddenPage";
import MyAccount from "../../components/MyAccount";
import PlanBillingPayment from "../../components/PlanBillingPayment";
import NewRequest from '../../components/NewRequest';

function ClientView(props) {
  const { user } = useSelector((state) => state.auth);
  return (
    <div>
      <NavBar />
      <Switch>
        <RedirectRoute exact path="/app/client" />
        <Route path="/app/client/myrequests">
          <MyRequests />
        </Route>
        <Route path="/app/client/myaccount">
          <MyAccount />
        </Route>
        <Route path="/app/client/payment">
          <PlanBillingPayment />
        </Route>
        <Route path="/app/client/submitrequest">
          {user?.group_id === 4
            ? <ForbiddenPage />
            : <SubmitNewRequest />
          }
        </Route>
        {/* <Route path="/app/client/newrequest">
          {user?.group_id === 4
            ? <ForbiddenPage />
            : <NewRequest />
          }
        </Route> */}
        <Route path="/app/client/reports">
          {user?.group_id === 4
            ? <ForbiddenPage />
            : <Reports />
          }
        </Route>
        <Route exact path="/app/client/requestsuccess">
          <RequestSuccess />
        </Route>
        <Route exact path="/app/client/404">
          <ErrorPage />
        </Route>
      </Switch>
    </div>
  );
}
function RedirectRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: "/app/client/myrequests",
            state: { from: location },
          }}
        />
      )}
    />
  );
}
export default ClientView;
