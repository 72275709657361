import React from "react";
import { Link } from "react-router-dom";

import "./index.scss";

function ErrorPage() {
  return (
    <div className="error-page">
      <div className="container">
        <div className="error-page-content">
          <div className="button-and-title">
            <h1>
              4<span>0</span>4
            </h1>
            <Link to="/app/client" class="btn btn-black" title="Go to Home">
              Go to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
