import { put, takeLatest, call, all } from "redux-saga/effects";
import queryString from "query-string";
import { postApi } from "../apis";
import apiUrl from "../../config/urls";

export const actionTypes = {
  CONTACTUS: "CONTACTUS",
  CONTACTUS_LOADIING: "CONTACTUS_LOADIING",
  CONTACTUS_SUCESSS: "CONTACTUS_SUCESSS",
  CONTACTUS_FAILED: "CONTACTUS_FAILED",
  CLEAR_CONTACTUS_FLAGS: "CLEAR_CONTACTUS_FLAGS",
};
const initialState = {
  setErrorMsgForContactus:"",
  contactusResponse:'',
  contactusLoading: false,
  setSuccessForContactus: false,
  contactusFailed: false,
};
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CONTACTUS_SUCESSS: {
      const { flag, contactusResponse } = action.payload;
      return {
        ...state,
        setSuccessForContactus: flag,
        contactusResponse:contactusResponse,
      };
    }
    case actionTypes.CONTACTUS_LOADIING: {
      const { flag } = action.payload;
      return {
        ...state,
        contactusLoading: flag,
      };
    }
    case actionTypes.CONTACTUS_FAILED: {
      const { flag, errorMessage } = action.payload;
      return {
        ...state,
        contactusFailed: flag,
        setErrorMsgForContactus: errorMessage,
      };
    }
    case actionTypes.CLEAR_CONTACTUS_FLAGS: {
        return {
          ...state,
          contactusLoading: false,
          setSuccessForContactus: false,
          contactusFailed: false,
          setErrorMsgForContactus:"",
          contactusResponse:'',
        };
      }
  
    default: {
      return state;
    }
  }
};

export const actions = {
  contactus: (payload) => ({
    type: actionTypes.CONTACTUS,
    payload,
  }),
  setContactusLoading: (flag) => ({
    type: actionTypes.CONTACTUS_LOADIING,
    payload: { flag },
  }),
  setContactusSuccess: (flag, contactusResponse="") => ({
    type: actionTypes.CONTACTUS_SUCESSS,
    payload: { flag, contactusResponse },
  }),
  setContactusFailed: (flag, errorMessage = "") => ({
    type: actionTypes.CONTACTUS_FAILED,
    payload: { flag, errorMessage },
  }),
  clearContactusFlags: () => ({
    type: actionTypes.CLEAR_CONTACTUS_FLAGS,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.CONTACTUS, contactusSaga);
}

function* contactusSaga(action) {
  yield all([
    put(actions.setContactusLoading(true)),
    put(actions.setContactusSuccess(false)),
    put(actions.setContactusFailed(false)),
  ]);
  try {
    const response = yield call(
        postApi,
        apiUrl("CONTACTUS_URL"),
        queryString.stringify(action.payload)
      );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.setContactusLoading(false)),
          put(actions.setContactusSuccess(true, responseData)),
          put(actions.setContactusFailed(false)),
        ]);
      } else {
        yield all([
          put(actions.setContactusLoading(false)),
          put(actions.setContactusSuccess(false)),
          put(actions.setContactusFailed(true)),
        ]);
      }
    } else if (response && response.status === 422) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.message) {
        yield all([
          put(actions.setContactusLoading(false)),
          put(actions.setContactusFailed(true, responseData.message)),
          put(actions.setContactusSuccess(false)),
        ]);
      } else {
        yield all([
          put(actions.setContactusLoading(false)),
          put(actions.setContactusFailed(true)),
          put(actions.setContactusSuccess(false)),
        ]);
      }
    }
  } catch (error) {
    yield all([
      put(actions.setContactusLoading(false)),
      put(actions.setContactusSuccess(false)),
      put(actions.setContactusFailed(true)),
    ]);
  }
}
