import {
  designerProductivityRatio,
  designerTotalRequestSubmitted
} from "../actionConstants/designerReports.constants";

const initialState = {

  designerProductivityRatioFetchProcess: {
      isFetchingProductivityRatio: false,
      productivityRatioFetchedData : {},
      isProductivityRatioFetchFailed : false,
      productivityRatioFetchFailedMessage:""
  },

  designerTotalRequestSubmittedFetchProcesses : {
    isFetchingTotalSubmittedRequset : false,
    totalRequestSubmittedFetchedData : {},
    isTotalSubmittedRequsetFetchFailed: false,
    totalSubmittedRequsetFetchFailedMessage : ""
  }
  
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case designerProductivityRatio.GET_DESIGNER_PRODUCTIVITY_RATIO_INIT: {
      return {
        ...state,
        designerProductivityRatioFetchProcess: {
          ...state.designerProductivityRatioFetchProcess,
          isFetchingProductivityRatio: true,        
          isProductivityRatioFetchFailed: false,
          productivityRatioFetchFailedMessage: "",
        },
      };
    }
    case designerProductivityRatio.GET_DESIGNER_PRODUCTIVITY_RATIO_SUCCESS: {
    return {
      ...state,
      designerProductivityRatioFetchProcess: {
        ...state.designerProductivityRatioFetchProcess,
        isFetchingProductivityRatio: false,
        productivityRatioFetchedData: payload,
        isProductivityRatioFetchFailed: false,
        productivityRatioFetchFailedMessage: "",
      },
    };
  }
  case designerProductivityRatio.GET_DESIGNER_PRODUCTIVITY_RATIO_FAILURE: {
    return {
      ...state,
      designerProductivityRatioFetchProcess: {
        ...state.designerProductivityRatioFetchProcess,
        isFetchingProductivityRatio: false,
        productivityRatioFetchedData: {},
        isProductivityRatioFetchFailed: true,
        productivityRatioFetchFailedMessage: payload,
      },
    };
  }

  case designerTotalRequestSubmitted.GET_DESIGNER_TOTAL_REQUEST_SUBMITTED_INIT : {
    return {
      ...state,
      designerTotalRequestSubmittedFetchProcesses : {
        ...state.designerTotalRequestSubmittedFetchProcesses,
        isFetchingTotalSubmittedRequset : true,
        // totalRequestSubmittedFetchedData : {},
        isTotalSubmittedRequsetFetchFailed: false,
        totalSubmittedRequsetFetchFailedMessage : ""
      }
    }
  }
  case designerTotalRequestSubmitted.GET_DESIGNER_TOTAL_REQUEST_SUBMITTED_SUCCESS : {
    return {
      ...state,
      designerTotalRequestSubmittedFetchProcesses : {
        ...state.designerTotalRequestSubmittedFetchProcesses,
        isFetchingTotalSubmittedRequset : false,
        totalRequestSubmittedFetchedData : payload,
        isTotalSubmittedRequsetFetchFailed: false,
        totalSubmittedRequsetFetchFailedMessage : ""
      }
    }
  }
  case designerTotalRequestSubmitted.GET_DESIGNER_TOTAL_REQUEST_SUBMITTED_FAILURE : {
    return {
      ...state,
      designerTotalRequestSubmittedFetchProcesses : {
        ...state.designerTotalRequestSubmittedFetchProcesses,
        isFetchingTotalSubmittedRequset : false,
        totalRequestSubmittedFetchedData : {},
        isTotalSubmittedRequsetFetchFailed: true,
        totalSubmittedRequsetFetchFailedMessage : payload
      }
    }
  }
    default:
      return state;
  }
};

export default reducer;
