import React, { memo, useEffect } from 'react'
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Input, Textarea } from '../ReusableComponents'
import { formFields, validationSchemaSignupForm } from './constant'
import { actions as AuthActions } from "../../redux/reducers/HomeReducer";


function ScheduleADemo() {
    const { setSuccessForContactus } = useSelector((state) => state.home);
    const dispatch = useDispatch();

    useEffect(() => {
        if (setSuccessForContactus) {
            setTimeout(() => {
                dispatch(AuthActions.clearContactusFlags());
            }, 5000)
        }
    }, [setSuccessForContactus])

    return (
        <div className="schedule-form-block clearfix">
            <h2>Schedule a Demo</h2>
            <div></div>
            <Formik
                initialValues={{ first_name: '', last_name: '', email: '', phone: '', company_name: '', website_url: '', message: '', phone_code: "1" }}
                validationSchema={validationSchemaSignupForm}
                onSubmit={(values, actions) => {
                    dispatch(AuthActions.contactus(values));
                    actions.resetForm();
                }}
            >
                {({ touched, errors, values, handleBlur, handleChange, handleSubmit }) => (
                    <div className="form-outer">
                        {
                            setSuccessForContactus && <div className='success-message-block'>
                                <i><img src={require("../../assets/images/success-icon.svg")} alt="Success" /></i>
                                <p>Thank you for your information. We will get back to you shortly.</p>
                            </div>
                        }
                        <ul>
                            <li>
                                <div className="form-group">
                                    <Input
                                        setting={formFields.first_name}
                                        touched={touched[formFields.first_name.key]}
                                        errors={errors[formFields.first_name.key]}
                                        value={values[formFields.first_name.key]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <Input
                                        setting={formFields.last_name}
                                        touched={touched[formFields.last_name.key]}
                                        errors={errors[formFields.last_name.key]}
                                        value={values[formFields.last_name.key]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <Input
                                        setting={formFields.email}
                                        touched={touched[formFields.email.key]}
                                        errors={errors[formFields.email.key]}
                                        value={values[formFields.email.key]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <Input
                                        setting={formFields.phone}
                                        touched={touched[formFields.phone.key]}
                                        errors={errors[formFields.phone.key]}
                                        value={values[formFields.phone.key]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <Input
                                        setting={formFields.company_name}
                                        touched={touched[formFields.company_name.key]}
                                        errors={errors[formFields.company_name.key]}
                                        value={values[formFields.company_name.key]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <Input
                                        setting={formFields.website_url}
                                        touched={touched[formFields.website_url.key]}
                                        errors={errors[formFields.website_url.key]}
                                        value={values[formFields.website_url.key]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <Textarea
                                        setting={formFields.message}
                                        touched={touched[formFields.message.key]}
                                        errors={errors[formFields.message.key]}
                                        value={values[formFields.message.key]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>
                            </li>
                        </ul>
                        <button type="button" onClick={handleSubmit} className="btn back book-demo orange-btn">SUBMIT</button>
                    </div>)}
            </Formik>
        </div>
    )
}
export default memo(ScheduleADemo)