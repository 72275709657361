import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { convertUTCToLocale } from '../../Utils/utils'
import "./index.scss";
import { useCookies } from "react-cookie";
import { actions as myRequestActions } from "../../redux/reducers/MyRequestReducer";
import SoldForm from "../Forms/SoldForm";

function ViewRequestHeader({ data, id }) {
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const editRequest = () => {
    history.push("/app/client/myrequests/editrequest/" + id);
  };
  const renderEditButton = () => {
    if (data) {
      if (
        data["is_editable"] &&
        data["request_status"]["status"][0]["slug"] === "queued" &&
        user.group_id !== 3
      ) {
        return (
          <>
            <button onClick={editRequest} className="edit-btn"></button>
          </>
        );
      }
    }
  };
  const navigateToReview = () => {
    history.push(`/app/client/myrequests/review-submit/${btoa(id)}`)
  }

  const requestRevision = () => {
    if (window.confirm('Are you sure you want to put this request for revision?')) {
      dispatch(
        myRequestActions.updateStatusClient(
          id,
          6,
          'revision-requested',
          0,
          cookies.clientAccessToken
        )
      );
    }
    // window.location.reload();
  }

  const isApproved = useMemo(() => {
    const { request_status = {} } = data;
    const { status = [] } = request_status;
    const [firstStatus = {}] = status;
    const { slug = "" } = firstStatus;
    return slug === "approved";
  }, [data])

  const handleSoldPopup = () => {
    dispatch(myRequestActions.markAsSoldPopup({ open: true, editSold: false }))
  }

  return (
    <div className="viewrequestheader">
      <div className="title-row cf">
        <div className="view_req_title_left">
          <h1>{data["request_title"]}</h1>
          <div className="detail">
            <span className="request-id">Request #{data["request_number"]}</span>
            <span className="name">Added by {data["added_by"]}</span>
            <span className="date">Created on {convertUTCToLocale(data["created_on"])?.dateAndTime}</span>
          </div>
          <div className="detail">
            <span className="date">Due: {convertUTCToLocale(data["due_date"])?.date}</span>
            <span className="date">Last Updated: {convertUTCToLocale(data["updated_on"])?.dateAndTime}</span>
          </div>
          <h3 className="assignee"><b>Designer : </b> {data["assign_to_name"]}</h3>
        </div>

        <div className="ratingtext">
          {isApproved && !data?.review && data?.sold_id === 0 && <p>We would love to hear your experince with this request<br />
            Please rate your experince.</p>}
          <div className="ctc-container">
            {isApproved && <button
              disabled={data?.remaining_revision === 0 || data?.sold_id > 0 ? true : false}
              onClick={requestRevision}
              className="btn request-revision">
              Request Revision {data?.remaining_revision === 0 ? <span>Sorry, you have exhausted the max revision request limit</span> : <span>You've {data?.remaining_revision} revision requests remaining</span>}
            </button>
            }

            {isApproved && !data?.review && data?.sold_id === 0 && <button onClick={navigateToReview} className="btn" type="submit">Submit Review</button>}

            {isApproved && data?.is_sold_allowed && data?.sold_id === 0 ?
              <button onClick={() => { handleSoldPopup() }} className="btn btn-orange" type="button">Mark As Sold</button>
              :
              <div className="status-container">
                {isApproved && data?.sold_id > 0 ?
                  <span className="request-status sold">Sold</span>
                  : null
                }
              </div>
            }
          </div>
        </div>
        {renderEditButton()}
        {isApproved ? <SoldForm data={data} /> : null}
      </div>
    </div>
  );
}

export default ViewRequestHeader;
