import React, { useState, memo } from 'react'
import PropTypes from 'prop-types';
import { RenderComponent } from './RenderComponent';
import { RenderHeadingsTag } from './RenderHeadingsTag';
import { RenderLi } from './RenderLi';
import useDeviceDetect from '../../../../hooks/useDeviceDetect';

/**
 * This is  CustomTab function Component. 
 * @param {componentList} componentList  componentList  Pass Component Value.
 * @param {tabTitle} tabTitle tabTitle Pass String Value.
 * @param {defaultPreselectTab} defaultPreselectTab defaultPreselectTab Pass Number Value.
 * @returns  CustomTab HTML Element
 * @author Vishal Gohel <vishal.gohel@commercepundit.com>
 * 
 *  Notes:- Dec 28 2021 (Vishal Gohel <vishal.gohel@commercepundit.com>)  Component Created
 */

function CustomTab({ componentList, tabTitle, defaultPreselectTab }) {
    const [state, setstate] = useState(defaultPreselectTab)

    /**
     * Check Device (Mobile || Desktop)  View
     */
    const isMobile = useDeviceDetect();

    const handleSelectTab = (index) => { setstate(index) }

    return (
        <div className='account-tabs clearfix'>
            {tabTitle && <h3>{tabTitle}</h3>}
            {!isMobile && <ul className="tabs" >
                {
                    componentList.map((item) =>
                        <RenderLi
                            key={item.id}
                            className={`${state === item.id ? "active" : ""}`}
                            onClick={() => { handleSelectTab(item.id) }}
                            tabName={item.tabName}
                        />
                    )
                }
            </ul>}
            <div className="tab_container">
                {componentList.map((item) => {
                    return <React.Fragment key={item.id}>
                        <RenderHeadingsTag
                            onClick={() => { handleSelectTab(item.id) }}
                            className={`mob-tab-heading ${state === item.id ? "d_active" : ""} 
                                ${isMobile ? "" : "tab_drawer_heading"}`}
                            tabName={item.tabName}
                        />
                        <RenderComponent state={state} item={item} />
                    </React.Fragment>
                })}
            </div>
        </div>
    )
}


CustomTab.propTypes = {
    componentList: PropTypes.array,
    TabTitle: PropTypes.string,
    defaultPreselectTab: PropTypes.number,
};

CustomTab.defaultProps = {
    componentList: [],
    TabTitle: "",
    defaultPreselectTab: 0,
}

export default memo(CustomTab)

