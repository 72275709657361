/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as myRequestActions } from "../../redux/reducers/MyRequestReducer";
import { useCookies } from "react-cookie";

import "./index.scss";
import AppliancePopup from "./AppliancePopup";
import SearchableDropDown from '../searchableDropDown';

function EditRequestContent({ data }) {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const {
    editRequestTitle,
    editRequestType,
    dynamicFormValues,
    dynamicFormdata,
  } = useSelector((state) => state.myRequest);
  const [showPopup, setShowPopup] = useState(false);
  const [applianceObject, setApplianceObject] = useState(null);
  const [disabledValue, setDisbaled] = useState(false);
  const [looseApplianceObject, setLooseApplianceObject] = useState(null);

  useEffect(() => {
    if (data) {
      dispatch(myRequestActions.setDynamicFormData(data.dynamic_form_data));
      dispatch(
        myRequestActions.setDynamicFormValues(
          data.dynamic_form_data,
          data.category_data
        )
      );
      // eslint-disable-next-line array-callback-return
      Object.keys(data.dynamic_form_data).map((key) => {
        if (key === "appliances") {
          setApplianceObject(data.dynamic_form_data[key]);
        }
        if(key === "loose-appliances"){
          setLooseApplianceObject(data.dynamic_form_data[key]);
        }
      });

      dispatch(
        myRequestActions.changeEditRequestType(data["request_type"][0]["id"])
      );
      dispatch(myRequestActions.changeEditRequestTitle(data["request_title"]));
    }
  }, [data, dispatch]);

  const changeRequestType = (e) => {
    dispatch(myRequestActions.changeEditRequestType(e.target.value));
  };
  const changeRequestTitle = (e) => {
    dispatch(myRequestActions.changeEditRequestTitle(e.target.value));
  };
  const openPopup = () => {
    setShowPopup(true);
  };
  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(()=>{
  if(data){
    Object.keys(data.category_data).map((key) => {
      if (key === "soffits") {
        if(data.category_data[key].data[0].category_option_value){
          setDisbaled(true);
        }
      }
    });
  }

  },[data]);
  const handleFormValueChange = (value, categoryName, optionId, type, childtype, isPopup) => {
    if(optionId == 6  && categoryName ==="soffits"){
      if(value== true){
      setDisbaled(true);
      dispatch(
        myRequestActions.changeDynamicFormValues(
          '',
          "soffits",
          7,
          "input-text"
        )
      );
      dispatch(
        myRequestActions.changeDynamicFormValues(
          '',
          "soffits",
          8,
          "input-text"
        )
      );
      }
      else
      setDisbaled(false);
    }
    dispatch(
      myRequestActions.changeDynamicFormValues(
        value,
        categoryName,
        optionId,
        type,
        childtype,
        isPopup,
      )
    );
  };

  const customHandleFormValueChange = (value, categoryName, optionId, type) =>{
    dispatch(
      myRequestActions.changeDynamicFormValues(
        value,
        categoryName,
        optionId,
        type
      )
    );
    dispatch(myRequestActions.getLatestDoorDetailsEdit(optionId,cookies.clientAccessToken))
  }

  const renderFormOptions = (options, valueOptions, key) => {
    if (options && options.length !== 0) {
      let formOptions = [...options];
      let dropdownOptions = formOptions.filter(
        (x) => x.category_option_type === "dropdown"
      );
      let formValueOptions = [...valueOptions];
      let dropdownValueOptions = formValueOptions.filter(
        (x) => x.category_option_type === "dropdown"
      );
      
      let secondaryDoorInputValue = formValueOptions.filter(
        (x) => x.category_option_type === "input-text"
      );

      let doorFinishWall = formValueOptions.filter(
        (x) => x.category_option_name === "Wall"
      );

      let doorFinishBase = formValueOptions.filter(
        (x) => x.category_option_name === "Base"
      );

      let doorFinishIsland = formValueOptions.filter(
        (x) => x.category_option_name === "Island"
      );

      let soffitsWidthHeight = formValueOptions.filter(
        (x) => x.category_name === "Soffits" && x.category_option_type === "input-number"
      );

      return (
        <div className="description-type">
          {formOptions && formOptions.length !== 0 && key !== "secondary-door-style" && key !== "multiplier"
            ? formOptions.map((data, index) => {
                let valueData = null;
                let valueDataIndex = formValueOptions.findIndex(
                  (x) => x.category_option_id === data.category_option_id
                );
                if (valueDataIndex !== -1) {
                  valueData = formValueOptions[valueDataIndex];
                }
                let datai = null;
                let dataOther = null;
                if(valueData && valueData['child_data']){
                  datai = valueData['child_data'].filter(
                    (x) => x && x.name == "Width"
                  );
                }
                let valueDropdown = null;
                if(valueData && valueData['child_data']){
                  valueDropdown = valueData['child_data'].filter(
                    (x) => x && x.category_option_type == "dropdown"
                  );
                }
                
                if(valueData && valueData['child_data'] && valueData['child_data'].length){
                  dataOther = valueData['child_data'].filter(
                    (x) => x && x.is_others == "1"
                  );
                }else{
                  if(valueData && valueData.is_others == "1"){
                    dataOther = valueData['others_value'];
                  }
                }

                switch (data.category_option_type) {
                  case "dropdown":
                    return null;
                  case "input-number":
                    return (
                      <div
                        key={data.category_option_id}
                        className="description-box inches-box"
                      >
                        <label
                          htmlFor={data.category_option_id}
                          className="label"
                        >
                          {data.category_option_name}
                        </label>
                        {data.category_name === 'Soffits' &&
                        <input
                          id={data.category_option_id}
                          className="input-box inch-input"
                          type="number"
                          disabled={(valueData && valueData.category_option_value) ? false : (disabledValue) ? true : false}
                          value={valueData && valueData.category_option_value ? valueData.category_option_value : ""
                          }
                          onChange={(e) =>
                            handleFormValueChange(
                              e.target.value? Number(e.target.value) : e.target.value,
                              key,
                              data.category_option_id,
                              "input-text"
                            )
                          }
                        />
                        }
                        {data.category_name !== 'Soffits' &&
                        <input
                          id={data.category_option_id}
                          className="input-box inch-input"
                          type="number"
                          value={valueData && valueData.category_option_value ? valueData.category_option_value : ""}
                          onChange={(e) =>
                            handleFormValueChange(
                              e.target.value? Number(e.target.value) : e.target.value,
                              key,
                              data.category_option_id,
                              "input-text"
                            )
                          }
                        />
                        }
                        {data.category_option_unit ? (
                          <span className="inches">
                            {data.category_option_unit}
                          </span>
                        ) : (
                          <span className="inches">inches</span>
                        )}
                      </div>
                    );
                  case "checkbox":
                    if(key == "soffits"){
                      return (
                        <div
                          key={data.category_option_id}
                          className="description-box check-box"
                        >
                          <input
                            id={data.category_option_id}
                            className="custom-checkbox"
                            type="checkbox"
                            checked={
                              soffitsWidthHeight.length > 0 || !disabledValue ? false
                                : true
                            }
                            onChange={(e) =>
                              handleFormValueChange(
                                e.target.checked,
                                key,
                                data.category_option_id,
                                "checkbox"
                              )
                            }
                          />
                          <label
                            htmlFor={data.category_option_id}
                            className="label"
                          >
                            {data.category_option_name}
                          </label>
                          {data.child_data && data.child_data.length && valueData ? <div style={{display: "flex",marginTop: "1rem"}} className="description-box">
                            {data.child_data.filter(
                                (x) => x.category_option_type === "dropdown"
                              ).length ?<SearchableDropDown 
                              key={key}
                              data={data.child_data.filter(
                                (x) => x.category_option_type === "dropdown"
                              )}
                              dataMapingString={{value: "name", key: "id"}}
                              onChange={(e) => {
                                  handleFormValueChange(
                                    e.target.value,
                                    key,
                                    data.category_option_id,
                                    key,
                                  );
                              }}
                              classNames="select-box input-box"
                              value={
                                valueDropdown && valueDropdown.length && valueDropdown[0]["selected"]
                                  ? valueDropdown[0]["id"]
                                  : ""
                              }
                            />:null}
                            <input
                              style={{width:"70px",marginLeft: "5px"}}
                              id={data.id}
                              className="input-box inch-input"
                              type="number"
                              min="0"
                              disabled={
                                valueData && valueData['child_data'].length
                                  ? false
                                  : true
                              }
                              value={datai.length && datai[0].value ? datai[0].value: ""} 
                              onChange={(e) =>
                                handleFormValueChange(
                                  e.target.value
                                    ? Number(e.target.value)
                                    : e.target.value,
                                  key,
                                  data.category_option_id,
                                  ""+key+"",
                                  valueData['child_data'].findIndex(
                                    (x) => x.name == "Width"
                                  ),
                                  true
                                )
                              }
                          />
                          </div>:""}
                        </div>
                      );
                    }else{
                      return (
                        <div
                          key={data.category_option_id}
                          className="description-box check-box"
                        >
                          <input
                            id={data.category_option_id}
                            className="custom-checkbox"
                            type="checkbox"
                            checked={
                              valueData && valueData.category_option_value && valueData.category_option_value == 1
                                ? valueData.category_option_value
                                : false
                            }
                            onChange={(e) =>
                              handleFormValueChange(
                                e.target.checked,
                                key,
                                data.category_option_id,
                                "checkbox"
                              )
                            }
                          />
                          <label
                            htmlFor={data.category_option_id}
                            className="label"
                          >
                            {data.category_option_name}
                          </label>
                          {data.is_others == "1" && data.child_data && data.child_data.length == "0" && valueData && valueData.category_option_value == true ?<input
                            className="form-control" placeholder="Enter"
                            style={{width:"100%",marginLeft: "5px", marginTop: "1rem"}}
                            id={data.id}
                            className="input-box inch-input"
                            type="text"
                            value={dataOther} 
                            onChange={(e) =>
                              handleFormValueChange(
                                e.target.value,
                                key,
                                data.category_option_id,
                                "others"
                              )
                            }
                        />: null}
                        {data.is_others == "1" && data.child_data && data.child_data.length && valueData && valueData.category_option_value == true ?<input
                            className="form-control" placeholder="Enter"
                            style={{width:"100%",marginLeft: "5px",marginTop: "1rem"}}
                            id={data.id}
                            className="input-box inch-input"
                            type="text"
                            value={dataOther.length && dataOther[0].value ? dataOther[0].value: ""} 
                            onChange={(e) =>
                              handleFormValueChange(
                                e.target.value,
                                key,
                                data.category_option_id,
                                ""+key+"",
                                valueData['child_data'].findIndex(
                                  (x) => x.is_others == "1"
                                ),
                                true
                              )
                            }
                        />: null}
                          {data.child_data && data.child_data.length && valueData ? <div style={{display: "flex",marginTop: "1rem"}} className="description-box">
                            {data.child_data.filter(
                                (x) => x.category_option_type === "dropdown"
                              ).length ?<SearchableDropDown 
                              key={key}
                              data={data.child_data.filter(
                                (x) => x.category_option_type === "dropdown"
                              )}
                              dataMapingString={{value: "name", key: "id"}}
                              onChange={(e) => {
                                  handleFormValueChange(
                                    e.target.value,
                                    key,
                                    data.category_option_id,
                                    key,
                                  );
                              }}
                              classNames="select-box input-box"
                              value={
                                valueDropdown && valueDropdown.length && valueDropdown[0]["selected"]
                                  ? valueDropdown[0]["id"]
                                  : ""
                              }
                            />:null}
                            {data.is_others == "0" ?<input
                              style={{width:"70px",marginLeft: "5px"}}
                              id={data.id}
                              className="input-box inch-input"
                              type="number"
                              min="0"
                              disabled={
                                valueData && valueData['child_data'].length
                                  ? false
                                  : true
                              }
                              value={datai.length && datai[0].value ? datai[0].value: ""} 
                              onChange={(e) =>
                                handleFormValueChange(
                                  e.target.value
                                    ? Number(e.target.value)
                                    : e.target.value,
                                  key,
                                  data.category_option_id,
                                  ""+key+"",
                                  valueData['child_data'].findIndex(
                                    (x) => x.name == "Width"
                                  ),
                                  true
                                )
                              }
                          />:null}
                          </div>:""}
                        </div>
                      );
                    }
                    
                  default:
                    return null;
                }
              })
            : null}
          {dropdownOptions && dropdownOptions.length !== 0 && dropdownOptions[0].category_name ? (
            <div className="description-box">
              <select
                onChange={(e) =>
                  {
                    if(dropdownOptions[0].category_name === 'Cabinet Brand'){
                    customHandleFormValueChange(
                      e.target.value,
                    key,
                    e.target.value,
                    "dropdown"
                    )
                  }else{
                     handleFormValueChange(
                     e.target.value,
                     key,
                     e.target.value,
                     "dropdown"
                   )
                  }              
                }
                }
                className="select-box input-box"
                value={
                  dropdownValueOptions && dropdownValueOptions.length !== 0
                    ? dropdownValueOptions[0]["category_option_id"]
                    : ""
                }
              >
                <option value="Select">Select</option>
                {dropdownOptions.map((data, index) => (
                  <option
                    id={data.category_option_id}
                    key={data.category_option_id}
                    value={data.category_option_id}
                  >
                    {data.category_option_name}
                  </option>
                ))}
              </select>
              {dropdownValueOptions && dropdownValueOptions.length !== 0 && dropdownValueOptions[0]['category_slug'] == "sink" && dynamicFormValues && dynamicFormValues['sink'] && dynamicFormValues['sink']['data'] && dynamicFormValues['sink']['data'].length
                              && dynamicFormValues['sink']['data'][0]["is_others"] == "1" ? <input placeholder="Enter" type="text" className="form-control sink-others-edit input-box"
                              value={dynamicFormValues && dynamicFormValues['sink'] && dynamicFormValues['sink']['data'] && dynamicFormValues['sink']['data'].length
                              ? dynamicFormValues['sink']['data'][0]["others_value"]
                              : ""}
                              onChange={(e) =>
                                handleFormValueChange(
                                  e.target.value,
                                  'sink',
                                  dynamicFormdata['sink']['data'][0]["category_option_id"],
                                  "others"
                                )}
                        ></input>:null}
            </div>
          ) : null}
          {key == "door-finish" ? 
                       <div
                       key={options[2].category_option_id}
                       className="description-box"
                        >
                          <label
                          htmlFor={options[2].category_option_id}
                          className="label"
                        >
                          {options[2].category_option_name}
                        </label>
                       <input
                            style={{width: "115px",marginLeft:"10px",marginRight:"10px"}}
                            id={options[2].category_option_id}
                            className="input-box inch-input"
                            type="text"
                            value={
                              doorFinishWall.length && doorFinishWall[0]['category_option_value']
                                  ? doorFinishWall[0]['category_option_value']: ""
                            }
                            onChange={(e) =>
                              handleFormValueChange(
                                e.target.value,
                                key,
                                options[2].category_option_id,
                                "input-text"
                              )
                            }
                          />
                          <label
                          htmlFor={options[1].category_option_id}
                          className="label"
                        >
                          {options[1].category_option_name}
                        </label>
                       <input
                            style={{width: "115px",marginLeft:"10px",marginRight:"10px"}}
                            id={options[1].category_option_id}
                            className="input-box inch-input"
                            type="text"
                            value={
                              doorFinishBase.length && doorFinishBase[0]['category_option_value']
                                  ? doorFinishBase[0]['category_option_value']: ""
                            }
                            onChange={(e) =>
                              handleFormValueChange(
                                e.target.value,
                                key,
                                options[1].category_option_id,
                                "input-text"
                              )
                            }
                          />
                          <label
                          htmlFor={options[0].category_option_id}
                          className="label"
                        >
                          {options[0].category_option_name}
                        </label>
                       <input
                            style={{width: "115px",marginLeft:"10px",marginRight:"10px"}}
                            id={options[0].category_option_id}
                            className="input-box inch-input"
                            type="text"
                            value={
                              doorFinishIsland.length && doorFinishIsland[0]['category_option_value']
                                  ? doorFinishIsland[0]['category_option_value']: ""
                            }
                            onChange={(e) =>
                              handleFormValueChange(
                                e.target.value,
                                key,
                                options[0].category_option_id,
                                "input-text"
                              )
                            }
                          />
                          
                       </div>
                      
                      :""}
          {key == "secondary-door-style" || key == "multiplier" || key == "freshsales-url" ? <div
                       key={options[0].category_option_id}
                       className="description-box"
                        >
                       <input
                          style={{width: "300px"}}
                          id={options[0].category_option_id}
                          className="input-box inch-input"
                          type="text"
                          value={
                            secondaryDoorInputValue.length && secondaryDoorInputValue[0]['category_option_value']
                                ? secondaryDoorInputValue[0]['category_option_value']: ""
                          }
                          onChange={(e) =>
                            handleFormValueChange(
                              e.target.value,
                              key,
                              options[0].category_option_id,
                              "input-text"
                            )
                          }
                        />
                       </div>
                      :""}
        </div>
      );
    } else {
      return null;
    }
  };
  const returnCategoryName = (name) =>{
    if(name == "Door Style"){
      return ""
    }else{
      return name;
    }
  }
  const renderForm = () => {
    if (dynamicFormdata) {
      return (
        <>
          {Object.keys(dynamicFormdata).map((key, index) => {
            if(key == "door-style"){
              if(dynamicFormdata[key]['data'][0]['category_name']){

              }else{
                return;
              }
            }
            return (
              <li key={key} className="description-option">
                  <label
                  className={
                    dynamicFormdata[key]["option"]["is_required"]
                      ? "description-label required"
                      : "description-label"
                  }
                >
                  {dynamicFormdata[key]["option"]["category_name"] === "Appliances" ?  "Standard Appliances": dynamicFormdata[key]["option"]["category_name"]}
                </label>
                {renderFormOptions(
                  dynamicFormdata[key]["data"],
                  dynamicFormValues[key]["data"],
                  key
                )}
              </li>
            );
          })}
        </>
      );
    }
  };

  return (
    <div className="request-description">
      <div className="container">
        <div className="editrequest-content">
          <AppliancePopup
            show={showPopup}
            closePopup={closePopup}
            applianceObject={applianceObject}
            dynamicFormValues={dynamicFormValues}
            looseApplianceObject={looseApplianceObject}
          />
          <div className="more-about-your-request">
            <ul>
              <li className="description-option">
                <label className="description-label required">
                  Request Name
                </label>
                <div className="description-type">
                  <div className="description-box">
                    <input
                      value={editRequestTitle}
                      className="input-box"
                      type="text"
                      placeholder="Jeanetta Brooks Kitchen Design"
                      onChange={changeRequestTitle}
                    />
                  </div>
                </div>
              </li>
              <li className="description-option">
                <label className="description-label">Request Type</label>
                <div className="description-type">
                  <div className="description-box">
                    <select
                      value={editRequestType}
                      className="select-box input-box"
                      onChange={changeRequestType}
                    >
                      {data &&
                        Object.keys(data["request_type_dropdown"]).map(
                          (key, index) => {
                            return (
                              <option
                                key={key}
                                value={data["request_type_dropdown"][key]["id"]}
                              >
                                {data["request_type_dropdown"][key]["name"]}
                              </option>
                            );
                          }
                        )}
                    </select>
                  </div>
                </div>
              </li>
              {renderForm()}
            </ul>
            {applianceObject &&
            <p className="if-text">
              If you have any non-standard appliances, please{" "}
              <a onClick={openPopup} href="#" title="click here">
                click here
              </a>{" "}
              and submit the detailed measurements.
            </p>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditRequestContent;
