import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { actions as AuthActions } from "../../../redux/reducers/MyAccountReducer";
import LoaderComponent from "../../../components/Loader";
import { ModelConfirmation } from '../../ReusableComponents';

function PaymentMethod() {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const {
    userAuth, savedCardsResponse, savedCardsLoading
  } = (useSelector(state => ({ ...state?.myAccountReducer, userAuth: state.auth })) || {});
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  useEffect(() => {
    dispatch(AuthActions.savedCards({ client_id: userAuth?.user?.parent_id ? userAuth?.user?.parent_id : userAuth?.user?.id, clientAccessToken: cookies.clientAccessToken }));
  }, [cookies.clientAccessToken, dispatch, userAuth]);

  const handleDelete = (id) => {
    dispatch(AuthActions.deleteCard({ id, client_id: userAuth?.user?.parent_id ? userAuth?.user?.parent_id : userAuth?.user?.id, clientAccessToken: cookies.clientAccessToken }));
  }

  const handleToggleDeleteModel = () => {
    setIsOpen(!isOpen)
  }

  const deleteConfirmationMessage = <div className="image-box">
    <p>Are you sure you want to delete this item?</p>
    <div className="buttons">
      <button className="common-button orange-btn" onClick={() => {
        handleDelete(deleteId)
        handleToggleDeleteModel();
      }}>Confirm</button>
      <button className="common-button border-btn" onClick={handleToggleDeleteModel}>Cancel</button>
    </div>
  </div>

  return (
    <div>
      <ModelConfirmation
        handleToggleModel={handleToggleDeleteModel}
        isOpen={isOpen}
        htmlElement={deleteConfirmationMessage}
      />
      <LoaderComponent showhide={savedCardsLoading} />
      <div className="listing-block payment-method">
        <div className="payment-form paymet-card-form">
          <form>
            {
              (savedCardsResponse?.data &&  savedCardsResponse?.data[0])?  savedCardsResponse.data.map((item) => {
                return <div key={item.id} className="radiobutton">
                  <label htmlFor="test8">
                    {/* <i><img src={require("../../../assets/images/visa-icon.png")} alt={item.card.brand} /></i> */}
                    <span style={{color:"#F58220",display:'inline'}}>{item.card.brand} </span>
                    xxxx-xxxx-xxxx-{item.card.last4}</label>
                  <img src={require("../../../assets/images/delete-icon-orange.svg")} alt="Delete" onClick={() => {
                    setDeleteId(item.id);
                    handleToggleDeleteModel()
                  }} />
                </div>
              }) :  <div className='no-data'>No Data Found...</div>
            }
            {/* <button type="button" className="btn btn-b-blue save-draft addcart-btn">ADD PAYMENT METHOD</button> */}
          </form>
        </div>
      </div>
    </div>
  )
}
export default PaymentMethod