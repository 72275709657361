/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./index.scss";

function NewRequestProgressBar() {
  const history = useHistory();

  const {
    step1Complete,
    step2Complete,
    step3Complete,
    step4Complete,
  } = useSelector((state) => state.clientRequest);
  useEffect(() => {
    if (step1Complete) {
    } else {
      history.push("/app/client/submitrequest");
    }
  }, [step1Complete]);
  return (
    <div className="new-request-progressbar">
      <div className="container">
        <ul className="progressbar-list">
          <li className="current">
            <span>Request Name</span>
          </li>
          <li className={step1Complete ? "current" : ""}>
            <span>Request Type</span>
          </li>
          <li className={step2Complete ? "current" : ""}>
            <span>Description</span>
          </li>
          <li className={step3Complete ? "current" : ""}>
            <span>Assets</span>
          </li>
          <li className={step4Complete ? "current" : ""}>
            <span>Submit</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default NewRequestProgressBar;
