import React,{ useState, useEffect } from "react";
import './index.scss';
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions as clientRequestActions } from "../../redux/reducers/NewRequestReducer";
import { actions as AuthActions } from "../../redux/reducers/AuthReducer";
import SearchableDropDown from '../searchableDropDown';
import { useCookies } from "react-cookie";
import LoaderComponent from "../Loader";
import { actions as generalActions } from "../../redux/reducers/GeneralReducer";
import ShortUniqueId from "short-unique-id";
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import config from "../../config/config";

const CheckoutForm = ({ closeModal }) => {
  const uid = new ShortUniqueId();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const { paymentSubmitData, selectedPlan, billingAddressId, cardList, plans} = useSelector(
    (state) => state.clientRequest
  );
  
  const handleSubmit = async (event) => { 
    event.preventDefault();
    if(!selectedPlan){
      dispatch(
        generalActions.addToast(
          "Required field",
          "Please select plan",
          "danger",
          uid()
        )
      );
      return;
    }
    if(!billingAddressId){
      dispatch(
        generalActions.addToast(
          "Required field",
          "Please add / select billing address",
          "danger",
          uid()
        )
      );
      return;
    } 
    if (elements == null) {
      return;
    }
    dispatch(clientRequestActions.clientRequestStepLoading(true));
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      dispatch(clientRequestActions.clientRequestStepLoading(false));
      dispatch(
        generalActions.addToast(
          "Payment Error",
          error.message,
          "danger",
          uid()
        )
      );
      console.log('[error]', error);
    } else {
      let submitPaymentData = paymentSubmitData;
      submitPaymentData['payment_method'] = JSON.stringify(paymentMethod);
      dispatch(
        clientRequestActions.setPaymentSubmitData(submitPaymentData)
      );
      dispatch(
        clientRequestActions.submitPaymentData(submitPaymentData, cookies.clientAccessToken)
      );
    }
  };

  const changePaymentSubmitData =(e)=>{
    let planType = false;
    plans.forEach(function(v){
      if(v.id == selectedPlan){
        if(v.plan_type == 'both'){
          planType = true;
        }
      }
    })
    if(!planType){
      let submitPaymentData = paymentSubmitData;
      submitPaymentData[e.target.name] = e.target.checked;
      dispatch(
        clientRequestActions.setPaymentSubmitData(submitPaymentData)
      );
    }
  }

  const closeCardModal =()=>{
    closeModal()
  }

  return (
    <form className="payment-form" onSubmit={handleSubmit}>
      <CardElement className="card-element" options={{ hidePostalCode: true }}/>
      <div className="form-group check-input">
                      <input id="16" class="custom-checkbox" type="checkbox" name="is_save_detail" onChange={changePaymentSubmitData}
                      checked={paymentSubmitData['is_save_detail']}/>
                      <label htmlFor="16" class="label">Securely save card details</label>
                    </div>
                    {plans.length && plans.filter(function (el) {
                      return el.id == selectedPlan;
                    }).length && plans.filter(function (el) {
                      return el.id == selectedPlan;
                    })[0].plan_type == "both" ? <span className="retainer-plan-note">The selected card will be charged automatically to renew the subscription. If you do not want the card to be auto charged please Cancel your subscription from the My Subscription section under My Accounts before the subscription expires.</span>: null} 
                    <div className="buttons">
                    <button style={{width: cardList.length == 0?"100%":"50%"}} class="btn btn-b-blue" type="submit" disabled={!stripe || !elements}>CONFIRM AND PAY</button>
                    
                   {cardList.length == 0 ? null: <button type="button" class="btn btn-b-blue" onClick={closeCardModal}>Cancel</button>}
                    </div>   
                         
      {/* <button className="btn btn-b-blue" type="submit" disabled={!stripe || !elements}>
        Pay
      </button> */}
    </form>
  );
};

const stripePromise = loadStripe(config.STRIPE_KEY)

function PlanBillingPayment() {

  const uid = new ShortUniqueId();
  const history = useHistory();
  const [showTab, setShowTab] = useState(1);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [months, setMonths] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']);
  const [yearList, setYearList] = useState([]);
  const [showCardModal, setShowCardModal] = useState(false);
  const [updateAddress, setUpdateAddress] = useState(false);
  const [addressId, setAddressId] = useState(false);
  const { plans, selectedPlan, addressSubmit, countryDropDown, stateDropDown, allStates, addressList, isAddressSaved, clientRequestLoading, 
    getAddressLoading, defaultAddress, paymentSubmitData, billingAddressId, redirectToSuccess, requestId, cardList, cardListLoading} = useSelector(
    (state) => state.clientRequest
  );
  const { user } = useSelector(
    (state) => state.auth
  );
  const [cookies] = useCookies(["clientAccessToken"]);
  const dispatch = useDispatch();
  
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      clientRequestActions.getCountryDropdown(cookies.clientAccessToken)
    );
    let submitAddress = addressSubmit;
    submitAddress['client_id'] = user.parent_id ? user.parent_id : user.id;
    dispatch(
      clientRequestActions.setAddress(submitAddress)
    );
    dispatch(
      clientRequestActions.getAddressList(cookies.clientAccessToken)
    );
    dispatch(
      clientRequestActions.getCardList(cookies.clientAccessToken)
    );
    var date = new Date().getFullYear();
    for(let i=0;i<100;i++){
      yearList.push(date);
      date = date + 1;
    }
    setYearList(yearList);
    if(!requestId){
      history.push("/app/client/submitrequest");
    }
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        // callMyFunction();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  useEffect(()=>{
    if(isAddressSaved){
      let submitAddress = addressSubmit;
      submitAddress['street'] = "";
      submitAddress['street_2'] = "";
      submitAddress['city'] = "";
      submitAddress['postcode'] = "";
      setUpdateAddress(false);
      setAddressId(false);
      dispatch(
        clientRequestActions.setAddress(submitAddress)
      );
      setShowAddressModal(false)
      dispatch(
        clientRequestActions.setAddressSaved(false)
      );
      dispatch(
        clientRequestActions.getAddressList(cookies.clientAccessToken)
      );
    }
  },[isAddressSaved])
  useEffect(()=>{ 
    if(getAddressLoading){
      setShowAddressModal(true)
      changeCountry(addressSubmit['country_id'])
      dispatch(
        clientRequestActions.getAddressLoading(false)
      );
    }
  },[getAddressLoading])
  useEffect(()=>{ 
    if(redirectToSuccess){
      dispatch(
        clientRequestActions.redirectToSuccess(false)
      );
      dispatch(AuthActions.getUserDetails(cookies.clientAccessToken));
      history.push("/app/client/requestsuccess");
    }
  },[redirectToSuccess])

  useEffect(()=>{
    if(cardList.length == 0){
      setShowCardModal(true)
    }else{
      setShowCardModal(false)
    }
  },[cardList])
  
  const backToRequestPage = ()=>{
    history.push("/app/client/submitrequest");
  }
  const changePlan = (id) =>{
    dispatch(
      clientRequestActions.setSelectedPlans(id)
    );
    let price, saveCardCheck;
    plans.forEach(function(v){
      if(v.id == id){
        price = v.price;
      }
    })
    let submitPaymentData = paymentSubmitData;
    submitPaymentData['amount'] = price;
    submitPaymentData['is_save_detail'] = true;
    dispatch(
      clientRequestActions.setPaymentSubmitData(submitPaymentData)
    );
  }
  const changeAddress = (e) =>{
    let submitAddress = addressSubmit;
    submitAddress[e.target.name] = e.target.value;
    dispatch(
      clientRequestActions.setAddress(submitAddress)
    );
  }
  const changeCountry = (val) =>{
    
    let submitAddress = addressSubmit;
    if(submitAddress['country_id'] !== val){
      submitAddress['state_id'] = '';
    }
    submitAddress['country_id'] = val;
    let countryCode;
    countryDropDown.forEach(function(v){
      if(v['id'] == val){
        countryCode = v['code'];
      }
    });
    let states = allStates.filter(function (el) {
      return el.country_code == countryCode;
    });
    dispatch(
      clientRequestActions.setAddress(submitAddress)
    );
    dispatch(
      clientRequestActions.setStateDropdown(states)
    );
  }

  const changeState=(val) =>{
    let submitAddress = addressSubmit;
    submitAddress['state_id'] = val;
    
    dispatch(
      clientRequestActions.setAddress(submitAddress)
    );
    
  }

  const submitAddress = ()=>{
    let errorArr = [];
    let errorArr1 = [];
    Object.keys(addressSubmit).map((key, index) => {
      if((key == "street" && !addressSubmit[key]) || (key == "city" && !addressSubmit[key]) || (key == "postcode" && !addressSubmit[key])){
        errorArr.push(key);
      }
      if((key == "state_id" && !addressSubmit[key])){
        errorArr1.push('State')
      }
      if((key == "country_id" && !addressSubmit[key])){
        errorArr1.push('Country')
      }
    });
    if(errorArr.length){
      dispatch(
        generalActions.addToast(
          "Required field",
          "Please enter "+errorArr.join(', '),
          "danger",
          uid()
        )
      );
      return;
    }

    if(errorArr1.length){
      dispatch(
        generalActions.addToast(
          "Required field",
          "Please select "+errorArr1.join(', '),
          "danger",
          uid()
        )
      );
      return;
    }
    
    
    if(updateAddress){
      dispatch(
        clientRequestActions.updateAddress(addressSubmit,addressId,cookies.clientAccessToken)
      );
    }else{
      dispatch(
        clientRequestActions.addNewAddress(addressSubmit,cookies.clientAccessToken)
      );
    }
  }

  const getAddress =(id)=>{
    setUpdateAddress(true);
    setAddressId(id);
    dispatch(
      clientRequestActions.getAddress(id,cookies.clientAccessToken)
    );
  }

  const setDefaultAddress = (e,id) =>{
    e.preventDefault();
    dispatch(
      clientRequestActions.selectBillingId(id)
    );
    // dispatch(
    //   clientRequestActions.changeDefaultAddress(id,cookies.clientAccessToken)
    // );
  }

  const addAddress = ()=>{
    let submitAddress = addressSubmit;
    submitAddress['street'] = "";
    submitAddress['street_2'] = "";
    submitAddress['city'] = "";
    submitAddress['postcode'] = "";
    submitAddress['country_id'] = "";
    submitAddress['state_id'] = "";
    dispatch(
      clientRequestActions.setAddress(submitAddress)
    );
    setShowAddressModal(true)
  }

  const changePaymentSubmitData =(e)=>{
    let submitPaymentData = paymentSubmitData;
    if(e.target.name == "is_save_detail"){
      submitPaymentData[e.target.name] = e.target.checked;
    }else if(e.target.name == "card_number"){
      if(e.target.value.length<17){
        submitPaymentData[e.target.name] = e.target.value;
      }
    }else{
      submitPaymentData[e.target.name] = e.target.value;
    }
    dispatch(
      clientRequestActions.setPaymentSubmitData(submitPaymentData)
    );
  }

  const changeCardID =(e,v)=>{
    e.preventDefault();
    let submitPaymentData = paymentSubmitData;
    submitPaymentData['card_id'] = v;
    dispatch(
      clientRequestActions.setPaymentSubmitData(submitPaymentData)
    );
  }

  const submitPaymentData =()=>{
    if(!selectedPlan){
      setShowTab(1)
      dispatch(
        generalActions.addToast(
          "Required field",
          "Please select plan",
          "danger",
          uid()
        )
      );
      return;
    }
    if(!paymentSubmitData['card_number']){
      setShowTab(3)
      dispatch(
        generalActions.addToast(
          "Required field",
          "Please enter credit card number",
          "danger",
          uid()
        )
      );
      return;
    }

    if(!paymentSubmitData['cvv']){
      setShowTab(3)
      dispatch(
        generalActions.addToast(
          "Required field",
          "Please enter cvv number",
          "danger",
          uid()
        )
      );
      return;
    } 

    if(!paymentSubmitData['month']){
      setShowTab(3)
      dispatch(
        generalActions.addToast(
          "Required field",
          "Please select month",
          "danger",
          uid()
        )
      );
      return;
    } 

    if(!paymentSubmitData['year']){
      setShowTab(3)
      dispatch(
        generalActions.addToast(
          "Required field",
          "Please select year",
          "danger",
          uid()
        )
      );
      return;
    } 

    if(!billingAddressId){
      setShowTab(2)
      dispatch(
        generalActions.addToast(
          "Required field",
          "Please add / select billing address",
          "danger",
          uid()
        )
      );
      return;
    } 

    dispatch(
      clientRequestActions.submitPaymentData(paymentSubmitData, cookies.clientAccessToken)
    );
  }

  const submitPaymentDataSavedCard =()=>{
    if(!selectedPlan){
      setShowTab(1)
      dispatch(
        generalActions.addToast(
          "Required field",
          "Please select plan",
          "danger",
          uid()
        )
      );
      return;
    }
    if(!billingAddressId){
      setShowTab(2)
      dispatch(
        generalActions.addToast(
          "Required field",
          "Please add / select billing address",
          "danger",
          uid()
        )
      );
      return;
    } 

    dispatch(
      clientRequestActions.submitPaymentDataSavedCard(paymentSubmitData, cookies.clientAccessToken)
    );
  }

  const closeCardModalFunction=()=>{
    setShowCardModal(false)
  }
  
  return (
    <div className="container cf request-container payment-container">
      <LoaderComponent showhide={clientRequestLoading || cardListLoading} />
    <div className={showTab == 1 || showTab == 2?"progressbar-line":"progressbar-line full-line"}></div>

      <div className="request-create-outer">
        <div className="request-accordian">
          <div className={showTab == 1?"accordian-title active":"accordian-title"}>
            <div className="left-content" onClick={() => {if(showTab == 2 || showTab == 3){
                    setShowTab(1)
                  }else{
                    setShowTab(2)
                  }}}>
              <h4>Select Plan</h4>
            </div>
            <div className="right-symbol">
            <a href={void(0)} className="circle-inside" onClick={() => {
                  if(showTab == 2 || showTab == 3){
                    setShowTab(1)
                  }else{
                    setShowTab(2)
                  }
                  }}></a>
            </div>
          </div>
          <div className={showTab == 1?"accordian-content":"accordian-content hide-accordian-slide"}>
           <div className="select-plan">
             <ul className={plans.length == 3?"plan-listing-threecolumn listing-block":"listing-block"}>
               {plans.map((data, index) => {
                 return (<li style={{cursor: 'pointer'}} key={'plan'+index} onClick={()=>{changePlan(data.id)}}>
                  <div className={selectedPlan == data.id?"inner-plan-block active":"inner-plan-block"}>
                  <div className="radiobutton" onClick={()=>{changePlan(data.id)}}>
                  <input type="radio" id={data.id} value={data.id} checked={selectedPlan == data.id} name="plans"></input>
                   <label htmlfor={data.id}></label>
                  </div>
                  <h5>{data.label}</h5>
                  <strong><sup>$</sup>{data.price}</strong>
                  <span>{data.name}</span>
                  <div dangerouslySetInnerHTML={{__html: data.description}} className="payment-plan-listing"></div>
                  {/* <button type="button" class="btn btn-b-blue" onClick={()=>{changePlan(data.id)}}>Select Plan</button> */}
                  </div>
                </li>)
               })
               }
             </ul>
             {selectedPlan && <p style={{textAlign: "center"}}><button type="button" class="btn btn-b-blue" onClick={() => {
                   setShowTab(2)
                  }}>PROCEED</button></p>}
           </div>
          </div>
        </div>


        <div className="request-accordian">
          <div className={showTab == 2?"accordian-title active":"accordian-title"}>
            <div className="left-content" onClick={() => {if(showTab == 1 || showTab == 3){
                    setShowTab(2)
                  }else{
                    setShowTab(1)
                  }}}>
              <h4>Billing Address</h4>
            </div>
            <div className="right-symbol">
            <a href={void(0)} className="circle-inside" onClick={() => {
                  if(showTab == 1 || showTab == 3){
                    setShowTab(2)
                  }else{
                    setShowTab(1)
                  }
                  }}></a>
            </div>
          </div>
          <div className={showTab == 2?"accordian-content":"accordian-content hide-accordian-slide"}>
            {!addressList.length && !showAddressModal && <div className="listing-block billing-address">
              <button type="button" class="btn btn-b-blue" onClick={addAddress}>ADD ADDRESS</button>
            </div>}

            {!showAddressModal && <div className="edit-address-block">
            {addressList.length > 0 && <button type="button" class="btn btn-b-blue" onClick={addAddress}>ADD NEW</button>}
              <ul>
                {addressList.map((data, index) => {
                  return (<li>
                    <div key={"add"+index} className="address-list">
                      <a className="editable-link" onClick={()=>{getAddress(data.id)}}>
                        <i><img src={require("../../assets/images/edit-icon.png")} alt="Edit" /></i>
                      </a>
                      <div className="radiobutton" onClick={(e)=>{setDefaultAddress(e,data.id)}}>
                        <input type="radio" id="test4" name="radio-group" checked={billingAddressId == data.id}></input>
                        <label for="test4">{data.street} {data.street_2} {data.city} {data.region_name} {data.postcode}</label>
                    </div>
                    </div>
                  </li>)
                })}
              </ul>
            </div>}

            {showAddressModal && <form className="add-address-form clearfix">
              <ul>
              <li>
                  <div className="form-group required">
                    <label>Street Address</label>
                    <input type="text" placeholder="Address Line 1" className="form-control" name="street" value={addressSubmit.street} onChange={changeAddress}></input>
                  </div>
                </li>
                <li>
                  <div className="form-group">
                    <label>&nbsp;</label>
                    <input type="text" placeholder="Address Line 2" className="form-control" name="street_2" value={addressSubmit.street_2} onChange={changeAddress}></input>
                  </div>
                </li>
                <li>
                <div className="form-group">
                  <label>Country</label>
                  <SearchableDropDown 
                    data={countryDropDown}
                    dataMapingString={{value: "name", key: "id"}}
                    onChange={(e) =>
                      {
                        changeCountry(e.target.value);           
                      }
                    }
                    classNames="select-box input-box"
                    value={addressSubmit.country_id}
                  />
              
                </div>
                </li>
                <li>
                  <div className="form-group required">
                    <label>City</label>
                    <input type="text" placeholder="Enter your city name" className="form-control" name="city" value={addressSubmit.city} onChange={changeAddress}></input>
                  </div>
                </li>
                <li>
                  <div className="form-group required">
                    <label>Zip/Postal Code</label>
                    <input type="number" placeholder="Enter your zip code" className="form-control" name="postcode" value={addressSubmit.postcode} onChange={changeAddress}></input>
                  </div>
                </li>
                <li>
                <div className="form-group required">
                  <label>State/Province</label>
                  <SearchableDropDown 
                    data={stateDropDown}
                    dataMapingString={{value: "name", key: "id"}}
                    onChange={(e) =>
                      {
                        changeState(e.target.value);           
                      }
                    }
                    classNames="select-box input-box"
                    value={addressSubmit.state_id}
                  />
                </div>
                </li>
              </ul>
              <button type="button" class="btn btn-b-blue" onClick={submitAddress}>SAVE</button>
              <button type="button" class="btn btn-b-blue btn-cancel" onClick={()=>{setShowAddressModal(false)}}>CANCEL</button>
            </form>}

            {billingAddressId && !showAddressModal && <p style={{textAlign: "center"}}><button type="button" class="btn btn-b-blue" onClick={() => {
                  setShowTab(3)
                  }}>PROCEED</button></p>}
          </div>
        </div>

        <div className="request-accordian">
          <div className={showTab == 3?"accordian-title active":"accordian-title"}>
            <div className="left-content" onClick={() => {if(showTab == 2 || showTab == 1){
                    setShowTab(3)
                  }else{
                    setShowTab(2)
                  }}}>
              <h4>Payment Method</h4>
            </div>
            <div className="right-symbol">
              <a href={void(0)} className="circle-inside" onClick={() => {
                    if(showTab == 2 || showTab == 1){
                      setShowTab(3)
                    }else{
                      setShowTab(2)
                    }
                    }}></a>
            </div>
          </div>
          {plans.length && plans.filter(function (el) {
                      return el.id == selectedPlan;
                    }).length && plans.filter(function (el) {
                      return el.id == selectedPlan;
                    })[0].type !== "FREE" || selectedPlan == null?<div className={showTab == 3?"accordian-content":"accordian-content hide-accordian-slide"}>
            {showCardModal && <div className="listing-block payment-method">
              <div className="payment-form">
                <span>Total Amount</span>
                <strong>${paymentSubmitData['amount']}</strong>
                <Elements stripe={stripePromise}>
                  <CheckoutForm closeModal={closeCardModalFunction}/>
                </Elements>
                {/* <form>
                    <div className="form-group required">
                      <label>Card Number</label>
                      <input type="text" placeholder="Please enter card number" className="form-control" name="card_number" 
                        value={paymentSubmitData['card_number']} onChange={changePaymentSubmitData}></input>
                    </div>
                    <div className="form-group date-select required">
                    <label>Expiration Date</label>
                    <select className="select-box" name="month" value={paymentSubmitData['month']} onChange={changePaymentSubmitData}>
                      <option value="">Select</option>
                      {months.map((data, index) => {
                        return (<option value={index+1}>{data}</option>)})}
                    </select>
                    <select className="select-box" name="year" value={paymentSubmitData['year']} onChange={changePaymentSubmitData}>
                      <option value="">Select</option>
                    {yearList.map((data, index) => {
                        return (<option value={data}>{data}</option>)})}
                    </select>
                    </div>
                    <div className="form-group required">
                      <label>CVV</label>
                      <input type="text" placeholder="CVV" className="form-control" name="cvv" value={paymentSubmitData['cvv']} onChange={changePaymentSubmitData}></input>
                    </div>
                    <div className="form-group check-input">
                      <input id="16" class="custom-checkbox" type="checkbox" name="is_save_detail" onChange={changePaymentSubmitData}
                      checked={paymentSubmitData['is_save_detail']}/>
                      <label htmlFor="16" class="label">Securely save card details</label>
                    </div>
                    <div className="buttons">
                    <button type="button" class="btn btn-b-blue" onClick={submitPaymentData}>CONFIRM AND PAY</button>
                    <button type="button" class="btn btn-b-blue" onClick={()=>{setShowCardModal(false)}}>Cancel</button>
                    </div>
                </form> */}
              </div>
            </div>}
            {!showCardModal &&<div className="listing-block payment-method">
            
              <div className="payment-form paymet-card-form">
                <span>Total Amount</span>
                <strong>${paymentSubmitData['amount']}</strong>
                {/* <Elements stripe={stripePromise}>
                  <CheckoutForm />
                </Elements> */}
                
                <form>
                    {cardList.length > 0 ? <h4>Preferred Payment</h4>:null}
                    {cardList.map((data, index) => {
                        return (<div key={'card'+index}>
                          <div className="radiobutton" onClick={(e)=>{changeCardID(e,data.id)}}>
                            <input type="radio" id="test8" name="radio-group" checked={paymentSubmitData['card_id'] == data['id']}></input>
                            <label htmlFor="test8"><span style={{color:"#F58220",display:'inline'}}>{data.card.brand}</span> xxxx-xxxx-xxxx-{data.card.last4}</label>
                          </div>
                          {paymentSubmitData['card_id'] == data['id'] && <div className="form-group date-select required pay-card">
                          {plans.length && plans.filter(function (el) {
                      return el.id == selectedPlan;
                    }).length && plans.filter(function (el) {
                      return el.id == selectedPlan;
                    })[0].plan_type == "both" ? <span className="retainer-plan-note">The selected card will be charged automatically to renew the subscription. If you do not want the card to be auto charged please Cancel your subscription from the My Subscription section under My Accounts before the subscription expires.</span>: null}
                            <button type="button" class="btn btn-b-blue" onClick={submitPaymentDataSavedCard}>PAY ${paymentSubmitData['amount']}</button>
                            
                          </div>}
                        </div>)
                      })}
                      
                    <button type="button" class="btn btn-b-blue save-draft addcart-btn" onClick={()=>{setShowCardModal(true)}}>ADD NEW CARD</button>
                </form>
                
              </div>
            </div>}
          </div>:<div className={showTab == 3?"accordian-content":"accordian-content hide-accordian-slide"}>
            <div className="freeplanblock">
            <h2>Hurray!!</h2>
            <p>No payment needed, enjoy your Free Plan</p>
          <button type="button" className="btn btn-black" onClick={submitPaymentDataSavedCard}>Submit</button>
            </div>
            
                  </div>}
        </div>
      </div> 

      <div class="actions-row">
        <button class="btn back" onClick={backToRequestPage}>Back</button>
        {/* <button type="button" class="btn btn-black next">Next</button>
        <button type="button" class="btn btn-b-blue save-draft">Save as Draft</button> */}
        </div> 
      
  </div>
  );
}

export default PlanBillingPayment;
