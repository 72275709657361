// Packages Import
import React, { memo, useMemo, useCallback } from 'react';
import { Button } from 'react-bootstrap';

// Compnent Imports
import Tag from "../tag";

// Style Imports
import "./FiltersTag.scss";

const FiltersTag = (props) => {
    // Variables
    const {
        tagsData = {},
        filterOptionsNames = [],
        onFilterOptionRemove,
        clearAllFilters
    } = props;
    const isSelectedAnyValue = useMemo(() => (
        Object.keys(tagsData)
            .some(key => ((typeof tagsData[key] === 'number' && tagsData[key]) || (typeof tagsData[key] === 'string' && tagsData[key]) || (Array.isArray(tagsData[key]) && tagsData[key]?.length)))
    ), [tagsData]);
    // Helper Functions
    /**
     * Function returns date string by changing seperator from `- (dash)` to `/ (slash)`
     * @param {string} date 
     * @returns {string}
     */
    const getFormatedDate = useCallback(
        (date) => {
            try {
                if (typeof date === 'string') {
                    const splitArray = date.split("-");
                    return `${splitArray[0]}/${splitArray[1]}/${splitArray[2]}`;
                }
            } catch (error) {
                console.log('error', error);
            }
            return '';
        },
        [],
    )

    /**
     * Function handles tag renderling logic depending on the data types.
     * @param {string, Array} data 
     * @param {string} category 
     * @param {Object} tagsData 
     * @returns {string}
     */
    const getTagsElement = (data, category, tagsData) => {
        try {
            if (category === 'start_date') {
                const startDate = getFormatedDate(data);
                const endDate = getFormatedDate(tagsData.end_date);

                const date = `${data ? startDate : ''} - ${tagsData.end_date ? endDate : ''}`;
                return <Tag key={`${category}-start_date`} tagLabel={date} onClickHandler={onFilterOptionRemove.bind(null, category)} />;
            }
            if (category === 'due_start_date') {
                const startDate = getFormatedDate(data);
                const endDate = getFormatedDate(tagsData.due_end_date);

                const date = `${data ? startDate : ''} - ${tagsData.due_end_date ? endDate : ''}`;
                return <Tag key={`${category}-start_date`} tagLabel={date} onClickHandler={onFilterOptionRemove.bind(null, category)} />;
            }
            if (Array.isArray(data)) {
                return (data.map(({ label, value }, num) => {
                    return <Tag key={`${value}-${num}`} tagLabel={label} onClickHandler={onFilterOptionRemove.bind(null, category, value)} />;
                }));
            }
            if (typeof data === 'string') {
                return <Tag key={`${data}`} tagLabel={data} onClickHandler={onFilterOptionRemove.bind(null, category, data)} />;
            }
            if (typeof data === 'number') {
                return <Tag key={`${data}`} tagLabel={"Yes"} onClickHandler={onFilterOptionRemove.bind(null, category, data)} />;
            }
        } catch (error) {
            console.log("Handled Error!", error);
        }
        return null;
    }

    const getProperCategoryName = useCallback(
        (category) => {
            switch (category) {
                case 'request_type':
                    return 'Request Type';

                case 'request_status':
                    return 'Status';

                case 'created_by':
                    return 'Created By';

                case 'assign_to':
                    return 'Designer To';

                case 'start_date':
                    return 'Created On';

                case 'end_date':
                    return 'Created On';

                case 'request_title':
                    return 'Request Title';

                case 'request_number':
                    return 'Request Number';

                case 'due_start_date':
                    return 'Due Date';

                case 'due_end_date':
                    return 'Due Date';

                case 'client_ids':
                    return 'Company';
                case 'has_updates':
                    return 'Has Updates';        
                default:
                    break;
            }
        },
        [],
    );

    return (
        <div id='tab-category-container'>
            {
                filterOptionsNames.map((category, index) => {
                    
                    const data = tagsData.hasOwnProperty(category) ? tagsData[category] : false;
                    
                    if(typeof data === 'number' && category == 'has_updates'){
                        return (
                            <span className='tag-category' key={`${category}-${index}`}>
                                <span className="tag-category-label">{getProperCategoryName(category)}:</span>
                                {
                                    getTagsElement(data, category, tagsData)
                                }
                            </span>
                        )
                    }

                    if (!data || !data?.length)
                        return null;

                    if (category === 'end_date' || category === 'due_end_date')
                        return null;
                    return (
                        <span className='tag-category' key={`${category}-${index}`}>
                            <span className="tag-category-label">{getProperCategoryName(category)}:</span>
                            {
                                getTagsElement(data, category, tagsData)
                            }
                        </span>
                    )
                })
            }
            {isSelectedAnyValue ? <Button
                id="clearAll"
                onClick={clearAllFilters}
            >
                Clear All
            </Button> : null}
        </div>
    );
};

export default memo(FiltersTag);
