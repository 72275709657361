import React, { memo } from 'react'
import PropTypes from 'prop-types';

/**
 * This is  Label function Component. 
 * @param {String} label  Label Pass String Value.
 * @param {String} htmlFor htmlFor Pass String Value.
 * @param {Boolean} required Required Pass Boolean Value.
 * @returns Label  HTML Element
 * @author Vishal Gohel <vishal.gohel@commercepundit.com>
 * 
 *  Notes:- Dec 9 2021 (Vishal Gohel <vishal.gohel@commercepundit.com>)  Component Created
 */

const Label = ({ label, htmlFor, required }) => (<label htmlFor={htmlFor} className={`label ${required ? "required" : ""}`}>{label}</label>)

Label.propTypes = {
    label: PropTypes.string,
    htmlFor: PropTypes.string,
    required: PropTypes.bool,
};

Label.defaultProps = {
    label: "",
    htmlFor: "",
    required: false
}

export default memo(Label)