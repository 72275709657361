import React from "react";
import { useSelector } from "react-redux";

import "./index.scss";
import Toast from "../Toast";

function ToastManager() {
  const { toasts } = useSelector((state) => state.general);
  return (
    <div className="toast-message-row">
      <div className="toast-message-box">
        {toasts.map((toast, index) => (
          <Toast
            key={toast.id}
            type={toast.type}
            heading={toast.heading}
            text={toast.text}
            id={toast.id}
          />
        ))}
      </div>
    </div>
  );
}

export default ToastManager;
