import React from "react";
import { Switch, Route } from "react-router-dom";

import "./index.scss";
import ClientsList from "../../components/ClientsList";
import ClientRequests from "../ClientRequests";

function AllClients() {
  return (
    <Switch>
      <Route exact path="/app/designer/allclients">
        <ClientsList />
      </Route>
      <Route path="/app/designer/allclients/clientrequests">
        <ClientRequests />
      </Route>
    </Switch>
  );
}

export default AllClients;
