import React from 'react'

export default function ModelConfirmation({handleToggleModel, isOpen, htmlElement}) {
    return (
        <div className={`image-popup-outer ${isOpen ? "show is-open-popup" : ""}`}>
            <div className="image-popup-inner">
                <span className="close-btn"  onClick={handleToggleModel}   ></span>
                {/* <div className="image-box">
                    <p>Are you sure you want to cancel</p>
                    <div class="buttons">
                        <button class="common-button orange-btn">NO</button>
                        <button class="common-button border-btn">YES</button>
                    </div>
                </div> */}
                {htmlElement}
            </div>
        </div>
    )
}
