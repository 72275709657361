import React, { memo } from "react";
import PaginationLinks from "./PaginationLinks";
import PaginationToolbar from "./PaginationToolbar";

const Pagination = ({ children, isLoading, paginationProps }) => (
  <>
    {children}
    <div className="d-flex justify-content-between align-items-center flex-wrap tbl-paginationblock">
      <PaginationLinks paginationProps={paginationProps} />
      <PaginationToolbar
        isLoading={isLoading}
        paginationProps={paginationProps}
      />
    </div>
  </>
);

export default memo(Pagination)