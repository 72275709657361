/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as designerRequestActions } from "../../redux/reducers/DesignerRequestReducer";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import LoaderComponent from "../Loader";

import "./index.scss";
import RequestListItem from "../RequestListItem";
import ClientRequestsHeader from "../../components/ClientRequestsHeader";
import { actions as AuthActions } from '../../redux/reducers/AuthReducer';

function MyOpenRequestList({ requestStatusSection }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const {
    myOpenRequestData,
    myOpenRequestDataLoading,
    filters,
    start,
    length,
  } = useSelector((state) => state.designerRequest);
  const { indicationStatus } = useSelector((state) => state.auth);
  const [ratingCopyTimeSpentFlag, setRatingCopyTimeSpentFlag] = useState(false);
  const [hideDeleteIcon, setHideDeleteIcon] = useState(true);
  const [initialStatus, setInitialStatus] = useState(false);
  let intervalID;
  useEffect(() => {
    dispatch(AuthActions.setUnderClientSection(false));
    dispatch(AuthActions.getTabIndicationStatus(null, cookies.clientAccessToken));
    getRequestList();
    if (requestStatusSection === "closed") {
      setRatingCopyTimeSpentFlag(true);
    } else {
      setRatingCopyTimeSpentFlag(false);
    }
  }, [requestStatusSection]);
  useEffect(() => {
    // dispatch(AuthActions.getTabIndicationStatus(null,cookies.clientAccessToken));
    setInitialStatus(indicationStatus);
    intervalID = setInterval(() => {
      dispatch(AuthActions.getTabIndicationStatus(null, cookies.clientAccessToken));
    }, 30000);
    return () => {
      clearInterval(intervalID)
    }
  }, []);
  useEffect(() => {
    if (initialStatus && indicationStatus) {
      if (initialStatus[requestStatusSection] !== indicationStatus[requestStatusSection]) {
        getRequestList();
        setInitialStatus(indicationStatus);
      }
    }
  }, [indicationStatus])
  const viewRequest = (id) => {
    dispatch(designerRequestActions.changeRequestId(id));
    // history.push("/app/designer/myopenrequest/viewrequest/" + id);
  };

  const viewRequestUrl = (id) => {
    return ("/app/designer/myrequest/viewrequest/" + id);
  };

  const loadMoreRequest = () => {
    let nextRecord = start + length; //10;
    dispatch(designerRequestActions.changeStartFromMyOpenRequest(nextRecord));
    if (requestStatusSection == "all-requests") {
      dispatch(
        designerRequestActions.getMyAllRequestList(
          filters,
          nextRecord,
          length,
          cookies.clientAccessToken
        )
      );
    }
    if (requestStatusSection == "open-requests") {
      dispatch(
        designerRequestActions.getOpenRequestList(
          filters,
          nextRecord,
          length,
          cookies.clientAccessToken
        )
      );
    }
    if (requestStatusSection == "wip") {
      dispatch(
        designerRequestActions.getMyWipRequestList(
          filters,
          nextRecord,
          length,
          cookies.clientAccessToken,
        )
      );
    }
    if (requestStatusSection == "priortize") {
      dispatch(
        designerRequestActions.getWipRequestList(
          filters,
          nextRecord,
          length,
          cookies.clientAccessToken
        )
      );
    }
    if (requestStatusSection == "needs-information") {
      dispatch(
        designerRequestActions.getMyNeedsInfoRequestList(
          filters,
          nextRecord,
          length,
          cookies.clientAccessToken
        )
      );
    }
    if (requestStatusSection == "closed") {
      dispatch(
        designerRequestActions.getMyClosedRequestList(
          filters,
          nextRecord,
          length,
          cookies.clientAccessToken
        )
      );
    }
    if (requestStatusSection == "needs-approval") {
      dispatch(
        designerRequestActions.getMyDeliveredRequestList(
          filters,
          nextRecord,
          length,
          cookies.clientAccessToken
        )
      );
    }
    // getRequestList();
  };
  const getRequestList = () => {
    if (requestStatusSection == "all-requests") {
      dispatch(
        designerRequestActions.getMyAllRequestList(
          filters,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    }
    if (requestStatusSection == "open-requests") {
      dispatch(
        designerRequestActions.getOpenRequestList(
          filters,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    }
    if (requestStatusSection == "wip") {
      dispatch(
        designerRequestActions.getMyWipRequestList(
          filters,
          0,
          length,
          cookies.clientAccessToken,
        )
      );
    }
    if (requestStatusSection == "priortize") {
      dispatch(
        designerRequestActions.getWipRequestList(
          filters,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    }
    if (requestStatusSection == "needs-information") {
      dispatch(
        designerRequestActions.getMyNeedsInfoRequestList(
          filters,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    }
    if (requestStatusSection == "closed") {
      dispatch(
        designerRequestActions.getMyClosedRequestList(
          filters,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    }
    if (requestStatusSection == "needs-approval") {
      dispatch(
        designerRequestActions.getNeedsApprovalRequestList(
          filters,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    }

  }
  const handleFilterChange = (id, isSold = false) => {
    let tempFilter = filters;
    if (id) {
      tempFilter['request_status'] = id;
      tempFilter['is_sold'] = isSold ? 1 : 0
    } else {
      delete tempFilter.request_status;
      delete tempFilter.is_sold;
    }
    dispatch(designerRequestActions.updateFilters(tempFilter));
    dispatch(designerRequestActions.changeStartFromMyOpenRequest(0));
    getRequestList();
  }
  return (
    <div>
      <LoaderComponent showhide={myOpenRequestDataLoading} />
      {myOpenRequestData['request_status'] && myOpenRequestData['request_status'].length && requestStatusSection !== "all-requests" ? <div className="container cf sub-status" style={{ marginBottom: "40px", marginTop: "0" }}>
        {myOpenRequestData['request_status'] && myOpenRequestData['request_status'].length && requestStatusSection !== "all-requests" ?
          <button className={"btn" + (filters && filters['request_status'] === "" || !filters['request_status'] ? " active" : "")} onClick={() => { handleFilterChange("") }}>All</button> : null}
        {myOpenRequestData?.request_status?.map((data, index) => (
          <>
            <button
              key={"status" + data.id}
              className={"btn" + (filters?.request_status === data.id && !filters?.is_sold ? " active" : "")}
              onClick={() => { handleFilterChange(data.id) }}>
              {data.name === 'Approve' ? 'Approved' : data.name === 'Close' ? 'Closed' : data.name}
            </button>
            {data?.name === 'Approve' ?
              <button
                key={"status" + data.id}
                className={"btn" + (filters?.request_status === data.id && filters?.is_sold ? " active" : "")}
                onClick={() => { handleFilterChange(data.id, true) }}>
                SOLD
              </button>
              : null
            }
          </>
        ))}
      </div> : null}
      <ClientRequestsHeader
        showClientName={false}
        myOpenRequestData={myOpenRequestData}
        requestDelivered={false}
        requestStatusSection={requestStatusSection}
      />
      <RequestListItem
        ratingCopyTimeSpentFlag={ratingCopyTimeSpentFlag}
        data={myOpenRequestData}
        loadMoreRequest={loadMoreRequest}
        viewRequest={viewRequest}
        viewRequestUrl={viewRequestUrl}
        hideDeleteIcon={hideDeleteIcon}
        requestStatusSection={'myopenrequest'}
        isAllPriortise={false}
      />
    </div>
  );
}

export default MyOpenRequestList;
