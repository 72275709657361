import React, {useState} from "react";
import { useDispatch } from "react-redux";

import "./index.scss";
import { actions as clientRequestActions } from "../../redux/reducers/ClientRequestReducer";

function AppliancePopup({
  show,
  closePopup,
  applianceObject,
  step3OptionValues,
  looseApplianceObject,
}) {
  const dispatch = useDispatch();
  const [disabledValue, setDisbaled] = useState(false);
  const [optionId, setOptionId] = useState(1);
  function closePopupi() {
    closePopup(false);
  }

  const handleFormValueChange = (
    value,
    categoryName,
    optionId,
    type,
    indexChild
  ) => {
    if(type=== 'checkbox'){
      setOptionId(optionId);
      if(value === false)
      setDisbaled(true);
      else {
      setDisbaled(false);
    }
  }
    dispatch(
      clientRequestActions.changeStep3OptionsValue(
        value,
        categoryName,
        optionId,
        type,
        indexChild,
        true
      )
    );
  };
  const renderForm = (object,keyObject) => {
    let formValueOptions;
    if (step3OptionValues) {
      if (step3OptionValues[keyObject] && step3OptionValues[keyObject]["data"]) {
        formValueOptions = step3OptionValues[keyObject]["data"];
        formValueOptions = [...formValueOptions];
      }
    }
    if (object) {
      return (
        <ul className="appliances-list">
          {object["data"].map((data, index) => {
            let valueData = null;
            if (formValueOptions) {
              let valueDataIndex = formValueOptions.findIndex(
                (x) => x.category_option_id === data.category_option_id
              );
              if (valueDataIndex !== -1) {
                valueData = formValueOptions[valueDataIndex];
              }
            }   
            if(data.is_others !== "1"){      
            return (
              <li key={index}>
                <div className="check-box title">
                  <input
                    id="range"
                    className="custom-checkbox"
                    type="checkbox"
                    checked={
                      valueData && valueData.category_option_value
                        ? valueData.category_option_value
                        : false
                    }
                    onChange={(e) =>
                      handleFormValueChange(
                        e.target.checked,
                        keyObject,
                        data.category_option_id,
                        "checkbox"
                      )
                    }
                  />
                  <label htmlFor="range" className="label">
                    {data["category_option_name"].indexOf("-") !== -1
                      ? data["category_option_name"].substr(
                          0,
                          data["category_option_name"].indexOf("-")
                        )
                      : data["category_option_name"]}
                  </label>
                </div>
                <div className="brand-and-size">
                  <div className="brand-model">
                    <label className="label" htmlFor="brandmodel_r">
                      {data["child_data"][0]["name"]}:
                    </label>
                    <input
                      type="text"
                      className="input-box"
                      value={
                        valueData &&
                        valueData.child_data &&
                        valueData.child_data[0]
                          ? valueData.child_data[0].value
                          : ""
                      }
                      disabled={
                        (optionId ===  data.category_option_id &&  disabledValue )|| !(valueData && valueData.category_option_value)
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        handleFormValueChange(
                          e.target.value,
                          keyObject,
                          data["category_option_id"],
                          keyObject,
                          0
                        )
                      }
                    />
                  </div>
                  <div className="dimension-row">
                    <div className="dimension-box inches-box">
                      <label className="label" htmlFor="depth_r">
                        {data["child_data"][1]["name"]}:
                      </label>
                      <input
                        type="number"
                        className="input-box inch-input"
                        min="0"
                        value={
                          valueData &&
                          valueData.child_data &&
                          valueData.child_data[1]
                            ? valueData.child_data[1].value  && Math.max(0, valueData.child_data[1].value)
                            : ""
                        }
                        disabled={
                          (optionId ===  data.category_option_id &&  disabledValue )|| !(valueData && valueData.category_option_value)
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          handleFormValueChange(
                            e.target.value,
                            keyObject,
                            data["category_option_id"],
                            keyObject,
                            1
                          )
                        }
                      />
                      <span className="inches">inches</span>
                    </div>
                    <div className="dimension-box inches-box">
                      <label className="label" htmlFor="width_r">
                        {data["child_data"][2]["name"]}:
                      </label>
                      <input
                        type="number"
                        className="input-box inch-input"
                        min="0"
                        value={
                          valueData &&
                          valueData.child_data &&
                          valueData.child_data[2]
                            ? valueData.child_data[2].value && Math.max(0, valueData.child_data[2].value) 
                            : ""
                        }
                        disabled={
                          (optionId ===  data.category_option_id &&  disabledValue )|| !(valueData && valueData.category_option_value)
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          handleFormValueChange(
                            e.target.value,
                            keyObject,
                            data["category_option_id"],
                            keyObject,
                            2
                          )
                        }
                      />
                      <span className="inches">inches</span>
                    </div>
                    <div className="dimension-box inches-box">
                      <label className="label" htmlFor="height_r">
                        {data["child_data"][3]["name"]}:
                      </label>
                      <input
                        type="number"
                        className="input-box inch-input"
                        min="0"
                        value={
                          valueData &&
                          valueData.child_data &&
                          valueData.child_data[3]
                            ? valueData.child_data[3].value && Math.max(0, valueData.child_data[3].value) 
                            : ""
                        }
                        disabled={
                          (optionId ===  data.category_option_id &&  disabledValue )|| !(valueData && valueData.category_option_value)
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          handleFormValueChange(
                            e.target.value,
                            keyObject,
                            data["category_option_id"],
                            keyObject,
                            3
                          )
                        }
                      />
                      <span className="inches">inches</span>
                    </div>
                  </div>
                </div>
              </li>
            );
                      }
          })}
        </ul>
      );
    }
  };
  return (
    <div
      style={{ display: show ? "block" : "none" }}
      className="appliances-popup-row"
    >
      <div className="appliances-popup">
        <span onClick={closePopupi} className="close-btn"></span>
        <h3>Appliances</h3>
        <p>
          Please select your preferred non-standard appliances and submit the
          respective measurements for the same below.
        </p>
        {renderForm(applianceObject,"appliances")}
        {renderForm(looseApplianceObject,"loose-appliances")}
        <div className="actions-row">
          <button className="btn cancel" onClick={closePopup}>
            Cancel
          </button>
          <button className="btn btn-black submit" onClick={closePopup}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default AppliancePopup;
