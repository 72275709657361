
export const getFormattedDate = (d) => {
  var date = new Date(d),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-")
}

export const getExtensionByUrl = ( url ) =>{
  return url.split(/[#?]/)[0].split('.').pop().trim();
}