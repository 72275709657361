export const clientReportDetails = {
    CLIENT_REPORT_DETAILS_INIT: 'CLIENT_REPORT_DETAILS_INIT',
    CLIENT_REPORT_DETAILS_SUCCESS : 'CLIENT_REPORT_DETAILS_SUCCESS',
    CLIENT_REPORT_DETAILS_FAILURE: 'CLIENT_REPORT_DETAILS_FAILURE'
}
export const clientProductivityRatio = {
    GET_CLIENT_PRODUCTIVITY_RATIO_INIT : "GET_CLIENT_PRODUCTIVITY_RATIO_INIT",
    GET_CLIENT_PRODUCTIVITY_RATIO_SUCCESS : "GET_CLIENT_PRODUCTIVITY_RATIO_SUCCESS",
    GET_CLIENT_PRODUCTIVITY_RATIO_FAILURE : "GET_CLIENT_PRODUCTIVITY_RATIO_FAILURE"
}
export const clientTotalRequestSubmitted = {
    GET_CLIENT_TOTAL_REQUESTS_SUBMITTED_INIT : "GET_CLIENT_TOTAL_REQUESTS_SUBMITTED_INIT",
    GET_CLIENT_TOTAL_REQUESTS_SUBMITTED_SUCCESS : "GET_CLIENT_TOTAL_REQUESTS_SUBMITTED_SUCCESS",
    GET_CLIENT_TOTAL_REQUESTS_SUBMITTED_FAILURE : "GET_CLIENT_TOTAL_REQUESTS_SUBMITTED_FAILURE"
}